import {Editor} from "tinymce";
import {Root} from "react-dom/client";
import {AiQueryWidgetConfig} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetConfig";
import {DesignerStore} from "controls/designer/designerStore";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {makeAutoObservable} from "mobx";
import {createTinymceEditor} from "vendor-init/tinymce";
import {CustomDataListWindowStore} from "controls/designer/features/widgets/custom-data-list/customDataListWindowStore";
import {AntButton} from "controls/react/ant/antButton";
import {PlusOutlined} from "@ant-design/icons";
import {AntModal, createContainerRoot, ModalPosition} from "controls/react/ant/antModal";
import {Section} from "controls/react/layout";
import {AiQueryWidgetOutput} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidget";
import {CustomDataListEntry} from "controls/designer/features/widgets/custom-data-list/customDataListEntry";
import {newGuid} from "tools/guid";
import {toStringTimeSelection} from "controls/react/form/timePeriodSelector";
import {apiFetch} from "framework/api";
import {getHealthInfo} from "controls/designer/api";
import {createNode} from "tools/helpers/dom";
import {formatNumber} from "tools/helpers/math";
import {AiQueryWidgetChat} from "controls/designer/features/widgets/ai-query-widget/aiQueryWidgetChat";
import React from "react";
import {sharedLocalization} from "controls/designer/features/widgets/localization";

const i18n = require('core/localization').translator({
  "Click to add to AI query": {
    "no": "Klikk for legge til en AI spørring"
  },
  "Value not found": {
    "no": "Fant ikke verdi"
  }
},
sharedLocalization);

const supportedWidgets = ['metric-single-graph', 'metric-barchart', 'metric-gauge',
	'metric-table', 'metric-stacked-chart', 'metric-sparkline-table', 'metric-single-graph', 'metric-multi-graph-asset']

export class AiQueryWidgetStore {
	queryEditor: Editor
	outputEditor: Editor
	chatRoot: Root
	outputRoot: Root
	updatingQuery = false
	showTextOutputInfo = false

	constructor(public config: AiQueryWidgetConfig, public designerStore: DesignerStore, public cell?: MxCell) {
		makeAutoObservable(this)
		if(!this.config.output){
			this.showTextOutputInfo = true
		}
	}

	setQueryContainerRef = (x: HTMLDivElement) => {
		if (!x) {
			this.queryEditor?.destroy()
			return
		}

		requestIdleCallback(async () => {
			const initialValue = this.config.query

			const editors = await createTinymceEditor({
				target: x,
				min_height: 200,
				setup: (ed: Editor) => {
					ed.on('Paste Change input Undo Redo NodeChange', () => {
						this.config.query = ed.getContent()
					})
				},
				height: 200,
				menu: {},
				toolbar: '',
				menubar: '',
				contextmenu: '',
				statusbar: false
			})

			this.queryEditor = editors[0]
			this.queryEditor.setContent(initialValue ?? '')
		})
	}

	setOutputContainerRef = (x: HTMLDivElement) => {
		if (!x) {
			this.outputEditor?.destroy()
			return
		}

		requestIdleCallback(async () => {
			const initialValue = this.config.output

			const editors = await createTinymceEditor({
				target: x,
				min_height: 200,
				setup: (ed: Editor) => {
					ed.on('Paste Change input Undo Redo NodeChange', () => {
						this.setTextOutput(ed.getContent())
					})
					ed.on('Focus', () => {
						this.showTextOutputInfo = false
					})
				},
				toolbar: 'undo redo | styleselect | fontsizeselect | bold italic | numlist bullist | alignleft aligncenter alignright alignjustify | outdent indent',
				menubar: '',
				height: 200,
				statusbar: false
			})

			this.outputEditor = editors[0]
			this.outputEditor.setContent(initialValue ?? '')
		})
	}

	customDataListWindowStore: CustomDataListWindowStore

	showCustomDataList = () => {
		if (this.customDataListWindowStore == null) {
			this.customDataListWindowStore = new CustomDataListWindowStore(this.designerStore, {
				dataSourcesFilter: item => {
					if (item.dataSource?.type == "metric" || item.dataSource?.type == 'cost') {
						return true
					}

					return item.widgetConfig?.type && supportedWidgets.indexOf(item.widgetConfig.type) != -1;
				},
				actions: (item) =>
					<AntButton icon={<PlusOutlined/>}
					           title={i18n('Click to add to AI query')}
					           type={'text'} onClick={() => this.insertEntry(item)}/>
			})
		}

		this.customDataListWindowStore.open()
	}

	showOutput = () => {
		if (this.outputRoot != null)
			return

		this.outputRoot = createContainerRoot()

		const geometry = this.designerStore.legacyDesigner.getCellAbsolutePosition(this.cell)

		const width = 600
		const left = Math.min(geometry.x + geometry.width + 20, window.innerWidth - width)

		const top = geometry.y

		const style = {
			top: top,
			left: left
		}

		this.outputRoot.render(
			<AntModal footer={null}
			          width={width}
			          height={400}
			          style={style}
			          open={true}
			          resizable={true}
			          positionType={ModalPosition.Custom}
			          onCancel={() => this.outputRoot?.unmount()}>
				<Section title={this.config.title} appearance={"none"} contentPadding={true}>
					<AiQueryWidgetOutput store={this}/>
				</Section>
			</AntModal>
		)
	}

	insertEntry = async (item: CustomDataListEntry) => {
		if (item.customDataId == null)
			return

		let customData = this.designerStore.customData
			.find(x => x.id == item.id)

		if (!customData) {
			console.warn('Custom data should not be empty')
			return
		}

		let id = newGuid()

		if (customData.dataSource?.type == 'metric') {
			this.config.dataSources.push({
				id,
				metricId: item.customDataId
			})
		} else if (customData.dataSource?.type == 'cost') {
			const dataSource = customData.dataSource

			this.config.dataSources.push({
				id,
				costProfileId: dataSource.costProfileId,
				costItemId: dataSource.costItemId,
				valueType: dataSource.valueType,
				timePeriod: toStringTimeSelection(dataSource.timePeriod),
				currency: dataSource.currency,
				costModelId: dataSource.modelId
			})

		} else if (customData.widgetConfig) {
			const config = customData.widgetConfig
			switch (config.type) {
				case 'metric-barchart':
				case 'metric-gauge':
				case 'metric-table':
				case "metric-stacked-chart":
				case "metric-sparkline-table":
				case 'metric-single-graph':
				case "metric-multi-graph-asset":
					this.config.dataSources.push({
						id,
						metricId: item.customDataId
					})
					break
			}
		}

		this.queryEditor.insertContent(`<span data-data-source-id='${id}' title="${item.customDataName}" style="color: #1677ff">{a value will be here}</span> `)

		await this.updateQueryWithActualValues()
	}

	async getActualValues() {
		const response = await apiFetch(getHealthInfo(this.config.dataSources))

		if (response.success) {
			return response.data
		}

		return []
	}

	async updateQueryWithActualValues() {
		if (!this.config.query)
			return

		this.updatingQuery = true

		const values = await this.getActualValues()

		const rootNode = createNode('<div>' + this.config.query + '</div>')

		this.config.dataSources.forEach(x => {
			const entryNode = rootNode.querySelector(`[data-data-source-id="${x.id}"]`)
			if (entryNode) {
				const valueHolder = values.find(v => x.id == v.id)
				if (valueHolder) {
					if ('metricId' in valueHolder) {
						if (valueHolder.metricInfo.data.length) {
							entryNode.innerHTML = formatNumber(valueHolder.metricInfo.data[0].v, 2)
						} else {
							entryNode.innerHTML = i18n('Value not found')
						}
					} else if ('costProfileId' in valueHolder) {
						entryNode.innerHTML = formatNumber(valueHolder.value, 2)
					} else {
						entryNode.innerHTML = 'COST VALUE'
					}
				}
			}
		})

		this.updatingQuery = false

		if (this.queryEditor == null) {
			this.config.query = rootNode.innerHTML
		} else {
			this.queryEditor.setContent(rootNode.innerHTML)
		}
	}

	openChat = async () => {
		if (this.chatRoot != null)
			return

		this.chatRoot = createContainerRoot()

		let left: number = null
		let top: number = null
		const width = 600

		if (this.cell != null) {
			const geometry = this.designerStore.legacyDesigner.getCellAbsolutePosition(this.cell)

			left = Math.min(geometry.x + geometry.width + 20, window.innerWidth - width)
			top = geometry.y
		}

		if (this.config.query) {
			await this.updateQueryWithActualValues()
		}

		this.chatRoot.render(
			<AiQueryWidgetChat title={this.config.title}
			                   width={width}
			                   top={top}
			                   left={left}
			                   query={this.config.query}
			                   onSetOutput={this.outputEditor ? this.setLastOutput : null}
			                   onClose={this.closeChat}/>
		)
	}

	setLastOutput = (lastOutput: string) => {
		this.outputEditor.setContent(lastOutput)
		this.setTextOutput(this.config.output = lastOutput)
	}


	setTextOutput = (content: string)=> {
		this.config.output = content
		if(content){
			this.showTextOutputInfo = false
		}
	}

	closeChat = () => {
		this.chatRoot?.unmount()
		this.chatRoot = null
	}

	destroy() {
		this.queryEditor?.destroy()
		this.outputEditor?.destroy()
		this.chatRoot?.unmount()
		this.outputRoot?.unmount()
	}
}
