import {Localization} from 'core/localization';

export const sharedLocalization = new Localization({
  "Summary": {
    "no": "Oversikt"
  },
  "Summary grid": {
    "no": "Sammendrag tabell"
  },
  "Grouped summary": {
    "no": "Gruppert sammendrag"
  },
  "History": {
    "no": "Historie"
  },
  "Selective Services": {
    "no": "Utvalgte tjenester",
    "en": "Selective services"
  },
  "Single Graph": {
    "no": "Enkel graf",
    "en": "Single graph"
  },
  "Multigraph (Asset)": {
    "no": "Multigraf (ressurs)",
    "en": "Multigraph (asset)"
  },
  "Multigraph (Asset Group)": {
    "no": "Multigraf (ressursgruppe)",
    "en": "Multigraph (assetgroup)"
  },
  "Stacked Chart": {
    "no": "Stablet",
    "en": "Stacked"
  },
  "Experimental": {
    "no": "Eksperimentell"
  },
  "Console": {
    "no": "Konsoll"
  },
  "Web Page": {
    "no": "Nettside",
    "en": "Webpage"
  },
  "Cloud": {
    "no": "Sky"
  },
  "Multi Graph": {
    "no": "Multigraf",
    "en": "Multigraph"
  },
  "Grid": {
    "no": "Tabell"
  },
  "Bar Chart": {
    "no": "Søylediagram",
    "en": "Bar chart"
  },
  "Cost": {
    "no": "Kostnad"
  },
  "Instances": {
    "no": "Instanser"
  },
  "Time widget": {
    "no": "Tidswidget",
    "en": "Time widget"
  },
  "Edit widget": {
    "no": "Rediger widget",
    "en": "Edit widget"
  },
  "Table": {
    "no": "Tabell"
  },
  "Cost Profile": {
    "no": "Kostprofil",
    "en": "Cost profile"
  },
  "Cost Model": {
    "no": "Kostnad modell",
    "en": "Cost model"
  },
  "Instances Grid": {
    "no": "Instans tabell",
    "en": "Instance grid"
  },
  "Linear gauge": {
    "no": "Lineær måler",
    "en": "Linear gauge"
  },
  "Bubble Chart": {
    "no": "Boblediagram",
    "en": "Bubble chart"
  },
  "GPS": {
    "no": "GPS"
  },
  "GPS Widget": {
    "no": "GPS widget",
    "en": "GPS widget"
  },
  "Widget could not be loaded": {
    "no": "Widgeten ble ikke lastet"
  },
  "Cost Grid": {
    "no": "Kosttabell",
    "en": "Cost grid"
  },
  "Datasource grid": {
    "no": "Datakilde tabell"
  },
  "Cloud Budget": {
    "no": "Sky budsjett",
    "en": "Cloud budget"
  },
  "Spent": {
    "no": "Brukt"
  },
  "Spent %": {
    "no": "Brukt %"
  },
  "Sparkline Table": {
    "no": "Sparkline tabell",
    "en": "Sparkline table"
  },
  "Profile": {
    "no": "Profil"
  },
  "Chart settings": {
    "no": "Graf innstillinger"
  },
  "Currency": {
    "no": "Valuta"
  },
  "Choose an asset group...": {
    "no": "Velg en ressursgruppe...",
    "en": "Choose an assetgroup..."
  },
  "Add widget": {
    "no": "Opprett widget"
  },
  "AI Query": {
    "no": "AI spørring"
  },
  "Costmodel/store/sheet is not found or has been deleted.": {
    "no": "Kostmodell/base/fane ble ikke funnet/er slettet."
  },
  "Datasource chart": {
    "no": "Datakildediagram"
  },
  "Decimal Numbers": {
    "no": "Desimalnummer"
  },
  "Hide time selector": {
    "no": "Skjul tidsvelger"
  }
});
