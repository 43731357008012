import {WidgetConfig} from "controls/designer/features/widgets/widgetConfig";
import {AntCheckboxOptionType} from "controls/react/ant/antCheckbox";
import {HealthRequestEntry} from "controls/designer/api";

const i18n = require('core/localization').translator({
  "Text": {
    "no": "Tekst"
  },
  "Icon": {
    "no": "Ikon"
  }
});

export enum AiWidgetDisplayMode{
	Text = "text",
	Icon = "icon",
}

let aiWidgetDisplayModeOptions: AntCheckboxOptionType[]
export function getAiWidgetDisplayModeOptions(){
	if(aiWidgetDisplayModeOptions == null){
		aiWidgetDisplayModeOptions = [{
			label: i18n("Icon"),
			value: AiWidgetDisplayMode.Icon
		},{
			label: i18n("Text"),
			value: AiWidgetDisplayMode.Text
		}]
	}

	return aiWidgetDisplayModeOptions
}

export interface AiQueryWidgetConfig extends WidgetConfig {
	displayMode: AiWidgetDisplayMode
	showChatIcon: boolean
	dataSources: HealthRequestEntry[]
	query: string
	output: string
}
