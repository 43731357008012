import {HealthDataHolder} from "framework/entities/healthData";
import {Geotag} from "framework/entities/geotag";
import {TimeSelectionString} from "controls/react/form/timePeriodSelector";
import {ApplicationState} from "framework/applicationState";
import {MonitorType} from "areas/assets/monitors/monitorType";
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition";
import {MetricValue} from "framework/entities/metricValue";

import {extractMetricSubscriptionFields} from "areas/service-boards/widgets/customMetrics/utils";

export enum EventType {
	Geolocation = 'Geolocation',
	AssetHealth = 'AssetHealth',
	CostBudget = 'CostBudget',
	CostReport = 'CostReport',
	ServiceStatus = 'ServiceStatus',
	ServiceAdmin = 'ServiceAdmin',
	ServiceSummary = 'ServiceSummary',
	ServiceModel = 'ServiceModel',
	EventSummary = 'EventSummary',
	MonitorHealth = 'MonitorHealth',
	Administration = 'Administration',
	Metric = 'Metric',
	ApplicationHealth = 'ApplicationHealth',
	Sla = 'Sla',
	State = 'State'
}

export class Event {
	wId: string;
	eventId: string;
	eventType: EventType
	time: number;
}

type AssetHealthEventArgs = {
	assetIds?: string[],
	assetId?: string,
	includeSubaccounts?: boolean,
	accountId?: string
}

export class AssetHealthEvent extends Event{
	assetHealth: {
		assetId: string;
	} & HealthDataHolder
	//those are not all fields, i've added only ones I used

	static is(e: Event): e is AssetHealthEvent{
		return e.eventType == EventType.AssetHealth;
	}

	static subscription(args?: AssetHealthEventArgs){
		return {
			eventType: EventType.AssetHealth,
			assetIds: args?.assetIds,
			assetId: args?.assetId,
			includeSubaccounts: args?.includeSubaccounts ?? ApplicationState.includeSubaccounts,
			accountId: args?.accountId ?? ApplicationState.accountId
		}
	}
}

export class GeolocationEvent extends Event{
	assetId: string;
	geotag: Geotag;

	static is(e: Event): e is GeolocationEvent{
		return e.eventType == EventType.Geolocation;
	}

	static subscription(assetIds: string[]){
		return {
			eventType: EventType.Geolocation,
			assetIds: assetIds
		}
	}
}

export class CostBudgetEvent extends Event{
	modelId: string;

	static is(e: Event): e is CostBudgetEvent{
		return e.eventType == EventType.CostBudget;
	}

	static subscription(modelId: string, itemIds?: string[] ){
		return {
			eventType: EventType.CostBudget,
			modelId: modelId,
			itemIds: itemIds
		}
	}
}

export class CostReportEvent extends Event{
	modelId: string;
	startDate: string;
	endDate: string;

	static is(e: Event): e is CostReportEvent{
		return e.eventType == EventType.CostReport;
	}

	static subscription(modelId: string, timePeriod: TimeSelectionString ){
		return {
			eventType: EventType.CostReport,
			modelId: modelId,
			timePeriod: timePeriod
		}
	}
}

export type ServiceStatusEventParams = {
	accountId?: string
	includeSubaccounts?: boolean
	serviceIds?: string[]
}

export class ServiceStatusEvent extends Event{
	accountId: string;
	includeSubaccounts: boolean;

	static is(e: Event): e is ServiceStatusEvent{
		return e.eventType == EventType.ServiceStatus;
	}

	static subscription(params: ServiceStatusEventParams){
		return {
			eventType: EventType.ServiceStatus,
			accountId: params.accountId,
			includeSubaccounts: params.includeSubaccounts,
			serviceIds: params.serviceIds
		}
	}
}

export class ServiceAdminEvent extends Event{
	actionTypes: string[];
	accountId: string;
	includeSubaccounts: boolean;

	static is(e: Event): e is ServiceAdminEvent {
		return e.eventType == EventType.ServiceAdmin;
	}

	static subscription(actionTypes: string[], accountId: string, includeSubaccounts: boolean){
		return {
			eventType: EventType.ServiceAdmin,
			actionTypes: actionTypes,
			accountId: accountId,
			includeSubaccounts: includeSubaccounts
		}
	}
}

export type ServiceModelEventAction = 'PROPERTIES_UPDATE' | 'MODEL_UPDATE'

export class ServiceModelEvent extends Event{
	serviceId: string
	actionType: ServiceModelEventAction

	static is(e: Event) : e is ServiceModelEvent{
		return e.eventType == EventType.ServiceModel
	}

	static subscribe(serviceId: string, actionTypes: ServiceModelEventAction[]){
		return {
			eventType: EventType.ServiceModel,
			serviceId: serviceId,
			actionTypes
		}
	}

	static subscribeForUpdate(serviceId: string){
		return ServiceModelEvent.subscribe(serviceId, ['PROPERTIES_UPDATE', 'MODEL_UPDATE'])
	}
}

export class ServiceSummaryEvent extends Event{
	static is(e: Event): e is ServiceSummaryEvent{
		return e.eventType == EventType.ServiceSummary
	}

	static subscription(){
		return {
			eventType: EventType.ServiceSummary,
			accountId: ApplicationState.accountId,
			includeSubaccounts: ApplicationState.includeSubaccounts
		}
	}

	static serviceSummaryGridSubscription(payload: object){
		return {
			eventType: EventType.ServiceSummary,
			...payload
		}
	}
}

type EventSummaryEventArgs = {
	accountId?: string
	includeSubaccounts?: boolean
	tags?: string[]
	filter?: RuleDefinition
	showUntagged?: boolean
}

export class EventSummaryEvent extends Event{
	static is(e: Event): e is EventSummaryEvent{
		return e.eventType == EventType.EventSummary;
	}

	static subscription(args?: EventSummaryEventArgs){
		args = args || {}

		return {
			eventType: EventType.EventSummary,
			accountId: args.accountId ?? ApplicationState.accountId,
			includeSubaccounts: args.includeSubaccounts ?? ApplicationState.includeSubaccounts,
			filter: args.filter
		}
	}
}

export class MonitorHealthEvent extends Event{

	static is(e: Event): e is MonitorHealthEvent{
		return e.eventType == EventType.MonitorHealth;
	}

	static subscription(monitorType?: MonitorType, accountId?: string, includeSubaccounts?: boolean){
		return {
			eventType: EventType.MonitorHealth,
			accountId: accountId ?? ApplicationState.accountId,
			includeSubaccounts: includeSubaccounts ?? ApplicationState.includeSubaccounts,
			monitorType: monitorType
		}
	}
}

type ApplicationEventArgs = {
	accountId?: string
	includeSubaccounts?: boolean
}

export class ApplicationHealthEvent extends Event{

	static is(e: Event): e is ApplicationHealthEvent{
		return e.eventType == EventType.ApplicationHealth;
	}

	static subscription(args?: ApplicationEventArgs){
		args = args || {}

		return {
			eventType: EventType.ApplicationHealth,
			accountId: args.accountId ?? ApplicationState.accountId,
			includeSubaccounts: args.includeSubaccounts ?? ApplicationState.includeSubaccounts
		}
	}
}

type SlaEventArgs = {
	accountId?: string
	includeSubaccounts?: boolean
}

export class SlaEvent extends Event{

	static is(e: Event): e is SlaEvent{
		return e.eventType == EventType.Sla;
	}

	static subscription(args?: SlaEventArgs){
		args = args || {}

		return {
			eventType: EventType.Sla,
			accountId: args.accountId ?? ApplicationState.accountId,
			includeSubaccounts: args.includeSubaccounts ?? ApplicationState.includeSubaccounts
		}
	}
}

export class AdministrationEvent extends Event{

	static is(e: Event): e is AdministrationEvent{
		return e.eventType == EventType.Administration;
	}

	static subscription(entityType: 'MONITOR', accountId?: string){
		return {
			eventType: EventType.MonitorHealth,
			entityType: entityType,
			accountId: accountId ?? ApplicationState.accountId,
		}
	}
}

export class MultipleMetricEventSubscription {
	releaseEvents?: boolean
	metrics: {
		metricId: string
		unitType: string
	}[]
}

export class MultipleMetricEvent extends Event {
	qualifierId: string;
	metric: MetricValue
	slope: number;
	intercept: number;

	static is(e: Event): e is MultipleMetricEvent{
		return e.eventType == EventType.Metric;
	}

	static subscription(subscription: MultipleMetricEventSubscription){
		if(subscription.metrics){
			subscription = {
				...subscription,
				metrics: extractMetricSubscriptionFields(subscription.metrics)
			}
		}

		return {
			eventType: 'MultipleMetric',
			...subscription
		}
	}
}

export interface QualifierSubscriptionConfig
{
	qualifierId: string;
	timeZone: string | undefined;
	conversion: string | undefined;
	customUnit: string | undefined;
	conversionUnit: string | undefined;
	unitType: string | undefined;
}

export class MetricEvent extends Event {
	assetId: string;
	metric: {
		e: any,
		t: number,
		u: string,
		c: any
	};
	slope: number;
	intercept: number;

	static is(e: Event): e is MultipleMetricEvent{
		return e.eventType == EventType.Metric;
	}

	static subscription(assetId: string) {
		return {
			eventType: EventType.Metric,
			assetId: assetId,
			releaseEvents: true
		};
	}

	static qualifierSubscription(config: QualifierSubscriptionConfig) {
		return {
			eventType: EventType.Metric,
			...config
		};
	}
}

export class StateEvent extends Event{

	static is(e: Event): e is StateEvent{
		return e.eventType == EventType.State;
	}

	static subscription(assetId: string){
		return {
			eventType: EventType.State,
			assetId: assetId
		}
	}
}
