import {DataListEntry} from "framework/entities/dataList";
import {AgentState} from "framework/entities/agentState";
import {translator} from "core/localization";
import {createModelSchemaWrapper} from "framework/serializr-integration";
import {primitive} from "serializr";
import {CssVariables} from "styles/cssVariables";
import {makeAutoObservable, makeObservable, observable} from "mobx";
import {AntSelectOption} from "controls/react/ant/antSelect";

const i18n = translator({
	'None': {
		no: 'Ingen'
	}
})

export {i18n as healthDataLocalization}

export enum Severity {
	None = 'NONE',
	Minor = 'MINOR',
	Major = 'MAJOR',
	Critical = 'CRITICAL',
	Invalid = 'INVALID'
}

export enum SlaSeverity {
	Breached = 'BREACHED',
	Warning = 'WARNING',
	Compliant = 'COMPLIANT'
}

let severityDataList: Array<DataListEntry<Severity>>;

export function getSeverityDataList(): typeof severityDataList{
	if(severityDataList == null){
		severityDataList = [{
			id: Severity.None,
			name: i18n('None')
		},{
			id: Severity.Minor,
			name: i18n('Minor')
		},{
			id: Severity.Major,
			name: i18n('Major')
		},{
			id: Severity.Critical,
			name: i18n('Critical')
		}];
	}

	return severityDataList;
}

let severityOptions: AntSelectOption[]
export function getSeverityOptions(): AntSelectOption[]{
	if(severityOptions == null){
		severityOptions = [{
			value: Severity.None,
			label: i18n('None')
		},{
			value: Severity.Minor,
			label: i18n('Minor')
		},{
			value: Severity.Major,
			label: i18n('Major')
		},{
			value: Severity.Critical,
			label: i18n('Critical')
		}];
	}

	return severityOptions;
}

export function severityToText(severity: Severity){
	switch (severity) {
		case Severity.None: return i18n('None');
		case Severity.Minor: return i18n('Minor');
		case Severity.Major: return i18n('Major');
		case Severity.Critical: return i18n('Critical');
		case Severity.Invalid: return i18n('Invalid');
	}
}

export const healthIndexToSeverity = (healthIndex:number) => {
	if(healthIndex == null)
		return Severity.Invalid;

	if(healthIndex >= 75 )
		return Severity.None;

	if (healthIndex >= 50)
		return Severity.Minor;

	if (healthIndex >= 25)
		return Severity.Major;

	if (healthIndex >= 0)
		return Severity.Critical;

	return Severity.Invalid;
}

export const severityToColor = (severity: Severity) => {
	switch (severity) {
		case Severity.None:
			return CssVariables.severityNone;
		case Severity.Minor:
			return CssVariables.severityMinor;
		case Severity.Major:
			return CssVariables.severityMajor;
		case Severity.Critical:
			return CssVariables.severityCritical;
		case Severity.Invalid:
			return CssVariables.severityInvalid;
	}
}

export class HealthDataHolder {
	agentState?: AgentState
	agentDown?: boolean
	assetMaintenance?: boolean
	healthIndex?: number
	assetHI?: number
	monitorDataUnavailable?: boolean
	monitorError?: boolean
	monitorMaintenance?: boolean
	monitorHI?: number
	severity?: Severity
	maintenanceDetails?: MaintenanceSubject[]

	constructor() {
		makeObservable(this, {
			agentState: observable,
			agentDown: observable,
			assetMaintenance: observable,
			healthIndex: observable,
			assetHI: observable,
			monitorDataUnavailable: observable,
			monitorError: observable,
			monitorMaintenance: observable,
			monitorHI: observable,
			severity: observable,
			maintenanceDetails: observable,
		})
	}
}

createModelSchemaWrapper(HealthDataHolder, {
	agentState: primitive(),
	agentDown: primitive(),
	assetMaintenance: primitive(),
	healthIndex: primitive(),
	assetHI: primitive(),
	monitorDataUnavailable: primitive(),
	monitorError: primitive(),
	monitorMaintenance: primitive(),
	monitorHI: primitive(),
	maintenanceDetails: primitive(),
	severity: primitive()
})

export enum MaintenanceSubject{
	Asset = 'ASSET',
	Monitor = 'MONITOR',
	Service = 'SERVICE',
	Agent = 'AGENT'
}

export class HealthDataFlags {
	severity: Severity
	exclamationMark: boolean
	wrench: boolean
	cross: boolean
	title: string
	label: string
	clock: boolean
}

const maintenanceLabelMap = {
	[MaintenanceSubject.Asset]: 'Asset in maintenance',
	[MaintenanceSubject.Monitor]: 'Monitor in maintenance',
	[MaintenanceSubject.Service]: 'Service in maintenance',
	[MaintenanceSubject.Agent]: 'Agent in maintenance',
}


export function healthDataToFlags(data: HealthDataHolder) {
	const flags = new HealthDataFlags();

	data = {...data}

	data.maintenanceDetails = data.maintenanceDetails ?? []

	if (data.assetMaintenance) {
		data.maintenanceDetails.push(MaintenanceSubject.Asset)
	}

	if (data.monitorMaintenance) {
		data.maintenanceDetails.push(MaintenanceSubject.Monitor)
	}

	if (data.agentState == AgentState.Maintenance) {
		data.maintenanceDetails.push(MaintenanceSubject.Agent)
	}

	data.healthIndex = data.healthIndex ?? data.assetHI ?? data.monitorHI

	if (data.healthIndex !== undefined && !data.severity) {
		flags.severity = healthIndexToSeverity(data.healthIndex)
	} else if (data.severity) {
		flags.severity = data.severity
	}

	flags.label = data.healthIndex?.toString() ?? 'N/A';

	if (data.maintenanceDetails.length > 0) {
		flags.wrench = true
		flags.title = data.maintenanceDetails.map(x => i18n(maintenanceLabelMap[x])).join(" | ")
	} else {

		if (data.agentState == AgentState.Error) {
			flags.cross = true;
		} else if (data.agentState == AgentState.Warning) {
			if (data.monitorDataUnavailable) {
				flags.title = i18n('Agent in warning') + ' | ' + i18n('Monitor error: data unavailable')
			} else if (data.monitorError) {
				flags.title = i18n('Agent in warning') + ' | ' + i18n('Monitor error')
			}

			if (flags.title) {
				flags.exclamationMark = true;
			}
		} else {
			if (data.monitorDataUnavailable) {
				flags.title = i18n('Monitor error: data unavailable')
			} else if (data.monitorError) {
				flags.title = i18n('Monitor error')
			}
			if (flags.title) {
				flags.exclamationMark = true;
			} else if (data.monitorMaintenance) {
				flags.title = i18n('Monitor in maintenance')
				flags.wrench = true;
			}
		}
	}

	flags.severity = flags.severity ?? Severity.Invalid;

	return flags;
}
