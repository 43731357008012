import './costBarChart.less'

import React from 'react';
import Highcharts from 'highcharts';

import {Cookies} from 'core/cookies';
import {addLinks} from 'core/react/links';
import Configuration from 'configuration';
import {
	ResourceType, getResourceTypeLabel,
	processApiResponse,
	sharedLocalization,
	subscribe,
	throttleLimit
} from './shared';
import {Section} from 'controls/react/layout/section';
import {FormEntry} from 'controls/react/form';
import {AntCheckbox} from 'controls/react/ant/antCheckbox';
import {IconButton} from 'controls/react/form/iconButton';
import {CloudBillingMonitorsApi} from 'areas/assets/monitors/cloud-billing/api';
import {AntInputNumber} from 'controls/react/ant/antInputNumber';
import {sharedLocalization as widgetsSharedLocalization} from 'controls/designer/features/widgets/localization';
import {LegendPositionSelect} from './legendPositionSelect';
import {LegendItemsSelect} from './legendItemsSelect';
import {formatNumber} from 'tools/helpers/math';
import Api from 'tools/api';
import {
	getDefaultConfig,
	updateConfigToTheLattestFormat,
	WidgetConfigurationTemplate
} from 'controls/designer/features/widgets/cloudServices/widgetConfigurationTemplate';
import {AntRadio} from 'controls/react/ant/antRadio';
import {DefaultWidgetWrapper} from 'controls/designer/features/widgets/defaultWidgetWrapper';
import regression from 'regression';
import {Tooltip} from 'antd';

const i = require('core/localization').translator({
		"Threshold tooltip": {
			"en": "Threshold is displayed as line in chart.\nNone: No threshold\nCalculated: Threshold is calculated based on selected resources\nTotal: Threshold is shown using total budget value.",
			"no": "Terskelverdi vises som linje i diagrammet.\nIngen: Ingen terskelverdi\nBeregnet: Terskel blir beregnet basert på utvalgte ressurser\nTotalt: Terskel vises ved bruk av total budsjettverdi."
		},
		"Monthly estimate template": {
			"en": "{0}(*)",
			"no": "{0}(*)"
		},
		"Monthly estimate column tooltip": {
			"en": "This is a monthly estimate",
			"no": "Dette er et månedlig estimate"
		},
		"Monthly estimate": {
			"en": "Monthly estimate",
			"no": "Månedestimat"
		},
		"Monthly estimate tooltip": {
			"en": "This display cost estimate for current month",
			"no": "Dette viser kostnadsestimat for nåværende måned"
		},
		"Historic months": {
			"en": "Historic months",
			"no": "historiske måneder"
		},
		"Historic months tooltip": {
			"en": "Number of historic months displayed. If field is empty current year is displayed.",
			"no": "Antall historiske måneder som vises. Vis feltet er tomt vil nåværende månederr vises."
		},
		"Legend Settings": {
			"no": "Innstillinger",
			"en": "Legend settings"
		},
		"Trend": {
			"no": "Trend"
		},
		"Show trend": {
			"no": "Vis trend",
			"en": "Show trend"
		},
		"Only show total value": {
			"no": "Bare vis total verdi",
			"en": "Only show total value"
		}
	},
	sharedLocalization,
	widgetsSharedLocalization
);

export function getWidgetDescription() {
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'cloud-services-cost-bar-chart',
			title: i('Cost'),
			...getDefaultConfig(),
			thresholdType: ThresholdType.None,
			historicMonths: 12,
			legendPosition: 'TOP',
			legendItems: ['subscription', 'cloudService'],
			resourceType: ResourceType.CloudService
		},
		widget: Widget,
		preProcessConfig: config => {
			config = updateConfigToTheLattestFormat(config);

			if (config.showThreshold != null && config.thresholdType == null) {
				config.thresholdType = config.showThreshold ? ThresholdType.Calculated : ThresholdType.None
			}

			return config;
		},
		fullTitle: i('Cloud') + '/' + i('Bar Chart'),
	}
}

const WidgetConfiguration = props => {
	const {configLink} = props;

	return <WidgetConfigurationTemplate {...props}>
		<FormEntry label={i('Historic months')} width={'fit'}>
			<IconButton iconName={'question-sign'} title={i('Historic months tooltip')}/>
			<AntInputNumber valueLink={configLink.get('historicMonths')}/>
		</FormEntry>
		<FormEntry label={i('Show Threshold')} width={'fit'}>
			<IconButton iconName={'question-sign'} title={i('Threshold tooltip')}/>
			<AntRadio.Group buttonStyle={'solid'} valueLink={configLink.get('thresholdType')}>
				<AntRadio.Button value={ThresholdType.None}>{i('None')}</AntRadio.Button>
				<AntRadio.Button value={ThresholdType.Calculated}>{i('Calculated')}</AntRadio.Button>
				<AntRadio.Button value={ThresholdType.Total}>{i('Total')}</AntRadio.Button>
			</AntRadio.Group>
		</FormEntry>
		<FormEntry width={'fit'}>
			<Tooltip title={i('Monthly estimate tooltip')} zIndex={20000}>
				<AntCheckbox valueLink={configLink.get('showMonthlyEstimate')}>{i('Monthly estimate')}</AntCheckbox>
			</Tooltip>
			<AntCheckbox valueLink={configLink.get('showRegression')}>{i('Show trend')}</AntCheckbox>
			<AntCheckbox valueLink={configLink.get('showOnlyTotalValue')}>{i('Only show total value')}</AntCheckbox>
		</FormEntry>
		<Section appearance={'frame'} title={i('Legend Settings')} childrenPadding={true}>
			<FormEntry label={i('Position')} width={'fit'}
					   valueLink={configLink.get('legendPosition')}>
				<LegendPositionSelect/>
			</FormEntry>
			<FormEntry label={i('Information')} width={'fit'}
					   valueLink={configLink.get('legendItems')}>
				<LegendItemsSelect disabled={configLink.get('legendPosition').value == 'NONE'}/>
			</FormEntry>
		</Section>
	</WidgetConfigurationTemplate>
}

const b = require('b_').with('cloud-services-cost-bar-chart-widget');

@addLinks
class Widget extends React.PureComponent {
	chartContainerRef = React.createRef();
	state = {
		loaded: false,
		loading: true,
	}

	render() {
		return (
			<DefaultWidgetWrapper {...this.props}>
				<div className={b('chart-container')} ref={this.chartContainerRef}>
					{this.state.loading && i('Loading...')}
					{this.state.noDataAvailable && i('Costmodel/store/sheet is not found or has been deleted.')}
				</div>
			</DefaultWidgetWrapper>
		)
	}

	async componentDidMount() {
		const response = await this.loadNameInfo();

		this.setState({
			accountName: response.data?.accountName || '',
			monitorName: response.data?.monitorName || ''
		}, async () => {

			await this.initializeChart();

			this.subscription = subscribe(
				this.chartData,
				this.props.config.monitorId,
				_.throttle(this.initializeChart, throttleLimit, {trailing: false})
			);
		});
	}

	componentWillUnmount() {
		this.subscription?.unsubscribe();
	}

	initializeChart = async () => {
		const response = await this.loadData();
		if (!response.success) {
			this.setState({
				loading: false,
				noDataAvailable: true
			});
			return;
		}

		this.chartData = response.data.items;

		const chart = {
			categories: [],
			series: [],
			threshold: response.data.budget
		}
		let lastMonthNumber = null;
		let chartDataEmpty = true;

		processApiResponse(response.data.items, dataRow => {
			let chartSeries = {
				name: dataRow.subscription + '/' + dataRow.resourceName,
				data: [],
				custom: {
					subscription: dataRow.subscription,
					resourceType: this.props.config.resourceType
				},
				legendObj: {
					account: this.state.accountName,
					cloudProvider: dataRow.cloudTypeText,
					monitor: this.state.monitorName,
					subscription: dataRow.subscription,
					resourceName: dataRow.resourceName
				},
			};

			for (var month of dataRow.months) {
				chartSeries.data.push(month.amount);
				chartDataEmpty = false;
			}

			if (this.props.config.showMonthlyEstimate) {
				chartSeries.data.push(dataRow.estimate);
				chartDataEmpty = false;
			}

			chart.series.push(chartSeries);

		}, (monthNumber, monthName) => {
			chart.categories.push(monthName);
		}, null, (lastMonth) => {
			lastMonthNumber = lastMonth;
		});

		if (this.props.config.showMonthlyEstimate) {
			if (lastMonthNumber === null) {
				lastMonthNumber = new Date().getMonth();
			}
			const monthName = new Date(2020, lastMonthNumber, 1).toLocaleString(Cookies.CeesoftUserLocale, {month: 'long'}) + ' ' + new Date().getFullYear();
			chart.categories.push(i('Monthly estimate template', monthName));
		}

		if (this.props.config.showRegression) {
			const regressionData = chart.categories.map((currElement, index) => {
				return [index, chart.series.reduce((t, n) => t + n.data[index] ?? 0, 0)]
			});
			const result = regression.linear(regressionData);
			chart.series.push({
				zIndex: 0,
				name: 'RegressionLine',
				color: 'rgba(255,165,0, 1)',
				type: 'line',
				data: result.points,
				showInLegend: true,
				marker: {
					enabled: false
				},
				enableMouseTracking: true,
				regressionInfo: result.equation[0]
			});
		}

		if (this.props.config.thresholdType != ThresholdType.None) {
			//add a new item in series to always show threshold
			let currentChartData = chart.series[0].data;
			chart.series.push({
				zIndex: 0,
				name: 'Threshold',
				color: '#ffffff',
				lineWidth: 0,
				type: 'scatter',
				data: [chart.threshold],
				showInLegend: false,
				marker: {
					enabled: false
				},
				enableMouseTracking: false
			});
		}

		this.setState({
			chart,
			loading: false,
			loaded: true
		}, this.drawChart);
	}

	async loadData() {
		return await CloudBillingMonitorsApi.getCost(this.props.config);
	}

	async loadNameInfo() {
		return await Api.fetch(Api.server + `monitors/` + this.props.config.monitorId + '/info');
	}

	drawChart = () => {
		var component = this;
		var decimalsNumber = this.props.config.decimalsNumber;
		this.chart = new Highcharts.Chart({
			chart: {
				type: 'column',
				renderTo: this.chartContainerRef.current,
				backgroundColor: null,
			},
			title: {
				text: null
			},
			credits: {
				enabled: false
			},
			exporting: Configuration.highcharts.exporting,
			xAxis: {
				categories: this.state.chart.categories
			},
			yAxis: {
				min: 0,
				title: {
					text: i('Cost')
				},
				stackLabels: {
					formatter: function () {
						return formatNumber(this.total, decimalsNumber)
					},
					enabled: true,
					style: {
						fontWeight: 'bold',
						color: ( // theme
							Highcharts.defaultOptions.title.style &&
							Highcharts.defaultOptions.title.style.color
						) || 'gray'
					}
				},
				plotLines: this.props.config.thresholdType != ThresholdType.None ?
					[{
						id: 'threshold',
						color: '#ff0000',
						dashStyle: 'ShortDash',
						width: 2,
						value: this.state.chart.threshold,
						zIndex: 0
					}] : []
			},
			legend: {
				enabled: component.props.config.legendPosition !== 'NONE',
				align: 'right',
				verticalAlign: component.props.config.legendPosition === 'TOP' ? 'top' : 'bottom',
				backgroundColor:
					Highcharts.defaultOptions.legend.backgroundColor || 'white',
				shadow: false,
				labelFormatter: function () {
					if (this.name === 'RegressionLine') {
						return i('Trend');
					}
					const legendItems = component.props.config.legendItems;
					const legendObj = this.userOptions.legendObj;
					let legendArray = [];
					for (let item of legendItems) {
						if (item == 'cloudService') {
							item = 'resourceName'
						}

						if (legendObj[item]) {
							legendArray.push(legendObj[item]);
						}
					}
					return legendArray.join('/');
				}
			},
			tooltip: {
				backgroundColor: 'white',
				borderWidth: 0,
				shadow: {
					offsetX: 0,
					offsetY: 0,
					opacity: 1,
					width: 16,
					color: 'rgb(0,0,0,0.01)'
				},
				useHTML: true,
				headerFormat: '<b>{point.x}</b><br/>',
				formatter: function (e) {
					if (this.series.userOptions.name === 'RegressionLine')
						return `${i('Trend')}: ${this.series.userOptions.regressionInfo}/mo`;

					const {custom} = this.series.userOptions;
					let tooltip = `${i(getResourceTypeLabel(custom?.resourceType))}: ${this.series.name}
						<br/>${i('Subscription')}: ${custom?.subscription}
						<br/>${i('Value')}: ${this.point.y}`;

					if (component.props.config.showMonthlyEstimate && this.point.index == this.point.series.data.length - 1) {
						tooltip += `<br/>${i('Monthly estimate column tooltip')}`;
					}

					return tooltip;
				}

			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: !this.props.config.showOnlyTotalValue,
						formatter: function () {
							let formattedNumber = formatNumber(this.y, decimalsNumber);
							if (parseInt(formattedNumber) === 0 && formattedNumber % 1 === 0) {
								return null;
							} else {
								return formattedNumber;
							}
						}
					}
				}
			},
			series: this.state.chart.series
		});
	}

	onResize = () => {
		this.chart?.setSize();
	}
}

export const ThresholdType = Object.freeze({
	None: 'none',
	Calculated: 'calculated',
	Total: 'total'
});
