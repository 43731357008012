import {createModelSchemaWrapper} from "framework/serializr-integration";
import {list, object, primitive} from "serializr"
import {makeAutoObservable} from "mobx"

import {addUniqueNameValidation, ModelValidator, ValidatableModel} from "framework/mobx-integration"
import {apiFetch} from "framework/api"
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition"
import {getServices} from "areas/services/services-list/api"
import {HealthDataFlags, Severity} from "framework/entities/healthData"
import {OperatingState} from "framework/entities/operatingState"

const i18n = require('core/localization').translator({
	'Please select at least one team': {
		no: 'Velg minst et team'
	}
});

export class ServiceStateSummary {
	down: number
	total: number
	warning: number
	inactive: number
	upWithError: number
	warningWithError: number
	downWithError: number
}

createModelSchemaWrapper(ServiceStateSummary, {
	down: primitive(),
	total: primitive(),
	warning: primitive(),
	inactive: primitive(),
	upWithError: primitive(),
	warningWithError: primitive(),
	downWithError: primitive(),
})

export class ServiceHealthSummary {
	total: number
	major: number
	minor: number
	maintenance: number
	critical: number
	ok: number
	noHealth: number
	minorFlag: boolean
	inactiveFlag: boolean
	majorFlag: boolean
	criticalFlag: boolean
	okFlag: boolean
}

createModelSchemaWrapper(ServiceHealthSummary, {
	total: primitive(),
	major: primitive(),
	minor: primitive(),
	maintenance: primitive(),
	critical: primitive(),
	ok: primitive(),
	noHealth: primitive(),
	minorFlag: primitive(),
	inactiveFlag: primitive(),
	majorFlag: primitive(),
	criticalFlag: primitive(),
	okFlag: primitive(),
})

export class OperatingSchedule {
	timeZone: string
	days: OperatingScheduleDay[]
	constructor() {
		makeAutoObservable(this)
	}
}

export class OperatingScheduleDay {
	day: string
	excluded: boolean
	hours: OperatingScheduleHour[]
	constructor() {
		makeAutoObservable(this)
	}
}

export class OperatingScheduleHour {
	fromHour: number
	toHour: number
	fromMinute: number
	toMinute: number
	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(OperatingSchedule, {
	timeZone: primitive(),
	days: list(object(OperatingScheduleDay))
})

createModelSchemaWrapper(OperatingScheduleDay, {
	day: primitive(),
	excluded: primitive(),
	hours: list(object(OperatingScheduleHour))
})

createModelSchemaWrapper(OperatingScheduleHour, {
	fromHour: primitive(),
	toHour: primitive(),
	fromMinute: primitive(),
	toMinute: primitive(),
})

export class ServiceSla {
	breaches: number
	ok: number
	warnings: number
	total: number
	indicator: string
}

createModelSchemaWrapper(ServiceSla, {
	breaches: primitive(),
	ok: primitive(),
	warnings: primitive(),
	total: primitive(),
	indicator: primitive()
})

export class ServiceIncidents{
	low: number
	medium: number
	high: number
	total: number
	open: number
	closed: number
}

createModelSchemaWrapper(ServiceIncidents, {
	low: primitive(),
	medium: primitive(),
	high: primitive(),
	total: primitive(),
	open: primitive(),
	closed: primitive()
})

export class ServiceLinkState{
	down: number
	up: number
	total: number
	inactive: number
	warning: number
}

createModelSchemaWrapper(ServiceLinkState, {
	down: primitive(),
	up: primitive(),
	total: primitive(),
	inactive: primitive(),
	warning: primitive()
})

export class ServiceSummary {
	information: string
	accountId: string
	accountName: string
	linkServiceState: ServiceLinkState
	incidents: ServiceIncidents
	serviceState: ServiceState
	agentStatus: AgentStatus
	assetMaintenance: boolean
	qualifierError: boolean
	stateDuration: number
	stateChangeTime: number
	operatingState: OperatingState
	coverage: number
	shared: boolean
	srvId: string
	srvName: string
	srvHealth: ServiceHealthSummary
	srvAssetHealth: ServiceHealthSummary
	srvGroupHealth: ServiceHealthSummary
	srvSla: ServiceSla
	srvState: ServiceStateSummary
	srvHealthIndex: number
	srvStateIndex: number
	srvHealthWarning: boolean
	srvIncidents: number
	srvSlaIndex: number

	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(ServiceSummary, {
	information: primitive(),
	accountId: primitive(),
	accountName: primitive(),
	serviceState: primitive(),
	agentStatus: primitive(),
	assetMaintenance: primitive(),
	qualifierError: primitive(),
	stateDuration: primitive(),
	stateChangeTime: primitive(),
	operatingState: primitive(),
	coverage: primitive(),
	shared: primitive(),
	srvId: primitive(),
	srvName: primitive(),
	incidents: object(ServiceIncidents),
	linkServiceState: object(ServiceLinkState),
	srvSla: object(ServiceSla),
	srvHealth: object(ServiceHealthSummary),
	srvAssetHealth: object(ServiceHealthSummary),
	srvGroupHealth: object(ServiceHealthSummary),
	srvState: object(ServiceStateSummary),
	srvHealthIndex: primitive(),
	srvStateIndex: primitive(),
	srvHealthWarning: primitive(),
	srvIncidents: primitive(),
	srvSlaIndex: primitive(),
})

export class Service implements ValidatableModel<Service>{
	accountName: string
	accountId: string
	name: string
	description: string
	id: string

	teamIds: string[]
	modelId: string
	agentStatus: AgentStatus
	serviceState: ServiceState
	qualifierBreached: boolean
	qualifierError: boolean
	stateDuration: number
	stateChangeTime: number
	lastState: string
	summary: ServiceSummary
	operatingSchedule: OperatingSchedule
	tags: string[]
	shared: boolean
	information: string = ""
	referenceCount: number
	calendar: string
	maintenanceCalendars: string[]

	//legacy field, should be converted to an array on fly
	get teamId(){
		return null
	}

	set teamId(value: string){
		if(this.teamIds == null){
			this.teamIds = [value]
		}
	}
	// maintenancePeriods: [
	// 	{
	// 		id: 28c8d260-936a-4dd5-8506-b7dce6f33d32,
	// 		fromTime: 1694584800000,
	// 		toTime: 1694588400000
	// 	}
	// ],
	// draftUpdateTime: null,
	// draftUpdatedBy: null,
	// referenceCount: 0,
	// calendar: BEGIN:VCALENDAR\r\nVERSION:2.0\r\nPRODID:-//Tri Tech Computers//node-icalendar//EN\r\nBEGIN:VEVENT\r\nDTSTAMP:20230801T103505Z\r\nUID:69776afa-b8e2-4d46-85f0-b2e12a91ebdb\r\nDTSTART;TZID=Europe/Oslo:20230719T080000\r\nDTEND;TZID=Europe/Oslo:20230719T090000\r\nSUMMARY:asdfasdf\r\nCATEGORIES:MAINTENANCE\r\nCREATED:20230717T082132Z\r\nORGANIZER:da066aed-c920-43df-9bb4-b00e849d7ed6\r\nEND:VEVENT\r\nBEGIN:VEVENT\r\nDTSTAMP:20230801T103505Z\r\nUID:a7ad22a0-08ad-4530-a40b-3ec565a8c30c\r\nDTSTART;TZID=Europe/Oslo:20230727T080000\r\nDTEND;TZID=Europe/Oslo:20230727T090000\r\nSUMMARY:manually\r\nCATEGORIES:MAINTENANCE\r\nCREATED:20230726T105454Z\r\nORGANIZER:dfda5bee-73a0-49c1-be56-d66e1ec30fa6\r\nEND:VEVENT\r\nBEGIN:VEVENT\r\nDTSTAMP:20230801T103505Z\r\nUID:e680bc11-b938-4fe2-ae31-de2d81fc3326\r\nDTSTART;TZID=Europe/Oslo:20230802T080000\r\nDTEND;TZID=Europe/Oslo:20230802T090000\r\nSUMMARY:tomorrwo\r\nCATEGORIES:MAINTENANCE\r\nCREATED:20230801T103452Z\r\nORGANIZER:dfda5bee-73a0-49c1-be56-d66e1ec30fa6\r\nEND:VEVENT\r\nBEGIN:VEVENT\r\nDTSTAMP:20230801T103505Z\r\nUID:28c8d260-936a-4dd5-8506-b7dce6f33d32\r\nDTSTART;TZID=Europe/Oslo:20230913T080000\r\nDTEND;TZID=Europe/Oslo:20230913T090000\r\nSUMMARY:sep\r\nCATEGORIES:MAINTENANCE\r\nCREATED:20230801T103501Z\r\nORGANIZER:dfda5bee-73a0-49c1-be56-d66e1ec30fa6\r\nEND:VEVENT\r\nEND:VCALENDAR\r\n,
	// maintenanceCalendars: [
	// 	86e06a5b-7646-47ae-b48b-db73aa2ca407
	// ]

	validator: ModelValidator<Service>

	constructor() {
		makeAutoObservable(this)
	}

	enableValidation(){
		this.validator = new ModelValidator<Service>(this)

		this.validator.required('name')

		addUniqueNameValidation<Service>(this, async (name) => {
			const group = RuleDefinition.fieldEqualRule('name', name)
			const response = await apiFetch(getServices({
				filter: group
			}))

			if(response.success){
				return response.data.visible == 0
			}

			return false
		})

		this.validator.required('teamIds', null, i18n('Please select at least one team') )
	}
}

createModelSchemaWrapper(Service, {
	id: primitive(),
	name: primitive(),
	accountId: primitive(),
	tags: list(primitive()),
	teamIds: list(primitive()),
	shared: primitive(),
	modelId: primitive(),
	accountName: primitive(),

	serviceState: primitive(),
	agentStatus: primitive(),
	qualifierBreached: primitive(),
	qualifierError: primitive(),

	summary: object(ServiceSummary),
	operatingSchedule: object(OperatingSchedule),
	stateChangeTime: primitive(),
	stateDuration: primitive(),
	lastState: primitive(),

	information: primitive(),
	description:  primitive(),
	referenceCount: primitive(),
	calendar: primitive(),
	maintenanceCalendars: list(primitive()),
})

export enum ServiceState{
	Inactive = 'INACTIVE',
	Warning = 'WARNING',
	Active = 'ACTIVE',
	Invalid = 'INVALID'
}

export enum AgentStatus{
	Down = 'AGENT_DOWN',
	Maintenance = 'AGENT_MAINTENANCE',
	Active = 'ACTIVE'
}

export function serviceStateToSeverity(state: ServiceState){
	switch (state){
		case ServiceState.Inactive:
			return Severity.Critical
		case ServiceState.Warning:
			return Severity.Major
		case ServiceState.Active:
			return Severity.None
		case ServiceState.Invalid:
			return Severity.Invalid
	}
}

export function serviceSummaryToHealthFlags(summary: Pick<ServiceSummary, 'serviceState'|'operatingState'|'qualifierError'|'agentStatus'|'assetMaintenance'>) {
	const flags = new HealthDataFlags()

	flags.severity = serviceStateToSeverity(summary.serviceState)

	if (summary.operatingState === OperatingState.InMaintenance) {
		flags.title = i18n('Service in maintenance')
		flags.wrench = true
		flags.severity = Severity.Invalid
	} else if (summary.operatingState === OperatingState.NonOperational) {
		flags.title = i18n('Outside operating period')
		flags.clock = true
		flags.severity = Severity.Invalid
	} else if (summary.qualifierError || [AgentStatus.Down, AgentStatus.Maintenance].indexOf(summary.agentStatus) !== -1 || summary.assetMaintenance) {
		flags.exclamationMark = true

		let messages: string[] = [];

		if (summary.qualifierError) {
			messages.push(i18n('Qualifier error'));
		}

		if (summary.agentStatus == AgentStatus.Down) {
			messages.push(i18n('Agent in warning'));
		} else if (summary.agentStatus == AgentStatus.Maintenance) {
			if (!summary.qualifierError) {
				flags.wrench = true
			}
			messages.push(i18n('Agent in maintenance'))
		}

		if (summary.assetMaintenance) {
			if (!summary.qualifierError) {
				flags.wrench = true
			}
			messages.push(i18n('Asset in maintenance'))
		}
		if (flags.wrench) {
			flags.exclamationMark = false
		}
		flags.title = messages.join(' / ')
	}

	return flags
}

export class ServiceLite{
	id: string
	name: string
	accountId: string
	accountName: string
}

export class ServiceElement{
	id: string
	serviceId: string
	accountId: string
	name: string
}

export interface ServiceQualifier {
	id: string
	accountId: string;
	accountName: string;
	agentId: string;
	agentName: string;
	assetId: string;
	assetName: string;
	categoryId: string;
	categoryNode: string;
	categoryPath: string;
	configuration?: string;
	conversion?: string;
	customUnit?: string;
	dataType: string;
	instanceName: string;
	maxValue: number;
	metricId: string;
	metricType: string;
	persist: boolean;
	registryIdentifier: string;
	registryOwnerId: string;
	registryOwnerName: string;
	registryType: string;
	unitType: string;
	unitTypeSymbol: string;
}
