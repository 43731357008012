import Utils, { isOneOf } from 'tools/utils'
import Settings from 'settings'
import {Cookies} from 'core/cookies'
import {ErrorHandler} from 'core/errorHandler'
import Api from 'tools/api';
import State from 'tools/state';

export class UserPrefsTool {
	static getSettings = (source, key, section = undefined, checksum = undefined, category = undefined) => {
		let value = null, currentPrefs;
		if (section) {
			let sectionPref = Utils.getFromJsonArray(source, 'key', section);
			currentPrefs = sectionPref && sectionPref.value && JSON.parse(sectionPref.value);
		} else if (category && checksum) {
			let current = checksum[category];
			for (let i = 0; i < current.length; i++) {
				if (current[i].key === key) {
					value = current[i].value;
				}
			}
		} else {
			currentPrefs = source;
		}

		if (!value) {
			currentPrefs = currentPrefs || [];
			for (let i = 0, length = currentPrefs.length; i < length; i++) {
				if (currentPrefs[i].key === key) {
					value = currentPrefs[i].value;
					break;
				}
			}
		}

		if (!key) {
			return value;
		}
		if (key.indexOf('Columns') !== -1 || key.indexOf('columns') !== -1) {
			//algorithm to replace the last column with no width, if hidden, to the previous one, to avoid enlarging all columns proportionally
			let columns = JSON.parse(value);
			let maxIndex = 0;
			//flag to keep track of grid columns with no width; only one is enough
			let shownColumnWithNoWidth = false;
			let maxIndexColumn;
			for (let column in columns) {
				let currentColumn = columns[column];
				if (!currentColumn.hidden && !currentColumn.width) {
					shownColumnWithNoWidth = true;
					break;
				}
				if (currentColumn.index > maxIndex && !currentColumn.hidden) {
					maxIndex = currentColumn.index;
					maxIndexColumn = currentColumn;
				}
			}
			if (!shownColumnWithNoWidth && maxIndexColumn) {
				delete maxIndexColumn.width;
				value = JSON.stringify(columns);
			}

			//override the user prefs settings for account columns with the global includeSubaccounts selector for general columns
			let isAccountColumn = false;
			for (let column in columns) {
				if (column === 'accountName') {
					isAccountColumn = true;
					columns[column].hidden = !State.includeSubaccounts;
				}
			}
			if (isAccountColumn) {
				value = JSON.stringify(columns);
			}
		}

		if (Utils.isGuid(key)) {
			//override the user prefs settings for account columns with the global includeSubaccounts selector for default view columns
			let valueCopy = JSON.parse(value);
			let columns = valueCopy?.columns ?? [];
			let isAccountColumn = false;
			for (let column in columns) {
				if (column === 'accountName') {
					isAccountColumn = true;
					columns[column].hidden = !State.includeSubaccounts;
				}
			}
			if (isAccountColumn) {
				valueCopy.columns = columns;
				value = JSON.stringify(valueCopy);
			}
		}

		if (key.indexOf('Filter') !== -1 && !isOneOf(key, ['HIFiltersHealth', 'defaultFiltersFsView', 'ahFiltersFSViews', 'ahFiltersSort'])) { // HACK for filters page
			//if includeSubaccounts not checked remove the accountName filtering as the column is hidden
			let filter = JSON.parse(value);
			if (filter && filter.filters) {
				let filters = filter.filters;
				for (let i = 0; i < filters.length; i++) {
					if (filters[i].field === 'accountName' && !State.includeSubaccounts) {
						filter.splice(i, 1);
					} else if (filters[i].filters) {
						let subfilters = filters[i].filters;
						for (let j = 0; j < subfilters.length; j++) {
							if (subfilters[j].field === 'accountName' && !State.includeSubaccounts) {
								subfilters.splice(j, 1);
							}
						}
					}
				}
			}
			value = JSON.stringify(filter);
		}

		if (key.indexOf('Sort') !== -1) {
			//if includeSubaccounts not checked remove the accountName sorting as the column is hidden
			let sort = JSON.parse(value);
			if (sort) {
				for (let i = 0; i < sort.length; i++) {
					if (sort[i].field === 'accountName') {
						sort.splice(i, 1);
					}
				}
			}
			value = JSON.stringify(sort);
		}
		return value;
	}
}

export let UserPrefs = {
	prefs: [],
	checksum: [],
	/**
	 * Returns a user preference based on key index
	 * @param {String} key The key value
	 * @param {String} section Section from additional grids
	 * @return {String} value The associated value for the given key or null
	 * if the key was not found
	 */
	get: function (key, section = undefined, category = undefined) {
		return UserPrefsTool.getSettings(this.prefs, key, section, this.checksum, category);
	},

	/**
	 * Sets a user preference based on key index
	 * @param {String} key The key value
	 * @param {String} value The associated value for the given key
	 * @param {String} section
	 */
	set: function (key, value, section) {
		var found = false, currentPrefs = this.prefs;

		if (section) {
			var sectionPref = Utils.getFromJsonArray(this.prefs, 'key', section);
			currentPrefs = sectionPref && sectionPref.value && JSON.parse(sectionPref.value);
		}

		for (var i = 0, length = currentPrefs.length; i < length; i++) {
			if (currentPrefs[i].key === key) {
				currentPrefs[i].value = value;
				found = true;
				break;
			}
		}
		if (!found) {
			currentPrefs.push({
				key: key,
				value: value
			});
		}
	},
	/*
	* Clears the category of all keys by deleting them from database
	* @param {Function} callback The callback function
	* */
	clearCategory: function (callback, category) {
		var category = category || this.category;
		if (category) {
			var url = Settings.serverPath + 'userSettings/categories/' + category + '/deleteAllKeys';
			Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
				if (!result.success) {
					return ErrorHandler.serverError(result);
				}

				if (callback) {
					callback.call(this, result);
				}
			}, this));
		}
	},
	/*
	* Handler function for getting the user prefs
	* */
	getUserPrefs: function () {
		return this.prefs;
	},
	/*
	* Handler function to set user preferences main array
	* @param {Array} userPrefs Array of user preferences
	* */
	setUserPrefs: function (userPrefs) {
		this.prefs = userPrefs;
	},
	/*
	 * Handler function to set user preferences main array checksum
	 * @param {Array} userPrefs Array of user preferences
	 * */
	setUserCheckSum: function (category, userPrefs) {
		this.checksum[category] = userPrefs;
	},
	remove: function (key) {
		var i, length, found = false;
		for (var i = 0, length = this.prefs.length; i < length; i++) {
			if (this.prefs[i].key === key) {
				found = true;
				break;
			}
		}
		if (found) {
			this.prefs.splice(i, 1);
		}
	},
	/*
	 * Saves user preferences
	 * @param {String} category The category value
	 * @param {Object} preferences
	 * @param {Object} callbackFn
	 * */
	save: function (category, preferences, callbackFn) {
		const that = this;
		if (!Cookies.sessionId)
			return;

		const urlPostfix = category ? `?category=${category}` : 'categories';
		const saveUrl =  `${Settings.serverPath}sessions/${Cookies.sessionId}/userSettings/` + urlPostfix;

		const	sPreferences = JSON.stringify(preferences);
		// if same checksum, return and don't save;
		if (this.checksum[category]) {
			if (UserPrefs.comparePrefs(this.checksum[category], preferences)) {
				return;
			}
		}

		return Utils.ajax(saveUrl, 'POST', sPreferences, function (result) {
			if (!result.success) {
				ErrorHandler.serverError(result);
			} else {
				that.setUserCheckSum(category, preferences);
				if (callbackFn) {
					callbackFn.call(this, result);
				}
			}
		});
	},
	/*
	* Loads user preferences based on multiple categories
	* @param {Array} categories The categories array
	* @param {Object} callbackFn The callback function
	* */

	loadMultipleCategories: function (categories, callbackFn) {
		this.prefs = [];
		var loadUrl = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/userSettings/categories/?category=' + categories.join('&category=');
		return Api.fetch(loadUrl)
			.then( result => {
				if (!result.success) {
				ErrorHandler.serverError.call(this, result);
				} else {
					for (var i = 0; i < result.data.length; i++) {
						var values = result.data[i].values;
						var category = result.data[i].category;
						this.checksum[category] = values;
						for (var j = 0; j < values.length; j++) {
							this.prefs.push(values[j])
						}
					}
					if (callbackFn) {
						callbackFn.call(this, result);
					}
				}
				return result;
			});
	},
	/*
	* Loads the user preferences
	* */
	load: function (category, callbackFn = null, sessionId = null) {
		this.category = category;

		this.prefs = [];
		let loadUrl = Settings.serverPath + 'sessions/' + (sessionId || Cookies.sessionId)
			+ '/userSettings/?category=' + category;

		return new Promise((resolve, reject) => {
			Utils.ajax(loadUrl, 'GET', {}, (result) => {
				if (!result.success) {
					ErrorHandler.serverError.call(this, result);
					reject(result)
				} else {
					if (result.data) {
						State.storeViews = true;
					} else {
						State.storeViews = false;
					}
					this.prefs = result.data;
					this.checksum[category] = result.data;

					callbackFn?.call(this, result);

					resolve(result);
				}
			});
		})
	},
	/**
	 * Saves a user preference based on key index
	 * @param {String} category The category value
	 * @param {String} key The key value
	 * @param {String} value The associated value for the given key
	 * @param {Object} callbackFn The callback function
	 * @param {String} section The section for additional grid
	 */
	saveByKey: function (category, key, value, callbackFn, section) {
		var saveUrl = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/userSettings/categories/' + category + '/keys/' + key;
		var data = {
			value: value
		};
		Utils.ajax(saveUrl, 'POST', JSON.stringify(data), function (result) {
			if (!result.success) {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			} else {
				if (callbackFn) {
					callbackFn.call(this, result);
				}
			}
		});
	},
	/*
	* Loads a user preference based on key index
	* @param {String} category The category value
	* @param {String} key The key value
	* @param {Object} callbackFn The callback function
	* @param {String} section The section for additional grid
	* */
	loadByKey: function (category, key, callbackFn, section) {
		var loadUrl = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/userSettings/category/' + category + '/key/' + key;
		Utils.ajax(loadUrl, 'GET', {}, function (result) {
			if (!result.success) {
				ErrorHandler.serverError.call(this, result);
			} else {
				if (callbackFn) {
					callbackFn.call(this, result);
				}
			}
		});
	},
	/*
	 * Removes a user preference based on key index
	 * @param {String} category The category value
	 * @param {String} key The key value
	 * @param {Object} callbackFn The callback function
	 * @param {String} section The section for additional grid
	 * */
	removeByKey: function (category, key, callbackFn, section) {
		var removeUrl = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/userSettings/categories/' + category + '/keys/' + key;
		Utils.ajax(removeUrl, 'DELETE', {}, function (result) {
			if (!result.success) {
				ErrorHandler.serverError(result);
			} else {
				if (callbackFn) {
					callbackFn.call(this, result);
				}
			}
		});
	},
	empty: function () {
		this.prefs = [];
	},
	comparePrefs: function (x, y) {
		var lengthX = x.length, lengthY = y.length, valueY;

		if (lengthX !== lengthY) {
			return false;
		}
		for (var i = 0; i < lengthX; i++) {
			var objY = Utils.getFromJsonArray(y, 'key', x[i].key);
			if (!objY) {
				return false;
			}
			valueY = objY.value;
			if (x[i].value !== null) {
				if (valueY === undefined || valueY === null) {
					return false;
				}

				if (valueY.toString() !== x[i].value.toString()) {
					return false;
				}
			} else {
				if (valueY !== null) {
					return false;
				}
			}
		}
		return true;
	},
	restoreDateFilter: function (filter) {
		if (filter) {
			var parseObject = function (filter) {
				if (filter.filters) {
					for (var i = 0; i < filter.filters.length; i++) {
						parseObject(filter.filters[i]);
					}
				} else {
					if (filter.value && typeof filter.value === 'string' && filter.value.split(':').length === 3) {
						filter.value = new Date(filter.value);
					}
				}
			};
			if (filter.filters) {
				for (var i = 0; i < filter.filters.length; i++) {
					parseObject(filter.filters[i]);
				}
			}
			return filter;
		} else {
			return;
		}

	}
};

export default UserPrefs;

export let saveUserPrefs =  (config, eventsToolbar, userPref) => {
	var preferences = [];
	$.merge(preferences, config.preferences || []);

	var setFilterPanelProps = function (config, preferences) {
		if (config.filterPanel) {
			if (config.keys.searchPhrase) {
				preferences.push({
					key: config.keys.searchPhrase,
					value: config.filterPanel.getSearchValue(config.multipleGrids)
				});
				delete config.keys.searchPhrase;
			}

			if (config.keys.FsViews && State.storeViews) {
				preferences.push({
					key: config.keys.FsViews,
					value: JSON.stringify(config.filterPanel.fsList)
				});
				delete config.keys.FsViews;
			}

			preferences.push({
				key: config.keys.defaultFsView || 'defaultFsView',
				value: config.filterPanel.defaultFsViewId || ''
			});
			delete config.keys.defaultFsView;

			if (!config.filterPanel.defaultFsViewId && config.filterPanel.filterList) {
				var fsId = config.filterPanel.filterList.value();
				var	item = config.filterPanel.filterList.dataSource.get(fsId);
				if (Utils.isGuid(fsId)) {
					preferences.push({
						key: 'lastFsView',
						value: fsId
					});
				}
			}
		}

		if (config.keys) {
			delete config.keys.searchPhrase;
			delete config.keys.FsViews;
		}

		return preferences;
	};

	setFilterPanelProps(config, preferences);
	if (config.keys?.filterPeriod && config.filterPeriod){
		preferences.push({
			key: config.keys.filterPeriod,
			value: JSON.stringify(config.filterPeriod)
		});
		delete config.keys.filterPeriod;
	}

	var setFilterGridProps = function (config, preferences) {
		if (config.grid) {
			if (config.keys.columns) {
				preferences.push({
					key: config.keys.columns,
					value: JSON.stringify(Utils.getGridColumns(config.grid))
				});
			}
			if (config.keys.sort) {
				preferences.push({
					key: config.keys.sort,
					value: JSON.stringify(config.grid.dataSource.sort() || [])
				});
			}

			if (config.keys.filter) {
				let filter = config.grid.dataSource.filter();
				if (filter && filter.filters) {
					filter = JSON.parse(JSON.stringify(filter));
					if (config.grid.dataSource.options.serverFiltering) {
						filter.filters = Utils.changeDateFilterToString(filter.filters);
					} else {
						filter.filters = Utils.changeDateFilterToTimestamp(filter.filters);
					}
				}
				preferences.push({
					key: config.keys.filter,
					value: JSON.stringify(filter || [])
				});
			}
			if (config.keys.group) {
				preferences.push({
					key: config.keys.group,
					value: JSON.stringify(config.grid.dataSource.group() || [])
				});
			}
		}

		if (config.keys) {
			delete config.keys.columns;
			delete config.keys.sort;
			delete config.keys.filter;
			delete config.keys.group;
		}

		return preferences;
	};

	setFilterGridProps(config, preferences);

	if (config.sections) {
		var gridKeys, adPreferences;
		for (var i = 0, length = config.sections.length; i < length; i++) {
			adPreferences = [];
			gridKeys = config.sections[i];

			setFilterPanelProps(gridKeys, adPreferences);
			setFilterGridProps(gridKeys, adPreferences);

			preferences.push({
				key: gridKeys.category,
				value: JSON.stringify(adPreferences)
			});
		}

		delete config.sections;

	}

	for (var key in config.keys) {
		preferences.push({
			key: key,
			value: config.keys[key]
		});
	}

	if (eventsToolbar) {
		preferences.push({
			key: 'eventsStatus',
			value: JSON.stringify({
				playing: eventsToolbar.playing,
				startFrom: eventsToolbar.startFrom
			})
		});
	}
	// add FS views
	if (userPref) {
		for (var i = 0, length = userPref.length; i < length; i++) {
			if (userPref[i].category) {
				if (userPref[i].category === config.category) {
					let values = userPref[i].values;
					for (let j = 0; j < values.length; j++) {
						if (Utils.isGuid(values[j].key)) {
							preferences.push(values[j]);
						}
					}
				}
			} else {
				// if is FS view
				if (Utils.isGuid(userPref[i].key)) {
					preferences.push(userPref[i]);
				}
			}
		}
	}
	UserPrefs.save(config.category, preferences);
}
