import './grid.less';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {GridColumnsHelpers, ReactHelpers} from "tools/helpers";
import {Utils} from "tools/utils";
import {translator} from 'core/localization/localization';
import {debounce} from 'lodash';

import {setValueOnPath} from "tools/helpers/math";

const b = require('b_').with('c-grid');
const i = translator({
	'Drag me':{
		no: 'Dra meg'
	}
});

export class Grid extends React.PureComponent {
	ref = React.createRef();
	containerRef = React.createRef();
	guid = Utils.guid();

	render() {
		//wrapping div here is required so we can change containerClass property
		//if we put containerClass directly on refDiv then on changing it we will loose all kendo's classes
		return <div ref={this.containerRef} className={classnames(b({fit: this.props.fit}), this.props.containerClass)}><div ref={this.ref}/></div>
	}

	componentDidMount() {
		this.initGrid();

		$(this.ref.current).on('dragstart', '.drag-source', e => {
			const dataItem = this.kendoGrid.dataItem($(e.currentTarget).closest('tr'));
			this.props.onDragStarted(e.originalEvent, dataItem);
		})

		this.containerRef.current.addEventListener('click', e => {
			let dataItem = this.kendoGrid.dataItem(e.target.closest('tr'))
			if (dataItem == null)
				return

			let column = e.target.getAttribute('data-column')
			if (column == null)
				return

			this.props.onCellClicked?.(dataItem, column, this.kendoGrid)
		})

		if (this.props.dynamicFit) {
			const resize = debounce(() => {
				this.kendoGrid?.resize()
			}, 300)
			this.resizeObserver = new ResizeObserver(resize)
			this.resizeObserver.observe(this.containerRef.current)
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.columns != this.props.columns || prevProps.noRecords?.template != this.props.noRecords?.template){
			this.destroyGrid();
			this.initGrid();
			return;
		}

		if (prevProps.dataSource != this.props.dataSource){
			this.kendoGrid.setDataSource(this.props.dataSource);
		}

		if (prevProps.dataSourceArray != this.props.dataSourceArray){
			const newDataSource = this.convertArrayToDataSource(this.props.dataSourceArray);
			this.kendoGrid.dataSource._sort && newDataSource.sort(this.kendoGrid.dataSource._sort);
			this.kendoGrid.dataSource._filter && newDataSource.filter(this.kendoGrid.dataSource._filter);
			this.kendoGrid.setDataSource(newDataSource);
		}

		if (prevProps.filter != this.props.filter){
			this.kendoGrid.dataSource.filter(this.props.filter);
		}

		if (prevProps.sort != this.props.sort){
			this.kendoGrid.dataSource.sort(this.props.sort);
		}

		if (prevProps.group != this.props.group || this.props.forceGroup){
			this.kendoGrid.dataSource.group(this.props.group);
		}

		if (this.props.setHeightOnResize && prevProps.height != this.props.height) {
			this.kendoGrid.setOptions({
				height: this.props.height
			});
		}

		if (prevProps.autoBind != this.props.autoBind) {
			this.kendoGrid.setOptions({
				autoBind: this.props.autoBind
			});
		}

		this.handleSelection(prevProps);
	}

	componentWillUnmount() {
		this.resizeObserver?.disconnect();
	}

	handleSelection(prevProps) {
		if (prevProps.disableSelection !== this.props.disableSelection) {
			if (this.props.disableSelection) {
				this.disableSelection();
			}
			else {
				this.enableSelection();
			}
		}

		this.fillSelected();
	}

	fillSelected() {
		if (!this.props.selected?.length) return;
		let rows = this.getDomRoot().querySelectorAll(this.gridCheckSelector());
		for(let row of rows){
			row.checked = this.props.selected.some(x => x.id == $(row).data('id'));
		}

		let entries = this.getCheckedItems()
		this.getDomRoot().querySelector(this.gridCheckAllSelector())
			.checked = entries.length == this.data().length;
	}

	disableSelection() {
		$('.cw_grid_check:not(:checked)', this.getDomRoot()).prop("disabled", true);
	}

	enableSelection() {
		$('.cw_grid_check', this.getDomRoot()).prop("disabled", false);
	}

	initGrid(){
		const config = this.getConfig();

		this.kendoGrid = $(this.ref.current)
			.kendoCustomGrid(config)
			.data('kendoCustomGrid');

		if( this.props.filter){
			this.kendoGrid.dataSource.filter(this.props.filter);
		}

		if (!this.props.skipSelectorColumn) {
			$(this.getDomRoot()).on('change', this.gridCheckSelector(), this.onRowSelected);
			this.getDomRoot().querySelector(this.gridCheckAllSelector())
				.addEventListener('change', e => this.setAllRowsChecked(e.target.checked));
		}

		$(this.getDomRoot()).on('click', '.k-grid-norecords', () => this.onNoRecordsClicked());

		if(this.props.onNoRecordsClicked){
			this.getDomRoot().classList.add('k-grid_no-records-clickable');
		}

		if (this.props.filterable) {
			let filterIcons = this.getDomRoot().querySelectorAll(this.gridFilterSelector());
			for (let icon of filterIcons) {
				icon.classList.add('k-i-arrowhead-s');
				icon.classList.remove('k-i-filter');
			}
		}

		if( this.props.onRowClicked){
			$(this.getDomRoot()).on('click', '.cw_grid_link', this.onRowClicked);
		}

		if (this.props.reorderRows) {
			this.kendoGrid.table.kendoSortable({
				cursor: "move",
				handler: '.drag-source',
				container: $(this.ref.current).find('tbody'),
				placeholder: function(element) {
					return element.clone().addClass("k-hover").css("opacity", 0.65);
				},
				filter: "> tbody > tr ",
				change: $.proxy(function(e) {
					let grid = this.kendoGrid,
						oldIndex = e.oldIndex , // The old position.
						newIndex = e.newIndex , // The new position.
						dataItem = grid.dataSource.getByUid(e.item.data("uid")); // Retrieve the moved dataItem.
					grid.dataSource.remove(dataItem);
					grid.dataSource.insert(newIndex, dataItem);
					this.props.change(grid.dataSource.data());
				}, this)
			})
		}

		if(this.props.showHeaderTooltip){
			this.kendoGrid.thead.kendoTooltip({
				filter: "th",
				width: 150,
				content: function (e) {
					var target = e.target;
					$(target).removeAttr('title');
					return $(target).find('.k-link').text();
				}
			});
		}

		this.props.height && $(this.ref.current).height(this.props.height);
		this.fillSelected();
	}

	getConfig() {
		const config = ReactHelpers.copy(this.props);

		config.dataSource = this.getDataSourceFromProps();
		config.schema = this.getSchema();
		config.change = e => {
			this.checkExpanded();
			this.props.change && this.props.change(e);
		}
		config.save = this.props.save;
		config.edit = this.props.onEdit ?? this.props.edit;

		config.height = this.props.fit ? '100%' : null;
		config.filterable = this.props.filterable || false;
		config.width = this.props.width;
		config.pageable = this.props.pageable ?? false;

		if (config.sortable === 'multiple') {
			config.sortable = {
				mode: 'multiple'
			}
		}

		config.onRowsSelected = this.props.onRowsSelected;
		config.dataBound = e => {
			e.sender.element.find('.k-button-icon').remove();
			this.props.dataBound && this.props.dataBound(e);
		}
		config.edit = e => {
			this.props.onEdit && this.props.onEdit(e);
		}
		config.columns = [];

		if(this.props.onDragStarted) {
			config.columns.push(
				{
					field: 'id',
					title: i('Drag me'),
					width: 30,
					editable: function() {
						return false;
					},
					sortable: false,
					filterable: false,
					template: item => `<div title="${this.props.dragTooltip}" draggable="true" class="drag-source"></div>`,
					headerTemplate:'',
					attributes: {
						// 'class': 'text_center'
					},
				}
			)
		}

		if (!this.props.skipSelectorColumn) {
			config.columns.push(GridColumnsHelpers.getSelectorColumn(this.guid));
		}

		config.columns = [...config.columns,...this.props.columns]

		config.detailInit = this.props.detailInit;

		config.filter = this.props.onFilter;
		return config;
	}

	getSchema(){
		let schema = this.props.schema ?? {};
		if(!this.props.skipSelectorColumn){
			setValueOnPath(schema, 'model.fields.id.editable', false);
			schema.model.id = "id";
		}

		return schema;
	}

	destroyGrid(){
		$(this.getDomRoot()).off();
		this.getDomRoot().classList.remove('k-grid_no-records-clickable');
		this.kendoGrid.destroy();
		$(this.ref.current).empty();
	}

	gridCheckSelector() {
		return `.cw_grid_check[data-guid='${this.guid}']`
	}

	gridCheckAllSelector() {
		return `.cw_grid_check_all[data-guid='${this.guid}']`
	}

	gridFilterSelector() {
		return `.k-i-filter`;
	}

	getDataSourceFromProps() {
		let dataSource = this.props.dataSource;
		if (!dataSource && this.props.dataSourceArray ) {
			dataSource = this.convertArrayToDataSource(this.props.dataSourceArray)
		}
		if( this.props.sort){
			dataSource.sort(this.props.sort);
		}
		if( this.props.group){
			dataSource.group(this.props.group);
		}
		return dataSource;
	}

	setDataArray(data) {
		this.kendoGrid.setDataSource(this.convertArrayToDataSource(data));
	}

	convertArrayToDataSource(data){
		return new kendo.data.DataSource({
			data: data,
			schema: this.getSchema(),
			sort: this.props.sort
		});
	}

	onRowSelected = e => {
		if (e) {
			const dataItem = this.kendoGrid.dataItem($(e.currentTarget).closest('tr'));
			this.props.onRowSelectionChanged && this.props.onRowSelectionChanged(e, dataItem, this);
		}

		let entries = this.getCheckedItems()

		this.getDomRoot().querySelector(this.gridCheckAllSelector())
			.checked = entries.length == this.data().length;

		this.props.onRowsSelected && this.props.onRowsSelected(entries, this);
	}

	onRowClicked = e => {
		const dataItem = this.kendoGrid.dataItem($(e.currentTarget).closest('tr'));
		this.props.onRowClicked(dataItem.toJSON(), e);
	}

	setAllRowsChecked(checked){
		let rows = this.getDomRoot().querySelectorAll(this.gridCheckSelector());
		for( let row of rows){
			row.checked = checked;
		}

		this.onRowSelected();

		if (this.props.onSelectAll) {
			this.props.onSelectAll(checked);
		}
	}

	getDomRoot(){
		return this.kendoGrid.element[0];
	}

	getRoot(){
		return this.kendoGrid;
	}

	getCheckedItems() {
		return Array.from(this.getDomRoot().querySelectorAll(`${this.gridCheckSelector()}:checked`))
			.map( checkbox => this.kendoGrid.dataItem(checkbox.closest('tr')).toJSON());
	}

	clear(){
		this.kendoGrid.setDataSource(new kendo.data.DataSource({
			data: []
		}));
	}

	onNoRecordsClicked = () => {
		this.props.onNoRecordsClicked && this.props.onNoRecordsClicked();
	}

	//-----------

	data(){
		return this.kendoGrid.dataSource.data();
	}

	dataSource(newDataSource){
		if(newDataSource){
			this.kendoGrid.setDataSource(newDataSource);
		}
		return this.kendoGrid.dataSource;
	}

	refresh(){
		this.kendoGrid.refresh();
	}

	push(newItem){
		newItem.id = Utils.guid();

		this.data().push(newItem);
	}

	async deleteSelected(apiCall){
		const selectedIds = this.getCheckedItems().map(x => x.id);

		if(apiCall){
			const result = await apiCall(selectedIds);
			if(!result.success){
				return false;
			}
		}

		let data = this.data();

		selectedIds.forEach( id => {
			let entry = data.find( x => x.id == id);
			data.remove(entry);
		});

		return true;
	}

	checkExpanded(row, directCheck){
		let selectedRow = directCheck ? row : this.kendoGrid.select();
		let myRow = selectedRow[0];
		let messageEl = $(myRow).find('.to_expand');
		if ($(messageEl).hasClass('cw_message_expanded')) {
			$(messageEl).removeClass('cw_message_expanded').addClass('ellipsis');
		} else {
			this.kendoGrid.wrapper.find('.k-grid-content').find('td.cw_message_expanded').removeClass('cw_message_expanded').addClass('ellipsis');
			$(messageEl).addClass('cw_message_expanded').removeClass('ellipsis');
		}
	}
}

export default Grid;

export function fullTextSearch(columns, value){
	if (!value || value.trim() == '') {
		return null;
	}

	return {
		logic: 'or',
		filters: columns.map( column =>{
			return {
				field: column,
				operator: 'contains',
				value: value
			};
		})

	}
}

//targetColumns - the ojbect of columns with all the other settings. If empty then source columns object is returned
//sourceColumns - the object of columns with just 'hidden' flag set
export function updateHiddenColumns(targetColumns, sourceColumns){
	if(targetColumns == null)
		return sourceColumns;

	let result = JSON.parse(JSON.stringify(targetColumns));

	for(const columnName of Object.keys(sourceColumns)){
		if(result[columnName] != null) {
			result[columnName].hidden = sourceColumns[columnName].hidden
		}
	}

	return result;
}

export function getGridStateForSaving(grid) {
	if(!grid)
		return {};

	let filter = grid.dataSource.filter();
	if (filter && filter.filters) {
		filter = JSON.parse(JSON.stringify(filter));
		if (grid.dataSource.options.serverFiltering) {
			filter.filters = Utils.changeDateFilterToString(filter.filters);
		} else {
			filter.filters = Utils.changeDateFilterToTimestamp(filter.filters);
		}
	}

	return {
		columns: Utils.getGridColumns(grid),
		sort: grid.dataSource.sort() || [],
		filter: filter || [],
		group: grid.dataSource.group() || []
	}
}

export const buildColumns = (allColumns, {
	enabledColumns,
	gridColumnsLastSnapshot = [],
	hiddenCallback = field => {}
}) => {
	//all columns - the list of all possible columns with all required grid settings
	//enabled columns -['fieldName']. Usually represents a set of enabled columns on a config page (like widget form)
	//gridColumnsLastSnapshot - the last saved grid.columns array. Used to determined actual order and width
	//newColumnsMapping - {newColumn: existingColumn}]- used te specify where to put a recently added column (it does not exist an snapshot, we just added it)
	//hiddenCallback - field => {} returns true if column should be hidden

	return new ColumnsBuilder(allColumns, {enabledColumns, gridColumnsLastSnapshot, hiddenCallback})
		.build();
}



class ColumnsBuilder{
	constructor(allColumns, settings) {
		this.allColumns = allColumns
		Object.assign(this, settings);

		//legacy structure support
		if (this.enabledColumns.length > 0 && typeof this.enabledColumns[0] != 'string') {
			this.enabledColumns = this.enabledColumns.filter(x => x.shown).map(x => x.field);
		}
	}

	build(){
		this.result = [];

		this.processSnapshot();
		this.addMissingColumns();

		return this.result;
	}

	//adding columns from the last snapshot. Usually contains all required columns unless we recently added a new one
	processSnapshot(){
		const snapshotColumns = this.getSnapshotColumnsList();

		for (const snapshotColumn of snapshotColumns) {
			const prototypeColumn = this.allColumns.find(x => x.field == snapshotColumn.field);

			if (!prototypeColumn)
				continue

			this.result.push(prototypeColumn);

			prototypeColumn.hidden = snapshotColumn.hidden
				|| this.isColumnDisabled(snapshotColumn.field);

			if (snapshotColumn.width) {
				prototypeColumn.width = snapshotColumn.width;
			}
		}
	}

	isColumnDisabled(field){
		return (this.enabledColumns != null && !this.enabledColumns.includes(field))
			|| this.hiddenCallback(field)
	}

	getSnapshotColumnsList() {
		//columns are stored as [{field: {index, width, hidedn}}]
		//and we need just an ordered array

		return Object.keys(this.gridColumnsLastSnapshot)
			.map(x => ({
				...this.gridColumnsLastSnapshot[x],
				field: x
			}))
			.sort((a, b) => a.index - b.index);
	}

	addMissingColumns(){
		//just in case there are columns left in all columns list
		for (const prototypeColumn of this.allColumns) {
			if (this.result.find(x => x.field == prototypeColumn.field))
				continue;

			if(this.isColumnDisabled(prototypeColumn.field))
				continue;

			this.result.push(prototypeColumn);
		}
	}
}

Grid.propTypes = {
	//fires when a row is checked or unchecked. Arguments: e, dataItem of the row, grid
	onRowSelectionChanged: PropTypes.func,
	//fires when a row is checked or uncheked. Arguments: [dataItem] of all selectedRows, grid
	onRowsSelected: PropTypes.func,
	onRowClicked: PropTypes.func,
	onCellClicked: PropTypes.func,
	columns: PropTypes.array.isRequired,
	skipSelectorColumn: PropTypes.bool,
	dataSourceArray: PropTypes.array,
	dataSource: PropTypes.object,
	onNoRecordsClicked: PropTypes.func,
	disableSelection: PropTypes.bool,
	//selectable: PropTypes.oneOf["row", "cell", "multiple, row", "multiple, cell"],
	fit: PropTypes.bool,
	dynamicFit: PropTypes.bool,
	filter: PropTypes.any,
	sort: PropTypes.array,
	//this is fired when row 'selected' in kendo terms - it is highlihted and returned by select() method
	//onRowHighlighted: PropTypes.func
	onFilter: PropTypes.func,
	selected: PropTypes.array
};
