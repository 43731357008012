import React, {useCallback, useState} from 'react'
import {observer} from "mobx-react";

import {AntSelect, AntSelectEditableTagOptionsEntry} from "controls/react/ant/antSelect"
import {makeAutoObservable, runInAction, toJS} from "mobx";
import {newGuid} from "tools/guid";
import {FormEntryNew} from "./react/form/formEntryNew";
import {AssetTarget} from "framework/entities/asset";



const i18n = require('core/localization').translator({
	'Asset target is in use': {
		no: 'Ressursmålet er i bruk'
	},
	'Please add targets to your asset': {
		no: 'Legg til m\u00e5l for din ressurs'
	},
	'Asset target': {
		no: 'Ressursm\u00e5l'
	}
});


type AssetTargetsEditorProps = {
	targets: AssetTarget[]
	disabled?: boolean
	onFocus?: () => void
	onBlur?: () => void
	onChange?: (targets: AssetTarget[]) => void,
}

class AssetTargetsEditorStore {
	options: AntSelectEditableTagOptionsEntry<string>[] = []
	value: string[] = []
	initialized: boolean = false

	constructor(public props: AssetTargetsEditorProps) {
		makeAutoObservable(this)

		this.init()
	}

	init(){
		this.options = this.props.targets.map(x => ({
			value: x.id,
			label: x.address,
			editable: !x.inUse,
			removable: !x.inUse
		}))

		this.value = this.props.targets.map(x => x.id)
		this.initialized = true
	}

	get optionsActual(){
		return toJS(this.options)
	}
}

export const AssetTargetsEditor = observer(class AssetTargetsEditorInner extends React.Component<AssetTargetsEditorProps> {
	store: AssetTargetsEditorStore
	constructor(props: AssetTargetsEditorProps) {
		super(props)

		this.store = new AssetTargetsEditorStore(this.props)
	}

	render(){
		if(!this.store.initialized)
			return null

		return <AntSelect
			options={this.store.optionsActual}
			mode={"tags"}
			sortValues={true}
			editable={true}
			onFocus={this.props.onFocus}
			onBlur={this.props.onBlur}
			disabled={this.props.disabled}
			nonRemovableMessage={i18n('Asset target is in use')}
			onChange={this.onChange}
			value={this.store.value}
			onLabelEdit={this.onLabelEdit}/>
	}

	onChange = (values: string[]) => {
		runInAction(() => {
			values.forEach((value, index) => {
				let item = this.store.options.find(x => x.value == value)
				if(!item) {
					let id = newGuid()
					this.store.options.push({value: id, label: value, editable: true, removable: true})
					values[index] = id
				}
			})

			this.store.value = values
		})

		this.fireOnChange()
	}

	fireOnChange(){
		let targets = this.store.value.map(x => ({
			address: this.store.options.find(o => o.value == x).label,
			id: x
		}))

		this.props.onChange?.(targets)
	}

	onLabelEdit = (value: string, label: string)=> {
		let item = this.store.options.find(x => x.value == value)
		if(item){
			item.label = label
		}

		this.fireOnChange()
	}
})

export const AssetTargetsFormElement = observer((props: {targetList:  AssetTarget[], onChange?: (value: AssetTarget[]) => void}) => {
	const {targetList, onChange} = props;
	const [model, setModel] = useState({assetTargetsList: targetList});
	const onValueChanged = useCallback((value: AssetTarget[]) => {
		onChange?.(value);
		setModel({assetTargetsList: value});
	}, []);
	return <FormEntryNew label={i18n('Asset target')} model={model} modelField={'assetTargetsList'} validationOnly={true}>
		<AssetTargetsEditor onChange={onValueChanged}
							targets={model.assetTargetsList}
		/>
	</FormEntryNew>
})



