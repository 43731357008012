import {makeAutoObservable, toJS} from "mobx"
import {observer} from "mobx-react"
import React from "react"

import {Section} from "controls/react/layout/section"
import FormEntry from "controls/react/form/formEntry"
import {AccountsSelector} from "controls/react/accountSelector"
import {linkModel, MobxManager} from "framework/mobx-integration"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {apiFetch} from "framework/api";
import {ShowAsLabel} from "controls/designer/dataSourcesManager/sharedProperties"
import {UnorderedListOutlined} from "@ant-design/icons"
import {AntButton} from "controls/react/ant/antButton"
import {searchAssetGroupsLite} from "areas/assets/api"
import {AntOption, AntSelect} from "controls/react/ant/antSelect";
import {AntCheckbox} from "controls/react/ant/antCheckbox"
import {designerLocalizations} from "areas/dashboards/graph-editor-extensions/designerLocalizations";
import {AssetGroup} from "framework/entities/assetGroup";
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource";
import {Designer} from "controls/designer/designer";
import {MxCell} from "controls/designer/mxGraphInterfaces"
import {AssetGroupDataSourceElement} from "controls/designer/dataSourcesManager/assetGroupDataSourceElement";
import {showDataSourceWindow} from "controls/designer/dataSourcesManager/dataSourceWindow";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "framework/applicationState";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {useStore} from "core/react/useStore";

const i18n = require('core/localization/localization').translator(designerLocalizations);

export class AssetGroupDataSourceEditorStore {
	assetGroupsDataListInvalid: boolean = true
	assetGroupsDataListLoading: boolean = false
	assetGroupsDataList: AssetGroup[] = []
	mobx = new MobxManager()

	dataSource: CellAssetGroupDataSource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellAssetGroupDataSource
	}

	init(){
		this.mobx.reaction(() => toJS(this.dataSource.accounts), async () => {
			await this.loadAssetGroups()
		})

		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}

		this.loadAssetGroups()
	}

	loadAssetGroups = async () => {
		if (this.dataSource.accounts.length == 0) {
			return;
		}

		this.assetGroupsDataListLoading = true

		let result = await apiFetch(searchAssetGroupsLite({
			accountIds: this.dataSource.accounts,
			includeSubaccounts: true
		}))
		if(result.success){
			this.assetGroupsDataList = result.data.items
			this.assetGroupsDataListInvalid = false
		}

		this.assetGroupsDataListLoading = false
	}

	showAssetGroupsWindow = () => {
		showDataSourceWindow({
			title: i18n('Asset group'),
			selectedIds: this.dataSource?.assetGroups?.map(x => x.id) ?? [],
			data: this.assetGroupsDataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			},{
				field: 'accountName',
				title: i18n('Account'),
				filterable: 'string'
			},{
				field: 'groupType',
				title: i18n('Type'),
				filterable: 'string'
			},{
				field: 'description',
				title: i18n('Description'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.assetGroups = items.map(x => ({
					id: x.id,
					accountId: x.accountId
				}))
			},
			designerStore: this.designerStore
		})
	}

	destroy() {
		this.mobx.destroy()
	}
}

export const AssetGroupDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => new AssetGroupDataSourceEditorStore(designerStore), {
		deps: [designerStore.dataSourcesManager.selected]
	})

	return <Section appearance={'none'} childrenPadding={true}>
		<FormEntryNew label={i18n('Account')} model={store.dataSource} modelField={"accounts"}>
			<AccountsSelector hidePlaceholder={true}/>
		</FormEntryNew>

		<AssetGroupsSelector store={store}/>

		<ShowAsLabel datasource={store.dataSource}/>

		<FormEntry>
			<AntCheckbox {...linkModel(store.dataSource, 'showHI')}>{i18n('Show Health Index')}</AntCheckbox>
		</FormEntry>

		<FormEntry>
			<AntCheckbox {...linkModel(store.dataSource, 'showReasons')}>{i18n('Show Health Reasons')}</AntCheckbox>
		</FormEntry>

		{designerStore.isApplicationMode &&
			<FormEntry>
				<AntCheckbox {...linkModel(store.dataSource, 'expanded')}>{i18n('Expanded')}</AntCheckbox>
			</FormEntry>
		}

		{designerStore.isApplicationMode &&
			<FormEntry>
				<AntCheckbox {...linkModel(store.dataSource, 'showSummary')}>{i18n('Show Summary Information')}</AntCheckbox>
			</FormEntry>
		}
	</Section>
})

const AssetGroupsSelector = observer( (props: {store: AssetGroupDataSourceEditorStore}) => {
	const store = props.store

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                               size={"small"}
	                               onClick={store.showAssetGroupsWindow}
	                               disabled={store.assetGroupsDataListInvalid}/>

	return <FormEntryNew label={i18n('Asset Group')} model={store.dataSource} modelField={"assetGroups"} headerAdditionalContent={icon}>
		<AntSelect disabled={store.assetGroupsDataListInvalid}
		           dataList={store.assetGroupsDataList}
		           loading={store.assetGroupsDataListLoading}
		           triggerChangeOnClose={true}
		           valueAsObject={true}
		           objectFields={['id', 'accountId']}
		           mode={'multiple'}>
			{store.assetGroupsDataList.map(x => <AntOption value={x.id} key={x.id + x.accountId}>{x.accountName}\{x.name}</AntOption>)}
		</AntSelect>
	</FormEntryNew>;
})

export type AssetGroupDataSourceEntry = {
	id: string
	accountId: string
}

export class CellAssetGroupDataSource implements CellDataSourceBase{
	id: string
	accounts: string[] = []
	showAsLabel: boolean
	type: CellDataSourceType.AssetGroup = CellDataSourceType.AssetGroup
	assetGroups: AssetGroupDataSourceEntry[] = []
	containerCellWidth: number
	containerCellHeight: number
	showHI: boolean
	showReasons: boolean
	expanded: boolean

	showSummary: boolean = false

	mobx = new MobxManager()

	constructor() {
		makeAutoObservable(this)

		this.mobx.reaction(() => toJS(this.accounts), () => {
			this.assetGroups = []
		})
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new AssetGroupDataSourceElement(designer, cell)
	}

	valid(){
		return this.assetGroups.length > 0
	}

	destroy(){
		this.mobx.destroy()
	}
}
