import './antModal.less'

import {Observer} from "mobx-react";
import React, {MutableRefObject, ReactNode, useCallback, useState} from 'react';
import {Modal, ModalProps} from 'antd';
import {Button} from "antd"
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';

import {State} from 'tools'
import {ResizableBox, ResizeCallbackData, ResizeHandle} from "react-resizable";
import {createRoot} from 'react-dom/client';
import {Toolbar} from "controls/react/layout/toolbar";
import {runAsAsync} from "tools/utils";
import {newGuid} from "tools/guid";
import classNames from "classnames";
import {getTopLeftPagePosition} from "../../modalWindow";
import {CssVariables} from "styles/cssVariables";
import {useTriggerContext} from "controls/react/ant/utils";
import {TriggerContext} from './utils'
import {AntButton} from "controls/react/ant/antButton";

const i = require('core/localization').translator();

export enum ModalPosition {
	Centered = 'centered',
	TopLeft = 'topLeft',
	Default = 'default',
	TopRight = 'topRight',
	Mouse = 'mouse',
	Anchor = 'anchor',
	Custom = 'custom'
}

export type AntModalProps = Omit<ModalProps, 'centered'> & {
	mode?: 'update' | 'create'
	positionType?: ModalPosition
	width?: number
	draggable?: boolean
	height?: number
	draggableHandle?: string
	draggableExclusion?: string
	resizable?: boolean
	defaultFooterButtons?: ('ok' | 'cancel')[]
	cancelText?: string
	positionAnchor?: React.MutableRefObject<HTMLElement>
	closeOn?: 'clickOutside'
	contentDivRef?: React.MutableRefObject<HTMLDivElement>
}

type AntModalPropsInternal = AntModalProps & {
	id: string
	customToolbarSet?: boolean
}

let mouseCurrentPosition = {
	left: 0,
	top: 0
}

document.addEventListener('mousemove', e => {
	mouseCurrentPosition.left = e.x
	mouseCurrentPosition.top = e.y
})

const defaultAntModalWidth = 520

const b = require('b_').with('ant-modal-wrapper')
const resizable = require('b_').with('react-resizable')


export const AntModal: React.FunctionComponent<AntModalProps> = (propsInitial => {
	let {context, subPopupElements} = useTriggerContext()

	let props = useDefaultValues(propsInitial)
	props = useFixedHeight(props)
	props = useInitialModalPosition(props)
	props = useOnScreenPosition(props)
	props = useCustomCloseConditions(props, subPopupElements)
	props = useResizableDraggableRenderer(props)
	props = useCreateUpdateMode(props)
	props = useDefaultFooter(props)
	props = useZIndexManager(props)

	const contentClassNames = b('content', {
		draggable: props.draggable
	});

	const {title, children, rootClassName, ...rest} = props

	const rootClassNameDerived = classNames(b({
		'custom-footer': props.customToolbarSet !== false,
	}), rootClassName)

	return <TriggerContext.Provider value={context}>
		<Modal rootClassName={rootClassNameDerived} {...rest}>
			<div ref={props.contentDivRef} className={contentClassNames} id={props.id}>
				{title && (title.type?.name == 'Toolbar' ? title : <Toolbar title={title}/>)}
				{children}
			</div>
		</Modal>
	</TriggerContext.Provider>
});

type OpenModalProps = Omit<AntModalProps, 'open'> & { closeTriggerExtractor?: (close: () => void) => void }




function useDefaultValues(props: AntModalProps) : AntModalPropsInternal{
	let [id] = React.useState(() => newGuid())
	const contentDivRef = React.useRef<HTMLDivElement>()
	return {
		id,
		contentDivRef,
		...props
	}
}

function useZIndexManager(props: AntModalPropsInternal) {
	const [zIndex, setZIndex] = React.useState<number | null>(null)
	const zIndexRef = React.useRef<number | null>(null)

	const onClick = React.useCallback(() => {
		if (props.zIndex && !isNaN(props.zIndex)) {
			setZIndex(props.zIndex)
		} else {
			if (!zIndexRef.current || CssVariables.currentZIndex > zIndexRef.current) {
				CssVariables.currentZIndex++
				zIndexRef.current = CssVariables.currentZIndex
				setZIndex(CssVariables.currentZIndex)
			}
		}
	}, [props.zIndex])

	React.useEffect(() => {
		if (props.contentDivRef.current) {
			const localRef = props.contentDivRef.current
			localRef.addEventListener('click', onClick)
			onClick()
			return () => localRef.removeEventListener('click', onClick)
		}
	}, [props.contentDivRef.current, onClick])

	return {...props, zIndex}
}

function useCreateUpdateMode(props: AntModalPropsInternal) {
	const {okText, cancelText, ...rest} = props
	if (props.okText === undefined) {
		props.okText = props.mode == 'update' ? i('Update') : i('Create');
	}
	if (props.cancelText === undefined) {
		props.cancelText = i('Cancel');
	}

	return {
		...rest,
		okText: okText ?? (props.mode == 'update' ? i('Update') : i('Create')),
		cancelText: cancelText ?? i('Cancel')
	}
}

function useDefaultFooter(props: AntModalPropsInternal) {
	if (props.footer !== undefined)
		return props

	//ant modal comes with cancel/ok buttons but we use ok/cancel in the app so we swap them here
	const footer = [];
	if (!props.defaultFooterButtons || props.defaultFooterButtons.includes('ok')) {
		footer.push(<AntButton onClick={props.onOk} type={'primary'}
		                       key={'ok'} {...props.okButtonProps}>{props.okText}</AntButton>);
	}
	if (!props.defaultFooterButtons || props.defaultFooterButtons.includes('cancel')) {
		footer.push(<AntButton onClick={props.onCancel}
		                       key={'cancel'} {...props.cancelButtonProps}>{props.cancelText}</AntButton>);
	}

	return {
		...props,
		customToolbarSet: false,
		footer
	}
}

export function useResizableDraggableRenderer(props: AntModalPropsInternal) {
	const [size, setSize] = useState(() => ({height: props.height, width: props.width}))

	let {modalRender, width, mask, ...rest} = props

	const draggableRef = React.useRef<HTMLDivElement>(null)

	let {wrapDraggable, bounds, draggable} = useDraggable(props, draggableRef)

	const wrapResizable = useResizable(props, [size, setSize])

	React.useEffect(() => {
		setSize({width: props.width, height: size.height})
	}, [props.width])

	React.useEffect(() => {
		setSize({width: size.width, height: props.height})
	}, [props.height])

	return {
		...rest,
		draggable,
		width: size.width,
		mask: draggable && mask === undefined ? false : mask,
		modalRender: React.useMemo(() => {
			if (!draggable && !resizable) {
				return null;
			}
			return (modal: React.ReactNode) => {
				return wrapDraggable(wrapResizable(<div ref={draggableRef} className={b('wrapper')}>{modalRender?.(modal) ?? modal}</div>));
			}
		}, [draggable, resizable, bounds, size.width, size.height, wrapResizable, wrapDraggable, modalRender])
	};
}

export function useInitialModalPosition(props: AntModalPropsInternal) {
	let {style, positionType, ...others} = props;
	positionType ??= ModalPosition.Default;

	const styleEffective = React.useMemo(() => {
		if (positionType == ModalPosition.Default || positionType == ModalPosition.Centered) {
			return style
		}

		let result: React.CSSProperties = {
			...(style ?? {})
		}

		const topLeftPosition = getTopLeftPagePosition()
		if (positionType == ModalPosition.TopLeft) {
			result.top = topLeftPosition.top
			result.left = topLeftPosition.left
		} else if (positionType == ModalPosition.TopRight) {
			result.top = topLeftPosition.top
			result.left = window.innerWidth - topLeftPosition.left - (others.width ?? defaultAntModalWidth)
		} else if (positionType == ModalPosition.Mouse) {
			result.top = Math.max(mouseCurrentPosition.top, topLeftPosition.top)
			result.left = mouseCurrentPosition.left
		} else if (positionType == ModalPosition.Anchor && !!others.positionAnchor) {
			const rect = others.positionAnchor.current.getBoundingClientRect();
			result.top = rect.top + rect.height;
			result.left = rect.left + rect.width;
		}

		result.margin = '0'

		return result
	}, [])

	return {
		...others,
		style: styleEffective,
		centered: positionType == 'centered'
	};
}

function useCustomCloseConditions(props: AntModalPropsInternal, popups: Record<string, HTMLElement>) {
	React.useEffect(() => {
		if(!props.closeOn || !props.open)
			return

		let onClick = (e: MouseEvent) => {
			if(!props.contentDivRef?.current)
				return

			if(e.target instanceof HTMLElement) {
				let clickOnWindow = false
				let target = e.target

				if (props.contentDivRef.current.contains(target)) {
					clickOnWindow = true
				}else {
					clickOnWindow = Object.values(popups).some(x => {
						return !!x && (x == target || x.contains(target))
					})
				}

				if(!clickOnWindow){
					props.onCancel(null)
				}
			}
		}

		let tuple = {
			onClick, destroyed: false
		}

		requestIdleCallback(() => {
			if(tuple.destroyed)
				return

			document.addEventListener('click', tuple.onClick)
		})

		return () => {
			document.removeEventListener('click', tuple.onClick)
			tuple.destroyed = true
		}
	}, [props.onClose, props.contentDivRef, props.closeOn, props.open])

	return props
}

function useOnScreenPosition(props: AntModalPropsInternal) {
	let {style, width, height, ...others} = props;

	const positioning = React.useMemo(() => {
		let result = {
			style: style,
			width: width,
			height: height
		}

		if (window.innerWidth < props.width){
			result.width = window.innerWidth - 20
		}

		if (window.innerHeight < props.height){
			result.height = window.innerHeight - 20
		}

		if (style == null || (typeof style.top != 'number' && typeof style.left != 'number'))
			return result

		const footerHeight = () => {
			if (props.footer === undefined || props.footer === null) {
				return 0
			}
			return 60
		}

		if (typeof result.style.left == 'number'){
			if (window.innerWidth < result.width + result.style.left) {
				result.style.left = window.innerWidth - result.width - 10
			}
		}

		if (typeof result.style.top == 'number'){
			if (window.innerHeight < result.height + result.style.top) {
				result.style.top = window.innerHeight - result.height - 10 - footerHeight()
			}
		}

		return result

	}, [props.style, props.width, props.height])

	return {
		...others,
		style: positioning.style,
		width: positioning.width,
		height: positioning.height
	}
}

const CustomResizeHandle = (props: any) => {
	const {resizeHandle, innerRef, ...others} = props;
	const modificator: { [id: string]: boolean } = {};
	modificator[resizeHandle] = true;
	return <span className={resizable('resize-handle', modificator)} ref={innerRef} {...others} />
}

export function useResizable(props: AntModalPropsInternal, sizeState: [{ height: number, width: number }, React.Dispatch<React.SetStateAction<{ height: number, width: number }>>]) {
	let {resizable} = props;
	const [{width, height}, setSize] = sizeState;

	const onResize = React.useCallback((e: React.SyntheticEvent, data: ResizeCallbackData) => {
		setSize({height: data.size.height, width: data.size.width})
	}, []);

	return React.useMemo(() => (content: React.ReactNode) => {
		if (!resizable) {
			return content;
		}
		return <div>
			<ResizableBox width={width ?? 0}
						  height={height ?? 0}
						  resizeHandles={['se', 'e', 's']}
						  handle={(resizeHandle: ResizeHandle, ref: React.RefObject<any>) => <CustomResizeHandle
							  resizeHandle={resizeHandle} innerRef={ref}/>}
						  onResize={onResize}>
				{content}
			</ResizableBox>
		</div>
	}, [resizable, width, height]);
}

export function useDraggable(props: AntModalPropsInternal, draggableRef: MutableRefObject<HTMLDivElement>) {
	const [bounds, setBounds] = React.useState(() => ({left: 0, top: 0, bottom: 0, right: 0}))
	let {draggable, draggableHandle, draggableExclusion} = props

	draggable ??= true;
	draggableHandle ??= `.ant-modal-wrapper__content_draggable[id="${props.id}"] > .section > .toolbar, .ant-modal-wrapper__content_draggable[id="${props.id}"] > .toolbar`;
	draggableExclusion ??= 'input';

	const onStart = React.useCallback((_event: DraggableEvent, uiData: DraggableData) => {
		const {clientWidth, clientHeight} = window.document.documentElement;
		const targetRect = draggableRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}

		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y),
		});
	}, []);

	return {
		wrapDraggable: React.useMemo(() => (content: React.ReactNode) => {
			if (!draggable) {
				return content;
			}
			return <Draggable handle={draggableHandle}
			                  bounds={bounds}
			                  cancel={draggableExclusion}
			                  onStart={(event, uiData) => onStart(event, uiData)}>
				{content}
			</Draggable>
		}, [draggable, bounds]),
		bounds,
		draggable
	};
}

export function useFixedHeight(props: AntModalPropsInternal) {
	const {styles, ...rest} = props
	const {body, ...otherStyles} = (styles ?? {})

	let bodyStylesUpdated = React.useMemo(() => {
		if (props.height == null || props.resizable ) {
			return styles?.body
		}

		let result: React.CSSProperties = {
			...(body ?? {})
		}

		result.height = props.height + 'px'

		return result
	}, [styles?.body, props.height, resizable])

	return {
		styles: {
			body: bodyStylesUpdated,
			...otherStyles
		},
		...rest
	}
}

export function openModal(props: (() => OpenModalProps) | OpenModalProps, content: React.ReactNode) {
	return new Promise<boolean>((resolve) => {
		const [root] = createContainer();

		root.render(<>
			<Observer>{() => {
				const getProps = (p: (() => OpenModalProps) | OpenModalProps) => {
					if (typeof p == 'function') {
						return p();
					}
					return p;
				};
				const {onCancel, onOk, ...restProps} = getProps(props);

				const onCancelWrapper = (e: React.MouseEvent<HTMLButtonElement>) => {
					root.unmount();
					onCancel?.(e);
					resolve(false);
				}

				const onOkWrapper = async (e: React.MouseEvent<HTMLButtonElement>) => {
					if (onOk) {
						try {
							resolve(await runAsAsync(onOk, [e]));
						} catch {
							resolve(false);
						}
					}
					if (!e.isDefaultPrevented()) {
						root.unmount();
					}
				}
				restProps.closeTriggerExtractor?.(() => onCancelWrapper(null))
				return <AntModal onOk={onOkWrapper}
								 onCancel={onCancelWrapper}
								 open={true}
								 {...restProps}>
					{content}
				</AntModal>
			}}</Observer>
		</>)

		State.mainApp.registerForOnNavigate(() => {
			root.unmount();
			resolve(false);
		});
	});
}

export function createContainer() {
	const container = document.createElement('div')
	document.body.appendChild(container)

	const root = createRoot(container)

	return [root, container] as const
}

export function createContainerRoot() {
	return createContainer()[0];
}
