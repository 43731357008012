import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {GridStore, linkGridAdditionalPayload} from "controls/grid/gridStore";
import {DrilldownEventObject} from "highcharts";
import {makeAutoObservable} from "mobx";
import {apiFetch} from "framework/api";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import moment from "moment";
import {ApplicationState} from "framework/applicationState";
import {observer} from "mobx-react";
import React from "react";
import {Section, Toolbar, ToolbarItemPosition} from "controls/react/layout";
import {Link} from "controls/react/link";
import {TimePeriodAppearance, TimePeriodSelector} from "controls/react/form/timePeriodSelector";
import {EventsTimePeriods} from "areas/summary/events/eventsSummaryPage";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {Grid} from "controls/grid/grid";
import {
	DataSourcesChartWidgetConfig
} from "controls/designer/features/widgets/datasources/datasourceChartWidget";
import {DataSourcesChartWidgetStore} from "controls/designer/features/widgets/datasources/datasourceStores";

const i18n = require('core/localization').translator({
	'Click to view all for': {
		en: 'Click to view all for {0}',
		no: 'Klikk for å se alt for {0}'
	}
}, sharedLocalization);

type DrilledDownModalProps = {
	store: DrilledDownWidgetStore
};

export class DrilledDownWidgetStore {
	gridStore: GridStore<any>

	get category() {
		return this.e.point.category;
	}

	get series() {
		return this.widgetStore.datasourceRuleConfig[this.key]?.label ?? this.key;
	}

	constructor(public widgetStore: DataSourcesChartWidgetStore, private config: DataSourcesChartWidgetConfig, private key: string, private e: DrilldownEventObject) {
		makeAutoObservable(this);
	}

	init = async () => {
		const confRequest = this.widgetStore.getDataSourceFiltersConfiguration(this.key, this.e);
		const dataRequest = this.widgetStore.getDataRequest(this.key, this.e);
		const filtersConfig = (await apiFetch(confRequest)).data

		this.gridStore = new GridStore<any>({
			columns: this.getColumns(filtersConfig),
			dataProvider: new RemoteDataProvider({
				dataSource: dataRequest,
				filtersConfiguration: filtersConfig
			}),
			defaults:{
				payload: {
					timePeriod: {
						period: TimePeriodType.Last24Hours
					},
					showHistoricEvents: false
				}
			},
			selection: GridSelectionType.None
		})
	}

	onDrillUp = async () => {
		const confRequest = this.widgetStore.getDataSourceFiltersConfiguration(this.key, this.e);
		const dataRequest = this.widgetStore.getDataRequest(this.key, this.e);
		confRequest.payload.drillDownField = '';
		dataRequest.payload.drillDownField = '';

		const filtersConfig = (await apiFetch(confRequest)).data

		this.gridStore.destroy();
		this.key = undefined;

		this.gridStore = new GridStore<any>({
			columns: this.getColumns(filtersConfig),
			dataProvider: new RemoteDataProvider({
				dataSource: dataRequest,
				filtersConfiguration: filtersConfig
			}),
			defaults:{
				payload: {
					timePeriod: {
						period: TimePeriodType.Last24Hours
					},
					showHistoricEvents: false
				}
			},
			selection: GridSelectionType.None
		})
	}

	getColumns(rules: RulesConfiguration){
		return Object.keys(rules)
			.filter(x => x != 'id')
			.map(columnName => {
				let column : GridColumnConfig<any> = {
					field: columnName,
					// hack from Bernt
					title: rules[columnName].label != 'rowHeader' ? rules[columnName].label : '',
					renderer: item => {
						if (rules[columnName].type == 'integer') {
							return item[columnName]?.toFixed(this.config.decimalsNumber ?? 0) ?? '';
						}
						if (rules[columnName].type == 'date' && !!item[columnName]) {
							return moment(item[columnName]).format(ApplicationState.momentDateTimeFormat);
						}
						return item[columnName] ?? '';
					}
				}

				return column
			})
	}

	destroy() {
		this.gridStore.destroy();
	}
}

export const DrilledDownModal = observer(class InnerModal extends React.Component<DrilledDownModalProps> {
	store: DrilledDownWidgetStore;
	constructor(props: DrilledDownModalProps) {
		super(props);
		this.store = props.store;
	}

	async componentDidMount() {
		await this.store.init();
	}

	componentWillUnmount() {
		this.store.destroy();
	}

	getTitle = () => {
		if (this.store.category && this.store.series) {
			return <><Link onClick={this.store.onDrillUp} title={i18n('Click to view all for', this.store.category)}>{this.store.category}</Link> / {this.store.series}</>
		}
		if (!this.store.category && !!this.store.series) {
			return <>{this.store.series}</>
		}
		if (!!this.store.category && !this.store.series) {
			return <Link onClick={this.store.onDrillUp} title={i18n('Click to view all for', this.store.category)}>{this.store.category}</Link>
		}
		return <></>
	}

	render() {
		if (!this.store.gridStore?.selfInitialized) {
			return null;
		}
		return <Section appearance="none" height={"fit"}>
			<Toolbar>
				<div position={ToolbarItemPosition.BEFORE_TITLE}>{this.getTitle()}</div>
				<TimePeriodSelector periods={EventsTimePeriods}
									appearance={TimePeriodAppearance.Buttons}
									size={"small"}
									{...linkGridAdditionalPayload(this.store.gridStore, "timePeriod")}/>
				<DataSizeIndicator store={this.store.gridStore}/>
				<GridSearchInput store={this.store.gridStore}/>
			</Toolbar>
			<Grid store={this.store.gridStore}/>
		</Section>
	}
})
