import Dialog from 'controls/dialog'
import State from 'tools/state'
import UserPrefs, {UserPrefsTool} from 'tools/userPrefs'
import Utils from "../tools/utils";
export let PreferencesPanel = function (config) {
	Utils.apply(this, config);

	if (!this.searchInput) {
		this.initComponent();
	} else {
		this.searchInput.off().on('keyup', $.proxy(this.onSearchKeyUp, this));
	}
};

export default PreferencesPanel;

jQuery.extend(PreferencesPanel.prototype, {
	/**
	 * Main init function
	 */
	initComponent: function () {
		if( typeof this.renderTo == 'string'){
			this.renderTo = '#' + this.renderTo;
			this.renderHandler = $(this.renderTo);
		}else{
			this.renderHandler = this.renderTo;
		}

		var showCounter = true;
		var html = '';
		var searchHeaderHtml = '';
		var gridTarget;
		if (this.secondaryGrid) {
			gridTarget = 'secondary';
		} else {
			gridTarget = 'primary';
		}
		this.searchRenderHandler = $('#' + this.searchHeaderRenderer);
		if (this.counter === false) {
			showCounter = false;
		}
		this.userPrefsHandler ??= UserPrefs;
		this.searchTimer = null;
		this.searchTimeout = 1000;
		this.defaultFsViewId = this.getSettings(this.defaultPrefViewKey, this.section);
		if (!this.hideViews) {
			html += '<button class="k-button k-button-icon cw_view_button" title="' + lang.grid.filter.FILTER_PREFERENCES + '">';
			html += '<span class="glyphicons filter"></span>';
			html += '</button>';
		}

		html += '<div class="cw_filters_header">';
		if (this.searchHeader) {
			searchHeaderHtml += '<input class="cw_search_box right" data-target="' + gridTarget + '" type="text" placeholder="' + lang.SEARCH + '" />';
		}
		if (showCounter) {
			if (this.searchHeader) {
				searchHeaderHtml += '<div class="cw_grid_results" style="float: right">';
				searchHeaderHtml += '<span class="cw_visible_items" data-target="' + gridTarget + '"></span><span class="hide cw_items_separator">/</span><span class="cw_total_items" data-target="' + gridTarget + '"></span>';
				searchHeaderHtml += '</div>';
			} else {
				html += '<div class="cw_grid_results">';
				html += '<span class="cw_visible_items"></span><span class="hide cw_items_separator">/</span><span class="cw_total_items"></span>';
				html += '</div>';
			}
		}
		if (!this.searchHeader) {
			html += '<input class="cw_search_box" type="text" placeholder="' + lang.SEARCH + '" />';
		}
		if (!this.hideViews) {
			html += '<input class="cw_filter_list" />';
		}

		html += '<button class="k-button k-button-icon cw_filter_reset cw_filter_clear" title="' + lang.grid.filter.FILTER_RESET + '"><span class="glyphicons filter-remove inactive"></span></button>';
		html += '</div>';// cw_filters_header

		if (!this.hideViews) {
			html += this.filtersPopupMarkup();
		}
		if (!this.onlySearchHeader) {
			this.renderHandler.empty().append(html);
		}
		if (this.searchHeader) {
			this.searchField = this.searchRenderHandler.find('.cw_search_box');
			if (!this.searchField.length) {
				this.searchRenderHandler.append(searchHeaderHtml);
			} else {
				this.searchField.empty();
			}
		} else {
			this.searchField = this.renderHandler.find('.cw_search_box');
		}

		if (this.renderHandler) {
			this.visibleColumnList = this.renderHandler.find('.cw_visible_columns').find('ul');
			this.opColumnsList = this.renderHandler.find('.cw_filter_op_columns').find('ul');
		}
		if (!this.hideViews) {
			this.populate();
			this.initKendoComponents();
		}

		if (this.grid) {
			this.removeListeners();
			this.attachListeners();
		}
	},
	filtersPopupMarkup: function () {
		return `<div class="cw_sliding_box hide">
			<div class="left w50">
				<div class="cw_header">
					<h3>${lang.grid.filter.FILTER_PREFERENCES}</h3>
				</div>
				<div class="cw_visible_columns">
					<h3>${lang.grid.filter.GRID_COLUMNS}</h3>
					<ul></ul>
				</div>
			</div>
			<div class="right w50">
				<div class="cw_filter_new">
					<label>${lang.grid.filter.ENTER_NEW_FILTER}: </label>
					<input type="text" class="k-input" />
					<button class="k-button k-primary cw_create_filter">${lang.CREATE}</button>
					<button class="k-button cw_cancel">${lang.CANCEL}</button>
				</div>
				<div class="cw_header">
					<button class="k-button k-button-icon cw_filter_create right" title="${lang.grid.filter.CREATE_VIEW}"><span class="glyphicons plus-sign"></span></button>
					<button class="k-button k-button-icon cw_filter_update right" title="${lang.grid.filter.UPDATE_VIEW}"><span class="glyphicons ok inactive"></span></button>
					<button class="k-button k-button-icon cw_filter_remove right" title="${lang.grid.filter.REMOVE_VIEW}"><span class="glyphicons bin inactive"></span></button>
					<div class="k-textbox k-space-right right">
						<input id="cw_filter_name" type="text" class="k-input" readonly />
						<span class="cw_filter_default glyphicons star ${this.defaultFsViewId === this.currentFsViewId ? 'default' : ''} inactive" title="${lang.summary.SET_AS_DEFAULT}"></span>
					</div>
				</div>
				<div class="cw_filter_op_columns">
					<h3>${lang.grid.filter.COLUMNS_SORTING}</h3>
					<ul class="cw_filter_panel_content"></ul>
					<div class="status"><p></p></div>
				</div>
			</div>
		</div>`
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		if(!this.grid)
			return;

		var panelElement = this.renderHandler;
		if (panelElement) {
			panelElement.find('.cw_view_button').on('click', $.proxy(this.onGearButton, this));
			panelElement.find('.cw_filter_clear').off().on('click', $.proxy(this.onClear, this));
			this.grid.element.on('click', '.k-header', $.proxy(this.onGridHeader, this));
			this.grid.dataSource.bind('change', $.proxy(this.onDataSourceChange, this));
			this.grid.bind('dataBound', $.proxy(this.onDataBound, this));
			this.grid.bind('columnResize', this.onColumnResize.bind(this));
			$('body').on('click', '.k-filter-menu .k-button', $.proxy(this.onGridFilterButton, this));
			$('body').on('click', '.k-item.k-sort-asc .k-link', $.proxy(this.onGridSort, this));
			$('body').on('click', '.k-item.k-sort-desc .k-link', $.proxy(this.onGridSort, this));
			$('.cw_main_nav li').on('click', $.proxy(this.close, this));
			$('.cw_actions_trigger').on('click', $.proxy(this.close, this));
			if (this.searchHeader) {
				this.searchRenderHandler.find('.cw_search_box').off().on('keyup', $.proxy(this.onSearchKeyUp, this));
				if (this.standaloneHeader) {
					this.searchRenderHandler.off().on('keyup', $.proxy(this.onSearchKeyUp, this));
				}
			} else {
				panelElement.find('.cw_search_box').off().on('keyup', $.proxy(this.onSearchKeyUp, this));
			}
			if (this.secondaryGrid) {
				this.secondaryGrid.element.on('click', '.k-header', $.proxy(this.onGridHeader, this));
				this.secondaryGrid.dataSource.bind('change', $.proxy(this.onDataSourceChange, this));
				this.secondaryGrid.bind('dataBound', $.proxy(this.onDataBound, this));
			}
		}
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		this.grid?.unbind('columnResize', this.onColumnResize.bind(this));

		var panelElement = this.renderHandler;
		if (panelElement) {
			panelElement.find('.cw_view_button').off();
			$('body').off('click', '.k-filter-menu .k-button');
			$('body').off('click', '.k-item.k-sort-asc .k-link');
			$('body').off('click', '.k-item.k-sort-desc .k-link');
			if (this.searchHeader) {
				this.searchRenderHandler.find('.cw_search_box').off();
			} else {
				panelElement.find('.cw_search_box').off();
			}
		}
	},
	/**
	 * Initializes kendo components
	 */
	initKendoComponents: function () {
		if (!this.onlySearchHeader) {
			var fsListPref = this.getSettings(this.prefListKey, this.section);

			this.fsList = fsListPref ? JSON.parse(fsListPref) : [];
			if(!Array.isArray(this.fsList)) { // fix wrong values in userSettings
				this.fsList = [];
			}
			var userPrefViews = [];

			if (this.userPref) {
				for (var i = 0; i < this.userPref.length; i++) {
					if (this.userPref[i].category && this.userPref[i].category === this.category) {
						let values = this.userPref[i].values;
						for (let j = 0; j < values.length; j++) {
							if (Utils.isGuid(values[j].key)) {
								userPrefViews.push(values[j].key);
							}
						}
					} else {
						if(Utils.isGuid(this.userPref[i].key)) {
							userPrefViews.push(this.userPref[i].key);
						}
					}
				}
				for (var j = 0; j < this.fsList?.length; j++) {
					if (userPrefViews.indexOf(this.fsList[j].id) === -1) {
						this.fsList.splice(j, 1);
					}
				}
			}

			this.filterList = this.renderHandler.find('input.cw_filter_list').kendoDropDownList({
				dataSource: new kendo.ceeview.DataSource({
					data: this.fsList,
					schema: {
						model: {
							id: 'id'
						}
					}
				}),
				dataTextField: 'name',
				dataValueField: 'id',
				optionLabel: {
					name: lang.grid.filter.SELECT_VIEW,
					id: '1'
				},
				dataBound: $.proxy(function (e) {
					var list = this.renderHandler.find('input.cw_filter_list').data('kendoDropDownList');
					if (this.fsList?.length) {
						list.enable(true);
						list.select(0);
					} else {
						list.span.text(lang.NO_FILTER);
						list.enable(false);
					}
				}, this),
				select: $.proxy(this.onListSelect, this),
				template: '<span data-id="${id}">${name}</span>'
			}).data('kendoDropDownList');
			var defaultFsId = this.getSettings(this.defaultPrefViewKey, this.section);
			var lastFsId = this.userPrefsHandler.get('lastFsView', this.section, this.category), fsId;
			if (Utils.isGuid(defaultFsId)) {
				fsId = defaultFsId;
				this.defaultFsId = defaultFsId;
			} else if (Utils.isGuid(lastFsId)) {
				fsId = lastFsId;
			}

			if (!this.dontLoadDefault && fsId) {
				this.currentFsViewId = fsId;
				this.filterList.value(fsId);
				this.applySelectedView(fsId)

				var fsPref = JSON.parse(this.getSettings(this.currentFsViewId, this.section));
				if (fsPref && this.grid) {
					this.grid.dataSource.filter(fsPref.filter);
					this.grid.dataSource.sort(fsPref.sort);
					this.grid.dataSource.group(fsPref.group);
				}
				this.enableButton('cw_filter_remove');
				this.enableButton('cw_filter_update');
				this.enableButton('cw_filter_default');
				this.enableButton('cw_filter_clear');
				this.updateOpList();
				this.populate();
				//this.windowWrapper.find('#cw_filter_name').removeAttr('readonly');
			}

			if (this.activateResetFilterIcon) {
				this.enableButton('cw_filter_clear');
			}
		}

		if (!this.dontLoadDefault && this.searchValue) {
			this.setSearchValue(this.searchValue);
		}
	},
	/**
	 * Populates the panel with the dynamic data
	 */
	populate: function () {
		if (this.grid) {
			var columns = JSON.parse(JSON.stringify(this.grid.columns));
			columns.sort((firstColumn, secondColumn) => firstColumn.title.localeCompare(secondColumn.title));
			var html = '';
			var accountNameIndex = Utils.getIndexFromJsonArray(columns, 'field', 'accountName');
			if (accountNameIndex > -1) {
				var accountName = columns[accountNameIndex];
				if (accountName) {
					if (State.includeSubaccounts) {
						accountName.forceHide = false;
					} else {
						accountName.forceHide = true;
					}
				}
			}

			for (var i = 0, length = columns.length; i < length; i++) {
				if (columns[i].title !== '&nbsp;' && !columns[i].forceHide) {
					let columnTitle;
					if (!columns[i].title || columns[i].title === ' ') {
						columnTitle = lang.SELECTOR;
					} else {
						columnTitle = columns[i].title;
					}
					html += `<li>
						<input type="checkbox" class="cw_include" id="${columns[i].field}" data-field="${columns[i].field}" ${columns[i].hidden ? '' : 'checked'}/>
						<label for="${columns[i].field}">${columnTitle}</label>
					</li>`
				}
			}
			if (this.visibleColumnList) {
				this.visibleColumnList.empty().append(html);
				this.visibleColumnList.find('li').off();
				this.visibleColumnList.find('li').on('click', $.proxy(this.onColumnCheck, this));
			}
		}
	},
	/**
	 * Handler function for the click event on the gear button. Hides/shows the
	 * preferences panel
	 * @param {Object} e The click event object
	 */
	onGearButton: function (e) {
		e.stopPropagation();
		var currentTarget = $(e.currentTarget),
			slidingBox = currentTarget.parent().find('.cw_sliding_box'),
			filterName = this.filterList.text().split('*').join(''),
			panelElement = this.renderHandler;

		currentTarget.toggleClass('is_selected');

		if (!this.window || !this.window.options.visible) {
			this.createSettingsWindow();

			if (this.currentFsViewId === this.defaultFsViewId) {
				$('.cw_filter_default').addClass('default').addClass('gold');
			} else {
				$('.cw_filter_default').removeClass('default').removeClass('gold');
			}
			this.updateOpList();
			this.populate();
			this.windowWrapper.find('#cw_filter_name').val(filterName !== lang.grid.filter.SELECT_VIEW ? filterName : lang.NO_FILTER);
			this.window.open();
		} else {
			this.window.close();
		}
	},
	/**
	 * Marks the fs view as dirty by adding a "*" after the name
	 */
	markDirty: function () {
		var item;
		this.currentFsViewId = null;
		if (this.filterList) {
			var fsId = this.filterList.value();
			if (Utils.isGuid(fsId)) {
				var text = this.filterList.text().split('*').join('');
				this.filterList.span.text(text + '*');
				item = this.filterList.dataSource.get(fsId);
				item.dirty = true;
				this.enableButton('cw_filter_update');
			}
		}
	},
	/**
	 * Handler function for the click event on a column's header
	 * @param {Object} e The click event
	 */
	onGridHeader: function (e) {
		if ($(e.target).parent().data('role') === 'customcolumnsorter') {
			this.markDirty();
		}
	},
	/**
	 * Handler function for the click event on Filter button of the grid's column
	 * menu
	 * @param {Object} e The click event object
	 */
	onGridFilterButton: function (e) {
		try {
			var popup = $(e.target).closest('ul').parent().closest('ul').parent().data('kendoPopup') || $(e.target).closest('form').data('kendoPopup');
			var anchor = popup.options.anchor;
			var grid = anchor.closest('.k-grid').data('kendoCustomGrid');
			var treelist = anchor.closest('.k-treelist').data('kendoCustomTreeList');
			if (grid === this.grid || grid === this.secondaryGrid || treelist === this.grid) {
				this.markDirty();
			}
		} catch (e) {
			// do nothing
		}
	},
	/**
	 * Resets the dropdown list to "Select filter..." text
	 */
	reset: function () {
		this.filterList.value('1');
	},
	/**
	 * Handler function for the click event on sort menu items of the grid column
	 * menus
	 * @param {Object} e The click event object
	 */
	onGridSort: function (e) {
		try {
			var popup = $(e.target).closest('ul').parent().closest('ul').parent().data('kendoPopup') || $(e.target).closest('form').data('kendoPopup');
			var anchor = popup.options.anchor;
			var grid = anchor.closest('.k-grid').data('kendoCustomGrid');
			var treelist = anchor.closest('.k-treelist').data('kendoCustomTreeList');
			if (grid === this.grid || grid === this.secondaryGrid || treelist === this.grid) {
				this.markDirty();
			}
		} catch (e) {
			// do nothing
		}
	},
	/**
	 * Handler for the select event of the fs list
	 * @param {Object} e The select event object
	 */
	onListSelect: function (e) {
		var id = $(e.item).find('[data-id]').data('id'), viewSearch = '';
		if (Utils.isGuid(id)) {
			e.sender.dataSource.get(id).dirty = false;
		}
		this.applySelectedView(id);
	},

	applySelectedView: function(id) {
		let viewSearch = '';
		let fsPref = Utils.isGuid(id) ? JSON.parse(this.getSettings(id)) : undefined;
		if (this.grid) {
			if (fsPref) {
				this.currentFsViewId = id;
				this.grid.dataSource.filter(fsPref.filter);
				this.grid.dataSource.sort(fsPref.sort);
				this.grid.dataSource.group(fsPref.group || []);
				viewSearch = this.filterList.dataSource.get(id).search;
				this.enableButton('cw_filter_clear');
				this.enableButton('cw_filter_remove');
				this.enableButton('cw_filter_update');
				this.enableButton('cw_filter_default');
				//this.windowWrapper.find('#cw_filter_name').removeAttr('readonly').val(this.filterList.text().split('*').join(''));
				this.updateOpList();
				this.populate();
				Utils.setGridColumns(this.grid, fsPref.columns);
			} else {
				this.disableButton('cw_filter_clear');
				this.disableButton('cw_filter_remove');
				this.disableButton('cw_filter_update');
				this.disableButton('cw_filter_default');
				//this.windowWrapper.find('#cw_filter_name').attr('readonly', 'readonly').val(lang.NO_FILTER);
				this.currentFsViewId = null;
				this.grid.dataSource.filter([]);
				this.grid.dataSource.sort([]);
				this.grid.dataSource.group([]);
				this.updateOpList();
				this.populate();
				Utils.setGridColumns(this.grid, this.defaultColumns);
			}
		}
		if (this.searchField && this.searchField.length) {
			this.searchField.val(viewSearch);
		}
	},
	/**
	 * Handler function for the change event of the grid's datasource
	 * @param {Object} e The change event object
	 */
	onDataSourceChange: function (e) {
		var view = e.sender.view();
		var data = e.sender.data();
		var counterHandler;
		if (this.searchHeader) {
			if (e.secondaryGrid) {
				counterHandler = $('input[data-target="secondary"]').closest('.cw_search_header');
			} else {
				counterHandler = $('input[data-target="primary"]').closest('.cw_search_header');
			}
		} else {
			counterHandler = this.renderHandler
		}
		var visible = counterHandler.find('.cw_visible_items');
		var total = counterHandler.find('.cw_total_items');
		var totalItems = 0;
		var currentItems = 0;
		var visibleItems;
		var filter = e.sender.filter();
		if (filter || (e.sender.sort() && e.sender.sort().length)) {
			this.enableButton('cw_filter_clear');
		}
		if(!filter){
			this.setSearchValue("");
		}
		if (visible.length && total.length) {
			var totalPages = e.sender.totalPages(), take = e.sender.take();
			if (e.initialItems) {
				totalItems = e.initialItems;
			} else if (e.sender.reader.totalItems) {
				totalItems = e.sender.reader.totalItems;
			} else if (e.sender.data()) {
				totalItems = e.sender.data().length;
			} else {
				totalItems = e.sender.total();
			}
			if (e.sender.reader.visibleItems >= 0) {
				visible.text(e.sender.reader.visibleItems);
			} else if (e.sender.page() !== undefined && take !== undefined) {
				currentItems = e.sender.page() * take;
				if (currentItems > totalItems) {
					visible.text(totalItems);
				} else {
					visible.text(e.sender.page() * take);
				}
			} else {
				if (e.initialItems) {
					visibleItems = Math.min(view.length, e.initialItems)
				} else {
					visibleItems = view.length;
				}
				visible.text(visibleItems);
			}
			total.text(totalItems);
		}
		$('.cw_items_separator').removeClass('hide');
	},
	/**
	 * Enables a button by given class name
	 * @param {String} buttonClassName
	 */
	enableButton: function (buttonClassName) {
		let button;
		if (this.renderHandler) {
			button = this.renderHandler.find('.' + buttonClassName);
			if (!button.length && this.windowWrapper) {
				button = this.windowWrapper.find('.' + buttonClassName);
			}
		} else if (this.windowWrapper) {
			button = this.windowWrapper.find('.' + buttonClassName);
		}
		if (buttonClassName === 'cw_filter_default') {
			button.removeClass('inactive').addClass('pointer');
		} else {
			button.find('span').removeClass('inactive');
		}
	},
	/**
	 * Disables a button by given class name
	 * @param {String} buttonClassName
	 */
	disableButton: function (buttonClassName) {
		let button;
		if (this.renderHandler) {
			button = this.renderHandler.find('.' + buttonClassName);
			if (!button.length && this.windowWrapper) {
				button = this.windowWrapper.find('.' + buttonClassName);
			}
		} else if (this.windowWrapper) {
			button = this.windowWrapper.find('.' + buttonClassName);
		}
		if (buttonClassName === 'cw_filter_default') {
			button.addClass('inactive').removeClass('default').removeClass('pointer');
		} else {
			button.find('span').addClass('inactive').removeClass('default');
		}
	},
	/**
	 * Handler function for the click event on the FS add button
	 * @param {Object} e The click event object
	 */
	onAddButton: function (e) {
		var createFilterDiv = this.windowWrapper.find('.cw_filter_new');
		createFilterDiv.slideDown(500);
	},
	/**
	 * Handler function for the click event on the FS update button
	 * @param {Object} e The click event object
	 */
	onUpdateButton: function (e) {
		var target = $(e.currentTarget).find('.glyphicons');
		if (target.hasClass('inactive')) {
			return;
		}
		var name = this.windowWrapper.find('#cw_filter_name').val().split('*').join('');
		this.windowWrapper.find('#cw_filter_name').val(name);
		var id = this.filterList.value();
		if (name) {
			this.windowWrapper.find('#cw_filter_name').removeClass('invalid');
			for (var i = 0, length = this.fsList.length; i < length; i++) {
				if (this.fsList[i].id === id) {
					this.fsList[i].name = name;
					this.fsList[i].search = this.searchField.val();
					break;
				}
			}

			this.filterList.dataSource.get(id).name = name;
			this.filterList.refresh();
			this.filterList.value(id);
			this.updateOpList();
			this.populate();
			var pref = {
				sort: this.grid.dataSource.sort() || [],
				filter: this.grid.dataSource.filter() || [],
				group: this.grid.dataSource.group() || [],
				columns: Utils.getGridColumns(this.grid)
			};
			for (var i = 0, length = this.userPref.length; i < length; i++) {
				if (this.userPref[i].key === id) {
					this.userPref[i].value = JSON.stringify(pref);
					break;
				}
			}

			this.userPref[id] = JSON.stringify(pref);
			this.userPrefsHandler.saveByKey(this.modulePrefName, id, JSON.stringify(pref), null, this.section);
			var status = this.windowWrapper.find('.status');
			status.find('p').addClass('success');
			status.find('p').text(lang.grid.filter.VIEW_UPDATED);
			status.slideDown().delay(2000).slideUp();

		} else {
			this.windowWrapper.find('#cw_filter_name').addClass('invalid');
		}
	},
	/**
	 * Handler function for the click event on the column item
	 * @param {Object} e The click e
	 */
	onColumnCheck: function (e) {
		var checkbox = $(e.currentTarget).find('.cw_include');
		if (checkbox.is(":checked")) {
			this.grid.showColumn(checkbox.data('field'));
			if (this.switchCheckExpand) {
				this.switchCheckExpand.call(this);
			}
		} else {
			this.grid.hideColumn(checkbox.data('field'));
		}
		this.markDirty();
	},
	/**
	 * Handler function for the click event on the clear button
	 * @param {Object} e the click event object
	 */
	onClear: function (e) {
		var target = $(e.currentTarget).find('.glyphicons');
		if (target.hasClass('inactive')) {
			return;
		}
		this.customSearch = false;
		this.setSearchValue('');
		this.grid.dataSource.filter([]);
		this.grid.dataSource.sort([]);
		this.grid.dataSource.group([]);
		if (this.secondaryGrid) {
			this.secondaryGrid.dataSource.filter([]);
			this.secondaryGrid.dataSource.sort([]);
			this.secondaryGrid.dataSource.group([]);
		}
		if (this.filterList) {
			this.filterList.value('1');
			this.filterList.text(lang.grid.filter.SELECT_VIEW);
		}
		if (this.eraseFilteredContent) {
			this.eraseFilteredContent(this.scope);
		}
		target.addClass('inactive');
		$(e.currentTarget).closest('.cw_filters_header').find('.cw_search_box').val('');
		Utils.setGridColumns(this.grid, this.defaultColumns);

		this.renderHandler.find("form.k-filter-menu button[type='reset']").trigger("click");

	},
	/**
	 * Creates a FS view if the ok button was pressed
	 */
	createFSView: function () {
		var name = this.windowWrapper.find('.cw_filter_new').find('input').val().split('*').join('');
		if (name) {
			var id = Utils.guid();
			var record = {
				id: id,
				name: name,
				search: this.searchField.val()
			};

			this.fsList.push(record);
			this.filterList.dataSource.add(record);
			this.filterList.refresh();
			this.filterList.value(id);
			this.enableButton('cw_filter_clear');
			this.enableButton('cw_filter_remove');
			this.enableButton('cw_filter_update');
			this.enableButton('cw_filter_default');
			this.updateOpList();
			this.populate();
			var pref = {
				sort: this.grid.dataSource.sort() || [],
				filter: this.grid.dataSource.filter() || [],
				group: this.grid.dataSource.group() || [],
				columns: Utils.getGridColumns(this.grid)
			};

			if (this.category) {
				for (let i = 0; i < this.userPref.length; i++) {
					if (this.userPref[i].category === this.category) {
						this.userPref[i].values.push({
							key: id,
							value: JSON.stringify(pref)
						})
					}
				}
			} else {
				this.userPref.push({
					key: id,
					value: JSON.stringify(pref)
				});
			}

			this.currentFsViewId = id;
			this.userPref[id] = JSON.stringify(pref);
			this.userPref[this.prefListKey] = JSON.stringify(this.fsList);
			this.userPrefsHandler.saveByKey(this.modulePrefName, this.prefListKey, JSON.stringify(this.fsList), null, this.section);
			this.userPrefsHandler.saveByKey(this.modulePrefName, id, JSON.stringify(pref), null, this.section);
			this.onCancelButton();
			this.windowWrapper.find('#cw_filter_name').removeAttr('readonly').val(this.filterList.text().split('*').join(''));
		} else {
		}
	},
	/*
	* Handler function for clicking the cancel button
	* @param {Object} e The click event
	* */
	onCancelButton: function (e) {
		var createFilterDiv = this.windowWrapper.find('.cw_filter_new');
		createFilterDiv.find('input').val('');
		createFilterDiv.slideUp(200);
	},
	/**
	 * Handler function for the click event on the options button
	 * @param {Object} e The click event object
	 */
	updateOpList: function () {
		if (!this.grid) {
			return;
		}
		var columns, dirHtml, filterHtml;
		var pref = this.currentFsViewId ? JSON.parse(this.getSettings(this.currentFsViewId, this.section)) : null;
		this.sortArray = this.grid.dataSource.sort() || (pref && pref.sort) || [];
		this.filter = this.grid.dataSource.filter() || (pref && pref.filter) || [];
		this.group = this.grid.dataSource.group() || (pref && pref.group) || [];
		this.filterName = this.filterList.text();
		var html = '';
		// add set as default checkbox"
		if (this.filter.filters) {
			this.filterArray = this.adjustFilterArray(this.processFilterArray(this.filter.filters));
			columns = this.joinColumns(this.sortArray, this.filterArray);
		} else {
			columns = this.sortArray;
		}
		for (var i = 0, length = columns.length; i < length; i++) {
			html += '<li>';
			dirHtml = '<span class="glyphicons arrow-right"></span>';
			if (columns[i].dir) {
				dirHtml = (columns[i].dir === 'asc' ? '<span class="glyphicons arrow-up"></span>' : '<span class="glyphicons arrow-down"></span>');
			}
			html += dirHtml;
			html += '<span>' + this.getHeaderName(columns[i].field) + ' </span>';
			filterHtml = '<span></span>';
			if (columns[i].operator) {
				filterHtml = '<span class="cw_fs_settings_filter">';
				switch (columns[i].operator) {
					case 'eq':
						filterHtml += '<span class="bold"> = </span>';
						break;
					case 'neq':
						filterHtml += '<span class="bold"> <> </span>';
						break;
					case 'lte':
						filterHtml += '<span class="bold"> <= </span>';
						break;
					case 'gte':
						filterHtml += '<span class="bold"> >= </span>';
						break;
					case 'gt':
						filterHtml += '<span class="bold"> > </span>';
						break;
					case 'lt':
						filterHtml += '<span class="bold"> < </span>';
						break;
					case 'startswith':
						filterHtml += '<span class=" bold"> ' + lang.grid.filter.STARTS_WITH.toLowerCase() + ' </span> ';
						break;
					case 'contains':
						filterHtml += '<span class=" bold"> ' + lang.grid.filter.CONTAINS.toLowerCase() + ' </span> ';
						break;
					case 'isin':
						filterHtml += '<span class=" bold"> ' + lang.grid.filter.ISIN.toLowerCase() + ' </span> ';
						break;
					case 'isnotin':
						filterHtml += '<span class=" bold"> ' + lang.grid.filter.ISNOTIN.toLowerCase() + ' </span> ';
						break;

				}
				var gridColumn = Utils.getFromJsonArray(this.grid.columns, 'field', columns[i].field);
				if (gridColumn && gridColumn.valueRenderer) {
					filterHtml += '"<div class="inline">' + gridColumn.valueRenderer(columns[i].value) + '</div>"</span>';
				} else {
					if (columns[i].operator === 'isin' || columns[i].operator === 'isnotin') {
						filterHtml += '"' + columns[i].value.join(', ') + '"</span>';
					} else {
						filterHtml += '"' + columns[i].value + '"</span>';
					}
				}
			}
			html += filterHtml;
			html += '</li>';
		}
		this.opColumnsList.empty().append(html);
	},
	/**
	 * Updates the columns list
	 */
	updateColumnList: function () {

	},
	/**
	 * Handler function for the click event on Set as Default button
	 * @param {Object} e The click event object
	 */
	onSetAsDefault: function (e) {
		var target = $(e.currentTarget);
		if (target.hasClass('inactive')) {
			return;
		}
		if (!target.hasClass('default')) {
			target.addClass('default');
			target.addClass('gold');
			this.defaultFsViewId = this.currentFsViewId;
			this.userPref[this.defaultPrefViewKey] = this.currentFsViewId;
			this.userPrefsHandler.saveByKey(this.modulePrefName, this.defaultPrefViewKey, this.currentFsViewId || '', null, this.section);
		} else {
			this.defaultFsViewId = '';
			target.removeClass('default');
			target.removeClass('gold');
			this.userPref[this.defaultPrefViewKey] = null;
			this.userPrefsHandler.saveByKey(this.modulePrefName, this.defaultPrefViewKey, '', null, this.section);
		}
	},
	/**
	 * Handler function for the click event on the remove FS button
	 */
	onRemoveButton: function (e) {
		var target = $(e.currentTarget).find('.glyphicons');
		if (target.hasClass('inactive')) {
			return;
		}
		var dialog = new Dialog({
			title: lang.INFO,
			msg: lang.summary.messages.FILTER_REMOVE_CONFIRMATION,
			icon: 'ERROR',
			actionText: 'DELETE',
			fn: $.proxy(function (value, button) {
				if (button === 'ok') {
					delete this.userPref[this.currentFsViewId];
					this.userPrefsHandler.removeByKey(this.modulePrefName, this.currentFsViewId, $.proxy(function () {
						var record = this.filterList.dataSource.get(this.currentFsViewId);
						if (this.getSettings(this.defaultPrefViewKey, this.section) === this.currentFsViewId) {
							delete this.userPrefsHandler[this.defaultPrefViewKey];
						}
						delete this.userPrefsHandler[this.currentFsViewId];
						Utils.removeFromArrayByKey(this.fsList, 'id', this.currentFsViewId);
						this.filterList.dataSource.remove(record);
						this.filterList.value('1');
						if (!this.filterList.dataSource.data().length) {
							//this.filterList.text('');
							this.filterList.text(lang.NO_FILTER);
						}
						if (this.currentFsViewId === this.defaultFsViewId) {
							this.defaultFsViewId = null;
							this.userPref[this.defaultPrefViewKey] = null;
							this.userPrefsHandler.saveByKey(this.modulePrefName, this.defaultPrefViewKey, '', null, this.section);
						}
						this.currentFsViewId = null;
						this.filterList.refresh();
						this.onRemove();
						if (this.filterList.text() === lang.grid.filter.SELECT_VIEW || this.filterList.text() === lang.NO_FILTER) {
							this.disableButton('cw_filter_default');
							this.disableButton('cw_filter_update');
							this.disableButton('cw_filter_remove');
						}
						this.windowWrapper.find('#cw_filter_name').val(this.filterList.text() !== lang.grid.filter.SELECT_VIEW ? this.filterList.text().split('*').join('') : lang.NO_FILTER);
						this.windowWrapper.find('.cw_view_button').trigger('click');

						this.window.close();
					}, this));
				}
			}, this)
		});
		dialog.show();
	},
	/**
	 * @private Helper function to merge sort and filter arrays
	 * @param sortArray
	 * @param filterArray
	 * @returns {Array}
	 */
	joinColumns: function (sortArray, filterArray) {
		if (!Array.prototype.joinWith) {
			+function () {
				Array.prototype.joinWith = function (that, by, select, omit) {
					var together = [], length = 0;
					if (select) {
						select.map(function (x) {
							select[x] = 1;
						});
					}

					function fields(it) {
						var f = {}, k;
						for (k in it) {
							if (!select) {
								f[k] = 1;
								continue;
							}
							if (omit ? !select[k] : select[k]) {
								f[k] = 1;
							}
						}
						return f;
					}

					function add(it) {
						var pkey = '.' + it[by], pobj = {};
						if (!together[pkey]) {
							together[pkey] = pobj;
							together[length++] = pobj;
						}
						pobj = together[pkey];
						for (var k in fields(it)) {
							pobj[k] = it[k];
						}
					}

					this.map(add);
					that.map(add);
					return together;
				};
			}();
		}
		var cols = sortArray.joinWith(filterArray, 'field', ['field', 'dir', 'operator', 'value']);
		var columns = [];
		for (var key in cols) {
			if (!isNaN(key)) {
				columns.push(cols[key]);
			}
		}
		delete Array.prototype.joinWith;
		return columns;
	},
	getSearchValue: function (multipleGrids) {
		var value;
		if (multipleGrids) {
			value = $('input[data-target="primary"]').val();
		} else {
			value = this.searchField.val();
		}
		return value;
	},
	setSearchValue: function (value) {
		this.onSearch && this.onSearch(value);

		if (this.multipleGrids) {
			$('input[data-target="primary"]').val(value)
		} else {
			this.searchField.val(value);
		}
	},
	/**
	 * Gets the grid header name based on given field
	 * @param {String} field The field name
	 * @return {String} header The header name
	 */
	getHeaderName: function (field) {
		var columns = this.grid.columns || [];
		var header = '';
		for (var i = 0, length = columns.length; i < length; i++) {
			if (columns[i].field === field) {
				header = columns[i].title;
				break;
			}
		}
		return header;
	},
	onSearchKeyUp: function (e) {
		clearTimeout(this.timer);
		var gridControl;
		var target = $(e.currentTarget);
		var value = target.val();
		var gridType = target.attr('data-target');
		if (!gridType || gridType === 'primary') {
			gridControl = this.grid;
		} else if (gridType === 'secondary') {
			gridControl = this.secondaryGrid;
		}
		if (value.length >= 1 && (e.keyCode !== 8 || e.keyCode !== 46)) {
			if (gridControl && gridControl.dataSource) {
				if (!this.tempFilter) {
					var tempFilter = gridControl.dataSource.filter();
					if (tempFilter && !tempFilter.isCustom) {
						this.tempFilter = tempFilter;
					}
				}
			}
		}
		this.timer = setTimeout($.proxy(function () {
			this.onSearch && this.onSearch(value);

			if (gridControl && gridControl.dataSource) {
				var dataSource = gridControl.dataSource;
				if (value) {
					var searchFilter = this.buildFilter(value, this.searchFields, gridControl);
					searchFilter.isCustom = true;
					dataSource.filter(searchFilter);
					if (this.filterList) {
						this.filterList.value('1');
					}
					this.enableButton('cw_filter_clear');
				} else {
					if (this.filterList) {
						this.disableButton('cw_filter_update');
						this.disableButton('cw_filter_default');
						this.renderHandler.find('#cw_filter_name').attr('readonly', 'readonly').val(lang.NO_FILTER);
						this.currentFsViewId = null;
						gridControl.dataSource.filter(this.tempFilter || []);
						this.filterList.value('1');
						this.updateOpList();
						this.populate();
					} else {
						dataSource.filter(this.tempFilter || []);
					}
				}
			}
		}, this), this.searchTimeout);
	},
	buildFilter: function (text, fields, grid) {
		// replace	a + b with a+b
		let processPlusOperator = function (text) {
			let found = true;
			while (found) {
				found = false;
				let index = text.indexOf('+ ');
				if (index > -1) {
					found = true;
					text = text.replace('+ ', '+', 'g');
				}
			}
			found = true;
			while (found) {
				found = false;
				let index = text.indexOf(' +');
				if (index > -1) {
					found = true;
					text = text.replace(' +', '+', 'g');
				}
			}
			return text;
		};
		let processMinusOperator = function (text) {
			let found = true;
			while (found) {
				found = false;
				let index = text.indexOf('- ');
				if (index > -1) {
					found = true;
					text = text.replace('- ', '-', 'g');
				}
			}
			return text;
		};
		text = processPlusOperator(text);
		text = processMinusOperator(text);
		let keywords = text.split(' ');
		let i, k;
		let andFilter = {
			logic: 'and',
			filters: []
		};
		let orFilter = {
			logic: 'or',
			filters: []
		};
		for (i = 0; i < keywords.length; i++) {
			if (keywords[i].trim()) {
				if (keywords[i][0] === '-') {
					keywords[i] = keywords[i].substr(1);
					if (keywords[i].trim()) {
						for (k = 0; k < fields.length; k++) {
							andFilter.filters.push({
								field: fields[k],
								operator: 'neq',
								value: keywords[i]
							});
						}
					}
				} else {
					for (k = 0; k < fields.length; k++) {
						orFilter.filters.push({
							field: fields[k],
							operator: 'contains',
							value: keywords[i]
						});
					}
				}
			}
		}

		let filter = {
			logic: 'and',
			filters: []
		};
		if (andFilter.filters.length) {
			filter.filters.push(andFilter);
		}
		if (orFilter.filters.length) {
			filter.filters.push(orFilter);
		}

		/*
		 * Remove from tempFilter the filters applied to searchable columns
		 * */
		if (this.tempFilter) {
			for (let i = 0, length = this.tempFilter.filters.length; i < length; i++) {
				if (!this.tempFilter.filters[i].field || fields.indexOf(this.tempFilter.filters[i].field) === -1) {
					filter.filters.push(this.tempFilter.filters[i]);
				}
			}
		}

		let finalFilters = filter.filters;
		if (finalFilters) {
			//if includeSubaccounts not checked remove accountName from filtering while searching
			for (let i = 0; i < finalFilters.length; i++) {
				let currentFilter = finalFilters[i];
				let finalSubFilters = currentFilter.filters;
				if (finalSubFilters) {
					for (let j = 0; j < finalSubFilters.length; j++) {
						if (finalSubFilters[j].field === 'accountName' && !State.includeSubaccounts) {
							finalSubFilters.splice(j, 1);
						}
					}
				}
			}
		}

		return filter;
	},
	/**
	 * Compacts multiple objects having same field and operator into single one
	 */
	processFilterArray: function (filterArray) {
		var newArray = [];
		for (var i = 0; i < filterArray.length; i++) {
			if (filterArray[i].logic) {
				newArray.push(this.processFilterArray(filterArray[i].filters));
			} else {
				var hasDuplicate = false;
				var currentObject = filterArray[i];
				var setObject = {
					field: currentObject.field,
					operator: currentObject.operator === 'eq' ? 'isin' : 'isnotin',
					value: [currentObject.value]
				};
				if (!currentObject.processed) {
					for (var j = i + 1; j < filterArray.length; j++) {
						if (currentObject.field === filterArray[j].field && currentObject.operator === filterArray[j].operator) {
							filterArray[j].processed = true;
							hasDuplicate = true;
							setObject.value.push(filterArray[j].value);
						}
					}
					if (hasDuplicate) {
						newArray.push(setObject);
					} else {
						newArray.push(currentObject);
					}
				}
				delete currentObject.processed;
			}
		}
		return newArray;
	},
	adjustFilterArray: function (filterArray) {
		var newArray = [];
		for (var i = 0; i < filterArray.length; i++) {
			if (filterArray[i].constructor === Array) {
				for (var j = 0; j < filterArray[i].length; j++) {
					newArray.push(filterArray[i][j]);
				}
			} else {
				newArray.push(filterArray[i]);
			}
		}
		return newArray;
	},
	/*
	 * Handler function which enabled/disables the component by adding a mask
	 * */
	enable: function (bool) {
		if (bool) {
			this.renderHandler.find('.cw_mask').remove();
		} else {
			if (!this.renderHandler.find('.cw_mask').length) {
				this.renderHandler.append('<div class="cw_mask"></div>');
			}
		}
	},
	/*
	* Handler function for opening the window settings
	* */
	createSettingsWindow: function () {
		if (!this.hideViews) {
			if (!this.window) {
				//should be destroyed
				let windowHeight = 350;
				this.window = this.renderHandler.find('.cw_sliding_box').kendoWindow({
					appendTo: 'body',
					title: false,
					pinned: true,
					draggable: false,
					width: 666,
					height: windowHeight,
					minWidth: 550,
					minHeight: 105,
					maxWidth: 950,
					maxHeight: 400,
					visible: true,
					actions: [],
					resize: function (e) {
						e.preventDefault();$
					},
					activate: $.proxy(function (e) {
						let renderHandlerHeight = this.renderHandler.height();
						let renderHandlerTop = this.renderHandler.offset().top;
						let top = renderHandlerHeight + renderHandlerTop;
						if (top + windowHeight > window.innerHeight) {
							top = renderHandlerTop - windowHeight - 30;
						}
						this.windowWrapper = e.sender.wrapper;
						this.windowWrapper.css({
							top: top,
							left: 'auto',
							right: 15
						});
						this.windowWrapper.find('.cw_sliding_box').removeClass('hide');
						var windowHandler = this.windowWrapper.closest('.k-widget.k-window');
						var style = windowHandler.attr('style');
						windowHandler.attr('style', style + 'padding-top: 0px !important');
						windowHandler.addClass('cw_preferences_window');

						this.windowWrapper.find('.cw_filter_default').off().on('click', $.proxy(this.onSetAsDefault, this));
						this.windowWrapper.find('.cw_filter_remove').off().on('click', $.proxy(this.onRemoveButton, this));
						this.windowWrapper.find('.cw_filter_update').off().on('click', $.proxy(this.onUpdateButton, this));
						this.windowWrapper.find('.cw_filter_create').off().on('click', $.proxy(this.onAddButton, this));
						this.windowWrapper.find('.cw_filter_new').off('click', '.cw_cancel').on('click', '.cw_cancel', $.proxy(this.onCancelButton, this));
						this.windowWrapper.find('.cw_filter_new').off('click', '.cw_create_filter').on('click', '.cw_create_filter', $.proxy(this.createFSView, this));

						this.windowWrapper.find('.k-resize-handle.k-resize-n').remove();
						this.windowWrapper.find('.k-resize-handle.k-resize-e').remove();
						this.windowWrapper.find('.k-resize-handle.k-resize-nw').remove();
						this.windowWrapper.find('.k-resize-handle.k-resize-ne').remove();
					}, this)
				}).data('kendoWindow');
			}
		}
	},

	setGrid(grid){
		this.removeListeners();

		this.grid = grid;

		this.attachListeners();
	},

	onDataBound: function () {
		this.grid.element.on('click', '.cw_grid_link', $.proxy(this.close, this));
	},

	onColumnResize: function (e) {
		this.markDirty();
	},
	/**
	 * Close event for preferences panel
	 */
	close: function () {
		if (this.window) {
			this.window.close();
		}
	},

	getSettings: function(key, section = undefined) {
		return UserPrefsTool.getSettings(this.userPref, key, section);
	},
	/*
	* Destroy
	* */
	destroy: function () {
		if (this.window) {
			this.window.destroy();
		}
	}
});
