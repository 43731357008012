import React from "react";
import {translator} from "core";
import {observer} from "mobx-react"
import CollapsibleFrame from "controls/react/layout/collapsibleFrame";
import {FormEntry} from "controls/react/form";
import {AntSelect, AntSelectOption} from "controls/react/ant/antSelect";
import {MobxManager} from "framework/mobx-integration";
import {RedirectType} from "controls/designer/features/redirectOnClick/redirectType";
import {AccountSelector} from "controls/react/accountSelector";
import {AntInput} from "controls/react/ant/antInput";
import {DataListLoader} from "controls/designer/features/redirectOnClick/dataListLoader";
import {Dashboard, getDashboardsLite} from "areas/dashboards/api";
import {makeAutoObservable} from "mobx";
import {RedirectConfig} from "controls/designer/features/redirectOnClick/redirectConfig";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {ModalPosition} from "controls/react/ant/antModal";
import {useMemoPermanent} from "framework/react-integration";
import {FormEntryNew} from "controls/react/form/formEntryNew";

import {RedirectModifier} from "tools/ceeviewNavigator";

export const i18n = translator({
  "Redirect": {
    "no": "Videresend"
  },
  "Custom Link": {
    "no": "Tilpasset lenke",
    "en": "Custom link"
  },
  "Open in new tab": {
    "no": "Åpne i ny fane"
  },
  "Dashboard": {
    "no": "Dashboard"
  },
  "Option": {
    "no": "Valg"
  },
  "New tab": {
    "no": "Ny fane"
  },
  "Popup": {
    "no": "Popup"
  },
  "Upper left corner": {
    "no": "Øvre venstre hjørne"
  },
  "Upper right corner": {
    "no": "Øvre høyre hjørne"
  },
  "Center": {
    "no": "senter"
  },
  "Mouse click": {
    "no": "Museklikk"
  },
  "Popup position": {
    "no": "Popup posisjon"
  },
  "Popup width": {
    "no": "Popup bredde"
  },
  "Popup height": {
    "no": "Popup høyde"
  }
});

const b = require('b_').with('redirect-to-config-section');


class RedirectConfigEditorStore {
	dashboards: DataListLoader<Dashboard>

	mobx = new MobxManager()

	constructor(public config: RedirectConfig) {
		makeAutoObservable(this)

		if(!this.config)
			return

		this.dashboards = new DataListLoader<Dashboard>(() => getDashboardsLite(this.config.accountId), {
			beforeLoad: () => {
				this.config.dashboardId = null
			}
		})

		this.mobx.reaction(() => this.config.modifier, () => {
			if (this.config.modifier == RedirectModifier.Popup && this.config.popup == null) {
				this.config.popup = {
					height: 600,
					width: 800,
					position: ModalPosition.TopLeft
				}
			}
		})
	}

	destroy() {
		this.mobx.destroy()
		this.dashboards.destroy()
	}
}

type RedirectConfigEditorProps = {
	config: RedirectConfig
}

export const RedirectConfigEditor = observer((props: RedirectConfigEditorProps) => {
	const store = useMemoPermanent(() => new RedirectConfigEditorStore(props.config), [props.config])

	if(store == null)
		return null

	const config = store.config

	return (
		<div className={b()}>
			<CollapsibleFrame childrenPadding={true} title={i18n('Redirect')}>
				<FormEntryNew label={i18n('Redirect')} model={config} modelField={"type"}>
					<AntSelect options={getRedirectTypeDatasource()}/>
				</FormEntryNew>

				{config.type == RedirectType.Dashboard &&
					<>
						<FormEntryNew label={i18n('Account')} model={config} modelField={"accountId"}>
							<AccountSelector/>
						</FormEntryNew>
						<FormEntryNew label={i18n('Dashboard')} model={config} modelField={"dashboardId"}>
							<AntSelect {...store.dashboards.props}
							           nameField={"tag"}/>
						</FormEntryNew>
					</>
				}

				{config.type == RedirectType.Custom &&
					<FormEntryNew label={i18n('Custom Link')} model={config} modelField={"link"}>
						<AntInput/>
					</FormEntryNew>
				}

				{config.type != RedirectType.None &&
					<FormEntryNew label={i18n('Option')} model={config} modelField={"modifier"}>
						<AntSelect options={getRedirectConfigModifierOptions()}/>
					</FormEntryNew>
				}
				{config.modifier == RedirectModifier.Popup && config.type != RedirectType.None && <>
					<FormEntryNew label={i18n('Popup height')} model={config.popup} modelField={"height"}>
						<AntInputNumber/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Popup width')} model={config.popup} modelField={"width"}>
						<AntInputNumber/>
					</FormEntryNew>
					<FormEntryNew label={i18n('Popup position')} model={config.popup} modelField={"position"}>
						<AntSelect options={getRedirectPopupPositionOptions()}/>
					</FormEntryNew>
				</>}
			</CollapsibleFrame>
		</div>
	)
})

let redirectConfigModifierOptions: AntSelectOption[]

function getRedirectConfigModifierOptions(){
	if(redirectConfigModifierOptions == null){
		redirectConfigModifierOptions = [{
			label: i18n('None'),
			value: RedirectModifier.None
		},{
			label: i18n('New tab'),
			value: RedirectModifier.NewTab
		},{
			label: i18n('Popup'),
			value: RedirectModifier.Popup
		}]
	}

	return redirectConfigModifierOptions
}

let redirectPopupPositionOptions: AntSelectOption[]

function getRedirectPopupPositionOptions(){
	if(redirectPopupPositionOptions == null){
		redirectPopupPositionOptions = [{
			label: i18n('Upper left corner'),
			value: ModalPosition.TopLeft
		},{
			label: i18n('Upper right corner'),
			value: ModalPosition.TopRight
		},{
			label: i18n('Center'),
			value: ModalPosition.Centered
		},{
			label: i18n('Mouse click'),
			value: ModalPosition.Mouse
		}]
	}

	return redirectPopupPositionOptions
}

let redirectTypeDatasource: AntSelectOption[]

function getRedirectTypeDatasource() {
	if (!redirectTypeDatasource) {
		redirectTypeDatasource = [{
			label: i18n('Default'),
			value: RedirectType.Default,
		}, {
			label: i18n('Dashboard'),
			value: RedirectType.Dashboard,
		}, {
			label: i18n('Custom'),
			value: RedirectType.Custom
		}, {
			label: i18n('None'),
			value: RedirectType.None
		}]
	}
	return redirectTypeDatasource;
}
