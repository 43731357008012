import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from "controls/react/form/multiSelect";
import {ACTIONS} from "./reducer";
import {b} from './filteringPanelShared'
import classnames from 'classnames';
import FilteringPanelApi from "./api";
import lang from "core/localization/lang";

function Monitor(props) {
	const [monitorsList, setMonitorsList] = React.useState([]);
	const isMounted = React.useRef(true);

	React.useEffect( () => {
		if (isMounted.current) {
			getMonitors();
		}
		return () => { isMounted.current = false; }
	}, [])
	const onMonitorsChange = React.useCallback((value) => {
		props.dispatch({ type: ACTIONS.SET_MONITORS, value, index: props.index });
	}, [props.dispatch]);

	const getMonitors = async() => {
		let monitors = await FilteringPanelApi.getMonitors();
		let list = [];
		for (let monitor of monitors.items) {
			list.push({
				text: monitor.data.name,
				value: monitor.data.id
			});
		}
		setMonitorsList(list);
	}

	return <>
		<div className={classnames(b('input'), b('reset-field'))}>
			<MultiSelect data={monitorsList}
			             value={props.monitors}
			             dataTextField={'text'}
			             dataValueField={'value'}
			             onChange={onMonitorsChange}/>
		</div>
	</>
}

Monitor.propTypes = {
	index: PropTypes.number.isRequired,
	monitors: PropTypes.arrayOf(PropTypes.any).isRequired,
	dispatch: PropTypes.func.isRequired,
};

export default Monitor;
