export function getFilterForField(field, value) {
	return {
		filters: [{
			field: field,
			operator: "eq",
			value: value
		}],
		logic: "and"
	}
}
