import {AreaChartOutlined, LoadingOutlined, StockOutlined} from "@ant-design/icons";
import {Section} from "controls/react/layout/section";
import {observer} from "mobx-react";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {newGuid} from "tools/guid";
import {Window} from "../../../controls/react/kendoWrappers";
import {CostBudgetItem} from "../models/costBudgetItem";
import './MetricCellWindow.less';
import {getWidgetDescription} from "controls/designer/features/widgets/allWidgets";
import {debounce} from "lodash";
import {ApplicationState} from 'framework/applicationState';
import {costTranslates} from "../translations";
import {translator} from 'core/localization';
import {CostMetric} from "../models/costMetric";
import {BudgetTableContext} from "../budget/components/BudgetTableContext";
import {MetricType} from "../budget/components/CostMetricCache";
import {UrlBuilder, Utils} from "tools";
import moment from "moment";
import {
	fillPeriodForBuilder,
	IntervalType,
	TimePeriod
} from "../../../controls/react/form/timePeriodSelector";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i = translator({
	"Cost Metrics": {
		"no": "Kost data"
	},
	"Generic Metrics": {
		"no": "Generelle data"
	}
}, costTranslates);

const b = require('b_').with('profile-metric-window');
const initWindowWidth = 650;

interface MetricCellWindowProps {
	title: string;
	position: any;
	onClose: () => void;
	onResize: (size: { width: number, height: number }) => void;
	children: React.ReactNode
}

export const CostMetricButton = observer(({record, type}: { record: CostBudgetItem, type: MetricType }) => {
	const {metricCache} = React.useContext(BudgetTableContext);
	const [position, setPosition] = useState({});
	const [size, setSize] = useState({width: 0, height: 0});

	const icon = React.useMemo(() => {
		switch (type) {
			case 'cost':
				return <StockOutlined/>;
			case 'generic':
				return <AreaChartOutlined/>;
		}
	}, [type]);

	const title = React.useMemo(() => {
		switch (type) {
			case 'cost':
				return i('Cost Metrics');
			case 'generic':
				return i('Generic Metrics');
		}
	}, [type]);


	const onClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
		const {top, left} = (e.target as HTMLElement).getBoundingClientRect();
		setPosition({top: top + 20, left: left - initWindowWidth});
		metricCache.toggle(record.id, type);
	}, []);

	const onResize = debounce((size: { width: number, height: number }) => {
		setSize({
			width: size.width,
			height: size.height
		});
	}, 300);

	const visible = metricCache.visible(record.id, type);
	const loading = metricCache.loading(record.id);

	return <>
		<a className={b('action', {[`${type}-metric`]: true})}
		   title={title}
		   onClick={onClick}>
			{loading ? <LoadingOutlined/> : icon}
		</a>
		{
			visible && !loading &&
			<MetricCellWindow
				title={title}
				position={position as any}
				onClose={() => metricCache.hide(record.id, type)}
				onResize={onResize}
			>
				<MetricChart metrics={metricCache.data(record.id, type)} size={size}/>
			</MetricCellWindow>
		}
	</>;
});


const MetricCellWindow = observer((props: MetricCellWindowProps) => {
	return <Window title={props.title}
				   notCenter={true}
				   height={250}
				   resizable={true}
				   width={initWindowWidth}
				   position={props.position}
				   contentClass={b('content')}
				   onClose={props.onClose}
				   onResize={props.onResize}>
		<Section childrenPadding={false} height="fit">
			{props.children}
		</Section>
	</Window>
});

type CalculatedPeriod = {
	period: string,
	startDate?: Date,
	endDate?: Date,
	width: number,
	interval: number,
	intervalType?: string,
	intervalValue?: number
};
const MetricChart = observer(({metrics, size}: { metrics: CostMetric[], size: { width: number, height: number } }) => {
	const id = useRef(newGuid()).current;
	const [displayChart, setDisplayChart] = useState(false);
	const widget = useRef(null);
	const {budget} = React.useContext(BudgetTableContext)

	const overrideUrl = (urlBuilder: UrlBuilder, calculatedPeriod: CalculatedPeriod) => {
		if (budget.endDate.isAfter(moment()) || ['CUSTOM', 'DYNAMIC'].includes(calculatedPeriod.period)) {
			const timePeriodSettings: TimePeriod = {
				startDate: new Date(calculatedPeriod.startDate).getTime(),
				endDate: new Date(calculatedPeriod.endDate).getTime(),
				intervalValue: calculatedPeriod.intervalValue,
				intervalType: calculatedPeriod.intervalType as IntervalType,
				period: calculatedPeriod.period as TimePeriodType
			}
			fillPeriodForBuilder(urlBuilder, timePeriodSettings);
			urlBuilder.add('maxSamples', calculatedPeriod.width);
			return;
		}
		const lastBudgetDay = moment(budget.endDate);
		const periodType = 'CUSTOM';
		const endDate = lastBudgetDay.toDate();
		const startAdjust = calculatedPeriod.period == 'LAST7DAYS'
			? -7
			: -30;
		const startDate = lastBudgetDay.add(startAdjust, 'day').toDate();
		const intervalData = Utils.getPeriodInterval({
			period: periodType,
			startDate: startDate,
			endDate: endDate,
			width: calculatedPeriod.width
		});
		urlBuilder.add('interval', intervalData.interval)
			.add('startDate', startDate.getTime())
			.add('endDate', endDate.getTime());
	};

	const initWidget = async () => {
		const metricIds = metrics.map(x => x.metricId);
		const metricDataArr = metrics.map(x => ({
			color: '',
			conversion: '',
			unitTypeSymbol: '',
			decimals: 2,
			...x
		}));
		const wDescription = getWidgetDescription('metric-multi-graph-asset');
		const config = Object.assign(wDescription.defaultConfig, {
			id: id,
			configuration: {
				id: id,
				accountId: ApplicationState.accountId,
				period: 'LAST7DAYS',
				ignoreMissingData: true,
				showRegression: false,
				showThreshold: false,
				chartType: 'line',
				aggregationType: 'high',
				timezone: ApplicationState.timezone,
				hideTimeSelector: false,
				labelTemplate: ['<Instance>'],
				legendType: 'legend',
				metrics: metricIds,
				decimals: 2,
				metricsItems: metricDataArr,
				excludedPeriods: ['LASTHOUR', 'LASTDAY'],
				overrideDataUrl: overrideUrl,
				reactTimePeriodSelector: true
			}
		});
		widget.current = wDescription.constructWidget
			? wDescription.constructWidget(config)
			: new wDescription.widget(config);

		widget.current.init?.();
		if (budget.endDate.isBefore(moment())) {
			widget.current.instanceConfiguration.endDate = budget.endDate;
		}
	};

	useEffect(() => {
		setDisplayChart(true);
		setTimeout(() => {
			initWidget().catch(console.error);
		});
	}, []);

	useEffect(() => {
		widget.current?.onSettingsUpdate();
	}, [size]);

	return <>
		{displayChart &&
			<div id={id} className={b('metric-cell')} onClick={e => e.stopPropagation()}>
				<div className="cw_section_content"></div>
			</div>
		}
	</>
});
