import {observer} from "mobx-react"

import {ApplicationState} from "framework/applicationState";
import {WidgetConfig, WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {GridState} from "controls/grid/gridState";
import React from "react";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntInput} from "controls/react/ant/antInput";
import {AccountSelector} from "controls/react/accountSelector";
import {GridStore, linkGridAdditionalPayload} from "controls/grid/gridStore";
import {apiFetch, ApiRequest, PagedList} from "framework/api";
import {RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {deserialize, serialize} from "serializr";
import {ExternalStateProvider} from "controls/grid/stateProviders";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {StylesFromMxGraphCellPlugin} from "controls/grid/plugins/stylesFromMxGraphCellPlugin";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {GridDataItem} from "controls/grid/gridDataItem";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import {Grid} from "controls/grid/grid";
import {TimePeriodAppearance, TimePeriodSelector} from "controls/react/form/timePeriodSelector";
import {DataSizeIndicator} from "controls/grid/dataSizeIndicator";
import {GridSearchInput} from "controls/grid/gridSearchInput";
import {ViewSelector} from "controls/grid/viewManager/viewSelector";
import {makeAutoObservable} from "mobx";
import {AntSelect} from "controls/react/ant/antSelect";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import moment from "moment";
import {MobxManager} from "framework/mobx-integration";
import {
	anyFlagSet,
	GridToolbarVisibilityFlags,
	GridToolbarVisibilityValueLink
} from "areas/service-boards/widgets/grids/gridToolbarVisibility";
import {GridToolbarVisibility} from "areas/service-boards/widgets/grids/gridToolbarVisibility";
import {BoxView} from "controls/react/layout/boxView";
import {UrlBuilder} from "tools";


const i18n = require('core/localization').translator({
	'Data not found': {
		no: 'Data ikke funnet'
	}
}, sharedLocalization);

export interface DataSourcesGridWidgetConfig extends Omit<WidgetConfig, 'accountId'> {
	dataSourceId: string
	accountId: string
	decimalsNumber: number
	gridToolbarVisibility: GridToolbarVisibilityFlags
}

export type DataSourcesGridWidgetPersistedState = {
	grid: GridState<any>
}

export function getWidgetDescription(){
	return {
		form: WidgetConfiguration,
		defaultConfig: {
			type: 'data-sources-grid',
			title: i18n('Datasource grid'),
			accountId: ApplicationState.accountId,
			gridToolbarVisibility: {},
			decimalsNumber: 0
		},
		minWidth: 800,
		widget: DataSourcesGridWidget,
		fullTitle: i18n('Datasource grid'),
	}
}

export class DataSourcesGridConfigStore {
	mobx= new MobxManager();
	dataSource: {name: string, id: string}[]

	constructor(public props: WidgetConfigurationProps<DataSourcesGridWidgetConfig>) {
		makeAutoObservable(this)
		this.mobx.reaction(_ => this.props.config.accountId, async _ => {
			this.props.config.dataSourceId = undefined;
			this.dataSource = (await apiFetch(getDataSources({accountId: this.props.config.accountId})))?.data
		});
	}

	async init(){
		this.dataSource = (await apiFetch(getDataSources({accountId: this.props.config.accountId})))?.data
	}

	destroy(){
		this.mobx.destroy();
	}
}

export const WidgetConfiguration = observer(class InnerWidget extends React.Component<WidgetConfigurationProps<DataSourcesGridWidgetConfig>> {
	store: DataSourcesGridConfigStore

	constructor(props: WidgetConfigurationProps<DataSourcesGridWidgetConfig>) {
		super(props)
		this.store = new DataSourcesGridConfigStore(props)
	}

	render() {
		return <Section>
			<Section appearance={"frame-top-only"} title={i18n('General')} childrenPadding={true}>
				<FormEntryNew label={i18n('Title')} model={this.store.props.config} modelField={'title'}>
					<AntInput/>
				</FormEntryNew>
			</Section>
			<Section appearance={"frame-top-only"} title={i18n('Data')} childrenPadding={true}>
				<FormEntryNew label={i18n('Account')} model={this.store.props.config} modelField={'accountId'}>
					<AccountSelector/>
				</FormEntryNew>
				<FormEntryNew label={i18n('Datasource')} model={this.store.props.config} modelField={'dataSourceId'}>
					<AntSelect options={this.store.dataSource} nameField={"name"} valueField={'dataSourceId'}/>
				</FormEntryNew>
				<FormEntryNew label={i18n('Decimal Numbers')} model={this.store.props.config} modelField={'decimalsNumber'} >
					<AntInputNumber min={0} max={8} />
				</FormEntryNew>
				<GridToolbarVisibility flags={this.props.config.gridToolbarVisibility}
				                       enabledFlags={["showViewSelector", "showFilters", "showTimeSettings"]}/>
			</Section>
		</Section>
	}

	async componentDidMount() {
		await this.store.init();
	}

	componentWillUnmount() {
		this.store?.destroy()
	}
})

const TimePeriods = [TimePeriodType.Last24Hours, TimePeriodType.Last7Days,
	TimePeriodType.Last30Days, TimePeriodType.Custom, TimePeriodType.DynamicInterval, TimePeriodType.All, TimePeriodType.LastN];

export const DataSourcesGridWidget = observer(
	class InnerWidget extends React.Component<WidgetProps<DataSourcesGridWidgetConfig, DataSourcesGridWidgetPersistedState>>{
		store: DataSourcesGridWidgetStore
		get viewMode() {
			return this.props.dashboardSettings.readOnly
		}

		constructor(props: WidgetProps<DataSourcesGridWidgetConfig, DataSourcesGridWidgetPersistedState>) {
			super(props)

			this.store = new DataSourcesGridWidgetStore(props)
		}

		async componentDidMount() {
			await this.store.init()
		}

		render() {
			const editMode = !this.props.dashboardSettings.readOnly
			const flags = this.props.config.gridToolbarVisibility

			return (
				<DefaultWidgetWrapper {...this.props} appearance={"none"}>
					{!!this.store.errorMessage && <BoxView type={'error'}>{this.store.errorMessage}</BoxView>}
					{!!this.store.gridStore && <Section appearance="none" height={"fit"}>
						{(anyFlagSet(flags) || editMode) &&
							<Toolbar>
								{(flags.showTimeSettings || editMode) && <TimePeriodSelector periods={TimePeriods}
																							 appearance={TimePeriodAppearance.Buttons}
																							 size={"small"}
																							 {...linkGridAdditionalPayload(this.store.gridStore, "timePeriod")}/>}

								{(flags.showFilters || editMode) && <DataSizeIndicator store={this.store.gridStore}/>}

								{(flags.showFilters || editMode) && <GridSearchInput store={this.store.gridStore}/>}

								{(flags.showViewSelector || editMode) &&
									<ViewSelector store={this.store.gridStore} createViewDisabled={this.viewMode}
												  editViewDisabled={this.viewMode}/>}
							</Toolbar>
						}
						<Grid store={this.store.gridStore}/>
					</Section>}
				</DefaultWidgetWrapper>
			)
		}

		getStateForSaving() {
			if (!this.store.gridStore) {
				return {}
			}

			return {
				grid: serialize(this.store.gridStore?.state)
			};
		}

		componentWillUnmount(){
			this.store?.destroy()
		}
	}
)

export class DataSourcesGridWidgetStore {
	gridStore: GridStore<any>
	mobx= new MobxManager();
	errorMessage: string;

	constructor(public props: WidgetProps<DataSourcesGridWidgetConfig, DataSourcesGridWidgetPersistedState>) {
		makeAutoObservable(this)
	}

	async init(){
		const result = await apiFetch(getDataSourceFiltersConfiguration(this.props.config))
		if (!result.success) {
			this.errorMessage = i18n('Data not found');
			return;
		}
		const filtersData = result.data;
		this.gridStore = new GridStore<any>({
			columns: this.getColumns(filtersData),
			stateProviders: [
				new ExternalStateProvider(deserialize<GridState<any>>(GridState, this.props.persistedState?.grid)),
			],
			dataProvider: new RemoteDataProvider({
				dataSource: getDataSourceData(this.props.config),
				filtersConfiguration: filtersData
			}),
			plugins: [
				//this.getAutoReloadPlugin(this.props.config),
				new StylesFromMxGraphCellPlugin(this.props.designer.store, this.props.cell)
			],
			defaults:{
				payload: {
					timePeriod: {
						period: TimePeriodType.Last24Hours
					},
					showHistoricEvents: false
				}
			},
			selection: GridSelectionType.Many
		})
	}

	getColumns(rules: RulesConfiguration){
		return Object.keys(rules).map(columnName => {
			let column : GridColumnConfig<any> = {
				field: columnName,
				// hack from Bernt
				title: rules[columnName].label != 'rowHeader' ? rules[columnName].label : '',
				renderer: item => {
					if (rules[columnName].type == 'integer') {
						return item[columnName]?.toFixed(this.props.config.decimalsNumber ?? 0) ?? '';
					}
					if (rules[columnName].type == 'date' && !!item[columnName]) {
						return moment(item[columnName]).format(ApplicationState.momentDateTimeFormat);
					}
					return item[columnName] ?? '';
				}
			}

			return column
		})
	}

	destroy(){
		this.gridStore?.destroy()
		this.mobx.destroy();
	}
}

type GetDatasourceFiltersConfigurationArgs = {
	accountId: string
	dataSourceId: string
	categoryColumn?: string,
	pivotData?: boolean
}

export function getDataSourceFiltersConfiguration(args: GetDatasourceFiltersConfigurationArgs){
	const urlBuilder = new UrlBuilder(`dataSources/${args.dataSourceId}/ruleConfiguration`);
	urlBuilder.add('categoryColumn', args.categoryColumn, !!args.categoryColumn);
	return new ApiRequest<RulesConfiguration>({
		url: urlBuilder.build(),
		accountId: args.accountId,
		accountBased: true,
		method: 'GET'
	})
}

type GetDatasourceDataArgs = {
	accountId: string
	dataSourceId: string
}

export function getDataSourceData(args: GetDatasourceDataArgs){
	return new ApiRequest<PagedList<GridDataItem<any>>>({
		url: `dataSources/${args.dataSourceId}/data`,
		accountBased: true,
		accountId: args.accountId,
		method: 'POST'
	})
}

type GetDataSourcesArgs = {
	accountId?: string
}

export class DataSource {
	accountId: string
	id: string
	parentId: string
	type: 'SCRIPT'
	name: string
	description: string
	caption: string
	unitType: string
	valueType: string
	attributes: {
		path: string
		parameters: string
		timeout: string
	}
	dataSelector: {
		type: 'LASTN'
		value: number
		fromTime: number
		toTime: number
		zoneId: string
		initializeStart: boolean
	}
	ttl: number
	timeCreate: number
	timeUpdate: number
	locale: string
	dataSourceId: string
}

export function getDataSources(args: GetDataSourcesArgs){
	return new ApiRequest<DataSource[]>({
		url: 'dataSources',
		accountBased: true,
		accountId: args?.accountId
	})
}
