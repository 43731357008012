import React from "react";
import {action, computed, makeObservable, observable, toJS} from "mobx"

import {ActionsManager} from "./actionsManager/actionsManager"
import {Designer as DesignerLegacy} from 'controls/designer/designer'
import {DesignerConfig} from "controls/designer/designerConfig"
import {Settings} from "./settings"
import {MobxManager} from "framework/mobx-integration"
import {
	isMxChildChange,
	MxCell,
	MxClipboard,
	MxEvent,
	MxEventObject,
	MxGraphModel
} from "controls/designer/mxGraphInterfaces"
import {DataSourcesManager} from "controls/designer/dataSourcesManager/dataSourcesManager"
import {RedirectConfigsManager} from "controls/designer/features/redirectOnClick/redirectConfigsManager";
import {WidgetsManager} from "controls/designer/features/widgets/widgetsManager";
import {newGuid} from "tools/guid";
import {CustomDataEntry} from "controls/designer/customDataEntry";
import {iterateAllCells, iterateChildren, iterateParents} from "controls/designer/utils";
import {serialize} from "serializr";
import {copyViaSerializr} from "framework/serializr-integration";
import {WidgetWizardStore} from "controls/designer/features/widgets/wizard/widgetWizardStore";
import {ArrayHelpers} from "tools/helpers";

export let DesignerContext = React.createContext<DesignerStore>(null);

export class ToolbarFeature{
	store: DesignerStore;

	enabled: boolean = false;
	items: string;

	constructor(store: DesignerStore) {
		this.store = store;

		this.items = store.config.toolbar ?? "";
	}
}

export class DesignerStore {
	legacyDesigner: DesignerLegacy

	config: DesignerConfig

	toolbar: ToolbarFeature

	scale = 100
	settings: Settings

	am: ActionsManager

	featuresLoaded: boolean = false

	resetWindowsPositionsTrigger: string

	customData: CustomDataEntry[] = []
	deletedCustomData: CustomDataEntry[] = []

	dataSourcesManager: DataSourcesManager
	redirectConfigsManager: RedirectConfigsManager
	widgetsManager: WidgetsManager

	widgetWizardStore: WidgetWizardStore

	get graph(){
		return this.legacyDesigner.graph;
	}

	get editor() {
		return this.legacyDesigner.editor;
	}

	cell: MxCell
	cells: MxCell[] = []

	mobx = new MobxManager()

	constructor(designer: DesignerLegacy) {
		this.legacyDesigner = designer
		this.config = designer.config
		this.dataSourcesManager = new DataSourcesManager(this)

		this.redirectConfigsManager = new RedirectConfigsManager(this)

		this.widgetsManager = new WidgetsManager(this)

		if(!this.config.chromeless) {
			this.widgetWizardStore = new WidgetWizardStore(this)
		}

		this.am = new ActionsManager(this)
		this.toolbar = new ToolbarFeature(this)

		makeObservable(this, {
			scale: observable,
			featuresLoaded: observable,
			settings: observable,
			resetWindowsPositionsTrigger: observable,
			customData: observable,
			cell: observable,
			cells: observable,
			getOrCreateCustomData: action,
			persistableCustomData: computed,
		})
	}

	get isApplicationMode(){
		return this.legacyDesigner.config.mode == 'application'
	}

	get persistableCustomData(){
		return this.customData
			.filter(x => !x.temporary)
			.map( x => serialize(CustomDataEntry, toJS(x)))
	}

	addCustomData(newCustomData: CustomDataEntry[], triggerEvent: boolean = true) {
		const processedCustomData = newCustomData.map(x => {
			if (x.cell != null)
				return x

			const customData = new CustomDataEntry(x)

			iterateAllCells(this.graph, (cell: MxCell) => {
				let customDataId = cell.getAttribute('customDataId')
				if (customData.id == customDataId) {
					customData.cell = cell
					return true
				}
			})

			return customData
		}).filter(x => x.cell != null)

		this.customData.push(...processedCustomData)

		if (triggerEvent) {
			this.triggerCustomDataAdded(processedCustomData)
		}
	}

	init(){
		this.graph.getSelectionModel().addListener(MxEvent.CHANGE, this.onSelectionChanged)
		this.graph.getModel().addListener(MxEvent.NOTIFY, this.processExecuteEvent)

		this.mobx.reaction(() => this.scale, () => {
			if (this.scale / 100 != this.graph.view.scale) {
				this.graph.view.setScale(this.scale / 100)
			}
		})

		this.graph.view.addListener(MxEvent.SCALE, () => {
			if (this.scale != this.graph.view.scale * 100) {
				this.scale = Math.round(this.graph.view.scale * 100)
			}
		})

		this.widgetsManager?.init()
	}

	onSelectionChanged = () => {
		this.cells = this.graph.getSelectionCells() ?? []
		this.cell = this.cells.length > 0 ? this.cells[0] : null
	}

	processExecuteEvent = async (graphModel: MxGraphModel, ev: MxEventObject) => {
		let newDataSources : CustomDataEntry[] = []

		ev.properties.changes.forEach(change => {
			if (isMxChildChange(change)) {
				let cell1 = change.child
				iterateChildren(change.child, (cell: MxCell) => {
					let customData = this.getCustomData(cell)
					if (customData == null) {
						customData = this.searchForCustomDataInClipboard(cell)
					}

					if(customData == null){
						customData = this.searchForCustomDataInDeleted(cell)
					}

					if (customData == null) {
						return
					}

					if (change.previous == null) {
						if (customData.cell == cell)
							return

						//that might look odd that we look for a custom data for a cell and then add it again
						//but this is for a scenario when cell is copied - we look for custom data with an id of existing cell
						//then copy it under new id and assign to a new cell
						let newCustomData = copyViaSerializr(customData)
						newCustomData.id = newGuid()
						newCustomData.cell = cell
						cell.setAttribute("customDataId", newCustomData.id)

						newDataSources.push(newCustomData)

					} else if (change.parent == null) {
						this.removeCustomData(customData)
					}
				})
			}
		})

		if(newDataSources.length){
			this.customData.push(...newDataSources)

			this.triggerCustomDataAdded(newDataSources)
		}
	}

	removeCustomData(customData: CustomDataEntry) {
		let index = this.customData.indexOf(customData)

		if (index != -1) {
			this.customData.splice(index, 1)
			this.triggerCustomDataRemoved(customData)

			customData.cell = null
			this.deletedCustomData.push(customData)
		}
	}

	triggerCustomDataAdded = (customData: CustomDataEntry[]) => {
		this.dataSourcesManager.onCustomDataAdded(customData)
		this.widgetsManager.onCustomDataAdded(customData)
	}

	triggerCustomDataRemoved = (customData: CustomDataEntry) => {
		this.dataSourcesManager.onCustomDataRemoved(customData)
		this.widgetsManager.onCustomDataRemoved(customData)
	}

	getCustomData(cell: MxCell) {
		if (cell == null)
			return null

		let customDataId = cell.getCustomDataId()

		return this.customData.find(x => x.id == customDataId)
	}

	searchForCustomDataInDeleted(cell: MxCell) {
		if (cell == null)
			return null

		let customDataId = cell.getCustomDataId()

		let index = this.deletedCustomData.findIndex(x => x.id == customDataId)
		if (index == -1)
			return null

		let customData = this.deletedCustomData[index]
		this.deletedCustomData.splice(index, 1)

		return customData
	}

	searchForCustomDataInClipboard(cell: MxCell){
		if (!Array.isArray(MxClipboard.customDataList)) {
			return null
		}

		let customDataId = cell.getCustomDataId()
		return MxClipboard.customDataList.find(x => x.id == customDataId)
	}

	getOrCreateCustomData(cell: MxCell){
		let result = this.getCustomData(cell)

		if (result == null) {
			result = new CustomDataEntry({
				id: newGuid(),
				cell
			})

			this.customData.push(result)
			cell.setAttribute('customDataId', result.id)
		}

		return result
	}


	_onDestroyCallbacks:  (() => void)[] = []

	onDestroy(callback: () => void){
		this._onDestroyCallbacks.push(callback)
	}

	onDestroyRemove(callback: () => void){
		ArrayHelpers.remove(this._onDestroyCallbacks, callback)
	}

	destroy(){
		this._onDestroyCallbacks.forEach(x => x())
		this.mobx?.destroy()
		this.widgetsManager?.destroy()
		this.dataSourcesManager?.destroy()
		this.widgetWizardStore?.destroy()
	}
}
