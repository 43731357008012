import React from "react";

import {useMonitors} from "./api";
import {AntSelect} from "controls/react/ant/antSelect";


const i = require('core/localization').translator({
  "Select a monitor...": {
    "no": "Velg Monitor",
    "en": "Select Monitor"
  }
});

export const MonitorsDropDown = props => {
	const {accountId, cloudType, ...others} = props;
	const monitors = useMonitors(accountId, cloudType);

	return (
		<AntSelect placeholder={i('Select a monitor...')}
		           dataList={monitors}
		           disabled={!cloudType}
		           {...others}
		/>
	);
}
