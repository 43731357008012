import './sparklineTableWidget.less'

import React, {useCallback, useEffect, useState} from 'react';

import {Section} from "controls/react/layout/section";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {FormEntry, Grid, MultiToggle} from "controls/react/form";
import AccountDropDown from "controls/react/dropdowns/accountDropDown";
import {AntInput} from "controls/react/ant/antInput";
import Highcharts from 'highcharts';
import {WidgetConfigurationProps} from "controls/designer/features/widgets/widgetConfig";
import {WidgetProps} from "controls/designer/features/widgets/widgetProps";
import {SparklineTableWidgetConfig} from './sparklineTableWidgetConfig';
import {SparklineTableWidgetPersistedState} from './sparklineTableWidgetPersistedState';
import {SparklineTableWidgetState} from './sparklineTableWidgetState';
import {SparklineTableWidgetData, SparklineTableWidgetRow} from './sparklineTableWidgetRow';
import {AssetsRouter} from "areas/assets/bundleDescription";
import {ApplicationState} from "framework/applicationState";
import {getMetrics} from './api';
import {apiFetch, HttpStatus} from "framework/api";
import {RegisteredMetric} from 'framework/entities/registeredMetric';
import Renderer from 'tools/renderer';
import {ColumnsVisibility} from "controls/designer/features/widgets/columnsVisibility";
import {ColumnVisibilityColType} from 'framework/entities/columnVisibilityColType';
import {getGridStateForSaving} from "controls/react/kendoWrappers/grid";
import {RemoteEventsManager} from 'core';
import Utils from 'tools/utils';
import {
	GenericMetricsWidgetConfiguration
} from "areas/service-boards/widgets/genericMetricsWidgetConfiguration/widgetConfiguration";
import _ from 'lodash';
import {
	MetricsItem,
	WidgetConfigurationType
} from 'areas/service-boards/widgets/genericMetricsWidgetConfiguration/widgetConfigurationType';
import {
	legacyPeriodsDynamicOnly,
	TimePeriod,
	TimePeriodAppearance,
	TimePeriodSelector
} from "controls/react/form/timePeriodSelector";
import {
	getMetricsWithCorrectConversionFields
} from "areas/service-boards/widgets/common/metricAdvancedUtils";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {extractMetricSubscriptionFields} from "areas/service-boards/widgets/customMetrics/utils";

const i = require('core/localization').translator({
	'Metric Name': {
		'no': 'Metrikknavn'
	},
	'Sparkline' : {'no' : 'Sparkline'},
	'Instance Name': { 'no': 'Instansnavn'},
	'Date Time': { 'no': 'Datotid'}
}, sharedLocalization);
const b = require('b_').with('sparkline-table-widget');

export function getWidgetDescription(){
	return {
		form: SparklineTableWidgetConfiguration,
		defaultConfig: {
			type: 'metric-sparkline-table',
			title: i('Sparkline Table'),
			accountId: ApplicationState.accountId,
			columns: getAllColumns().map(x => x.field),
			period: 'LASTDAY',
			selectedMetrics: ([] as MetricsItem[]),
			hideTimePeriodSelector: false
		},
		widget: SparklineTableWidget,
		fullTitle: i('Metric') + '/' + i('Sparkline Table'),
	}
}
let allColumns: ColumnVisibilityColType[];

function getAllColumns(): ColumnVisibilityColType[] {
	if(!allColumns){
		allColumns = [{
			field: 'assetName',
			title: i('Asset'),
			shown: true
		},{
			field: 'categoryNode',
			title: i('Name'),
			shown: true
		}, {
			field: 'registryType',
			title: i('Type'),
			shown: true
		},{
			field: 'registryOwnerName',
			title: i('Source'),
			shown: true
		},{
			field: 'instanceName',
			title: i('Instance Name'),
			shown: true
		},{
			field: 'unitType',
			title: i('Unit'),
			shown: true
		},{
			field: 'value',
			title: i('Value'),
			shown: true
		},{
			field: 'time',
			title: i('Date Time'),
			shown: true
		},{
			field: 'data',
			title: i('Sparkline'),
			shown: true
		}];
	}
	return allColumns;
}

const SparklineTableWidgetConfiguration = (props: WidgetConfigurationProps<SparklineTableWidgetConfig>) => {
	const {configLink} = props;
	const [period, setPeriod] = useState({period: TimePeriodType.Last24Hours});
	useEffect(() => {
		const periodValue = configLink.get('period').value;
		if (typeof periodValue == 'string') {
			setPeriod({period: periodValue as TimePeriodType});
		} else {
			setPeriod(periodValue);
		}
	}, [configLink.get('period').value]);

	const periodChanged = useCallback((value: TimePeriod) => {
		setPeriod(value);
		configLink.get('period').onChange?.(value)
	}, []);
	const onMetricsChanged = (value: WidgetConfigurationType) => {
		const newMetrics = value.configuration.metricsItems;
		if(_.isEqual(configLink.get('selectedMetrics').value, newMetrics))
			return;

		configLink.get('selectedMetrics').update(newMetrics)
	}

	return <Section appearance={"none"}
	                contentPadding={false}
	                childrenPadding={true}>
		<FormEntry label={i('Title')}>
			<AntInput valueLink={configLink.get('title')}/>
		</FormEntry>
		<FormEntry label={i('Period')}>
			<TimePeriodSelector appearance={TimePeriodAppearance.Buttons}
								periods={legacyPeriodsDynamicOnly}
								value={period}
								onChange={periodChanged}
								size="middle" />
			<AntCheckbox {...configLink.get('hideTimePeriodSelector').props} >{i('Hide time selector')}</AntCheckbox>
		</FormEntry>
		<ColumnsVisibility
			columnsLink={configLink.get('columns')}
			allColumns={getAllColumns()}
		/>
		<GenericMetricsWidgetConfiguration
			config={{
				id: configLink.get('id').value,
				title: configLink.get('title').value,
				type: configLink.get('type').value,
				configuration: {
					accountId: configLink.get('accountId').value,
					metrics: configLink.get('selectedMetrics').value,
					metricsItems: configLink.get('selectedMetrics').value
				}
			}}
			isExisting={true}
			hideThreshold={true}
			showAdvanced={true}
			metricsOnly={true}
			onChange={onMetricsChanged}/>
	</Section>
}


class SparklineTableWidget extends React.Component<WidgetProps<SparklineTableWidgetConfig, SparklineTableWidgetPersistedState>, SparklineTableWidgetState> {
	grid: Grid;
	subscription: any;
	sparklineList: any[];
	periods = [
		TimePeriodType.LastHour,
		TimePeriodType.Last24Hours,
		TimePeriodType.Last7Days,
		TimePeriodType.Last30Days,
		TimePeriodType.DynamicInterval,
		TimePeriodType.Custom
	]

	constructor(props: WidgetProps<SparklineTableWidgetConfig, SparklineTableWidgetPersistedState>) {
		super(props)

		let period = this.props.config.period;

		if (typeof this.props.config.period == 'string') {
			period = {period: this.props.config.period};
		}

		this.state = {
			gridHeight: "auto",
			dataSource: [],
			period: period,
			loading: true
		}
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		this.unsubscribe();
	}

	render() {
		const toolbarAtTheEnd = this.props.config.hideTimePeriodSelector !== true
			? <TimePeriodSelector appearance={TimePeriodAppearance.Buttons}
													periods={this.periods}
													value={this.state.period}
													onChange={this.onPeriodChange}
													size="small" />
			: null

		return <DefaultWidgetWrapper {...this.props} containerClass={b()} toolbarAtTheEnd={toolbarAtTheEnd}>
				<Section contentPadding={false} height={"fit"} overlaySpinner={this.state.loading} contentOverlay={this.state.loading}>
					{ this.state?.dataSource &&	<Grid dataSourceArray={this.state.dataSource}
						columns={this.getGridColumns()}
						height={this.state.gridHeight}
						scrollable={true}
						reorderable={true}
						skipSelectorColumn={true}
						noRecords={{template: i('No data')}}
						containerClass={b("sparkline-table")}
						sortable
						sort={this.props.persistedState?.sort || []}
						fit={true}
						ref={grid => this.grid = grid}
						dataBound={this.onGridDataBound}
						resizable={true}>
						</Grid>}
				</Section>
			</DefaultWidgetWrapper>
	}

	init = ()=> {
		this.unsubscribe();
		this.loadData();
	}
	onPeriodChange = (value: TimePeriod) => {
		const period = Object.assign({}, this.props.config.period, value);
		this.setState({period: period}, ()=>{
			this.init();
		})
	}

	async loadData() {
		this.setState({loading: true})

		const items = getMetricsWithCorrectConversionFields(this.props.config.selectedMetrics)
		const metricsDataResult = await apiFetch(
			getMetrics(items, this.state.period)
		)

		if (metricsDataResult.success) {
			this.subscribe(metricsDataResult.data);

			this.setState({
				dataSource: metricsDataResult.data.map((x: RegisteredMetric) => {
					const lastElement = x.data[x.data.length - 1];
					return {...x.qualifier, items: x.data, time: lastElement?.t, value: lastElement?.v}
				})
			})
		}

		this.setState({loading: false})
	}

	getGridColumns() {
		const id = this.props.config.id
		const gridColumns = [{
			field: 'assetName',
			title: i('Asset'),
			template: (item: SparklineTableWidgetData)=> {
				return `<a ${this.props.navigator.renderLink(AssetsRouter.details(item.assetId))}>${item.assetName}</a>`;
			}
		},{
			field: 'categoryNode',
			title: i('Name')
		}, {
			field: 'registryType',
			title: i('Type')
		},{
			field: 'registryOwnerName',
			title: i('Source')
		},{
			field: 'instanceName',
			title: i('Instance Name')
		}, {
			field: 'unitType',
			title: i('Unit'),
		},{
			field: 'value',
			title: i('Value'),
		},{
			field: 'time',
			title: i('Date Time'),
			template: (item: SparklineTableWidgetData) => Renderer.browserDateRenderer(item.time, "datetime")
		},{
			field: 'data',
			title: i('Sparkline'),
			template: function (item: SparklineTableWidgetData) {
				return `<div data-id="${id}" data-metricId="${item.metricId}"></div>`;
			}
		}];
		const showColumns: any[] = [];
		gridColumns.forEach((col) => {
			if(this.props.config.columns.includes(col.field)){
				showColumns.push(col)
			}
		});
		if (this.props.persistedState?.columns) {
			return Utils.rearrangeColumns(showColumns, this.props.persistedState?.columns);
		}
		return showColumns;
	}

	drawSparklines() {
		if(!this.props.config.columns.includes('data'))
			return;
		const sparkLine = function (container: any, options: any) {

			const defaultOptions = {
				chart: {
					renderTo: container,
					backgroundColor: null as string,
					borderWidth: 0,
					type: 'area',
					margin: [2, 0, 2, 0],
					height: 35,
					style: {
						overflow: 'visible',
						width: '100%',
					},
					skipClone: true,
				},
				title: {
					text: ''
				},
				credits: {
					enabled: false
				},
				exporting: false,
				xAxis: {
					labels: {
						enabled: false
					},
					title: {
						text: null as string
					},
					startOnTick: false,
					endOnTick: false,
					tickPositions: [] as number[]
				},
				yAxis: {
					endOnTick: false,
					startOnTick: false,
					labels: {
						enabled: false
					},
					title: {
						text: null as string
					},
					tickPositions: [0]
				},

				legend: {
					enabled: false
				},
				tooltip: {
					hideDelay: 0,
					outside: true,
					shared: true,
					backgroundColor: 'white',
					borderWidth: 0,
					shadow:{
						offsetX: 0,
						offsetY: 0,
						opacity: 1,
						width: 16,
						color: 'rgb(0,0,0,0.01)'
					},
					useHTML: true,
				},
				plotOptions: {
					series: {
						animation: false,
						lineWidth: 1,
						shadow: false,
						states: {
							hover: {
								lineWidth: 1
							}
						},
						marker: {
							radius: 1,
							states: {
								hover: {
									radius: 2
								}
							}
						},
						fillOpacity: 0.25
					},
					column: {
						negativeColor: '#910000',
						borderColor: 'silver'
					}
				}
			};
			options = Highcharts.merge(defaultOptions, options);

			return new Highcharts.Chart(container, options);
		};
		const rows: SparklineTableWidgetRow[] = [];
		this.state.dataSource.forEach((x: SparklineTableWidgetData) => {
			const spanCont = document.querySelector(`div[data-id='${this.props.config.id}'][data-metricId='${x.metricId}']`)
			const color = this.props.config.selectedMetrics.find(m => m.metricId == x.metricId).color;
			rows.push({container: spanCont, items: x.items, name: this.getTooltipText(x), color: color})
		})
		for (const row of rows) {
			sparkLine(row.container, {
				series: [{
					data: row.items.map(i => i.v),
					pointStart: 1,
					lineColor: row.color,
					color: row.color
				}],
				tooltip: {
					formatter: function (e: any) {
						const t = this as any;
						if (t.points) {
							const s = Renderer.browserDateRenderer(row.items[t.points[0].x - 1].t, 'datetime', '', ApplicationState.timezone);
							return s + '<br />' + row.name.replace('[VALUE]', t.points[0]?.y);
						}
					}
				},
				chart: {}
			});
		}
	}

	onResize() {
		setTimeout(()=>{
			let wrapper = this.grid?.kendoGrid.wrapper;
			let gridContainerHeight = wrapper.closest('.section__content').height();
			this.setState({
				gridHeight: gridContainerHeight
			}, () => {
				this.drawSparklines()
			});
		},0)
	}

	getTooltipText (x: SparklineTableWidgetData): string {
		let tooltipText = `${x.agentName}/${x.categoryNode}/${x.registryType}<br/>${x.instanceName}: [VALUE] ${x.unitTypeSymbol}`;
		return tooltipText;
	}

	onGridDataBound = (e: any) => {
		this.drawSparklines();
	}

	getStateForSaving() {
		return getGridStateForSaving(this.grid?.kendoGrid);
	}

	subscribe (data: RegisteredMetric[]) {
		const metricIds = this.props.config.selectedMetrics.map(m => m.metricId);
		this.subscription = RemoteEventsManager.subscribeCallback([{
			eventType: 'MultipleMetric',
			metrics: extractMetricSubscriptionFields(this.props.config.selectedMetrics)
		}], this.onEvents);

		let intervals: Record<string, number> = {};
		const {interval} = Utils.getPeriodInterval({
			period: this.state.period,
			width: 300
		});
		metricIds.forEach(x => {intervals[x] = interval})
		RemoteEventsManager.releaseEvents(this.subscription.subscriberId, {
			intervals
		});
	}

	unsubscribe() {
		this.subscription?.unsubscribe();
	}
	onEvents = (events: any) => {
		if(!events || !events.metric)
			return;

		const dataSource = [...this.state.dataSource];
		const metric = dataSource.filter(x => x.metricId == events.qualifierId);

		if(metric?.length > 0){
			metric[0].items.push({
				t: events.metric.t,
				v: events.metric.v,
				e: events.metric.e
			})
			metric[0].time = events.metric.t;
			metric[0].value = events.metric.v;
		}
		this.setState({dataSource})
	}
}
