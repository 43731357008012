import {createModelSchemaWrapper} from "framework/serializr-integration"
import {list, object, primitive} from "serializr"

import { HealthDataHolder } from "./healthData"
import {makeAutoObservable, makeObservable, observable} from "mobx";

export class AssetTarget {
	inUse?: boolean
	address: string
	id: string
}

createModelSchemaWrapper(AssetTarget, {
	id: primitive(),
	address: primitive(),
	inUse: primitive()
})

export class Asset extends HealthDataHolder {
	id: string
	name: string
	accountName: string
	accountId: string
	information: string
	shared: boolean
	description: string
	assetType: string
	systemType: string = 'GENERIC'
	targets: AssetTarget[] = []
	assetGroups: string[] = []
	tags: string[] = []

	constructor(data?: Partial<Asset>) {
		super()

		if (data) {
			Object.assign(this, data)
		}

		makeObservable(this, {
			id: observable,
			name: observable,
			accountName: observable,
			accountId: observable,
			information: observable,
			shared: observable,
			description: observable,
			assetType: observable,
			systemType: observable,
			targets: observable,
			assetGroups: observable,
			tags: observable,
		})
	}
}

createModelSchemaWrapper(Asset, {
	id: primitive(),
	name: primitive(),
	shared: primitive(),
	information: primitive(),
	description: primitive(),
	assetType: primitive(),
	systemType: primitive(),
	targets: list(object(AssetTarget)),
	assetGroups: list(primitive()),
	tags: list(primitive())
})

