import React from "react"
import {AntPopover} from "./react/ant/antPopover"
import {ApplicationState} from "../framework/applicationState"
import {Permission} from "../framework/permission"
import b_ from 'b_'
import {observer} from "mobx-react"
import {CaretDownOutlined, HomeOutlined, SyncOutlined} from '@ant-design/icons'
import './accountSelector.less'
import {makeAutoObservable} from "mobx"
import {Section, Toolbar, ToolbarItemPosition, ToolbarItemsSet} from "./react/layout"
import { AntButton } from "./react/ant/antButton"
import {AntInput} from "./react/ant/antInput"
import {ActionButtons} from "./react/form"
import {AntTree} from "./react/ant/antTree"
import Cookies from "../core/cookies"
import Utils from "../tools/utils"
import {getAccountsForAccountSelect} from "../areas/administration/accounts/api"
import { apiFetch } from "framework/api"
import type { TreeDataNode } from 'antd'
import {flatTree, getPath} from "../tools/tree"
import Settings from "../settings"

const b = b_.with('account-selector')

const i = require('core/localization').translator()

export interface Account {
	id: string
	items: Account[]
	name: string
}

const getParentKey = (key: React.Key, tree: TreeDataNode[]): React.Key => {
	let parentKey: React.Key
	for (let i = 0; i < tree.length; i++) {
		const node = tree[i]
		if (node.children) {
			if (node.children.some((item) => item.key === key)) {
				parentKey = node.key
			} else if (getParentKey(key, node.children)) {
				parentKey = getParentKey(key, node.children)
			}
		}
	}
	return parentKey!
}

class AccountSelectorStore {
	popupOpened: boolean = false
	accounts: Account[]
	loading: boolean = false
	searchValue: string = ''

	manualExpandedKeys: boolean = true
	savedExpandedKeys: string[]

	selectedAccountId: string

	constructor() {
		makeAutoObservable(this)
	}

	onNameClick = () => {
		if(!this.hasPermissions) {
			return
		}
		this.popupOpened = !this.popupOpened
		if(this.popupOpened) {
			this.loadAccounts()
		}
	}

	onMainAccountClick = () => {
		if (Cookies.get('CeesoftAccountId') !== Cookies.get('CeesoftCurrentAccountId')) {
			Cookies.create('CeesoftCurrentAccountId', Cookies.get('CeesoftAccountId'), Settings.COOKIE_TIMEOUT)
			Cookies.create('CeesoftCurrentAccountName', Cookies.get('CeesoftAccountName'), Settings.COOKIE_TIMEOUT)
			Cookies.erase("CeesoftParentAccountId")
			Cookies.erase("CeesoftParentAccountName")
			this.popupOpened = false
			Utils.redirectTo(Settings.httpRoot)
		}
	}

	get isDefaultAccount() {
		return Cookies.get('CeesoftAccountId') === Cookies.get('CeesoftCurrentAccountId')
	}

	loadAccounts = async ({force} : {force: boolean} = {force: false}) => {
		if(this.accounts && !force) {
			return
		}
		this.loading = true
		const result = await apiFetch(getAccountsForAccountSelect(Cookies.get('CeesoftAccountId')))
		if (result.success) {
			this.accounts = [result.data as Account]
			this.manualExpandedKeys = true
			this.savedExpandedKeys = this.accounts.map(x => x.id)
		} else {
			// @ts-ignore
			Utils.showInfo(i('Alert'), result.message, result.details)
		}
		this.loading = false
	}

	mapAccountsToTree = (accounts: Account[]) : TreeDataNode[] => {
		return accounts?.map(x => ({
			key: x.id,
			title: x.name,
			children: this.mapAccountsToTree(x.items)
		}))
	}

	onRefreshClick = async () => {
		await this.loadAccounts({force: true})
	}

	onSave = () => {
		this.popupOpened = false
		const account = flatTree(this.accounts, x => x.items).find(x => x.id === this.selectedAccountId)
		const {id, name} = account
		const path = getPath(this.accounts, this.selectedAccountId)
		const isChild = path.length > 0
		const timeout = Settings.COOKIE_TIMEOUT
		if (isChild) {
			Cookies.create('CeesoftParentAccountId', Cookies.get('CeesoftCurrentAccountId'), timeout)
			Cookies.create('CeesoftParentAccountName', Cookies.get('CeesoftCurrentAccountName'), timeout)
		} else {
			if (id === Cookies.get('CeesoftParentAccountId')) {
				Cookies.create('CeesoftParentAccountId', '', timeout)
				Cookies.create('CeesoftParentAccountName', '', timeout)
			}
		}
		Cookies.create('CeesoftCurrentAccountId', id, timeout)
		Cookies.create('CeesoftCurrentAccountName', name, timeout)
		Cookies.erase('CeesoftAccountLogoId')
		ApplicationState.accountName = name
		ApplicationState.accountId = id
		Utils.redirectTo(Settings.httpRoot)
	}

	onCancel = () => {
		this.searchValue = ''
		this.savedExpandedKeys = [this.accounts[0].id]
		this.popupOpened = false
	}

	get hasPermissions(): boolean {
		return ApplicationState.hasPermissions(Permission.AccountList, Permission.AccountTraverse)
	}

	onExpand = (keys: React.Key[]) => {
		this.manualExpandedKeys = true
		this.savedExpandedKeys = keys as string[]
	}

	get expandedKeys() {
		if(this.manualExpandedKeys) {
			return this.savedExpandedKeys
		}
		const newKeys =  this.flatTreeData
			.map((item) => {
				if ((item.title as string).toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1) {
					return getParentKey(item.key, this.fullTreeData)
				}
				return null
			})
			.filter((item, i, self): item is React.Key => !!(item && self.indexOf(item) === i))
		return newKeys
	}

	setSearchValue = (value: string) => {
		this.manualExpandedKeys = false
		this.searchValue = value
	}


	filter = (data: TreeDataNode[]): TreeDataNode[] => {
		return data?.map((item) => {
			const strTitle = item.title as string
			const index = strTitle.toLowerCase().indexOf(this.searchValue.toLowerCase())
			const beforeStr = strTitle.substring(0, index)
			const searchStr = strTitle.substring(index, this.searchValue.length + index)
			const afterStr = strTitle.slice(index + this.searchValue.length)
			const title =
				index > -1 ? (
					<span>
							{beforeStr}
						<span className={b('search-highlight')}>{searchStr}</span>
						{afterStr}
					</span>
				) : (
					<span>{strTitle}</span>
				)
			return { title, key: item.key, children: this.filter(item.children) }
		})
	}

	onTreeSelect = (keys: React.Key[]) => {
		this.selectedAccountId = keys[0] as string
	}

	get fullTreeData() : TreeDataNode[] {
		return this.mapAccountsToTree(this.accounts)
	}

	get flatTreeData() : TreeDataNode[] {
		return flatTree(this.fullTreeData, x => x.children)
	}

	get treeData() {
		if(!this.searchValue) {
			return this.fullTreeData
		}
		return this.filter(this.fullTreeData)
	}

	popupOpenedChange = (open: boolean) => {
		this.popupOpened &&= open
	}
}

const AccountSelectorPopover = observer(({store}: {store: AccountSelectorStore}) => {
	return <Section appearance={'none'} containerClass={b('popover')} childrenPadding={true} contentPadding={true} overlaySpinner={true} contentOverlay={store.loading}>
		<Toolbar>
			<ToolbarItemsSet position={ToolbarItemPosition.BEFORE_TITLE}>
				<AntButton type={'default'} onClick={store.onMainAccountClick} disabled={store.isDefaultAccount}>
					<HomeOutlined/>
				</AntButton>
				<AntInput className={b('filter')} value={store.searchValue} onChange={store.setSearchValue}/>
				<AntButton type={'default'} onClick={store.onRefreshClick}>
					<SyncOutlined />
				</AntButton>
			</ToolbarItemsSet>
		</Toolbar>
		<AntTree onSelect={store.onTreeSelect} treeData={store.treeData} onExpand={store.onExpand} expandedKeys={store.expandedKeys} autoExpandParent={!store.manualExpandedKeys} />
		<Toolbar appearance={'transparent'} containerClass={b('actions-toolbar')}>
			<ActionButtons actionButtonLabel={i('Select')} onSave={store.onSave} onCancel={store.onCancel} saveDisabled={!store.selectedAccountId} className={b('actions')}/>
		</Toolbar>
	</Section>
})

export const AccountSelector = observer(() => {
	// click permission: ACCOUNT_LIST && ACCOUNT_TRAVERSE
	const [store] = React.useState(() => new AccountSelectorStore())

	return <AntPopover
		content={<AccountSelectorPopover store={store} />}
		arrow={false}
		trigger={'click'}
		open={store.popupOpened}
		onOpenChange={store.popupOpenedChange}
	>
		<div className={b()} onClick={store.onNameClick}>
			<span className={b('name')}>{ApplicationState.accountName}</span>
			{store.hasPermissions && <CaretDownOutlined />}
		</div>
	</AntPopover>
})
