import React, {CSSProperties} from "react";
import {observer} from "mobx-react"
import classnames from "classnames"

import {GridStore} from "controls/grid/gridStore"
import {GridColumn, RenderingResult} from "controls/grid/gridColumnConfig"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {addResizeListener} from "tools/detectElementResize";
import {MobxManager} from "framework/mobx-integration";
import {useExpandCollapseButton} from "framework/react-integration";
import {AntButton} from "controls/react/ant/antButton";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons";
import {GridDataItem} from "controls/grid/gridDataItem";

const i = require('core/localization/localization').translator({
  "Loading": {
    "no": "Laster"
  }
});

type ItemRendererProps<DataItem extends GridDataEntry> = {
	index: number
	style: CSSProperties
	data: GridStore<DataItem>
	isScrolling?: boolean | undefined
}

const b = require('b_').with('ceeview-grid')

export const ItemRenderer = observer(<DataItem extends GridDataEntry>(props: ItemRendererProps<DataItem>) => {
	const item = props.data.dataProvider.get(props.index)

	if(item == undefined){
		return <GridRowLoading {...props}/>
	}

	if(item.type == 'group'){
		return  <GridRowGroupHeader {...props}/>
	}

	return <GridRowRenderer {...props}/>
})

const GridRowLoading = observer(<DataItem extends GridDataEntry>(props: ItemRendererProps<DataItem>) => {
	return <div className={b('row-loading', {loading: true})} style={props.style}>
		{i('Loading...')}
	</div>
})

const GridRowGroupHeader = observer(
	class GridRowRendererInner<DataItem extends GridDataEntry> extends React.Component<ItemRendererProps<DataItem>> {

		get item(){
			return this.props.data.dataProvider.get(this.props.index)
		}

		get store(){
			return this.props.data
		}

		render() {
			const field = this.store.state.groupBy[this.item.groupLevel - 1]
			const column = this.store.columns.all.find(x => x.field == field)

			let renderingResult = renderColumn(column, this.item)

			const style = {...this.props.style}
			style.width = this.store.columns.visibleWidth
			style.paddingLeft = Math.max(5, (this.item.groupLevel - 1) * 15) + 'px'

			return <div key={this.item.id} className={b('row-group')} onClick={this.toggleGroup} style={style}>
				<AntButton size={"small"} icon={this.isCollapsed() ? <CaretDownOutlined/> : <CaretUpOutlined/>} type={"text"}/>
				<div className={b('row-group-field-title')}>{column.config.title}</div>
				<div className={b('row-group-field-value')}>{renderingResult.content}</div>
			</div>
		}

		toggleGroup = () => {
			let collapsedGroups = this.store.state.collapsedGroups
			const index = collapsedGroups.findIndex(x => x == this.item.id)
			if (index != -1) {
				this.store.state.collapsedGroups.splice(index, 1)
			} else {
				this.store.state.collapsedGroups.push(this.item.id)
			}
		}

		isCollapsed(){
			let collapsedGroups = this.store.state.collapsedGroups
			return collapsedGroups.findIndex(x => x == this.item.id) != -1
		}
	}
)

const GridRowRenderer = observer(
	class GridRowRendererInner<DataItem extends GridDataEntry> extends React.Component<ItemRendererProps<DataItem>> {
		mobx = new MobxManager()

		get item(){
			return this.props.data.dataProvider.get(this.props.index)
		}

		get store(){
			return this.props.data
		}

		render() {
			const {index, data: store} = this.props

			//there is a very hackish algorithm to get real height of a row content
			//When user clicks on a row we remove CSS restrictions which prevents row from expanding and
			//and also ignoring row height provided by the plugin so the row expands to its real height
			//Then we receive an event that row size changed and update store.rowsCustomHeight dictionary with a real height of the row
			//and then apply this height on the row so the plugin can update scrollbar with a new value
			//There is a catch though - we fix row height on a first resize event. If size is changed for some reason again then a new click is required

			let styles = {...this.props.style}
			styles.width = store.columns.visibleWidth
			styles.paddingLeft = (this.item.groupLevel - 1) * 15 + 'px'

			if (this.item && store.rowsCustomHeight[this.item.id] == -1) {
				delete styles.height
			}

			const classes = b('row-entry', {
				item: true,
				loaded: true,
				selected: this.store.selection.isSelected(this.item),
				'free-size': store.rowsCustomHeight[this.item.id] !== undefined
			})

			return <div
				className={classes}
				style={styles}
				ref={this.elementSetRefCallback}>
				{store.columns.visible.map(c => <GridCellRenderer
					store={store}
					rowIndex={index}
					onClick={c.config.expandOnClick && this.startHeightCalculation}
					column={c}
					key={c.field}/>
				)}
			</div>

		}

		componentWillUnmount() {
			this.mobx.destroy()
		}

		elementSetRefCallback = (ref: HTMLDivElement) => {
			if (!ref) {
				return
			}

			addResizeListener(ref, () => {
				if(this.item == null) {
					return
				}

				this.store.updateRowHeight(this.item, this.props.index, ref)
			})
		}

		startHeightCalculation = () => {
			if(this.props.data.rowsCustomHeight[this.item.id]){
				delete this.props.data.rowsCustomHeight[this.item.id]
				this.store.listControl.resetAfterIndex(this.props.index)
			}else {
				this.props.data.rowsCustomHeight[this.item.id] = -1
			}
		}
	}
)

type GridCellRendererProps<DataItem extends GridDataEntry> = {
	store: GridStore<DataItem>
	rowIndex: number
	column: GridColumn<DataItem>
	onClick: () => void
}

const GridCellRenderer = observer(<DataEntry extends GridDataEntry>(props: GridCellRendererProps<DataEntry>) => {
	const {store, column, rowIndex} = props

	let renderingResult = renderColumn( column, store.dataProvider.get(rowIndex))

	const onClick = () => {
		renderingResult.onClick?.()
		props.onClick?.()
	}

	const classes = b('cell', {
		nowrap: true,
		fixed: column.state.fixed ?? 'none',
		align: column.config.align,
		disabled: renderingResult.disabled,
		clickable: renderingResult.onClick != null,
		'fit-content': renderingResult.fitContent
	})

	const configClassName = typeof column.config.className != 'function' ? column.config.className : column.config.className();
	return <div className={classnames(classes, renderingResult.className, configClassName)}
	            onClick={onClick}
	            style={column.styles}>
		{renderingResult.content}
	</div>
})

export function renderColumn<DataEntry extends GridDataEntry>(column: GridColumn<DataEntry>, item: GridDataItem<DataEntry>) {
	if (column.config.renderer) {
		return getRenderingResult(column.config.renderer(item.data, {
			column: column,
			gridItem: item
		}))
	}

	return {
		content: item.data[column.config.field as keyof DataEntry] as React.ReactNode
	}
}

export function getRenderingResult(result: React.ReactNode | RenderingResult) : RenderingResult{
	if(!isReactContent(result))
		return result

	return {
		content: result
	}
}

function isReactContent<DataItem>(result: React.ReactNode | RenderingResult): result is React.ReactNode{
	if(result === null)
		return true

	if(result === undefined){
		//to find it just hit a breakpoint here, then in  call stack get to GridCellRenderer and take a look at column.config
		console.warn('Looks like you forgot to return a result from a render callback')
	}

	// @ts-ignore
	return result.content === undefined
}
