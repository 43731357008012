import React from "react"
import {makeAutoObservable} from "mobx";

import {minTimePeriodForTimeStamp, TimePeriod} from "controls/react/form/timePeriodSelector"
import {Service, serviceSummaryToHealthFlags} from "framework/entities/service"
import {ApplicationState} from "framework/applicationState"
import {Permission} from "framework/permission"
import {
	dateToString,
	getCurrentTimeStringInTimeZone,
	isTimeZoneDifferentFromCurrent,
	parseDateFormat
} from "tools/dateTimeUtils"
import {apiFetch} from "framework/api"
import {getService, getServiceModel} from "areas/services/api"
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {GridStore} from "controls/grid/gridStore";
import {ServicesListEntry} from "areas/services/services-list/servicesListEntry";
import {UserSettingsStateProvider} from "controls/grid/stateProviders";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {getServicesListFilters, SummaryDocument, summaryDocuments} from "areas/services/services-list/api";
import {StateOverridePlugin} from "controls/grid/plugins/stateOverridePlugin";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {ArrayDataProvider} from "controls/grid/arrayDataProvider";
import {SeverityIndicator} from "controls/react/severityIndicator";
import {Link} from "controls/react/link";
import {ServicesRouter} from "areas/services/bundleDescription";
import {serviceStateRenderer} from "areas/services/services-list/servicesListStore";
import moment from "moment";
import Renderer from "tools/renderer";
import Settings from "settings";
import {Cookies} from "core";
import {State} from "tools";
import {PlainResponseRemoteDataProvider} from "controls/grid/plainResponseRemoteDataProvider";

export const i18n = require('core/localization').translator({
	'Service Time': {
		no: 'Tjeneste tid'
	},
	'Service is Shared': {
		no: 'Tjenesten er delts'
	}
});

export let ServiceDetailsContext = React.createContext<ServiceDetailsStore>(null)

export type ServiceDetailsProps = {
	id: string
	updateMode?: boolean
}

export class ServiceDetailsStore {
	timePeriod: TimePeriod = {
		period: TimePeriodType.Last24Hours
	}

	timePeriodZoomed: TimePeriod

	service: Service
	serviceModel: any

	loading: boolean = false
	loaded: boolean = false

	selectedElement: MxCell
	selectedQualifierId: number
	documentsGridStore: GridStore<SummaryDocument>;

	constructor(public props: ServiceDetailsProps) {
		makeAutoObservable(this)
	}

	get readOnly() {
		return !ApplicationState.hasPermissions(Permission.ServiceUpdate)
	}

	get existsActiveDocuments() {
		return this.documentsGridStore.dataProvider.visibleRowsCount
	}

	get serviceTime() {
		return getCurrentTimeStringInTimeZone(this.service.operatingSchedule.timeZone);
	}

	get timePeriodEffective() {
		return this.timePeriodZoomed ?? this.timePeriod
	}

	get isTimezoneDifferent(){
		return isTimeZoneDifferentFromCurrent(this.service.operatingSchedule.timeZone);
	}

	updateTimePeriod = (timePeriod: TimePeriod) => {
		this.timePeriod = timePeriod
		this.timePeriodZoomed = null
	}

	async init() {
		this.loading = true

		let serviceResult = await apiFetch(getService({
			id: this.props.id,
			updateMode: this.props.updateMode && !this.readOnly
		}))

		if (serviceResult.success) {
			this.service = serviceResult.data

			const modelResult = await apiFetch(getServiceModel({
				id: this.props.id,
			}));

			if(modelResult.success){
				this.serviceModel = modelResult.data
			}

			this.loaded = true
		}

		this.documentsGridStore = new GridStore<SummaryDocument>({
			columns: [{
				field: 'type',
				title: i18n('Type'),
				renderer: (item) => <div className={'file-type-icon'} dangerouslySetInnerHTML={{__html: Renderer.fileIcon(item.type)}}></div>,
				width: 60
			}, {
				field: 'name',
				title: i18n('Name'),
				renderer: item => <Link onClick={() => this.onDocumentNameClick(item)}>{item.name}</Link>,
				width: 200
			}, {
				field: 'created',
				title: i18n('Created'),
				renderer: item => moment(item.created).format(parseDateFormat(ApplicationState.dateTimeFormat).replace('.SSS', '').replace(':ss', '')),
				minWidth: 100
			}],
			dataProvider: new PlainResponseRemoteDataProvider({dataSource: summaryDocuments(this.props.id)}),
			heightByContent: true,
			selection: GridSelectionType.None,
			defaults: {
				sorting: [{field: 'created', direction: 'asc'}]
			}
		});

		this.loading = false
	}

	refreshSummaryDocumentsGrid = () => {
		this.documentsGridStore.dataProvider.silentReload();
	}

	onDocumentNameClick(item: SummaryDocument) {
		const downloadUrl = item.type === 'application/x-url'
			? item.content
			: Settings.serverPath + 'sessions/' + ApplicationState.sessionId + '/services/' + State.currentApp.id + '/documents/' + item.id + '/preview/';

		window.open(downloadUrl);
	}
}
