import React, { forwardRef, ReactNode } from "react";
import "./spaceHighlighter.less";
import b_ from "b_";

const b = b_.with("space-highlighter");

interface SpaceHighlighterProps {
	children: ReactNode;
}

const transformFn = (text: string): JSX.Element => {
	const leadingSpacesLength = text.match(/^\s+/)?.[0]?.length || 0;
  const trailingSpacesLength = text.match(/\s+$/)?.[0]?.length || 0;
  const trimmedText = text.trim(); // Remove leading and trailing spaces

	return (
		<>
			{!!leadingSpacesLength &&
				Array.from({ length: leadingSpacesLength }).map((_, i) => (
					<span key={i} className={b("space", {leading: true})}>{"\u00A0\u00A0"}</span>
				))}
			{trimmedText}
			{!!trailingSpacesLength &&
				Array.from({ length: trailingSpacesLength }).map((_, i) => (
					<span key={i} className={b("space", {trailing: true})}>{"\u00A0\u00A0"}</span>
				))}
		</>
	);
};
export const SpaceHighlighter = ({ children }: SpaceHighlighterProps) => {
	return (
		<span className={b()}>
			{React.Children.map(children, (child) => {
				if (typeof child === "string") {
					return transformFn(child);
				}

				return child;
			})}
		</span>
	);
};
