import React from "react"
import {RemoteEventsManager} from "core";
import {default as getDefaultConfig} from "./config";
import {Api, Utils} from "tools";
import Highcharts from "highcharts";
import moment from "moment";
import {
	setBasicChartConfig,
	setMinMax,
	setLegend,
	setChartEvent,
	setSeriesData,
	setValueSuffix,
	setMetricConfig, setResetButton, setValueDecimals
} from "./helpers";
import {Section} from "controls/react/layout/section";
import classnames from "classnames";

import "./stackChartComponent.less";
import {
	legacyPeriods, periodToMilliseconds,
	TimePeriodAppearance,
	TimePeriodSelector, timePeriodUrlParams
} from "../../../../controls/react/form/timePeriodSelector";
import {getMetricsWithCorrectConversionFields} from "../common/metricAdvancedUtils";
import {extractMetricSubscriptionFields} from "areas/service-boards/widgets/customMetrics/utils";

function StackChartTitle(props) {
	return <div className={classnames("stackchart-title", "react-widget-title")}>
		{!props.skipTitle && <div className={'title-text'}>{props.title}</div>}
		{!props.hideTimeSelector && <TimePeriodSelector appearance={TimePeriodAppearance.Buttons}
														periods={legacyPeriods}
														value={props.period}
														onChange={props.onChange}
														size="small" />}
	</div>;
}

export default class StackChartComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			metrics: [],
			loading: false
		};

		this.highCharts = {};
		this.config = JSON.parse(JSON.stringify(this.props.config.configuration));
		if (typeof this.config.period == 'string') {
			this.config.period = {period: this.config.period};
		}

		this.config.startDateISO = new Date(this.config.startDate);
		this.config.endDateISO = new Date(this.config.endDate);
		this.config.startDate = new Date(this.config.startDate).getTime();
		this.config.endDate = new Date(this.config.endDate).getTime();

		this.setCurrentTitle();

		Highcharts.setOptions({
			global: {
				useUTC: true,
				timezoneOffset: -moment.tz(this.config.timezone).utcOffset()
			}
		});

		this.props.events.bind('period:change', this.setPeriod.bind(this));
		this.props.events.bind('events', this.onEvents.bind(this));

		this.showResetButton = false;

		this.containerRef = React.createRef();
	}

	setCurrentTitle() {
		this.props.setTitle(<StackChartTitle title={this.props.config.title}
											 onChange={this.setPeriod.bind(this)}
											 period={this.config.period}
											 startDate={this.config.startDateISO}
											 endDate={this.config.endDateISO}
											 hideTimeSelector={this.config.hideTimeSelector}
		/>);
	}

	getRegisteredMetricUrl() {
		return this.props.requestPath() + 'metrics/registeredMetrics/data';
	}

	static prepareMetricData(metric) {
		if (!metric || !metric.data) {
			return null;
		}

		const data = metric.data;

		return data.map((item) => [item.t, item.vH || item.v]);
	}

	static prepareData(result, metricIds) {
		if (!Array.isArray(result)) {
			return {};
		}

		const out = {};

		for(const metricId of metricIds) {
			const metric = result.find((item) => item && item.qualifier && item.qualifier.metricId === metricId);
			out[metricId] = {
				data: StackChartComponent.prepareMetricData(metric),
				metric: metric.qualifier
			};
		}

		return out;
	}

	resetZoom(highchart) {
		this.config.startDate = null;
		this.config.endDate = null;
		this.showResetButton = false;

		this.getData();
	}

	setPeriod(value) {
		this.config.period = Object.assign({}, this.config.period, value);
		this.getData();
		this.setCurrentTitle();
	}

	hideReset(highchart) {
		const renderTo = highchart.renderTo;
		$('.highcharts-button', renderTo).hide();
	}

	showReset(highchart) {
		const renderTo = highchart.renderTo;
		$('.highcharts-button', renderTo).show();
	}

	onSelection(event) {
		if (!event.xAxis) {
			return;
		}

		const metricIds = Object.keys(this.highCharts);
		metricIds.forEach((metricId) => {
			const item = this.highCharts[metricId];
			const {highchart} = item;

			if (!highchart) {
				return;
			}

			const xAxis = highchart.xAxis[0];

			this.showResetButton = true;
			xAxis.setExtremes(event.xAxis[0].min, event.xAxis[0].max, true);
		});

		this.config.startDate = Math.floor(event.xAxis[0].min);
		this.config.endDate = Math.floor(event.xAxis[0].max);
		this.getData();
	}

	subscribe(data) {
		this.subscriberId = this.props.config.id;

		RemoteEventsManager.subscribe(this.subscriberId, [{
			eventType: 'MultipleMetric',
			metrics: extractMetricSubscriptionFields(data.map(x=>x.qualifier))
		}]);
	}

	onEvents(events) {
		events.forEach((event) => {
			const series = this.getSeries(event.qualifierId);

			if (series) {
				series.addPoint([event.metric.t, event.metric.vH || event.metric.v], true);
				const period = periodToMilliseconds(this.config.period);
				if (period) {
					const pointsToRemove = series.data.filter(x => x.x < event.metric.t - period);
					pointsToRemove.forEach(x => series.removePoint(x));
				}
			}
		});
	}

	getSeries(metricId) {
		try {
			return this.highCharts[metricId].highchart.series[0];
		} catch(e) {
			return null;
		}
	}

	getConfigMetric(metricId) {
		const additionalConfig = this.config.metricsItems.find(x => x.metricId == metricId);
		const config = this.state.metricsInformation.find(x => x.metricId == metricId);
		return Object.assign({}, config, additionalConfig);
	}

	releaseEvents(intervals) {
		if (this.subscriberId) {
			if (Object.keys(this.data).length) {
				intervals = this.recalculateIntervalsConsideringData();
			}
			RemoteEventsManager.releaseEvents(this.subscriberId, {
				intervals: intervals
			});
		}
	}

	recalculateIntervalsConsideringData() {
		const {period} = this.config;
		return Object.entries(this.data).reduce((acc, [metricId, chartData]) => {
			if (metricId && chartData.data.length) {
				const {interval} = Utils.getPeriodInterval({
					...period,
					width: $(this.containerRef.current).parent().width()
				}, chartData.data[0][0]);
				acc[metricId] = interval;
			}
			return acc;
		}, {});
	}

	unsubscribe() {
		if (this.subscriberId) {
			RemoteEventsManager.unsubscribe(this.subscriberId);
			this.subscriberId = null;
		}
	}

	syncCharts(event) {
		const metricIds = Object.keys(this.highCharts);
		metricIds.forEach((metricId) => {
			const item = this.highCharts[metricId];
			const {highchart, hoverPoint} = item;

			if (!highchart) {
				return;
			}

			const normalizedEvent = highchart.pointer.normalize(event);
			const point = highchart.series[0].searchPoint(normalizedEvent, true);

			if (hoverPoint) {
				hoverPoint.setState('');
			}

			if (point) {
				item.hoverPoint = point;
				point.setState('hover');
			}
		});
	}

	cleanHighlights(event) {
		const metricIds = Object.keys(this.highCharts);
		metricIds.forEach((metricId) => {
			const item = this.highCharts[metricId];
			const {highchart, hoverPoint} = item;

			if (!highchart) {
				return;
			}

			if (hoverPoint) {
				hoverPoint.setState('');
			}
		});
	}

	applySyncEvents(el) {
		el.addEventListener('mousemove', (e) => this.syncCharts(e));
		el.addEventListener('mouseout', (e) => this.cleanHighlights(e));
	}

	updateSeries(highchart, data) {
		const series = highchart.series[0];
		series.setData(data, true);

		if (this.config.startDate && this.config.endDate) {
			highchart.xAxis[0].setExtremes(this.config.startDate, this.config.endDate, true);
		}
	}

	renderHighcharts() {
		const metricIds = Object.keys(this.highCharts);
		const count = metricIds.length;
		const height = $(this.containerRef.current).parent().height();
		const chartHeight = Math.floor(height / count) - Math.max(1, 4-count)*5;

		const {labelTemplate, period, timezone, startDate, endDate, chartType} = this.config;

		metricIds.forEach((metricId) => {
			const item = this.highCharts[metricId];
			const data = this.data[metricId] ? this.data[metricId].data: [];
			const metric = this.getConfigMetric(metricId);

			if (item.highchart) {
				return this.updateSeries(item.highchart, data);
			}

			const config = getDefaultConfig();
			const resetZoom = this.resetZoom.bind(this);

			setBasicChartConfig(config, item.el, chartType, chartHeight);

			if(this.config.showThreshold && this.data[metricId]?.metric?.configuration?.algorithm?.threshold){
				config.yAxis.plotLines = [{
					id: 'threshold',
					color: '#FF0000',
					dashStyle: 'ShortDash',
					width: 2,
					value: this.data[metricId].metric.configuration.algorithm.threshold,
					zIndex: 0
				}];
			}
			setSeriesData(config, data);

			if(this.config.showRegression){
				config.series[0].regression = this.config.showRegression;

				config.series[0].regressionSettings = {
					marker: {
						enabled: false
					},
					name: metric.assetName,
					fullName: metric.assetName,
					id: Utils.guid(),
					type: 'linear',
					color: 'rgba(255,165,0, 1)'
				};
			}
			setMetricConfig(config, metric);
			setLegend(config, labelTemplate, metric, period, timezone);
			setValueSuffix(config, metric);
			setValueDecimals(config, metric);
			setResetButton(config, resetZoom);

			const self = this;

			config.chart.events.load = function () {
				const chart = this;

				if (self.showResetButton) {
					self.showReset(chart);
				} else {
					self.hideReset(chart);
				}
			};

			if (startDate && endDate) {
				setMinMax(config, startDate, endDate);
			}

			setChartEvent(config.chart, 'selection', this.onSelection.bind(this));

			this.applySyncEvents(item.el);

			item.highchart = new Highcharts.Chart(config);
		});
	}

	async getData() {
		const {ignoreMissingData, timezone, period} = this.config;

		const width = $(this.containerRef.current).parent().width();

		const {interval} = Utils.getPeriodInterval({
			...period,
			width: width,
		});

		const queryParams = {
			ignoreMissingData,
			timeZone: timezone,
			interval: interval,
			...timePeriodUrlParams(period)
		};

		const metrics = this.config.metricsItems ?? []

		const url = Api.compileUrlWithParams(this.getRegisteredMetricUrl(), queryParams);

		this.setState({loading: true});

		const requestData = getMetricsWithCorrectConversionFields(metrics)
		const {result} = await Utils.ajaxPromise(url, 'POST', JSON.stringify(requestData));
		const metricIds = metrics.map((item) => item.metricId);

		this.data = StackChartComponent.prepareData(result, metricIds);
		const intervals = metricIds.reduce((acc, item) => {
			acc[item] = interval;
			return acc;
		}, {})

		this.setState({
			metrics: metricIds,
			metricsInformation: result.map(x => x.qualifier),
			loading: false
		}, () => {
			this.unsubscribe();
			this.renderHighcharts();
			this.subscribe(result);
			this.releaseEvents(intervals)
		});
	}

	componentDidMount() {
		this.getData();
	}

	componentWillUnmount() {
		this.props.unsetTitle();
		this.unsubscribe();
	}

	render() {
		return <div className={"stack-chart__container"}>
			<Section containerOverlay={this.state.loading}
					 overlaySpinner={true}
					 containerClass={"stack-chart__container"}
					 containerRef={this.containerRef}
		>
			{this.state.metrics.map((metricId) => {
				return <div key={metricId} ref={(el) => this.highCharts[metricId] = {el}}>Hello</div>
			})}
		</Section></div>;
	}
}
