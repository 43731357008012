import React from 'react';

import {Section} from 'controls/react/layout/section'
import {FormEntry} from "controls/react/form/formEntry";
import {AccountDropDown} from "controls/react/dropdowns/accountDropDown";
import {Cookies} from "core";
import {ApplicationWidget} from "./applicationWidget";
import {AntInput} from "controls/react/ant/antInput";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {ApplicationsDropDown} from "areas/application/applicationsDropDown";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {IconButton} from "controls/react/form/iconButton";
import {sharedLocalization} from "controls/designer/features/widgets/localization";

const i = require('core/localization').translator({
  "Title is required": {
    "no": "Tittel kreves"
  },
	'Scale tooltip': {
  	    en: 'Scale will fit selected content to widget size.',
		no: 'Skalering vil tilpasse valgt innhold til størrelsen av widgeten'
	},
	'Display events in popup':{
  	    no: 'Vis hendelser i popup'
	}
}, sharedLocalization);


export function getWidgetDescription(){
	return {
		form: PreviewWidgetForm,
		defaultConfig: {
			type: 'application-preview',
			title: i('Preview'),
			contentPadding: 10,
			accountId: Cookies.CeesoftCurrentAccountId,
			isFromDashboard: true,
			zoom: false
		},
		constructWidget: config => new ApplicationWidget(config),
		legacyWidget: true,
		fullTitle: i('Application') + '/' + i('Preview'),
	}
}

const PreviewWidgetForm = props => {
	const {configLink} = props;

	const applicationLink = configLink.get('applicationId').required();
	const accountLink = configLink.get('accountId').required();

	return (<Section childrenPadding={true} contentPadding={false} appearance={"none"}>
		<FormEntry label={i('Title')} width={"fit"}>
			<AntInput {...configLink.get('title').props}/>
		</FormEntry>
		<FormEntry label={i('Account')} width={"fit"} valueLink={accountLink}>
			<AccountDropDown {...configLink.get('accountId').props}/>
		</FormEntry>
		<FormEntry label={i('Application')} valueLink={applicationLink} width={"fit"}>
			<ApplicationsDropDown accountId={configLink.value.accountId}/>
		</FormEntry>
		<FormEntry label={i('Padding')} width={"fit"}>
			<AntInputNumber {...configLink.get('contentPadding').props}/>
		</FormEntry>
		<FormEntry width={"fit"}>
			<AntCheckbox {...configLink.get('displayEventsInPopup').props}>{i('Display events in popup')}</AntCheckbox>
		</FormEntry>
		<FormEntry width={"fit"}>
			<AntCheckbox {...configLink.get('zoom').props}>{i('Scale')}<IconButton iconName={'question-sign'} title={i('Scale tooltip')}/></AntCheckbox>
		</FormEntry>
	</Section>);
}
