import React from 'react';
import ReactDOM from "react-dom";
import {Utils, applyRequired} from 'tools/utils';
import Settings from 'settings';

import ErrorHandler from 'core/errorHandler';
import ConfigurationBase from 'areas/service-boards/configurationBase';
import Cookies from 'core/cookies';
import FormEntry from "controls/react/form/formEntry";
import {TagsSelect} from "controls/react/tagsSelect";
import {sharedLocalization} from "../../../../controls/designer/features/widgets/localization";
import {SeverityStateConfigurator} from "../../../../controls/react/barsStatesWidget";
import {createRoot} from "react-dom/client";

const i = require('core/localization').translator({
  "application-state": {
    "en": "Open Events in Popup",
    "no": "Åpne hendelser i popup"
  },
  "service-state": {
    "en": "Open Services in Popup",
    "no": "Åpne Tjenester i popup"
  }
}, sharedLocalization);


export function StateWidgetConfiguration(config) {
	Utils.apply(this, config);
	this.initComponent();
};

export {StateWidgetConfiguration as default}

jQuery.extend(StateWidgetConfiguration.prototype, ConfigurationBase.prototype, {
	destroyers: [],
	initComponent: function () {
		var html;
		var widgetObj = this.widgetObj;
		html = '<div class="cw_field"><label class="cw_inline">' + lang.TITLE + ': </label><input type="text" class="cw_widget_title cw_inline" value="' + (widgetObj.title || '') + '" /></div>';
		html += '<div class="cw_field" id="cw_ss_account"><label class="cw_inline">' + lang.ACCOUNT + ': </label>';
		html += '<div class="cw_dropdown_container"><input id="cw_bc_service_state" type="text" value="' + (widgetObj.configuration.accountId || '') + '" /></div></div>';
		html += '<div class="cw_field flex-align-center"><input id="cw_barss_include_subaccounts_check" checked type="checkbox" class="k-checkbox cw_show_column" /><label for="cw_barss_include_subaccounts_check" class="k-checkbox-label">' + lang.INCLUDE_SUBACCOUNTS + '</label></div>';
		html += '<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + lang.TAGS + '</span>';
		html += '<div class="w100 left">';
		html += '<div class="cw_checkoxes_with_labels"><input type="checkbox" id="cw_untagged" class="k-checkbox" /><label for="cw_untagged" class="k-checkbox-label">' + lang.SHOW_UNTAGGED + '</label></div></div>';
		html += '<div class="left w100" id="cw_tags"></div>';
		html += '</div>';

		if (widgetObj.type === 'service-state') {
			html += `<div class="cw_field"></div>
					<div class="cw_field flex-align-center">
						<input id="cw_barss_include_open_in_popup" type="checkbox" class="k-checkbox cw_show_column" />
						<label for="cw_barss_include_open_in_popup" class="k-checkbox-label">${i(widgetObj.type)}</label>
					</div>`;
		}

		html += '<div class="cw_separator">';
		html += '<span class="cw_separator_title">' + i('Display settings') + '</span>';
		html += '<div class="left w100" id="cw_severities"></div>';
		html += '</div>';

		this.widgetConfigurationFormEl.empty().append(html);

		if (widgetObj.configuration.includeSubaccounts === undefined || widgetObj.configuration.includeSubaccounts === true) {
			$('#cw_barss_include_subaccounts_check').prop('checked', true);
		} else {
			$('#cw_barss_include_subaccounts_check').prop('checked', false);
		}

		if (widgetObj.configuration.openEventsInPopup === true) {
			$('#cw_barss_include_open_in_popup').prop('checked', true);
		}

		if (widgetObj.configuration.ignoreUserTags) {
			$('#cw_ignore_user_tags').prop('checked', true);
		}
		if (widgetObj.configuration.showUntagged) {
			$('#cw_untagged').prop('checked', true);
		}

		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
		const requiredFields = ['#cw_bc_service_state'];
		applyRequired(requiredFields);
	},
	/**
	 * Removes listeners
	 */
	removeListeners: function () {
		$('#cw_barss_include_subaccounts_check').off();
	},
	/**
	 * Attaches listeners
	 */
	attachListeners: function () {
		$('#cw_barss_include_subaccounts_check').on('click', $.proxy(this.onIncludeSubaccountsToggle, this));
	},
	/**
	 * Iniitalizes kendo components
	 */
	initKendoComponents: function () {
		this.tagsList = this.widgetObj.configuration.tags;
		$('#cw_bc_service_state').kendoComboBox({
			dataSource: new kendo.ceeview.DataSource({
				transport: {
					read: {
						url: Settings.serverPath + 'accounts/' + this.serviceBoardAccountId + '/subaccounts/allLevels/lite',
						contentType: "application/json; charset=utf-8",
						type: "GET",
						dataType: "json",
						cache: false
					}
				},
				error: ErrorHandler.kendoServerError,
				sort: {field: 'name', dir: 'asc'}
			}),
			dataTextField: 'name',
			dataValueField: 'id',
			clearButton: false,
			value: this.mode === 'create' ? Cookies.CeesoftCurrentAccountId : this.widgetObj.accountId,
			template: '<span id="${ data.id }">${ data.name }</span>',
			autoBind: true,
			placeholder: lang.SELECT,
			select: $.proxy(this.onAccountListSelect, this),
			change: $.proxy(function () {
				this.tagsList = [];
				this.setTagsDataSource();
			}, this),
			dataBound: $.proxy(function () {
				this.setTagsDataSource();
			}, this)
		}).data('kendoComboBox');

		this.destroyers.push(this.initSeveritiesSelector());
	},
	/**
	 * Handler function for setting the data source to tags multiselect depending of what account is selected
	 */
	setTagsDataSource: function () {
		this.destroyTagDataSource?.();
		const selectedAccount = $('#cw_bc_service_state').data('kendoComboBox').value();
		const includeSubaccounts = $('#cw_barss_include_subaccounts_check').is(':checked');

		const container = $('#cw_tags').get()[0];
		const root = createRoot(container)
		root.render(<FormEntry label={lang.TAGS} vertical={true}>
			<TagsSelect
				mode={'tags'}
				accountId={selectedAccount}
				includeSubaccounts={includeSubaccounts}
				onChange={value => this.onTagsChange(value)}
				defaultValue={this.tagsList} />
		</FormEntry>);
		this.destroyTagDataSource = () => root.unmount();
	},
	onTagsChange(value) {
		this.tagsList = value || [];
	},

	initSeveritiesSelector() {
		const {severities, displayOptions} = this.widgetObj.configuration;
		this.severitiesConfig = {severities, displayOptions};

		const dataSource = this.dataSource ??
			[['DOWN', i('DOWN')], ['WARNING', i('WARNING')], ['UP', i('UP')], ['INACTIVE', i('INACTIVE')]];
		const onSeverityChanged = (data) => {
			this.severitiesConfig = data;
		};
		const container = $('#cw_severities').get()[0];
		const root = createRoot(container)

		let severitiesSorted = dataSource.filter(x => severities.indexOf(x[0]) != -1)
			.map(x => x[0])

		root.render(<SeverityStateConfigurator displayOptions={displayOptions}
		                                       defaultValue={severitiesSorted}
		                                       dataSource={dataSource}
		                                       onChange={onSeverityChanged}/>);
		return () => root.unmount();
	},

	/**
	 * Handler function for toggling on/off the include subaccounts checkbox
	 */
	onIncludeSubaccountsToggle: function () {
		this.tagsList = [];
		this.setTagsDataSource();
	},
	/*
	 * Handler function for getting the widget configuration values
	 * */
	getValues: function () {
		var title = $('.cw_widget_title').val().trim();
		var widgetObj = {
			id: this.mode === 'update' || this.wasUpdate ? this.widgetObj.id : Utils.guid(),
			type: this.widgetType,
			title: title,
			accountId: $('#cw_bc_service_state').data('kendoComboBox').value(),
			configuration: {
				includeSubaccounts: $('#cw_barss_include_subaccounts_check').is(':checked'),
				openEventsInPopup: $('#cw_barss_include_open_in_popup').is(':checked'),
				tags: this.tagsList,
				ignoreUserTags: $('#cw_ignore_user_tags').is(':checked'),
				showUntagged: $('#cw_untagged').is(':checked'),
				...this.severitiesConfig
			}
		};

		return widgetObj;
	},
	/*
	 * Checks if form is valid
	 * */
	isValid: function () {
		var valid = true;
		var validationMessage = '';

		var account = $('#cw_bc_service_state').data('kendoComboBox');
		if (!Utils.isGuid(account.value())) {
			valid = false;
			validationMessage = lang.SELECT;
		}
		if(!this.severitiesConfig?.severities?.length){
			valid = false;
			validationMessage = i("Select Severity");
		}

		return {
			valid: valid,
			validationMessage: validationMessage
		};
	},
	/*
	 * Handler function for selecting an account
	 * @param {Object} e The select event
	 * */
	onAccountListSelect: function (e) {
		//For comboboxes we need to check if e.item is valid
		//if we write/search invalid accounts e.item is null
		if (e.item) {
			var item = e.item.find('span'), id = item.attr('id');
			if (id) {
				this.widgetAccountId = id;
				this.widgetAccountName = item.text();
			}
		}
	},

	destroy() {
		this.destroyers.forEach(x => x?.());
		this.destroyTagDataSource?.();
	}
});
