import './metricValueAsSeverity.less'

import {observer} from "mobx-react"
import React from "react";

import {Toolbar} from "controls/react/layout/toolbar"
import {Section} from "controls/react/layout/section"
import {Button, IconButton} from "controls/react/form"
import FormEntry from "controls/react/form/formEntry"
import {AntCheckbox} from "controls/react/ant/antCheckbox"

import {linkModel} from "framework/mobx-integration";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {makeAutoObservable} from "mobx";
import {useMemoPermanent} from "framework/react-integration";
import {Severity} from "framework/entities/healthData";
import {SeverityIndicator} from "controls/react/severityIndicator";
import {debounce} from "lodash";
import {AntFlex} from "controls/react/ant/antFlex";
import {compareByOperator} from "tools/helpers/math";
import {FormEntryNew, useGenericModel} from "controls/react/form/formEntryNew";

const b = require('b_').with('metric-value-as-severity')

const i18n = require('core/localization/localization').translator({
  "Hide Metric Value": {
    "no": "Skjul metrisk verdi",
    "en": "Hide metric value"
  },
  "Value as Severity": {
    "no": "Verdi som alvorlighet",
    "en": "value as severity"
  },
  "metric-value-as-severity-tooltip": {
    "en": "If selected you can use metric value to custom set severity of shape. Severity will match first value that matches.",
    "no": "Hvis dette er valgt, kan du bruke metrikkverdien til å sette formens alvorlighetsgrad. Alvorlighetsgraden vil matche første verdi som samsvarer."
  },
  "To set severity for the values above set the below numbers in the inputs. \n\nCurrent configuration: \n": {
    "no": "For å angi alvorlighetsgrad for verdiene ovenfor, sett inn verdier under. \n\nGjeldende konfigurasjon: \n"
  },
  "Everything above {0} will give a CRITICAL in the shape": {
    "no": "Alt over {0} vil gi en KRITISK i formen",
    "en": "Everything above {0} will give a CRITICAL in shape"
  },
  "Everything above {0} will give a OK in the shape": {
    "no": "Alt over {0} vil gi en OK i formen",
    "en": "Everything above {0} will give an OK in shape"
  },
  "Everything will give a CRITICAL in the shape": {
    "no": "Alt vil gi en KRITISK i formen",
    "en": "Everything will give a CRITICAL in shape"
  },
	'Value less or equal to': {
	  no: 'Verdi mindre eller lik'
	},
	'Value greater or equal to': {
	  no: 'Verdi større eller lik'
	},
	'Else': {},
	'Then': {}
});

export type MetricValueToSeverityMap = {
	operator: 'gte'|'lte',
	threshold: number
}[]

export type MetricValueToSeverityHolder = {
	metricValueAsSeverity: boolean
	hideMetricValue: boolean
	metricValueToSeverity: MetricValueToSeverityMap
}

export type MetricValueAsSeverityProps = {
	dataSource: MetricValueToSeverityHolder
}

class MetricValueAsSeverityStore {
	constructor(public dataSource: MetricValueToSeverityHolder) {
		makeAutoObservable(this)
	}

	getValue(severity: Severity) {
		return this.dataSource.metricValueToSeverity[this.getSeverityIndex(severity)].threshold
	}

	setValue(severity: Severity, value: number) {
		this.dataSource.metricValueToSeverity[this.getSeverityIndex(severity)].threshold = value
	}

	getSeverityIndex(severity: Severity) {
		if (severity == Severity.Critical)
			return 2
		if (severity == Severity.Major)
			return 1

		return 0
	}

	get operator(): string {
		return this.dataSource.metricValueToSeverity[0].operator;
	}

	set operator(value: 'lte' | 'gte') {
		this.dataSource.metricValueToSeverity.forEach(x => {
			x.operator = value
		});
	}

	flipOperator = () => {
		const operators = {'lte': 'gte', 'gte': 'lte'} as const

		this.dataSource.metricValueToSeverity.forEach(x => {
			x.operator = (operators[x.operator] ?? 'lte')
		});
	}

	destroy() {

	}
}

export const MetricValueAsSeverity = observer((props: MetricValueAsSeverityProps) => {
	const store: MetricValueAsSeverityStore = useMemoPermanent(() => new MetricValueAsSeverityStore(props.dataSource), [props.dataSource])
	const dataSource = store?.dataSource;

	if(!store)
		return null

	return <Section title={i18n('Value as Severity')}
	                margin={"top"}
	                appearance={"frame-top-only"}
	                childrenPadding={true}
	                className={b()}>
		<FormEntry>
			<AntCheckbox {...linkModel(dataSource, "metricValueAsSeverity")}>{i18n('Enabled')}</AntCheckbox>
			{dataSource.metricValueAsSeverity &&
				<AntCheckbox {...linkModel(dataSource, 'hideMetricValue')}>{i18n('Hide Metric Value')}</AntCheckbox>
			}
		</FormEntry>

		{dataSource.metricValueAsSeverity && <>
			<FormEntry>
				<Button onClick={store.flipOperator} title={"Flip operator"}/>
			</FormEntry>
			<div className={b('values')}>
				<MetricValueToSeverityRow store={store} severity={Severity.Critical}/>
				<MetricValueToSeverityRow store={store} severity={Severity.Major}/>
				<MetricValueToSeverityRow store={store} severity={Severity.Minor}/>

				<div></div><div></div>
				<div>{i18n('Else')}</div>
				<div><SeverityIndicator severity={Severity.None}/></div>
			</div>
		</>}
	</Section>
})

export type MetricValueToSeverityRowProps = {
	store: MetricValueAsSeverityStore
	severity: Severity
}

export const MetricValueToSeverityRow = observer((props: MetricValueToSeverityRowProps) => {
	let model = useGenericModel(() => props.store.getValue(props.severity),
		(value: number) => props.store.setValue(props.severity, value), [props.store])

	return <>
		<div>{i18n(getOperatorLabel(props.store.operator))}</div>
		<FormEntryNew noUi={true} model={model} modelField={"value"} changeOnBlur={true}>
			<AntInputNumber size={'small'} />
		</FormEntryNew>
		<div>{i18n('Then')}</div>
		<div><SeverityIndicator severity={props.severity}/></div>
	</>
})

let indexToSeverity = [Severity.Minor, Severity.Major, Severity.Critical]

export function getSeverityFromValueToSeverityMap(map: MetricValueToSeverityMap, value: number) {
	let index = map
		.findLastIndex(x => compareByOperator(x.operator, value, x.threshold))

	if (index == -1)
		return Severity.None

	return indexToSeverity[index];
}

function getOperatorLabel(operator: string) {
	if (operator == 'lte') {
		return 'Value less or equal to'
	} else {
		return 'Value greater or equal to'
	}
}
