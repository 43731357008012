import './sortingEditor.less'

import React from "react"
import {observer} from "mobx-react"
import {DeleteOutlined} from "@ant-design/icons"

import {GridDataEntry} from "controls/grid/gridDataEntry"
import {SortingIndicator} from "controls/grid/sortingIndicator"
import {AntButton} from "controls/react/ant/antButton"
import {GridColumn} from "controls/grid/gridColumnConfig"
import {Section} from "controls/react/layout/section"
import {ViewManagerProps} from "./viewManagerStore"
import { addDragAndDrop } from 'tools/helpers/dom'
import {AntSelect} from "controls/react/ant/antSelect";
import {linkModel} from "framework/mobx-integration";
import {GridStore} from "controls/grid/gridStore";
import {makeAutoObservable} from "mobx";
import {useStore} from "core/react/useStore";
import {AntFlex} from "controls/react/ant/antFlex";

const i18n = require('core/localization/localization').translator({
	'Remove sorting on the column': {
		no: 'Fjern sortering fra kolonnen'
	},
	'Click to toggle sorting direction or drag to reorder.': {
		no: 'Klikk for å bytte sorteringsretning eller dra for å endre rekkefølge.'
	},
	'Select column...': {
		no: 'Velg kolonne...'
	}
});

const b = require('b_').with('grid-view-sorting-editor')

export class SortingEditorStore<DataItem extends GridDataEntry>{
	columnToAdd: string

	get columnsWithSorting(){
		return this.store.state.sortingOrder
			.map(x => this.store.columns.all.find(c => c.field == x))
			.filter(x => x)
	}

	get columnsWithoutSorting(){
		return this.store.columns.all
			.filter(x => x.field != 'id' &&  this.columnsWithSorting.find(y => y.field == x.field) == null)
			.map(x => ({value: x.field, label: x.config.title}))
			.toSorted((lhv, rhv) => lhv?.label.localeCompare(rhv.label))
	}

	constructor(public store: GridStore<DataItem>) {
		makeAutoObservable(this)
	}

	addColumn = () => {
		if (!this.columnToAdd)
			return

		let column = this.store.columns.all.find(x => x.field == this.columnToAdd)

		if (!column)
			return

		column.triggerSorting()

		this.columnToAdd = null
	}

	init(){}
	destroy(){}
}


export const SortingEditor = observer(<DataItem extends GridDataEntry>(props: ViewManagerProps<DataItem>) => {
	const store = useStore(() => new SortingEditorStore(props.store.gridStore))

	return <Section contentPadding={false} childrenPadding={true}>
		<AntFlex justify={"end"} gap={"middle"}>
			<AntSelect placeholder={i18n('Select column...')}
			           {...linkModel(store, "columnToAdd")}
			           width={200}
			           options={store.columnsWithoutSorting}/>
			<AntButton type={"primary"} disabled={!store.columnToAdd} onClick={store.addColumn}>{i18n('Add')}</AntButton>
		</AntFlex>
		<div className={b()}>
			{store.columnsWithSorting.map(x => <SortingEditorEntry key={x.field} column={x} store={props.store}/>)}
		</div>
	</Section>
})

type SortingEditorEntryProps<DataItem extends GridDataEntry> = ViewManagerProps<DataItem> & {
	column: GridColumn<DataItem>
}

const SortingEditorEntry = observer(
	class SortingEditorEntryInner<DataItem extends GridDataEntry> extends React.Component<SortingEditorEntryProps<DataItem>> {
		ref: HTMLDivElement

		render() {
			return <div className={b('entry')}
			            ref={this.onRef}
			            title={i18n('Click to toggle sorting direction or drag to reorder.')}
			            draggable={true}
			            onClick={this.props.column.flipSorting}>
				<div className={b('name')}>{this.props.column.config.title}</div>
				<div className={b('sorting-indicator')}><SortingIndicator column={this.props.column}/></div>
				<div className={b('delete')}>
					<AntButton icon={<DeleteOutlined/>}
					           title={i18n('Remove sorting on the column')}
					           onClick={() => {
						           this.props.column.removeSorting()
					           }}/>
				</div>
			</div>
		}

		onRef = (element: HTMLDivElement) => {
			if (element == null) {
				this.ref = null
				return
			}

			this.ref = element

			addDragAndDrop(this.ref, {
				data: this.props.column.field,
				overClassName: b('entry') + '_over',
				onDrop: (draggedColumnName) => {
					if (draggedColumnName == null)
						return

					this.props.store.gridStore.columns.moveSortingBefore(this.props.column.field, draggedColumnName)
				}
			})
		}
	}
)
