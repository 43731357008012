import 'controls/grid/refreshTimer.less'

import React from 'react'
import {observer} from "mobx-react"
import {ClockCircleOutlined, CloudDownloadOutlined, PauseOutlined, ReloadOutlined} from '@ant-design/icons'
import classnames from "classnames"

import {AntButton} from 'controls/react/ant/antButton'
import {GridStore} from "controls/grid/gridStore"
import {linkModel} from 'framework/mobx-integration'
import {AntInputNumber} from "controls/react/ant/antInputNumber"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {AntPopover} from "controls/react/ant/antPopover"
import {Section} from "controls/react/layout/section"
import {AutoReloadPlugin, UpdateMode} from "controls/grid/plugins/autoReloadPlugin";


const i18n = require('core/localization/localization').translator({
	'Click to edit interval': {no: 'Klikk for å redigere intervall'},
	'Reload Data': {no: 'Last Data'},
	'Do not refresh grid': {no: 'Ikke oppdater Tabell'},
	'Refresh grid on interval': {no: 'Tabell oppdateres på intervall'},
	'Refresh grid on events': {no: 'Tabell oppdateres på hendelser'},
	'Timer tooltip': {
		en: 'Update interval. Minimum value is 10 seconds.',
		no: 'Oppdateringsintervall. Minimum verdi er 10 sekunder.'
	}
});

const b = require('b_').with('refresh-timer')

type RefreshTimerProps<T extends GridDataEntry> = {
	store: GridStore<T>
}

type RefreshTimerState = {
	modeSelectorVisible: boolean
}

export const RefreshTimer = observer(
	class RefreshTimerInner<T extends GridDataEntry> extends React.Component<RefreshTimerProps<T>, RefreshTimerState >{
		constructor(props: RefreshTimerProps<T>) {
			super(props);

			this.state = {
				modeSelectorVisible: false
			}
		}

		modeSelectorButtons = [{
			icon: PauseOutlined,
			tooltip: i18n('Do not refresh grid'),
			mode: UpdateMode.None
		},{
			icon: CloudDownloadOutlined,
			tooltip: i18n('Refresh grid on events'),
			mode: UpdateMode.OnEvent
		},{
			icon: ClockCircleOutlined,
			tooltip: i18n('Refresh grid on interval'),
			mode: UpdateMode.OnTimer
		}]

		render(){
			if(this.plugin == null)
				return <div>AutoReload plugin is not added</div>

			const buttonsList = <Section direction={"row"} contentPadding={false}>
				{this.modeSelectorButtons.map(b =>{
					const Icon = b.icon
					const onClick = () => {
						this.plugin.timerSettings.mode = b.mode
						this.setState({modeSelectorVisible: false})
					}

					return <AntButton icon={<Icon/>}
					                  title={b.tooltip}
					                  onClick={onClick}
					                  key={b.mode}
					                  type={this.plugin.timerSettings.mode == b.mode ? 'primary' : 'default'}/>
				})}
			</Section>

			const CurrentModeIcon = this.getCurrentMode().icon

			return <div className={b()} title={this.plugin.disabled ? this.plugin.disabledReason : null}>
				<AntPopover trigger={"click"}
				            content={buttonsList}
				            open={this.state.modeSelectorVisible}
				            onOpenChange={(visible) => this.setState({modeSelectorVisible: visible})}
				            placement={"top"}>
					<AntButton className={b('current-mode-icon', {mode: this.plugin.timerSettings.mode})}
					           icon={<CurrentModeIcon/>}
					           disabled={this.plugin.disabled}
					           title={this.plugin.disabled ? this.plugin.disabledReason : this.getCurrentMode().tooltip}
					           size={"small"}/>
				</AntPopover>


				{this.plugin.timerSettings.mode == UpdateMode.OnTimer &&
					<AntInputNumber className={classnames(b('time-input'),'_bypass-dirty-check')}
					                onPressEnter={this.plugin.saveInterval}
					                onBlur={this.plugin.saveInterval}
					                onFocus={this.plugin.editTimer}
					                disabled={this.plugin.disabled}
					                size={"small"}
					                title={this.plugin.disabled ? this.plugin.disabledReason : i18n('Timer tooltip')}
					                onPressEsc={this.plugin.cancelEditing}
					                controls={false}
					                value={this.plugin.editing ? this.plugin.tempInterval : this.plugin.timeLeftDisplay}
					                onChange={linkModel(this.plugin, 'tempInterval').onChange}
					/>
				}

				<AntButton className={b('refresh-button')}
				           onClick={this.plugin.forceReload}
				           size={"small"}
				           disabled={this.plugin.disabled}
				           title={this.plugin.disabled ? this.plugin.disabledReason : i18n('Reload Data')}
				           icon={<ReloadOutlined />}/>
			</div>
		}

		get plugin(){
			return this.props.store.plugins.find(x => x.id == 'auto-reload') as AutoReloadPlugin<any>
		}

		getCurrentMode(){
			return this.modeSelectorButtons.find( x => x.mode == this.plugin.timerSettings.mode)
		}
	}
)
