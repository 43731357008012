import React from "react";
import {observer} from "mobx-react";

import {Toolbar} from "controls/react/layout/toolbar";
import {Section} from "controls/react/layout/section";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {linkModel} from "framework/mobx-integration";
import {Link} from "core/react/links";
import {HelpIcon} from "controls/react/form/formEntryNew";

const i18n = require('core/localization').translator({
  "Grid toolbar settings": {
    "no": "Tabellvisning"
  },
  "Event timer": {
    "no": "Hendelse timer"
  },
  "Menu": {
    "no": "Meny"
  },
  "View management": {
    "no": "Visningsadministrasjon"
  },
  "Help text": {
    "en": "When you're in Edit Mode, changes you make in Grid toolbar setting won't be immediately shown. This allows you to adjust the grid's filters and time settings as needed before displaying the updated content. To see your changes, simply exit Edit Mode once your configurations are complete.",
    "no": "Når du er i Redigeringsmodus, vil ikke endringene du gjør i tabellvisnings innstillinger vises umiddelbart. Dette gir deg mulighet til å justere gridens filtre og tidsinnstillinger etter behov før det oppdaterte innholdet vises. For å se endringene, avslutt Redigeringsmodus når konfigurasjonene er ferdige."
  }
});

export type GridToolbarVisibilityFlags = {
	showMenu?: boolean
	showRefreshTimer?: boolean
	showTimeSettings?: boolean
	showFilters?: boolean
	showViewSelector?: boolean
}

export type GridToolbarVisibilityValueLinkProps = {
	flags: Link<GridToolbarVisibilityFlags>
	enabledFlags?: (keyof GridToolbarVisibilityFlags)[]
}

export const GridToolbarVisibilityValueLink = observer ((props: GridToolbarVisibilityValueLinkProps) => {
	let isFlagEnabled = React.useCallback((flag: keyof GridToolbarVisibilityFlags) => {
		return !props.enabledFlags || props.enabledFlags.indexOf(flag) != -1
	}, [props.enabledFlags])

	return <Section appearance={"frame"} childrenPadding={true} direction={"row"}>
		<Toolbar title={i18n('Grid toolbar settings')}><HelpIcon tooltip={i18n('Help text')}/></Toolbar>
		{isFlagEnabled("showMenu") && <AntCheckbox {...props.flags.get('showMenu').props}>{i18n('Menu')}</AntCheckbox>}
		{isFlagEnabled("showRefreshTimer") && <AntCheckbox {...props.flags.get('showRefreshTimer').props}>{i18n('Event timer')}</AntCheckbox>}
		{isFlagEnabled("showTimeSettings") && <AntCheckbox {...props.flags.get('showTimeSettings').props}>{i18n('Time selector')}</AntCheckbox>}
		{isFlagEnabled("showFilters") && <AntCheckbox {...props.flags.get('showFilters').props}>{i18n('Filters')}</AntCheckbox>}
		{isFlagEnabled("showViewSelector") && <AntCheckbox {...props.flags.get('showViewSelector').props}>{i18n('View management')}</AntCheckbox>}
	</Section>
})


export type GridToolbarVisibilityProps = {
	flags: GridToolbarVisibilityFlags
	enabledFlags?: (keyof GridToolbarVisibilityFlags)[]
}

export const GridToolbarVisibility = observer ((props: GridToolbarVisibilityProps) =>{
	let isFlagEnabled = React.useCallback((flag: keyof GridToolbarVisibilityFlags) => {
		return !props.enabledFlags || props.enabledFlags.indexOf(flag) != -1
	}, [props.enabledFlags])

	return <Section appearance={"frame"} childrenPadding={true} direction={"row"}>
		<Toolbar title={i18n('Grid toolbar settings')}><HelpIcon tooltip={i18n('Help text')}/></Toolbar>
		{isFlagEnabled("showMenu") && <AntCheckbox {...linkModel(props.flags, "showMenu")}>{i18n('Menu')}</AntCheckbox>}
		{isFlagEnabled("showRefreshTimer") && <AntCheckbox {...linkModel(props.flags, "showRefreshTimer")}>{i18n('Event timer')}</AntCheckbox>}
		{isFlagEnabled("showTimeSettings") && <AntCheckbox {...linkModel(props.flags, "showTimeSettings")}>{i18n('Time selector')}</AntCheckbox>}
		{isFlagEnabled("showFilters") && <AntCheckbox {...linkModel(props.flags, "showFilters")}>{i18n('Filters')}</AntCheckbox>}
		{isFlagEnabled("showViewSelector") && <AntCheckbox {...linkModel(props.flags, "showViewSelector")}>{i18n('View management')}</AntCheckbox>}
	</Section>
})

export function copyVisibilityFlags(source: GridToolbarVisibilityFlags, target: GridToolbarVisibilityFlags){
	let keys : (keyof GridToolbarVisibilityFlags)[] = ['showMenu', 'showFilters', 'showTimeSettings', 'showViewSelector', 'showRefreshTimer']
	for (const key of keys) {
		if(source[key] !== undefined && target[key] === undefined){
			target[key] = source[key]
			source[key] = undefined
		}
	}
}

export function anyFlagSet(flags: GridToolbarVisibilityFlags) {
	if (flags == null)
		return false

	for (let key of Object.keys(flags)) {
		if (flags[key as keyof GridToolbarVisibilityFlags] === true) {
			return true
		}
	}

	return false
}
