import React from 'react';
import {Cookies} from "core/cookies";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {ServiceDocumentConfiguration} from './serviceDocumentConfiguration';
import {ServiceDocumentWidgetApi} from './serviceDocumentWidgetApi';
import {ServiceDocumentColumns} from './serviceDocumentColumns';
import {Grid, fullTextSearch} from "controls/react/kendoWrappers/grid";
import {getGridFilterable} from './shared'
import {addLinks} from "core/react/links";
import {AntInput} from "controls/react/ant/antInput";
import {FilterResetButton} from "controls/react/form/filterResetButton";
import {getGridStateForSaving} from "controls/react/kendoWrappers/grid";
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import {ApplicationState} from "framework/applicationState";


const i = require('core/localization').translator( {
	"Document": {
	},
}, sharedLocalization);

export function getWidgetDescription() {
	return {
		form: ServiceDocumentConfiguration,
		defaultConfig: {
			type: 'service-document',
			title: i('Document'),
			fullTitle: i('Document'),
			accountId: ApplicationState.accountId,
			isDashboard: true,
			columns: ServiceDocumentColumns.get().map(x => x.field)
		},
		widget: ServiceDocumentWidget,
	}
}

@addLinks
export class ServiceDocumentWidget extends React.PureComponent {
	constructor(props) {
		super(props);
		this.availableSearchColumns = ['name', 'serviceName', 'accountName', 'description'];
		this.state = {
			gridHeight: "auto",
			gridColumns: this.getGridColumns(),
			gridSort: this.props.persistedState?.sort || [{field: "created", dir: "desc"}],
			searchFilter: this.props.persistedState?.filter || {},
			searchValue: this.props.persistedState?.searchValue || '',
			loaded: false
		}
	}

	componentDidMount() {
		this.getDocuments();
	}

	getDocuments = async () => {
		let resp = await ServiceDocumentWidgetApi.getDocuments(this.props.config.dynamicServices, this.props.config.accountIds, this.props.config.tags, this.props.config.serviceIds, this.props.config.documentLimit)
		this.setState({
			data: new kendo.data.DataSource({
				data: resp
			}),
			loaded: true
		})
	}

	onRowClicked = async (dataItem, e) => {
		if (e.target.className != 'cw_grid_link')
			return;
		var downloadUrl = await ServiceDocumentWidgetApi.getDownloadUrl(dataItem)
		window.open(downloadUrl);
	}

	getGridColumns = () => {
		let columns = [];
		const allColumns = ServiceDocumentColumns.get();
		const persistedCols = this.props.persistedState?.columns;

		for (const col of allColumns) {
			if (this.props.config.columns.findIndex(x => x === col.field) == -1)
				continue;
			if(persistedCols && persistedCols[col.field]?.width)
				col.width = persistedCols[col.field].width;

			if (this.availableSearchColumns.includes(col.field)) {
				col.filterable = true;
			}
			columns.push(col)
		}
		if(persistedCols)
			columns = Utils.rearrangeColumns(columns, persistedCols)
		return columns;
	}

	onFilterReset() {
		this.setState({
			searchFilter: {},
			gridSort: [],
			searchValue: ''
		});
	}

	onSearchChanged = (value) => {
		this.setState({
			searchFilter: fullTextSearch(
				this.availableSearchColumns,
				value
			),
			searchValue: value
		});
	}

	render() {
		const toolbarAtTheEnd = [
			<AntInput key={1} value={this.state.searchValue} onChange={this.onSearchChanged} placeholder={i('Search...')}/>,
			<FilterResetButton key={2} onReset={() => this.onFilterReset()}></FilterResetButton>
		];
		return <DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd} overlaySpinner={!this.state.loaded}>
			{this.state.loaded && <Grid dataSource={this.state.data}
				height={this.state.gridHeight}
				setHeightOnResize={true}
				skipSelectorColumn={true}
				filterable={getGridFilterable(this)}
				filter={this.state.searchFilter}
				resizable={true}
				sort={this.state.gridSort}
				sortable={{ mode: 'multiple' }}
				reorderable={true}
				fit={true}
				onRowClicked={this.onRowClicked}
				columns={this.state.gridColumns}
				ref={grid => this.grid = grid} />
			}
		</DefaultWidgetWrapper>
	}

	onResize() {
		let wrapper = this.grid?.kendoGrid.wrapper;
		let gridContainerHeight = wrapper.closest('.section__content').height();
		this.setState({
			gridHeight: gridContainerHeight
		});
	}

	getStateForSaving() {
		return {
			...getGridStateForSaving(this.grid?.kendoGrid),
			searchValue: this.state.searchValue
		};
	}
}
