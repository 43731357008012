import React from "react";

import {useCloudTypes} from "./api";
import {AntSelect} from "controls/react/ant/antSelect";


const i = require('core/localization').translator({
  "Select a cloud provider...": {
    "no": "Velg en skyleverandør"
  }
});

export const CloudTypesDropDown = props => {
	const {accountId, ...others} = props;
	const cloudTypes = useCloudTypes(accountId);

	return (
		<AntSelect placeholder={i('Select a cloud provider...')}
		           dataList={cloudTypes}
		           nameField={'text'}
		           valueField={'value'}
		           {...others}
		/>
	);
}
