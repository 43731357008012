import {AntSelect, AntSelectPropsPlain, AntSelectValue} from "controls/react/ant/antSelect";
import React, {useEffect, useState} from "react";
import {apiFetch} from "../../../../framework/api";
import {getMetricUnits} from "./dataSources/MetricSettingsApi";
import {MetricConfigurationUnitType} from "./metricSelectorAdvanced";
import { observer } from "mobx-react";

const i = require('core/localization').translator({
	'{0} (default)': {},
	'Autoscaling': {}
});

export type MetricUnitDropDownProps = AntSelectPropsPlain<AntSelectValue> & {
	unitType: string,
	withCustom?: boolean
}

export const MetricUnitDropDown = observer((props: MetricUnitDropDownProps) => {
	const {unitType, withCustom, ...others} = props;
	const [units, setUnits] = useState([]);
	const [loaded, setLoaded] = useState(false)
	useEffect(() => {
		const load = async () => {
			if (!unitType)
				return

			const response = await apiFetch(getMetricUnits(unitType));

			if (response.success) {
				let units = response.data
				const defaultIndex = units.findIndex(x => x.value == unitType);
				if (defaultIndex >= 0) {
					units[defaultIndex].description = i('{0} (default)', units[defaultIndex].description)
				}

				const newUnits = [
					{
						label: i('Autoscaling'),
						value: MetricConfigurationUnitType.Autoscaling
					}, withCustom ? {
						label: i('Custom'),
						value: MetricConfigurationUnitType.Custom
					} : null, ...units.map(x => ({label: x.description, value: x.value}))
				].filter(x => x)

				setUnits(newUnits)
				setLoaded(true)
			}
		};
		load();
	}, [unitType, withCustom]);

	return <AntSelect disabled={!loaded} options={units} showSearch={false} {...others}/>
})
