import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import React from 'react';
import {throttle} from 'lodash'
import {reaction} from 'mobx'

import {Utils, State, UserPrefs, CustomTheme} from 'tools';

import {Cookies, RoutesTable, RemoteEventsManager, LocalEventsManager, translator} from 'core';

import {
	CustomNotification, ContextMenu, Breadcrumb, ModalWindow,
	Dialog, ModuleStatusIndicator
} from 'controls'
import {topLeftCornerPosition} from 'controls/modalWindow'

import Settings from 'settings'
import Configuration from 'configuration'
import Monitors from 'tools/monitors'
import Incidents from 'areas/incidents/incidents'

import Sla from 'areas/sla/sla'
import {
	AccountForm,
	AssetForm,
	CalendarForm,
	AuthenticationForm,
	AuthenticationsView,
	TeamDetails,
	TeamForm,
	UserDetails
} from 'areas/administration'
import {RoleDetails, RoleView} from "areas/administration/roles";
import Reports from 'areas/reports/reports';

import {
	NewServiceWindow,
	ServiceLogDetailsView,
	ServicesList
} from 'areas/services';
import LastChangesView from 'areas/views/lastChangesView';
import {AgentConfiguration} from "areas/management";
import lang from "./localization/lang";
import {Api} from "tools/api";
import {GenericDirtyCheck} from 'tools/genericDirtyCheck';
import {SlaRouter} from 'areas/sla/bundleDescription';
import {ReportsRouter} from "areas/reports/bundleDescription";
import {DashboardsRouter} from "areas/dashboards/bundleDescription";
import {AutomationRouter} from "areas/automation/bundleDescription";
import {AssetGroupRouter} from 'areas/assetgroups/bundleDescription';
import {getPromiseWithResolver} from 'tools/promise';
import {newGuid} from "tools/guid";
import {openInNewWindow} from "tools/navigation";
import {UserRouter} from "../areas/administration/users/bundleDescription";
import NewApplicationWindow from "../areas/application/newApplicationWindow";
import { CostsRouter } from 'areas/cost/bundleDescription';
import {NewBudgetWindow} from "areas/cost/budget/newBudgetWindow"
import {IncidentsRouter} from "../areas/incidents/bundleDescription";
import {ApplicationState} from "../framework/applicationState";
import {NavigationAction, NavigationStore} from "framework/navigationStore";
import {AdministrationRouter} from "../areas/administration/bundleDescription";
import {isOneOf} from "../tools/utils";
import {ViewsRouter} from "../areas/views/bundleDescription";
import {AgentsRouter} from "areas/management/agents/bundleDescription";
import {writeRouteIdLog} from "../tools/log";
import {FavoritesStore} from "../layout/favoritesStore";
import {Session} from "core/session";
import {addUnknownTimeZonesToMoment, fixMomentLocale} from "../vendor-init/momentTimezonesInit";

let i = translator();

function setBodyClass(className) {
	document.body.className = "css-var-r0"
	if (className) {
		document.body.classList.add('custom-theme')
		document.body.classList.add(...className.split(' '))
	}
}

export class MainApplication {
	_runtime = {};
	_genericDirtyCheck = null;
	_navigationEnabled = true;

	constructor() {
		this.getStatusesCount = throttle(this.getStatusesCount, 10000)
		this.container = document.querySelector('#content_area')
	}

	run() {
		setBodyClass("");
		CustomTheme.apply();
		addUnknownTimeZonesToMoment();
		fixMomentLocale();
		this._routesTable = new RoutesTable();
		this._genericDirtyCheck = new GenericDirtyCheck();

		//We need to handle clicks on all <a> on a page so we subscribe to document and
		//check event.target there
		document.addEventListener('click', this.onClicked, true);

		window.addEventListener('popstate', e => {
			//we assume that the popstate event is fired when a user clicks 'back' so we should ignore navigation restriction
			//and just move a user back. (for example a user currently can be on a dashboard which does not allow redirects but we still
			// need to allow user to go back)
			this._navigationEnabled = true

			if (State.isUnloading) {
				window.location.reload();
				return;
			}

			this.navigate(document.location.href, {
				doNotPushState: true,
				navigationAction: NavigationAction.RewindToTheSame
			});
		});

		Utils.getConfig(() => {
			this.createSocketConnection();
		});

		let intervalId = setInterval(() => {
			if(!Cookies.CeesoftCurrentAccountId){
				clearInterval(intervalId)
				let loginUrl = Settings.defaultLogoutPage + "?forceLogin=1";
				Utils.redirectTo(loginUrl, true)
			}
		}, 2000)
	}

	onClicked = async (e) => {
		let aTag = e.target.tagName == 'a'
			? e.target
			: e.target.closest('a');

		if (aTag == null) {
			return;
		}

		const url = aTag.getAttribute('href');
		if( !url || url === '#' || url.startsWith('javascript:') || url.indexOf('#') == -1 || aTag.getAttribute('target') == '_blank')
			return;

		const additional = {
			navigationAction: aTag.getAttribute("navigation-action")
		};

		e.preventDefault();

		await this.navigate(url, additional);
	}


	async navigate(url, additional) {
		if(!this._navigationEnabled)
			return false;

		//we are in standalone mode so we need to entre a 'full' mode first, thus we need to reload the page completely
		if(this.standalone && this._runtime.module != null && !url.includes('standalone=1')) {
			this._navigationEnabled = false

			if(additional?.extra) {
				window.sessionStorage.setItem('storageAdditional', JSON.stringify(additional?.extra));
			}

			window.location.hash = url + "?sessionId=" + newGuid();
			window.location.reload();
			return
		}

		if(additional?.newTab){
			openInNewWindow(url, additional?.extra ? {extra: additional.extra} : undefined);
			return true;
		}

		$('.toolbar__entry.cw_userinfo .cw_item').removeClass('is_selected');
		this.executeOnNavigateCallbacks();

		additional = additional || {};
		url = url.split('#').pop();

		let route = this._routesTable.find(url);
		if(route == null) {
			route = this._routesTable.find('/');
		}

		if(additional.doNotPushState !== true){
			window.history.pushState(null, null, '#' + url);
		}

		if( route.description == null ){
			//TODO: legacy route, handler does not return description, it navigates itself instead
			return true;
		}

		return await this.setMainModule(route.description,
			route.params,
			additional
		);
	}

	setReactRef(ref){
		this.reactRef = ref;
	}

	initComponent() {
		this.actionNotification = new CustomNotification({
			appendToElement: '.window_area',
		});
		this.updateUsername();

		this.reloadCurrentApp = false;
		this.tempUserTagsWindow = {
			clickedElement: null,
			close: function () {
			}
		};

		this.removeListeners();
		this.attachListeners();
		this.initKendoComponents();
		this.initCustomComponents();
		this.isVisitedModule = false;
		this.isRoutedModule = false;

		this.kendoLocaleLoaded = false;
		this.localeLoaded = false;

		kendo.culture(Cookies.CeesoftUserLocale);
		// set monday as first weekday for all locales
		kendo.culture().calendars.standard.firstDay = 1;
		this.kendoLocaleLoaded = true;

		this.localeLoaded = true;
		this.session = new Session();
		//this.session.getUserRoles(this.onRolesRetrieve, this);
		Utils.getServerId();

		if (!this.standalone) {
			Utils.checkLicenseStatus();
		}

		this.onRolesRetrieve()
	}

	updateUsername() {
		if (Cookies.CeesoftUsername) {
			$('title').text('Ceeview - ' + Cookies.CeesoftCurrentAccountName);
		} else {
			Utils.redirectTo(Settings.defaultLogoutPage, true);
		}
	}

	createSocketConnection() {
		this.subscriberId = Utils.guid();
		this.eventSourceId = Utils.guid();

		RemoteEventsManager.connect({
			sessionId: Cookies.sessionId,
			subscriberId: this.subscriberId,
			eventSourceId: this.eventSourceId,
			handleEvents: $.proxy(this.handleEvents, this),
			handleConfigEvents: $.proxy(this.handleConfigEvents, this),
			onInitialized: $.proxy(function () {
				this.initComponent();
			}, this),
			onSessionExpired: function () {
				//whats a hack to relogin into a public dashboard if session is dead (for example the server is restarted)
				if(ApplicationState.isGuestUser && Cookies.CeesoftLoggedIntoDashboardId != null){
					Cookies.erase("CeesoftLoggedIntoDashboardId")
					window.location.reload()
				}else {
					Utils.redirectTo(Settings.defaultLogoutPage, true);
				}
			}
		});
	}

	updateLangMessages() {
		$.fn.kendoCustomTreeList.widget.fn.options.messages.noRows = lang.grid.NO_RECORDS;
		$.fn.kendoCustomGrid.widget.fn.options.noRecords = {
			template: lang.grid.NO_RECORDS
		};
		if (kendo.ui.Pager) {
			Object.assign(kendo.ui.Pager.fn.options.messages, lang.kendo.pager.messages)
		}
		if (kendo.ui.ColumnMenu) {
			Object.assign(kendo.ui.ColumnMenu.fn.options.messages, lang.kendo.columnMenu.messages)
		}
	}

	loadUserPreferences() {
		var scope = this;
		UserPrefs.load('Ceeview', $.proxy(function (result) {
			if (result.success) {
				var prefs = result.data;
				for (let i = 0; i < prefs.length; i++) {
					UserPrefs.set(prefs[i].key, prefs[i].value);
				}
				// interpret preferences - include subAccounts
				var is = UserPrefs.get('includeSubaccounts');
				if (is !== null && is !== undefined) {
					State.includeSubaccounts = (is === 'true');
				}
				// set default values if not loaded
				else {
					State.includeSubaccounts = true;
				}
				if (!scope.session.hasRole('ACCOUNT_TRAVERSE')) {
					State.includeSubaccounts = false;
				}

				ApplicationState.includeSubaccounts = State.includeSubaccounts;

				UserPrefs.load('LastChanges', $.proxy(function (result) {
					if (result.success) {
						for (let i = 0; i < result.data.length; i++) {
							UserPrefs.set(result.data[i].key, result.data[i].value);
						}
						State.lastChangesTimestamp = UserPrefs.get('lastChangesTimestamp');
					}
					scope.subscribe();
					scope.setNavState();
					scope.start();
				}, this));
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}


	loadAccountDetails() {
		var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/logoId';
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var timeout = Settings.COOKIE_TIMEOUT;
				Cookies.create("CeesoftAccountLogoId", result.data, timeout);
				if (result.data) {
					Renderer.setAccountLogo(result.data);
				}
			}
		}, this));
	}

	/**
	 * Saves user preferences
	 */
	saveUserPreferences() {
		if(ApplicationState.isGuestUser)
			return

		var preferences = [{
			key: 'minimizedNav',
			value: Boolean($('.is_minimized_nav').length)
		}, {
			key: 'includeSubaccounts',
			value: State.includeSubaccounts
		}, {
			key: 'collapsedShortcuts',
			value: Boolean($(".cw_nav_tier2:hidden").length)
		}, {
			key: 'lastChangesTimestamp',
			value: State.lastChangesTimestamp
		}];

		UserPrefs.save('Ceeview', preferences);
	}

	// todo: remove after new menu confirmed
	setNavState() {
		var isMinimized = UserPrefs.get('minimizedNav');
		if (isMinimized === 'true') {
			$('.cw_toggle_nav').removeClass('chevron-left').addClass('chevron-right');
			$('.main_content').addClass('is_minimized_nav');
		} else {
			$('.cw_toggle_nav').removeClass('chevron-right').addClass('chevron-left');
			$('.main_content').removeClass('is_minimized_nav');
		}
		var isMinimizedShortcuts = UserPrefs.get('collapsedShortcuts');
		if (isMinimizedShortcuts === 'true') {
			$(".cw_nav_tier2").addClass("hide");
		} else {
			$(".cw_nav_tier2").removeClass("hide");
		}
	}

	transformToObject(parametersAsString) {
		var params = {};
		var paramArray = parametersAsString.split("&");
		for (let  i = 0; i < paramArray.length; i++) {
			var tmp = paramArray[i].split("=");
			params[tmp[0]] = decodeURIComponent(tmp[1]);
		}
		return params;
	}

	loadFirstMenuItem() {
		$('#nav li').first().trigger('click');
	}

	start() {
		//console.profileEnd();
		var module = {};
		// create breadcrumb
		this.breadcrumb = new Breadcrumb({reactRef: this.reactRef});

		this.setInstalledMonitors();
		FavoritesStore.load();

		if (this.kendoLocaleLoaded && this.localeLoaded) {
			let redirectModule = sessionStorage.getItem('redirectModule');
			if (redirectModule) {
				let redirectConfig = '';
				try {
					if (sessionStorage.getItem('redirectConfig'))
						redirectConfig = JSON.parse(sessionStorage.getItem('redirectConfig'));
				}catch(e){
					console.error(e)
				}

				let redirectId = '';
				if(sessionStorage.getItem('redirectId'))
					redirectId = sessionStorage.getItem('redirectId');

				this.loadModule(redirectModule, redirectId, redirectConfig);

				this.getStatusesCount();
				sessionStorage.removeItem('redirectModule');
				sessionStorage.removeItem('redirectConfig');
				return;
			}

			//check for GET parameters
			var prmstr = window.location.search.substr(1);
			var params = (prmstr !== null && prmstr !== "") ? this.transformToObject(prmstr) : {};
			if (params.moduleName) {
				params.id = params.id || null;
				this.loadModule(params.moduleName, params.id, params);
				this.getStatusesCount();
				return;
			}

			this.navigate(window.location.hash);
			this.getStatusesCount();

			reaction(() => ApplicationState.includeSubaccounts, () => {
				if (!State.currentApp.isHomeView) {
					this.reloadCurrentModule()
				}
			})
		}
	}

	setMainModule = async (moduleDescription, routeParams, additional) => {
		let error = moduleDescription.checkAccess && moduleDescription.checkAccess(this, routeParams);
		if (error) {
			Utils.showInfo(lang.INFO, error);
			return false;
		}

		const unloaded = await this.unloadModule();
		if (!unloaded)
			return false;

		if (moduleDescription.loadingIndicator !== false) {
			this.addLoadingMask();
		}

		this._runtime = {
			moduleDescription,
			configuration: routeParams
		};

		if (moduleDescription.jspUrl) {
			await Api.loadContent(moduleDescription.jspUrl, this.container);
		}

		let stringStorageAdditional = window.sessionStorage.getItem('storageAdditional');
		if (stringStorageAdditional && stringStorageAdditional != 'undefined') {
			try {
				let storageAdditional = JSON.parse(stringStorageAdditional);

				if (!additional) {
					additional = {};
				}

				if (!additional.extra) {
					additional.extra = {};
				}

				Object.assign(additional.extra, storageAdditional)
			}catch{

			}

			window.sessionStorage.removeItem('storageAdditional')
		}

		const combinedParams = {...routeParams, ...moduleDescription.params, ...additional?.extra};
		combinedParams.standalone = this.standalone;
		if (combinedParams.id === 'null') {
			combinedParams.id = null;
		}

		const jsModule = await moduleDescription.getModuleImport(routeParams);
		const [initializationCallback, moduleLoadedPromise] = getPromiseWithResolver()

		if (moduleDescription.react || jsModule.default.prototype == null || jsModule.default.prototype.render) {
			this._runtime.reactRoot = createRoot(this.container)
			const ModuleComponent = jsModule.default;

			if (jsModule.Store) {
				if (jsModule.Store.prototype.render) {
					const ModuleStore = jsModule.Store;

					this._runtime.reactRoot.render(
						<ModuleStore  {...combinedParams}
						              ref={ref => this._runtime.module = ref}
						              initialized={initializationCallback}>
							<ModuleComponent/>
						</ModuleStore>
					);
				} else {
					const storeInstance = new jsModule.Store({
						...combinedParams,
						initialized: initializationCallback
					})

					this._runtime.module = storeInstance
					const Context = jsModule.Context

					this._runtime.reactRoot.render(
						<Context.Provider value={storeInstance}>
							<ModuleComponent/>
						</Context.Provider>
					)
				}
			} else {
				this._runtime.reactRoot.render(
					<ModuleComponent {...combinedParams}
					                 ref={ref => this._runtime.module = ref}
					                 initialized={initializationCallback}/>
				);
			}
		} else {
			const module = this._runtime.module = new jsModule.default();
			Object.assign(module, combinedParams);
			module.initialized = initializationCallback;
			module.app = this;
			module.container = this.container
			module.init();
		}

		const initializationResult = await moduleLoadedPromise;

		if (initializationResult.subscriptions) {
			const subscriberId = initializationResult.subscriberId || Utils.guid();
			this._runtime.subscription = RemoteEventsManager.subscribe(subscriberId, initializationResult.subscriptions);
		}

		FavoritesStore.currentPageTitleOverride = initializationResult.favoritesTitle;
		FavoritesStore.currentPagePrefixOverride = initializationResult.favoritesPrefix;
		ApplicationState.currentHelpKey = initializationResult.helpKey;

		State.currentApp = this._runtime.module
		ApplicationState.currentRouteId = this._runtime.routeId ?? this._runtime.moduleDescription?.routeId ?? State.mainApp._currentModuleConfig?.routeId ?? ''

		writeRouteIdLog(ApplicationState.currentRouteId);

		await NavigationStore.push({
			title: initializationResult.title || 'Module did not provide title',
			url: window.location.hash
		}, {
			action: additional?.navigationAction,
			defaultItems: initializationResult.defaultBreadcrumbItems
		});

		setBodyClass(moduleDescription.bodyCssClass);

		this.removeLoadingMask();

		window.sessionStorage.removeItem('storageAdditional');

		return true;
	}

	updateLoadedModulesInformationForLegacyModules() {
		if (State.currentApp == null) {
			return;
		}

		if (this._runtime?.module == State.currentApp)
			return;

		this._runtime = {
			module: State.currentApp,
			configuration: this._currentModuleConfig,
			reactRoot: this._runtime.reactRoot,
			routeId: this._runtime.moduleDescription?.routeId ?? this._currentModuleConfig.routeId
		};

		State.currentApp.app = this;
	}

	//this method try 2 algorythms of unloading of a module
	//The new one with promises which  not need redirectModule and waitForConfirmation and additional loadModule call
	//and the old one with all of those
	async unloadModule() {
		if (!this._runtime.module)
			return true;

		try {
			let dirtyCheck = null;
			if (this._runtime.module.dirtyCheck) {
				dirtyCheck = await this._runtime.module.dirtyCheck();
			} else {
				dirtyCheck = await this._genericDirtyCheck.dirtyCheck();
			}

			if (dirtyCheck?.isDirty) {
				if (!await this.showDirtyCheckQuestion(dirtyCheck))
					return;
			}
		} catch (e) {
			console.log(e);
		}


		let destroyed = true;
		if (!!this._runtime.module && !this._runtime.module.wasDestroyed && this._runtime.module.destroy) {
			try {
				destroyed = await this._runtime.module.destroy();
			} catch (e) {
				console.error(e)
			}

			if (destroyed == null) { //legacy implementations does not return anything from destroy()
				if (this._runtime.module.waitForConfirmation) {
					this._runtime.module.redirectModule = {
						application: this._currentModuleConfig.moduleName,
						id: this._currentModuleConfig.id,
						config: this._currentModuleConfig
					};
					destroyed = false;
				} else {
					destroyed = true;
				}
			}
		}

		if (destroyed) {
			this._runtime.subscription?.unsubscribe();
			this._genericDirtyCheck.reset();

			this.cleanUpContainer()

			State.currentApp = null

			this._runtime = {};
		}

		return destroyed;
	}

	showDirtyCheckQuestion(dirtyCheckResult) {
		return new Promise(resolve => {
			const dialog = new Dialog({
				title: lang.INFO,
				msg: dirtyCheckResult.message,
				icon: 'INFO',
				actionText: 'CLOSE',
				buttons: {
					ok: true,
					cancel: true
				},
				fn: (value, button) => {
					if (button === 'ok') {
						resolve(true);
					} else {
						resolve(false)
					}
				}
			});
			dialog.show();
		});
	}

	cleanUpContainer() {
		if (this._runtime) {
			this._runtime.reactRoot?.unmount()
			this._runtime.reactRoot = null
		}
		this.container.innerHTML = ''
	}

	loadModule = async (application, id, config, event, isFromBreadcrumb, loadInNewTab) => {
		if(!this._navigationEnabled)
			return;

		if(loadInNewTab){
			sessionStorage.setItem('redirectModule', application);
			if(config)
				sessionStorage.setItem('redirectConfig', JSON.stringify(config));
			if(id)
				sessionStorage.setItem('redirectId', id);
			openInNewWindow('')
			return;
		}

		config = config || application;

		if( typeof application === 'object' && application != null ){
			this._currentModuleConfig = application;
		}else{
			this._currentModuleConfig = {
				moduleName: application,
				isFromBreadcrumb,
				id,
				...config
			};
		}

		const monitorModule = Monitors.isModule(application);
		if(monitorModule && Configuration.installedMonitors.includes(monitorModule.type)) {
			this._currentModuleConfig.monitorType = monitorModule.type;
			this._currentModuleConfig.sharedAgents = Configuration.installedMonitorSettings[monitorModule.type].sharedAgents;
		}

		if (isOneOf('ManagementAgents', [application, this._currentModuleConfig.application])) {
			return this.navigate(AgentsRouter.list(), config);
		}

		// we don't have calls of loadModule for Dashboard
		if (application == 'Dashboard') {
			return this.navigate(DashboardsRouter.viewer(this._currentModuleConfig.id));
		}

		if (application == 'AccountDetails') {
			return this.navigate(AdministrationRouter.accountDetails(this._currentModuleConfig.id));
		}

		if (application == 'AccountConfigurationDetails') {
			const {id, data, mode} = this._currentModuleConfig;
			const {type, name, showProfile, factoryName} = data;
			return this.navigate(AdministrationRouter.accountConfigurationDetails(id,
				type, mode, name, showProfile, factoryName, ));
		}

		if (application == 'AdministrationCalendarsDetails') {
			return this.navigate(AdministrationRouter.calendarDetails(this._currentModuleConfig.id));
		}

		$('.toolbar__entry.cw_userinfo .cw_item').removeClass('is_selected');
		this.executeOnNavigateCallbacks();

		if(State.mainApp.standalone){
			sessionStorage.setItem('redirectModule', application);
			sessionStorage.setItem('redirectConfig', JSON.stringify(config));
			Utils.redirectTo(Settings.defaultLogoutPage);
			return;
		}

		try {
			State.isFromBreadcrumb = this._currentModuleConfig.isFromBreadcrumb;

			var pathname = window.location.pathname,
				path,
				position = pathname.toLowerCase().indexOf('index.jsp'),
				module = {
					application: null, //module unique id
					name: null //used as as title to show to users on UI
				},
				breadcrumbItem = {};

			if (position > -1) {
				path = pathname.substr(0, position);
			} else {
				path = String(pathname);
			}

			var historyUrl = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + path.split('#')[0];

			if(!await this.unloadModule()) {
				return false;
			}

			if (event && (event.ctrlKey || event.shiftKey)) {
				var url = 'index.jsp?moduleName=' + encodeURIComponent(this._currentModuleConfig.moduleName)
					+ '&id=' + encodeURIComponent(this._currentModuleConfig.id);
				var focus = false;
				for (var key in this._currentModuleConfig) {
					if (key !== 'id') {
						url += '&' + key + '=' + encodeURIComponent(this._currentModuleConfig[key]);
					}
				}
				event.preventDefault();
				/*if (window.event && (window.event.ctrlKey || window.event.shiftKey)) {
				 focus = window.event.shiftKey;
				 window.event.ctrlKey = false;
				 window.event.shiftKey = false;
				 window.event.preventDefault();
				 }*/
				var openWindow = function () {
					var windowName = 'popup_' + Utils.guid();
					var newWindow = window.open(url, windowName, 'width=' + screen.width + ',height=' + screen.height + ',location=yes,scrollbars=yes,status=yes;');
					if (focus && window.focus) {
						newWindow.focus();
					} else {
						window.focus();
					}
				};
				setTimeout(openWindow, 500);
				return;
			}

			if (this._currentModuleConfig.moduleName) {
				// TODO: check if this code is used
				if (this._currentModuleConfig.moduleName.indexOf('Administration') === -1) {
					//flag helpful when going to grids accesible from upper buttons, even if the administration left panel is still expanded
					this.isFalseAdministrationPanel = true;
					ApplicationState.includeSubaccountsMenuDisabled = false
				} else {
					this.isFalseAdministrationPanel = false;
					ApplicationState.includeSubaccountsMenuDisabled = true
				}
			}

			//this.cleanUpContainer();

			setBodyClass("");

			switch (this._currentModuleConfig.moduleName) {
				// SERVICE
				case 'Service':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						$('#nav li').removeClass('current').parent().find('#service').addClass('current');
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.SERVICE;
						breadcrumbItem.text = 'Service';
					} else {
						module.name = lang.SERVICE;
						module.text = 'Service';
					}
					setBodyClass('cw_service_module');
					$('#content_area').empty().load('include/Service.jsp', $.proxy(function () {
						State.currentApp = new ServicesList(this._currentModuleConfig);
					}, this));
					break;

				case 'ServiceLogDetails':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						module.name = lang.SERVICE;
						module.application = 'Service';
						module.text = 'Service';
					}
					breadcrumbItem.name = this._currentModuleConfig.name || lang.service.SERVICE_LOG_DETAILS;
					$('#content_area').empty().load('include/Service/LogDetailsView.jsp', $.proxy(function () {
						State.currentApp = new ServiceLogDetailsView(this._currentModuleConfig);
					}, this));
					break;

				case '':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						module.name = lang.widget.SLA;
						module.application = 'SLAs';
						module.text = 'SLA';
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.widget.SLA;
					}
					setBodyClass('cw_sla_module');
					$('#content_area').empty().load('include/SLAs.jsp', $.proxy(function () {
						State.currentApp = new Sla(this._currentModuleConfig);
					}, this));
					historyUrl += '#/sla';
					break;
				case 'Incidents':
					if ($.isEmptyObject(this.breadcrumb.module)) {
						module.name = lang.service.INCIDENT;
						module.application = 'Incidents';
						module.text = 'Incidents';
						$('#nav li').removeClass('current').parent().find('#incidents').addClass('current');
					}
					if (this._currentModuleConfig.moduleName !== this.breadcrumb.module.application) {
						breadcrumbItem.name = lang.service.INCIDENT;
						breadcrumbItem.text = 'Incident';
						module.text = 'Incidents';
					} else {
						module.name = lang.service.INCIDENT;
					}
					setBodyClass('cw_incident_module');
					$('#content_area').empty().load('include/Incidents.jsp', $.proxy(function () {
						State.currentApp = new Incidents(this._currentModuleConfig);
					}, this));
					historyUrl += '#/incidents';
					break;
				case 'AdministrationUserDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || ' ';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/User/Details.jsp', $.proxy(function () {
						State.currentApp = new UserDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationTeamDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || ' ';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/Team/Details.jsp', $.proxy(function () {
						State.currentApp = new TeamDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationUserCategoriesView':
					breadcrumbItem.name = lang.USER_CATEGORIES;
					breadcrumbItem.text = 'Roles';
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/UserCategories/View.jsp', $.proxy(function () {
						State.currentApp = new RoleView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;
				case 'AdministrationUserCategoriesDetails':
					breadcrumbItem.name = lang.USER_CATEGORIES;
					setBodyClass('cw_administration_module');
					$('#content_area').empty().load('include/Administration/UserCategories/Details.jsp', $.proxy(function () {
						State.currentApp = new RoleDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/administration';
					break;

				case 'AccountDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || lang.ACCOUNT;
					breadcrumbItem.text = 'Account';
					import('areas/administration/accounts/details').then( m => {
						State.currentApp = new m.default(this._currentModuleConfig);
					});
					break;
				case 'AccountConfigurationDetails':
					breadcrumbItem.name = this._currentModuleConfig.name || lang.ACCOUNT;
					breadcrumbItem.text = 'Configuration';
					import('areas/administration/accounts/configurationDetails').then( m => {
						State.currentApp = new m.default(this._currentModuleConfig);
					});
					break;
				// MANAGEMENT
				case 'ManagementAgents':
					breadcrumbItem.name = lang.AGENTS;
					breadcrumbItem.text = 'Agents';
					setBodyClass('cw_management_module');
					$('#content_area').empty().load('include/Management/AgentsView.jsp', $.proxy(function () {
						State.currentApp = new AgentsView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/management';
					break;
				case 'LastChanges':
					module.name = lang.LAST_CHANGES;
					module.application = 'LastChanges';
					module.text = 'Last changes';
					$('#nav li').removeClass('current');
					$('#content_area').empty().load('include/Summary/LastChangesView.jsp', $.proxy(function () {
						State.currentApp = new LastChangesView(this._currentModuleConfig);
					}, this));
					historyUrl += '#/lastchanges';
					break;
				case 'AgentConfiguration':
					breadcrumbItem.name = this._currentModuleConfig.agentName || ' ';
					if (this._currentModuleConfig.id !== '') {
						$('#content_area').empty().load('include/Management/AgentConfiguration.jsp', $.proxy(function () {
							State.currentApp = new AgentConfiguration(this._currentModuleConfig);
						}, this));
						historyUrl += '#/management';
					} else {
						this.loadModule('ManagementAgents');
					}
					break;
				// PROFILE
				case 'Profile':
					module.name = lang.PROFILE;
					module.application = 'Profile';
					setBodyClass('');
					$('#nav li').removeClass('current');
					$('#content_area').empty().load('include/Administration/User/Details.jsp', $.proxy(function () {
						State.currentApp = new UserDetails(this._currentModuleConfig);
					}, this));
					historyUrl += '#/profile';
					break;
			}

			if (window.history && window.history.pushState) {
				if (!this.isVisitedModule || !this.isRoutedModule) {
					if (historyUrl.indexOf('#') > -1) {
						try {
							window.history.pushState(this._currentModuleConfig, document.title, historyUrl);
						} catch (e) {
							// console.log(e);
						}
					}
				} else {
					if (this.isRoutedModule) {
						this.isRoutedModule = false;
					}
					if (this.isVisitedModule) {
						this.isVisitedModule = false;
					}
				}
			}

			// BREADCRUMB
			if (!$.isEmptyObject(module)) {
				module.application = module.application || this._currentModuleConfig.moduleName;
				module.config = module.config || this._currentModuleConfig;
				if (!module.name && module.config && module.config.name) {
					module = module.config;
				}
				this.breadcrumb?.setModule(module);
			}
			if (!$.isEmptyObject(breadcrumbItem)) {
				breadcrumbItem.application = this._currentModuleConfig.moduleName;
				breadcrumbItem.config = this._currentModuleConfig;
				if (this._currentModuleConfig.parentBreadcrumb) {
					this.breadcrumb?.addItem(this._currentModuleConfig.parentBreadcrumb, false);
				}
				if (this._currentModuleConfig.moduleName !== this.breadcrumb?.module.application ) {
					this.breadcrumb?.addItem(breadcrumbItem);
				}
			}
			//Utils.removeNameTooltip();
		} catch (e) {
			console.log('App error:', e);
		}
	}

	removeListeners() {
		$('.cw_main_nav').off();
		$('.nav_header').off();
		$('.cw_current_user').off();
		$('#notify_bubble').off();
		$('.cw_multi_caret').off();
		$('#cw_multi_account').off();
		$('#cw_multi_agent').off();
		$('#cw_multi_asset').off();
		$('#cw_multi_asset_group').off();
		$('#cw_multi_auth').off();
		$('#cw_multi_user').off();
		$('#cw_multi_team').off();
		$('#cw_multi_service').off();
		$('#cw_multi_sla').off();
		$('#cw_multi_report').off();
		$('#cw_multi_dashboard').off();
		$('#cw_multi_automation').off();
		$('#cw_multi_filter').off();
		$('.aside').off();
		$('.row').off();
		$('body').off();
		$('.cw_usertags_section').off();
		$('.cw_toggle_nav').off();
		$(document).off('click');
	}

	attachListeners() {
		$(document).on('click', $.proxy(this.resetTimer, this));
		$(document).on('keypress', $.proxy(this.resetTimer, this));
		$(document).on('mousemove', $.proxy(this.resetTimer, this));
		$(document).on('mousedown', $.proxy(this.resetTimer, this));
		$(document).on('DOMMouseScroll', $.proxy(this.resetTimer, this));
		$(document).on('mousewheel', $.proxy(this.resetTimer, this));
		$('.cw_main_nav > li').on('click', $.proxy(this.onCloseSubmenu, this));
		$('.nav_header').on('click', '.back, .main', $.proxy(this.onMenuBackClick, this));
		$('.cw_multi_caret').on('click', $.proxy(this.onToggleMenu, this));
		$('#nav_shortcuts .caret').on('click', $.proxy(this.onToggleShortcuts, this));
		$('#cw_multi_account').on('click', $.proxy(this.onNewAccount, this));
		$('#cw_multi_asset').on('click', $.proxy(this.onNewAsset, this));
		$('#cw_multi_asset_group').on('click', $.proxy(this.onNewAssetGroup, this));
		$('#cw_multi_auth').on('click', $.proxy(this.onNewAuthentication, this));
		$('#cw_multi_user').on('click', $.proxy(this.onNewUser, this));
		$('#cw_multi_team').on('click', $.proxy(this.onNewTeam, this));
		$('#cw_multi_service').on('click', $.proxy(this.onNewService, this));
		$('#cw_multi_sla').on('click', $.proxy(this.onNewSla, this));
		$('#cw_multi_report').on('click', $.proxy(this.onNewReport, this));
		$('#cw_multi_dashboard').on('click', $.proxy(this.onNewDashboard, this));
		$('#cw_multi_automation').on('click', $.proxy(this.onNewAutomation, this));
		$('#cw_multi_filter').on('click', $.proxy(this.onNewFilter, this));
		$('#cw_multi_calendar').on('click', $.proxy(this.onNewCalendar, this));
		$('#cw_multi_role').on('click', $.proxy(this.onNewRole, this));
		$('#cw_multi_application').on('click', $.proxy(this.onNewApplication, this));
		$('#cw_multi_cost').on('click', () => this.onNewCostModel());
		$('.row').on('click', $.proxy(this.closePopups, this));

		$(window).on("unload", $.proxy(function () {
			State.isUnloading = true;
			this.destroy();
			if (State.currentApp) {
				State.currentApp.destroy();
			}
		}, this));
		$(window).on('popstate', $.proxy(function (event) {
			this.isVisitedModule = true;
			/*
			 * if (window.location.href.indexOf('index.jsp') > -1) { var r =
			 * confirm("Are you sure you want to leave the application?"); if (r ===
			 * true) { x = "You pressed OK!"; } else { x = "You pressed Cancel!"; } }
			 */
		}, this));

		$(document).off('click').on('click', $.proxy(function (e) {
			var preferecensWindow = $(e.target).closest(".k-window.cw_preferences_window");
			if (preferecensWindow.length === 0) {
				var windows = $('.cw_preferences_window'), window, handler;
				for (let i = 0, length = windows.length; i < length; i++) {
					handler = $(windows[i]).find('.k-window-content');
					window = handler.data('kendoWindow');
					if ($(windows[i]).find('.k-window-content').length && window) {
						window.close();
					}
				}
			}
		}, this));
		$(document).on('click', $.proxy(this.closeProfileMenu, this));
		this.startTimer();
	}

	startTimer() {
		if (!ApplicationState.isGuestUser) {
			this.timeoutId = window.setTimeout($.proxy(this.goInactive, this), Settings.instanceTimeout * 60000);
		}
	}

	resetTimer() {
		window.clearTimeout(this.timeoutId);
		this.goActive();
	}

	closeProfileMenu() {
		$('.cw_user_actions').removeClass('is_open');
		$('.cw_current_user').removeClass('is_selected');
	}

	goActive() {
		this.startTimer();
	}

	goInactive() {
		if (State.currentApp && !State.currentApp.isPublic) {
			this.doLogout();
		}
	}

	initKendoComponents() {
		$('#new_content').kendoDropDownList();
	}

	initCustomComponents() {

	}

	doLogout() {
		this.destroy();
		if (State.currentApp && State.currentApp.destroy) {
			State.currentApp.destroy();
		}
		State.currentApp = null;

		Api.fetchPost(Settings.serverPath + 'sessions/logout/', [])

		Cookies.erase('CeesoftUsername');
		Cookies.erase('CeesoftUserId');
		Cookies.erase('CeesoftAccountName');
		Cookies.erase('CeesoftAccountId');
		Cookies.erase('sessionId');
		Cookies.erase('CeesoftUserLocale');
		Cookies.erase('sbAccountId');
		Cookies.erase('sbSessionId');
		Cookies.erase('CeesoftLoggedIntoDashboardId');

		Utils.redirectTo(Settings.logoutPage);
	}

	onNewAccount(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('ACCOUNT_CREATE')) {
			State.mainApp.loadModule('AccountDetails', null, {
				id: null,
				mode: 'create',
				redirectBack: true
			}, e);
			e.stopPropagation();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}

	onNewAsset(e, assetGroupId) {
		e.stopPropagation();
		this.closeCreatePanel();
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			let form = null;
			var modalWindow = new ModalWindow({
				title: lang.account.ASSET_CREATE,
				width: 400,
				minHeight: 625,
				height: 'auto',
				position: topLeftCornerPosition,
				url: 'include/Administration/AssetForm.jsp',
				refresh: function () {
					form?.destroy();
					form = new AssetForm({
						id: null,
						groupId: null,
						mode: 'create',
						assetGroupId: assetGroupId
					});
				},
				onClose: () => {
					form?.destroy();
				}
			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
	}

	onNewAssetGroup(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('ASSET_CREATE')) {
			var assetGroup = {
				extra: {}
			};
			assetGroup.extra.mode = 'create';
			var target = $(e.currentTarget);
			State.mainApp.navigate(AssetGroupRouter.createNew(), assetGroup);
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new authentication menu item
	 * @param {Object} e The click event object
	 */
	onNewAuthentication(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('AUTHENTICATION_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.CREATE,
				width: 400,
				minHeight: 400,
				maxHeight: 800,
				position: topLeftCornerPosition,
				id: 'authentication_modal',
				url: 'include/Administration/AuthenticationForm.jsp',
				refresh: $.proxy(function () {
					new AuthenticationForm({
						id: 'new_authentication',
						modalId: 'authentication_modal',
						mode: 'create'
					});
				}, this)

			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new user menu item
	 * @param {Object} e The click event object
	 */
	onNewUser(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('USER_CREATE')) {
			State.mainApp.navigate(UserRouter.profile('new'), {extra: {mode: 'create'}});
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new team menu item
	 * @param {object} e The click event
	 */
	onNewTeam(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('TEAM_CREATE')) {
			var modalWindow = new ModalWindow({
				title: lang.account.TEAM_CREATE,
				width: 400,
				minHeight: 435,
				height: 'auto',
				position: topLeftCornerPosition,
				url: 'include/Administration/TeamForm.jsp',
				refresh: function () {
					new TeamForm({
						id: null,
						mode: 'create'
					});
				}
			});
			modalWindow.open();
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new service board menu item
	 * @param {Object} e The click event object
	 */
	onNewDashboard(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('SERVICEBOARD_CREATE')) {
			State.mainApp.navigate(DashboardsRouter.create())
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new sla menu item
	 * @param {Object} e The click event object
	 */
	onNewSla(e) {
		e.stopPropagation();
		if (State.mainApp.session.hasRole('SLA_CREATE')) {
			State.mainApp.navigate(SlaRouter.createNew());
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	/**
	 * Handler function for the create new report menu item
	 * @param {Object} e The click event object
	 */
	onNewReport(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		State.mainApp.navigate(ReportsRouter.createNew());
	}
	/**
	 * Handler function for the create new presentation menu item
	 * @param {Object} e The click event object
	 */
	onNewPresentation(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		this.navigate(DashboardsRouter.create());
	}
	/**
	 * Handler function for the create new automation menu item
	 * @param {Object} e The click event object
	 *
	 */

	onNewAutomation(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		this.navigate(AutomationRouter.createNew());
	}

	onNewFilter(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		const url = AssetsRouter.createAssetHealthFilter()
		NavigationStore.go(url)
	}

	onNewRole(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		NavigationStore.go(AdministrationRouter.roleDetails());
	}

	onNewApplication() {
		$('.aside').append('<div id="application_window_container"></div>');
		let onCloseApplicationWindow = () => {
			ReactDOM.unmountComponentAtNode(document.getElementById('application_window_container'));
		};
		ReactDOM.render(<NewApplicationWindow onCancel={onCloseApplicationWindow}/>, document.getElementById('application_window_container'));
		this.closeCreatePanel();
	}

	onNewCost(type) {
		$('.aside').append('<div id="cost_window_container"></div>');
		let onCloseCostWindow = () => {
			ReactDOM.unmountComponentAtNode(document.getElementById('cost_window_container'));
		};
		ReactDOM.render(
			<NewBudgetWindow onCancel={onCloseCostWindow} modelType={type}/>,
			document.getElementById('cost_window_container')
		);
		this.closeCreatePanel();
	}

	onNewCostModel() {
		this.onNewCost('MANUAL');
	}

	onNewCostStore() {
		this.onNewCost('CLOUD');
	}

	onNewCostGateway() {
		NavigationStore.go(CostsRouter.gatewayCreate());
	}

	onNewIncident() {
		State.mainApp.navigate(IncidentsRouter.createNew('GENERIC'), {
			extra: {
				accountList: [{
					id: ApplicationState.AccountId,
					name: ApplicationState.AccountName
				}]
			}
		});
	}

	/**
	 * Handler function for the create new service menu item
	 * @param {Object} e The click event object
	 */
	onNewService(e) {
		if(e) {
			e.stopPropagation();
		}
		if (State.mainApp.session.hasRole('SERVICE_CREATE')) {
			var win = new NewServiceWindow({});
		} else {
			Utils.showInfo(lang.INFO, lang.messages.NO_RIGHTS);
		}
		this.closeCreatePanel();
	}
	onNewCalendar(e) {
		e.stopPropagation();
		this.closeCreatePanel();
		let modalWindow = new ModalWindow({
			title: lang.account.CALENDAR_CREATE,
			width: 400,
			resizable: false,
			height: 440,
			position: topLeftCornerPosition,
			url: 'include/Administration/CalendarForm.jsp',
			refresh: function () {
				new CalendarForm({
					id: null,
					mode: 'create'
				});
			}
		});
		modalWindow.open();
		e.stopPropagation();
	}
	/**
	 * Handler function for Multi Content Menu
	 * @param {Object} e The click event object
	 */
	onToggleMenu(e) {
		e.stopPropagation();
		this.closePopups(e);
		$('.cw_multi_options').toggleClass('is_open');
	}
	/**
	 * Handler function for Toggle Shortcuts menu
	 */
	onToggleShortcuts() {
		$('#nav_shortcuts ul.cw_nav_tier2').toggleClass('hide');
	}
	/**
	 * Closes all floating panels
	 * @param {object} e The click event
	 */
	closePopups(e) {
		var target = e.target ? $(e.target) : $(e.sender.element), secondaryNav = $('.cw_nav_secondary.extended'),
			openControls = $('body').find('.is_open');

		if (!$(document).has(target).length || target.closest('.is_open').length) {
			return;
		}

		$('.cw_userinfo .cw_item.is_selected, .cw_actions_trigger.is_selected, .cw_view_button.is_selected').removeClass('is_selected');
		openControls.removeClass('is_open');

		if (secondaryNav.length && !secondaryNav.is(':visible') && !$('.cw_multi_content').hasClass('is_open')) {
			secondaryNav.show().animate({left: '180px'}, 250);
		}

		this.tempUserTagsWindow.clickedElement = target;
		this.tempUserTagsWindow.close();
	}

	closeCreatePanel() {
		let panel = $('.cw_multi_options');
		panel.removeClass('is_open');
	}

	/**
	 * Verifies if a service model still exists. TODO: check if needed anymore
	 * @param {Object} serviceModelId The service model id
	 */
	serviceModelExists(serviceModelId) {
		for (let i = 0; i < this.serviceModels.length; i++) {
			if (this.serviceModels[i].id === serviceModelId) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Gets the notifications counters and unread notifications
	 */
	getStatusesCount = () => {
		if(ApplicationState.standalone)
			return

		var lastChanges = State.lastChangesTimestamp || '';
		if (this.session.hasRole('SUMMARY_LIST')) {
			var url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/summary/stateCounters?includeSubaccounts=' + (State.includeSubaccounts ? 'true' : 'false') + '&lastChanges=' + lastChanges;
			Utils.ajax(url, 'GET', '', $.proxy(function (result) {
				if (result.success) {
					this.updateStatuses(result.data);
				} else {
					Utils.showInfo(lang.ALERT, result.message, result.details);
				}
			}, this));
		} else {
			//console.log('np SUMMARY_LIST role', this.session);
		}
	}
	updateStatuses(statuses) {
		$('.cw_statuses').empty();
		if (this.session.hasRole('SERVICE_LIST')) {
			this.serviceIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'service',
				items: {
					down: statuses.serviceCounters.down,
					error: statuses.serviceCounters.warning,
					inactive: statuses.serviceCounters.inactive
				}
			});
		}
		if (this.session.hasRole('ASSET_LIST')) {
			this.healthIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'health',
				items: {
					critical: statuses.healthCounters.critical,
					major: statuses.healthCounters.major,
					minor: statuses.healthCounters.minor,
					maintenance: statuses.healthCounters.maintenance
				}
			});
		}
		this.applicationIndicators = new ModuleStatusIndicator({
			renderTo: $('.cw_statuses'),
			type: 'application',
			items: {
				critical: statuses.applicationCounters.critical,
				major: statuses.applicationCounters.major,
				minor: statuses.applicationCounters.minor,
				maintenance: statuses.applicationCounters.maintenance
			}
		});
		if (this.session.hasRole('SLA_LIST')) {
			this.slaIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'sla',
				items: {
					breached: statuses.slaCounters.breaches,
					warning: statuses.healthCounters.warnings
				}
			});
		}
		if (this.session.hasRole('AGENT_LIST')) {
			this.agentIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'agent',
				items: {
					down: statuses.agentCounters.agentsDown,
					warning: statuses.agentCounters.agentsWarning,
					maintenance: statuses.agentCounters.agentsMaintenance
				}
			});
		}
		if (this.session.hasRole('SERVER_MENU')) {
			this.serverIndicators = new ModuleStatusIndicator({
				renderTo: $('.cw_statuses'),
				type: 'server',
				items: {
					down: statuses.serverCounters.agentsDown,
					warning: statuses.serverCounters.agentsWarning,
					maintenance: statuses.serverCounters.agentsMaintenance
				}
			});
		}

		this.eventStateIndicators = new ModuleStatusIndicator({
			renderTo: $('.cw_statuses'),
			type: 'event',
			items: {
				critical: statuses.eventCounters?.critical,
				major: statuses.eventCounters?.major,
				minor: statuses.eventCounters?.minor,
				maintenance: statuses.eventCounters?.maintenance
			}
		});
		ApplicationState.ignoreTags = statuses.ignoreTags;
		ApplicationState.notificationCounters = statuses;
	}
	/**
	 * Gets the notifications count
	 */
	getUnreadNotifications() {
		var messageCount = 3;
		var url = Settings.serverPath + 'notifications/messages/last' + messageCount + 'Unread';
		Utils.ajax(url, 'GET', '', $.proxy(function (result) {
			var notificationsList = $('.cw_user_notifications');
			var notificationCode, notification;
			notificationsList.empty();
			notificationsList.append('<li class="cw_item cw_notifications_header">' + i('Notifications') + ' </li>');
			var notificationsHeader = $('.cw_notifications_header');
			if (result.length) {
				notificationsHeader.append('<button id="cw_clear_notifications" class="k-button right">' + lang.CLEAR + '</button>');
				for (let i = 0; i < result.length; i++) {
					notification = result[i];
					notificationCode = '<li data-uid="' + notification.notificationId + '" class="cw_item cw_notification">';
					notificationCode += '<h2>' + notification.fromUserName + '</h2>';
					notificationCode += '<div class="cw_message_excerpt">';
					notificationCode += '<p class="ellipsis">' + notification.message + '</p>';
					notificationCode += '<div class="cw_field"><time class="cw_notification_date" title="' + Renderer.browserDateRenderer(notification.time, Constants.DATETIME) + '">' + Renderer.messageLocalDate(notification.utcTime, notification.time) + '</time>';
					notificationCode += '<span class="cw_mark_as_read"><span class="glyphicons check go_right" title="' + lang.MARK_READ + '"></span></span></div>';
					notificationCode += '</div></li>';
					notificationsList.append(notificationCode);
				}
			} else {
				notificationsList.append('<li class="cw_item">' + lang.mscenter.messages.NO_MESSAGES + '</li>');
			}
			notificationsList.append('<li class="cw_item cw_message_center">' + lang.mscenter.messages.MESSAGE_CENTER_GO + '</li>');

			$('.cw_notification').off();
			$('.cw_notification').on('click', $.proxy(this.onNotificationView, this));
			$('.cw_message_center').off();
			$('.cw_message_center').on('click', $.proxy(this.openNotifications, this));
			$('#cw_clear_notifications').off();
			$('#cw_clear_notifications').on('click', $.proxy(this.clearNotifications, this));
			$('.cw_mark_as_read').off();
			$('.cw_mark_as_read').on('click', $.proxy(this.markAsReadNotification, this));
			$('.cw_notifications').addClass('is_selected');
		}, this));
	}
	/**
	 * Handler function for the click event on the View Message button
	 * @param {Object} e The click event object
	 */
	onNotificationView(e) {
		var notificationId = $(e.currentTarget).attr('data-uid');
		if (!$(e.target).hasClass('cw_mark_as_read') && !$(e.target).parents('.cw_mark_as_read').length) {
			$('.cw_notifications').toggleClass('is_selected');
			NavigationStore.go(ViewsRouter.messageCenter())
		}
	}
	/**
	 * Handler function for the click event on the Go To Message Center button
	 * @param {Object} e The click event object
	 */
	openNotifications(e) {
		e.stopPropagation();
		NavigationStore.go(ViewsRouter.messageCenter())
		$('.cw_user_notifications').removeClass('is_open');
		$('.cw_userinfo').find('.cw_notifications').removeClass('is_selected');
	}
	/**
	 * Handler function for the click event on the Clear button (notifications)
	 * @param {Object} e The click event object
	 */
	clearNotifications(e) {
		var notificationIds = $(e.currentTarget).parent().parent().children('[data-uid]');
		var notificationsList = [];
		for (let i = 0, length = notificationIds.length; i < length; i++) {
			notificationsList.push($(notificationIds[i]).attr('data-uid'));
		}
		var url = Settings.serverPath + 'notifications/messages/markRead';
		Utils.ajax(url, 'POST', JSON.stringify(notificationsList), $.proxy(function (result) {
			if (result.success) {
				$(e.currentTarget).parent().parent().children('[data-uid]').slideUp(350);
				$(e.currentTarget).remove();
				this.getStatusesCount();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}
	/**
	 * Handler function for the click event on the Mark As Read button
	 * (notifications)
	 * @param {Object} e The click event object
	 */
	markAsReadNotification(e) {
		var notificationId = $(e.currentTarget).parent().parent().parent().attr('data-uid');
		var url = Settings.serverPath + 'notifications/messages/markRead';
		e.preventDefault();
		Utils.ajax(url, 'POST', JSON.stringify([notificationId]), $.proxy(function (result) {
			if (result.success) {
				$(e.currentTarget).parent().parent().parent().slideUp(350);
				this.getStatusesCount();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}

	/**
	 * Called when user menu is clicked
	 * @param {Object} e The click event
	 */

	onCurrentUser(e) {
		var wasOpen = $('.cw_current_user').hasClass('is_selected');
		// hide other open elements
		this.closePopups(e);
		// need wait to close popup code will be finished
		setTimeout(() => {
			if (!wasOpen) {
				$('.cw_current_user').addClass('is_selected');
				$('.cw_user_actions').addClass('is_open');
			} else {
				$('.cw_current_user').removeClass('is_selected');
				$('.cw_user_actions').removeClass('is_open');
			}
		})
	}
	/**
	 * Called when the notifications menu is clicked
	 * @param {Object} e The click event
	 */
	onNotificationsMenu(e) {
		e.stopPropagation();
		var target = $(e.target);
		var currentTarget = $(e.currentTarget);
		// hide other open elements
		this.closePopups(e);

		if (target[0] === currentTarget[0] || target.parent('.cw_notifications').length || target.is('.glyphicons.envelope i') || target.is('.cw_message_center')) {
			this.getUnreadNotifications();
			$('.cw_notifications').toggleClass('is_selected');
		}
		$('.cw_user_notifications').addClass('is_open');
		$(document).on('click', this.closeNotificationsMenu);
	}

	closeNotificationsMenu = (e) => {
		if (!$(e.target).closest('.cw_user_notifications').length) {
			$(document).off('click', this.closeNotificationsMenu);
			$('.cw_user_notifications').removeClass('is_open');
			$('.cw_notifications').toggleClass('is_selected');
		}
	}

	/**
	 * Called when one or multiple events are received from the server
	 * @param {Array} events The event data
	 */
	handleEvents(events) {
		//console.log('Multiple Events:', events);
		var refreshStatuses = false, refreshNotifications = false;
		if (State.currentApp && State.currentApp.handleEvents) {
			State.currentApp.handleEvents(events);
		}

		if(ApplicationState.standalone)
			return

		for (let i = 0, length = events.length; i < length; i++) {
			if (events[i].eventType === 'AccountSummary' || events[i].eventType === 'AgentState' || events[i].eventType == 'EventSummaryCounter') {
				refreshStatuses = true;
			} else if (events[i].eventType === 'Notification') {
				refreshNotifications = true;
			}
		}
		if (refreshStatuses || refreshNotifications) {
			this.getStatusesCount();
		}
		if (refreshNotifications) {
			var messageGrid = $('.cw_messages_list').data('kendoCustomGrid');
			if (messageGrid) {
				messageGrid.dataSource.read();
				messageGrid.refresh();
			}
		}
	}
	/*
	* Called when one config event is received from the server
	* @param {Object} event The event data
	* */
	handleConfigEvents(properties) {
		if (properties && properties.logoutPage) {
			var timeout = Settings.COOKIE_TIMEOUT;
			Cookies.create("CeesoftLogoutPage", properties.logoutPage, timeout);
		}
		Utils.apply(Settings, properties);
	}
	/**
	 * Called after the user roles were obtained from server
	 * @param {Object} roles The user roles object
	 */
	onRolesRetrieve() {
		this.updateLangMessages();

		this.contextMenu = new ContextMenu();
		this.contextMenu.setActionButton('ACCOUNT');

		this.loadUserPreferences();
		if (Cookies.CeesoftCurrentAccountId) {
			this.loadAccountDetails();
		}
		//this.monitorsMenu();
	}
	/**
	 * Handler function for setting the user icon
	 */
	setUserIcon() {
		var pictureId = (Cookies.CeesoftUserPictureId != 'null' && Cookies.CeesoftUserPictureId != null) ? Cookies.CeesoftUserPictureId : 'user-rounded';
		var handler = '';
		$('#cw_user_icon').remove();
		if (Utils.isGuid(pictureId)) {
			//use img with src
			var src = Settings.serverPath + 'sessions/' + Cookies.sessionId + '/accounts/' + Cookies.CeesoftAccountId + '/users/' + Cookies.CeesoftUserId + '/picture/' + pictureId;
			handler = '<img alt="' + lang.USER_PROFILE + '" src="' + src + '" id="cw_user_icon" onError="$(this).hide();$(\'.cw_current_user\').append(\'<span class=&quot;glyphicons user&quot;></span>\')" />';
		} else {
			// use span with glyphicon
			handler = '<span id="cw_user_icon" class="glyphicons ' + pictureId + '"></span>';
		}

		$('.cw_current_user').append(handler);
		$('#cw_user_icon').on('click', e => this.onProfileIconClick(e));
	}

	/**
	 * Displays the about dialog
	 */
	showAboutDialog() {
		var loadUrl = Settings.serverPath + 'about/';
		Utils.ajax(loadUrl, 'GET', {}, $.proxy(function (result) {
			if (result.success) {
				var data = result.data;
				let message = '';
				let frontEndData = data['FRONTEND'];
				message += '<div style="margin: 0px 0 15px 5px">';
				message += '<div>' + '<span style="font-weight: bold">' + frontEndData.name + '</span>' + ': ' + frontEndData.version + ' (build ' + frontEndData.buildNumber + ')' + '</div>';
				message += '<div>' + 'Build time' + ': ' + frontEndData.buildDate + '</div>';
				message += '</div>';
				let serverData = data['SERVER'];
				message += '<div style="margin-left: 5px">';
				message += '<div>' + '<span style="font-weight: bold">' + serverData.name + '</span>' + ': ' + serverData.version + ' (build ' + serverData.buildNumber + ')' + '</div>';
				message += '<div>' + 'Build time' + ': ' + serverData.buildDate + '</div>';
				message += '</div>';

				var dialog = new Dialog({
					title: lang.INFO,
					msg: message,
					icon: 'INFO',
					buttons: {
						ok: true
					},
					dialogHeight: 220
				});
				dialog.show();
			} else {
				Utils.showInfo(lang.ALERT, result.message, result.details);
			}
		}, this));
	}
	/*
	 * Sets the health/monitors installed monitors
	 */
	setInstalledMonitors(isMonitorManagement) {
		var url;
		if (isMonitorManagement) {
			url = Settings.managementPath + 'monitors/';
		} else {
			url = Settings.serverPath + 'accounts/' + Cookies.CeesoftCurrentAccountId + '/monitors/types';
		}
		Configuration.installedMonitors = [];
		Configuration.installedMonitorsNames = [];
		Configuration.installedMonitorSettings = [];
		Utils.ajax(url, 'GET', {}, $.proxy(function (result) {
			for (let i = 0; i < result.length; i++) {
				Configuration.installedMonitors.push(result[i].value);
				Configuration.installedMonitorsNames[result[i].value] = result[i].text;
				Configuration.installedMonitorSettings[result[i].value] = result[i];
			}

			ApplicationState.enabledMonitors = [...result]

		}, this));
	}
	/**
	 * Handler for the back button in the menu header
	 * @param {Object} e The click event object
	 */
	onMenuBackClick(e) {
		var target = $(e.currentTarget),
			menuId = target.closest('ul').attr('id');

		if (target.hasClass('back')) {
			this.backToMenu(menuId, false);
		} else {
			this.backToMenu(menuId, true);
		}
	}
	/**
	 * Handler for closing submenu
	 * @param {Object} e The click event object
	 */
	onCloseSubmenu(e) {
		var target = $(e.currentTarget),
			menuId = target.attr('id');
		if ($('.cw_nav_secondary.expanded').attr('data-parent') !== menuId) {
			this.backToMenu(menuId, true);
		}
	}
	/**
	 * Opens a submenu
	 * @param {String} menuId The id os the menu to expand
	 * @param {String} parentItemId The id of the parent menu item
	 * @param {Boolean} [openFirstItem=true] Optional parameter used to configure if the first item of the submenu should be clicked
	 */
	openSubmenu(menuId, parentItemId, openFirstItem) {
		var submenu = $('#' + menuId),
			parentItem = $('#' + parentItemId),
			clickOnFirst = true;
		if (openFirstItem === false) {
			clickOnFirst = false;
		}
		submenu.addClass('expanded');
		if (clickOnFirst) {
			submenu.find('.cw_item').first()[0].click();
		}
		parentItem.attr('data-state', 'open');
	}
	/**
	 * Closes a submenu or all submenus
	 * @param {String} menuId The id os the menu to close
	 * @param {Boolean} backToMainMenu
	 */
	backToMenu(menuId, backToMainMenu) {
		var menu, parentItem;
		if (menuId) {
			menu = $('#' + menuId);
			parentItem = $('#' + menu.data('parent'));
			if (backToMainMenu) {
				$('.cw_nav_secondary').removeClass('expanded');
				$('.cw_item[data-state=open]').data('state', 'closed');
				$('.cw_item[data-state=open]').attr('data-state', 'closed');
			} else {
				menu.removeClass('expanded');
				parentItem.data('state', 'closed');
				parentItem.attr('data-state', 'closed');
			}
		}
	}
	/**
	 * Starts the events sending from server
	 */

	addLoadingMask(type = "mask", bounceTime = 0){
		if(this.setLoadingMaskTimeout){
			clearTimeout(this.setLoadingMaskTimeout)
		}

		if(bounceTime > 0) {
			this.setLoadingMaskTimeout = setTimeout(() => {
				document.getElementById("content_area")
					.classList.add(type);
			}, bounceTime);
		}else{
			document.getElementById("content_area")
				.classList.add(type);
		}

		if(this.loadingMaskTimeout){
			clearTimeout(this.loadingMaskTimeout);
		}

		this.maskRemoved = false;
		this.loadingMaskTimeout = setTimeout(() => {
			if (!this.maskRemoved) {
				this.removeLoadingMask();
			}
		}, 10000)
	}

	removeLoadingMask = () => {
		if(this.setLoadingMaskTimeout){
			clearTimeout(this.setLoadingMaskTimeout)
		}

		$('.cw_white_mask').remove();
		$('.cw_content_mask').remove();
		$('#main_loading_mask').remove();
		$('.k-loading-mask').remove();

		setTimeout(function() {
			$("#content_area")
				.removeClass("mask")
				.removeClass("busy")
				.removeClass("mask_hide");
		},300);

		$('#content_area').addClass('mask_hide')

		this.maskRemoved = true;
	}

	/**
	 * Subscribes to server Notification events
	 */
	subscribe() {
		if (ApplicationState.standalone) {
			return
		}

		if (typeof State.includeSubaccounts === 'undefined') {
			State.includeSubaccounts = true;
		}
		if (this.session.hasRole('ACCOUNT_LIST')) {
			var subscriptionObj = [{
				eventType: 'AccountSummary',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			}, {
				eventType: 'Notification',
				userId: Cookies.CeesoftUserId,
				notificationType: 'MESSAGE'
			}];
			if (this.session.hasRole('SYSTEM_ERROR_LIST')) {
				subscriptionObj.push({
					eventType: 'Notification',
					notificationType: 'SYSTEM_ERROR',
					includeSubaccounts: State.includeSubaccounts
				});
			}
			if (this.session.hasRole('SERVER_MENU')) {
				subscriptionObj.push({
					eventType: 'AgentState',
					serverAgent: true
				});
			}
			/*
			* This is used for accountPicker
			* */
			subscriptionObj.push({
				eventType: 'Administration',
				entityType: 'ACCOUNT'
			});

			subscriptionObj.push({
				eventType: 'EventSummaryCounter',
				accountId: Cookies.CeesoftCurrentAccountId,
				includeSubaccounts: State.includeSubaccounts
			});
			RemoteEventsManager.subscribe(this.subscriberId, subscriptionObj);
		}
	}

	_onNavigateCallbacks = [];
	registerForOnNavigate(callback){
		this._onNavigateCallbacks.push(callback);
	}

	executeOnNavigateCallbacks(){
		this._onNavigateCallbacks.forEach(x => x());
		this._onNavigateCallbacks = [];
	}

	markDirty(dirty = true){
		this._genericDirtyCheck.markDirty(dirty);
	}
	/**
	 * Destroy
	 */
	destroy() {
		RemoteEventsManager.unsubscribe(this.subscriberId);
		RemoteEventsManager.closeConnection();
		this.saveUserPreferences();
	}

	reloadCurrentModule(){
		if(this._runtime.moduleDescription){
			this.setMainModule(this._runtime.moduleDescription,
				this._runtime.configuration);
		}else {
			this.loadModule(this._runtime.configuration);
		}
	}

	onProfileIconClick(e) {
		e.stopPropagation();
		const userId = Cookies.CeesoftUserId;
		State.mainApp.navigate(UserRouter.profile(userId), {
			navigationAction: NavigationAction.PopOne
		});
	}
}

export default MainApplication;
