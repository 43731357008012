import {makeAutoObservable} from "mobx"
import {observer} from "mobx-react"
import React from "react"

import {Section} from "controls/react/layout/section"
import {AccountSelector} from "controls/react/accountSelector"
import {MobxManager, SingleToArrayConverter} from "framework/mobx-integration"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {designerLocalizations} from "areas/dashboards/graph-editor-extensions/designerLocalizations"
import {TimePeriod, TimePeriodSelector} from "controls/react/form/timePeriodSelector"
import {AntSelect} from "controls/react/ant/antSelect"
import {DataListLoader} from "controls/designer/features/redirectOnClick/dataListLoader"
import {getCostTypeDataList} from "areas/cost/models/costValueType"
import {CostProfile} from "areas/cost/models/costProfile"
import {
	CostCurrency,
	CostResource,
	listCostModels,
	listCostProfiles,
	listCostResources,
	listCurrencies
} from "areas/cost/api";
import {CostModel} from "areas/cost/models/costModel";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {ShowAsLabel} from "controls/designer/dataSourcesManager/sharedProperties";
import {getFirstEntry} from "tools/helpers/array";
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource";
import {Designer} from "controls/designer/designer";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {CostDataSourceElement} from "controls/designer/dataSourcesManager/costDataSourceElement";
import {getShowCurrencyDataList, ShowCurrencyMode} from "controls/designer/dataSourcesManager/costShared";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "framework/applicationState";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {useStore} from "core/react/useStore";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {MetricValueAsSeverity} from "controls/designer/dataSourcesManager/metricValueAsSeverity";

const i18n = require('core/localization/localization').translator({
	"Cost Model": {
		"no": "Kostnad Modell"
	},
	"Cost Model Item": {
		"no": "Kostnad Modell Sak"
	},
	"Calculation": {
		"no": "Kalkulering"
	},
	"Budget": {
		"no": "Budsjett"
	},
	"Current Month": {
		"no": "Nåværende måned",
	},
	"cost-display-label-tooltip": {
		"en": "Show the name of the Cost Item",
		"no": "Vis navnet til kostelementet"
	},
	"Display label": {
		"no": "Visningsnavn",
	},
	"Display": {
		"no": "Vise"
	},
	"Show Currency": {
		"no": "Vis Valuta",
	},
	'Value as severity': {
		no: 'Verdi som alvorlighet'
	}
}, designerLocalizations);


const b = require('b_').with('cost-data-source-editor');

export class CostDataSourceEditorStore {
	costProfileDataList: DataListLoader<CostProfile> // 'Cost Model'
	costModelDataList: DataListLoader<CostModel> // 'Cost Sheet'
	costProfileItemDataList: DataListLoader<CostResource> // 'Cost Model Item'
	currenciesDataList: DataListLoader<CostCurrency>
	mobx = new MobxManager()

	dataSource: CellCostDataSource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellCostDataSource

		this.costProfileDataList = new DataListLoader<CostProfile>(
			() => {
				if(!this.dataSource.accounts?.length)
					return null
				return listCostProfiles(getFirstEntry(this.dataSource.accounts))
			},
			{
				beforeLoad: () => {
					this.dataSource.costProfileId = null
				}
			})

		this.costModelDataList = new DataListLoader<CostModel>(
			() => this.dataSource.costProfileId
				? listCostModels(this.dataSource.costProfileId, getFirstEntry(this.dataSource.accounts))
				: null,
			{
				beforeLoad: () => {
					this.dataSource.modelId = null
				}
			})

		this.costProfileItemDataList = new DataListLoader<CostResource>(
			() => {
				if(!this.dataSource.modelId)
					return null

				return listCostResources({
					costProfileId: this.dataSource.costProfileId,
					costModelId: this.dataSource.modelId,
					accountId: getFirstEntry(this.dataSource.accounts)
				})
			},
			{
				beforeLoad: () => {
					this.dataSource.costItemId = null
				}
			})

		this.currenciesDataList = new DataListLoader<CostCurrency>(
			() => listCurrencies(getFirstEntry(this.dataSource.accounts)), {
				nameField: "text",
				valueField: "value"
			}
		)

		this.mobx.reaction(x => this.dataSource.metricValueAsSeverity, () => {
			this.dataSource.metricValueToSeverity = this.dataSource.metricValueAsSeverity
				? [
					{threshold: 0, operator: 'lte'},
					{threshold: 0, operator: 'lte'},
					{threshold: 0, operator: 'lte'},
				]
				: undefined;
		})

		this.mobx.reaction(x => this.dataSource.costProfileId, (value: string) => {
			const profile = this.costProfileDataList.dataList.find(x => x.id == value)
			if(profile) {
				this.dataSource.currency = profile.defaultCurrency
			}
		})
	}

	init(){
		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}
	}

	timePeriodsForEstimate = [TimePeriodType.CurrentMonth, TimePeriodType.CurrentYear]
	allTimePeriods = [TimePeriodType.Last24Hours, TimePeriodType.Last7Days, TimePeriodType.Last30Days, TimePeriodType.PreviousYear,
		TimePeriodType.CurrentMonth, TimePeriodType.CurrentYear, TimePeriodType.PreviousMonth, TimePeriodType.Custom]

	get timePeriods(){
		return this.dataSource.valueType == 'ESTIMATE'
			? this.timePeriodsForEstimate
			: this.allTimePeriods
	}

	destroy() {
		this.mobx.destroy()
	}
}

export const CostDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => {
		return new CostDataSourceEditorStore(designerStore)
	}, {
		deps: [designerStore.dataSourcesManager.selected]
	})

	const dataSource = store.dataSource

	const converter = React.useMemo(() => new SingleToArrayConverter(store.dataSource, "accounts"), [store.dataSource])

	return <Section appearance={'none'} childrenPadding={true}>
		<FormEntryNew label={i18n('Account')} model={converter} modelField={"value"}>
			<AccountSelector hidePlaceholder={true}/>
		</FormEntryNew>

		<FormEntryNew label={i18n('Cost Model')} model={dataSource} modelField={"costProfileId"}>
			<AntSelect {...store.costProfileDataList.props}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Cost Sheet')} model={dataSource} modelField={"modelId"}>
			<AntSelect {...store.costModelDataList.props}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Cost Model Item')} model={dataSource} modelField={"costItemId"}>
			<AntSelect {...store.costProfileItemDataList.props}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Cost Type')} model={dataSource} modelField={"valueType"}>
			<AntSelect dataList={getCostTypeDataList()}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Currency')} model={dataSource} modelField={"currency"}>
			<AntSelect {...store.currenciesDataList.props}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Show Currency')} model={dataSource} modelField={"showCurrency"}>
			<AntSelect dataList={getShowCurrencyDataList()}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Time Range')} model={dataSource} modelField={"timePeriod"}>
			<TimePeriodSelector periods={store.timePeriods}/>
		</FormEntryNew>
		<FormEntryNew label={i18n('Decimals')} model={dataSource} modelField={"decimalsNumber"}>
			<AntInputNumber min={0} max={20}/>
		</FormEntryNew>
		<MetricValueAsSeverity dataSource={dataSource} />
		<ShowAsLabel datasource={dataSource}/>
	</Section>
})

export class CellCostDataSource implements CellDataSourceBase {
	id: string
	accounts: string[] = []
	showAsLabel: boolean = false
	type: CellDataSourceType.Cost = CellDataSourceType.Cost
	costProfileId: string
	costItemId: string
	valueType: string
	timePeriod: TimePeriod = {
		period: TimePeriodType.Last7Days
	}
	currency: string
	modelId: string
	showCurrency: ShowCurrencyMode = ShowCurrencyMode.Text
	decimalsNumber: number
	metricValueAsSeverity: boolean = false
	hideMetricValue: boolean = false
	metricValueToSeverity: {
		operator: 'gte'|'lte',
		threshold: number
	}[] = [{
		operator: "gte",
		threshold: null
	},{
		operator: "gte",
		threshold: null
	},{
		operator: "gte",
		threshold: null
	}]

	constructor() {
		makeAutoObservable(this)
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new CostDataSourceElement(designer, cell)
	}

	valid(){
		return this.costProfileId != null
	}
}
