import './antButton.less'

import React from 'react';
import {observer} from 'mobx-react';

import {Flex, FlexProps} from 'antd';

export interface AntFlexProps extends FlexProps{
	width?: number
}

export const AntFlex = observer((props:AntFlexProps) => {
	return <Flex {...props}/>
});
