import React from 'react'
import { observer } from 'mobx-react'
import b_ from 'b_'
import {StarOutlined, StarFilled} from '@ant-design/icons'
import {FavoritesStore} from "./favoritesStore";
import './favoritesButton.less'

const b = b_.with('favorites-button')
const i = require('core/localization').translator({
	'Add to Favorites': {
		no: 'Legg til Favoritter'
	},
	'Remove from Favorites': {
		no: 'Fjern fra Favoritter'
	}
});

export const FavoritesButton = observer(() => {
	if (!FavoritesStore.favoriteIconVisible) {
		return null
	}
	const added = FavoritesStore.currentPageIsFavorite
	const title = added ? i('Remove from Favorites') : i('Add to Favorites')
	return <div className={b({added})} onClick={FavoritesStore.toggleCurrentPage} title={title}>
		{!added && <StarOutlined />}
		{added && <StarFilled />}
	</div>
})
