import './addAssetForm.less';
import React, {Component, useMemo} from 'react';
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import {Section} from "controls/react/layout";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AntInput, AntTextArea} from "controls/react/ant/antInput";
import {AccountsSelector} from "controls/react/accountSelector";
import {AntSelect, AntSelectPropsGeneric} from "controls/react/ant/antSelect";
import {AssetTargetsEditor} from "controls/assetTargetsEditor";
import {TagsSelect} from "controls/react/tagsSelect";
import {useAssetGroups} from "controls/react/assetGroupMultiSelect";
import {addNameValidation, MobxManager, ModelValidator} from "framework/mobx-integration";
import {Asset} from "framework/entities/asset";
import {Api} from "tools";
import {AssetsApi} from "areas/assets/api";

import {getFilterForField} from "controls/react/kendoWrappers/utils";


let b = require('b_').with('add-asset-form');
const i18n = require('core/localization/localization').translator({
	'GENERIC': {
		en: 'Generic',
		no: 'Generisk'
	},
	'WINDOWS': {
		en: 'Windows',
		no: 'Windows'
	},
	'UNIX': {
		en: 'Unix',
		no: 'Unix'
	},
	'System type': {no: 'Systemtype'},
	'Asset target': {no: 'Ressursm\u00e5l'},
	'Assetgroup': {no: 'Assetgroup'},
}, sharedLocalization);

export type AddAssetFormStoreProps = {
	mode: 'view' | 'update' | 'create'
	asset: Asset
}

export class AddAssetFormStore {
	mobx = new MobxManager();
	validator: ModelValidator<Asset>

	constructor(public props: AddAssetFormStoreProps) {

		makeAutoObservable(this);

		this.validator = new ModelValidator(this.props.asset)

		this.validator.required('name');

		addNameValidation(this.props.asset, this.validator, async (name) => {
			let result = await Api.fetchPost(AssetsApi.getDynamicSearchUrl('false'), {
				filter: getFilterForField("name", name?.trim())
			})
			return result.items.length == 0
		})
	}

	destroy = () => {
		this.mobx.destroy();
		this.validator.destroy();
	}
}

export type AddAssetFormProps = {
	store: AddAssetFormStore
}

export const AddAssetForm = observer(class AddAssetFormImpl extends Component<AddAssetFormProps> {
	store: AddAssetFormStore

	constructor(props: AddAssetFormProps) {
		super(props);
		this.store = props.store
	}

	get asset(){
		return this.store.props.asset
	}

	render() {
		return <Section containerClass={b()} appearance={'none'} contentPadding={false} childrenPadding={true}>
			<FormEntryNew label={i18n('Account')} model={this.asset} modelField={'accountId'} disabled={this.store.props.mode == 'view'}>
				<AccountsSelector mode={null} />
			</FormEntryNew>
			<FormEntryNew label={i18n('Name')} model={this.asset} modelField={'name'}
			              disabled={this.store.props.mode == 'view'}
			              validator={this.store.validator}>
				<AntInput />
			</FormEntryNew>
			<FormEntryNew label={i18n('Description')} model={this.asset} modelField={'description'} disabled={this.store.props.mode == 'view'}>
				<AntTextArea autoSize={{minRows: 1, maxRows: 3}}  />
			</FormEntryNew>
			{this.store.props.mode != 'create' && <FormEntryNew label={i18n('Asset type')} model={this.asset} modelField={'assetType'} disabled={this.store.props.mode == 'view'}>
				<AntInput />
			</FormEntryNew>}
			<FormEntryNew label={i18n('System type')} model={this.asset} modelField={'systemType'} disabled={this.store.props.mode == 'view'}>
				<SystemType />
			</FormEntryNew>
			<FormEntryNew label={i18n('Asset target')} model={this.asset} modelField={'targets'} disabled={this.store.props.mode == 'view'}>
				<AssetTargetsEditor targets={this.asset.targets} />
			</FormEntryNew>
			<FormEntryNew label={i18n('Assetgroup')} model={this.asset} modelField={'assetGroups'} disabled={this.store.props.mode == 'view'}>
				<AssetGroupSelect accountId={this.asset.accountId} />
			</FormEntryNew>
			<FormEntryNew label={i18n('Tags')} model={this.asset} modelField={'tags'} disabled={this.store.props.mode == 'view'}>
				<TagsSelect />
			</FormEntryNew>
			{this.store.props.mode != 'create' && <FormEntryNew label={i18n('CVID')} model={this.asset} modelField={'id'}>
				<AntInput disabled={true}/>
			</FormEntryNew>}
		</Section>
	}
})

const SystemType = observer((props: AntSelectPropsGeneric<string>) => {
	const dataSource = useMemo(() => {
		let options = ['GENERIC', 'WINDOWS', 'UNIX'].map(x => ({name: i18n(x), id: x}));
		if (props.value != 'GENERIC') {
			options = options.filter(x => x.id == props.value);
		}
		return options;
	}, []) // props.value not in deps because if we select something not 'GENERIC' options shouldn't be changed
	return <AntSelect placeholder={null} dataList={dataSource} {...props} />
})

const AssetGroupSelect = observer((props: {accountId: string, value?: string[], onChange?: (value: string[]) => void}) => {
	const {accountId, ...others} = props;
	const [allAssetGroups] = useAssetGroups(accountId, 'STATIC');
	return <AntSelect mode={'multiple'} dataList={allAssetGroups} {...others} />
})
