import React from "react";

import {useResources} from "./api";
import {AntSelect} from "controls/react/ant/antSelect";


const i = require('core/localization').translator({
  "Select a resource...": {
    "no": "Velg Ressurs",
    "en": "Select Resource"
  }
});

export const ResourcesDropDown = props => {
	const {accountId, cloudType, monitorId, subscriptions, resourceType, hideTotal, ...others} = props;
	const [cloudServices] = useResources(accountId, cloudType, monitorId, subscriptions, resourceType, hideTotal);

	return (
		<AntSelect placeholder={i('Select a resource...')}
		           dataList={cloudServices}
		           mode="multiple"
		           nameField={"text"}
		           valueField={"value"}
		           disabled={!monitorId ||!cloudType ||!resourceType || !subscriptions || subscriptions.length == 0}
		           {...others}
		/>
	);
}
