import {ApplicationState} from "./applicationState";
import {deserialize, serialize} from "serializr";
import {makeAutoObservable, runInAction} from "mobx";
import UrlBuilder from "tools/urlBuilder";
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition";
import React from "react";

export const serverRoot = '/rest/';

export declare type Constructor<T> = new (...args: any[]) => T;

export class ApiRequest<T> {
	url: string
	payload?: any
	method?: 'POST' | 'GET' | 'PUT' | 'DELETE'

	accountId?: string
	accountBased?: boolean = false

	includeSubaccounts: boolean
	subaccountsFilter?: boolean

	ignoreTags?: boolean

	responseType?: Constructor<T> | 'blob'
	responseTypeArray?: Constructor<any>
	deserializationCallback: (data: T) => T

	constructor(init?: Partial<ApiRequest<T>>) {
		Object.assign(this, init);
	}
}

export type FilterOption = {
	value: string;
	text: string;
}

export type PagedListFilterOptions = Record<string, FilterOption[]>

export type PagedList<T> = {
	filterOptions: PagedListFilterOptions
	items: T[]
	page: number
	total: number
	visible: number
	scrollable: number
}

export type PagedListPayloadSortingEntry = {
	field: string;
	dir: 'desc' | 'asc'
}

export type PagedListFilterEntry = {
	filters: PagedListFilterEntry[]
	logic: "and" | "or"
} | {
	field: string
	operator: string
	value: string
}

export class PagedListPayloadFilter {
	filters: PagedListFilterEntry[] = []
	isCustom: boolean = true
	logic: string = "and"

	constructor() {
		makeAutoObservable(this)
	}
}

export type PagedListPayload = {
	skip: number
	take: number
	sort?: PagedListPayloadSortingEntry[]
	filter?: RuleDefinition
}

export enum HttpStatus{
	Ok = 200,
	Unauthorized = 401,
	Forbidden = 403,
	InternalServerError = 500
}

export class ApiResponse<T> {
	data?: T;
	code?: number = 0;
	status: HttpStatus = HttpStatus.Ok;
	message?: string;
	details?: string[]

	get success(){
		return this.status == HttpStatus.Ok;
	}

	public constructor(init?: Partial<ApiResponse<T>>) {
		Object.assign(this, init);
	}
}

class RequestsCache{
	entitiesMap: Record<string, CachedValue<any>> = {}

	constructor() {
		makeAutoObservable(this)
	}

	getValue<TEntity>(hash: string){
		return this.entitiesMap[hash] as CachedValue<TEntity>
	}

	setValue<TEntity>(hash: string, value: CachedValue<TEntity>){
		this.entitiesMap[hash] = value
	}
}

export class ApiRequestObservable<TResult> extends ApiRequest<TResult>{
	defaultValue: TResult

	constructor(init?: Partial<ApiRequestObservable<TResult>>) {
		super(init)
	}
}

export type CachedValue<T> = {
	data: T
	loaded: boolean
	loading: boolean
}

const cache = new RequestsCache()

export function apiFetchObservable<TResult>(request: ApiRequestObservable<TResult>){
	const hash = getHash(request)

	if (cache.getValue<TResult>(hash) == null) {
		cache.setValue(hash, {
			loaded: false,
			loading: true,
			data: request.defaultValue ?? []
		})
		apiFetch(request).then((r) => {
			runInAction(() => {
				let cachedValue = cache.getValue<TResult>(hash)
				if (r.success) {
					cachedValue.loaded = true
					cachedValue.data = r.data
				} else {
					cachedValue.loaded = false
				}
				cachedValue.loading = false
			})
		})
	}

	return cache.getValue<TResult>(hash)
}

type ApiFetchExtra = {
	init?: RequestInit
}

export async function apiFetch<TResult>(apiRequest: ApiRequest<TResult>, extra?: ApiFetchExtra): Promise<ApiResponse<TResult>> {
	if (!extra) {
		extra = {}
	}

	let request = buildRequest(apiRequest, extra.init)

	let response = await fetch(request);

	if (!response.ok && response.body == null) {
		return new ApiResponse<TResult>({
			status: response.status
		});
	}

	try {
		if (apiRequest.responseType == 'blob') {
			const content = await response.blob()
			return new ApiResponse<TResult>({
				status: HttpStatus.Ok,
				data: content as TResult
			})
		}
	}
	catch{
		return new ApiResponse<TResult>({
			status: HttpStatus.InternalServerError
		});
	}

	let text: string = ''
	try{
		text = await response.text()
		const resultTemp = JSON.parse(text);

		if (resultTemp.success === false || !response.ok) {
			return new ApiResponse<TResult>({
				status: HttpStatus.InternalServerError,
				message: resultTemp.message,
				data: resultTemp.data,
				details: resultTemp.details
			});
		}

		if (resultTemp.success === true) {
			resultTemp.status = HttpStatus.Ok;
			delete resultTemp.success;
		}

		if (resultTemp.data === undefined) { // in case response is just plain data
			return new ApiResponse<TResult>({
				data: resultTemp as TResult,
				status: HttpStatus.Ok
			})
		} else {

			if (apiRequest.responseType != null || apiRequest.deserializationCallback || apiRequest.responseTypeArray) {
				try {
					runInAction(() => {
						if (apiRequest.deserializationCallback) {
							resultTemp.data = apiRequest.deserializationCallback(resultTemp.data);
						} else if (apiRequest.responseType) {
							resultTemp.data = deserialize(apiRequest.responseType as Constructor<TResult>, resultTemp.data);
						} else if (apiRequest.responseTypeArray) {
							//@ts-ignore
							resultTemp.data = resultTemp.data.map(x => deserialize(apiRequest.responseTypeArray, x));
						}
					});
				} catch (e: unknown) {
					let message = e instanceof Error ? e.message : e?.toString()

					console.log(message)

					return new ApiResponse<TResult>({
						status: HttpStatus.InternalServerError,
						message: 'There was an error deserializing response:' + message
					})
				}
			}
			return new ApiResponse<TResult>(resultTemp);
		}
	} catch (e) {
		return new ApiResponse<TResult>({
			status: HttpStatus.InternalServerError,
			message: text,
		});
	}
}


function buildRequest<TResult>(apiRequest: ApiRequest<TResult>, requestInit?: RequestInit) {
	const init = {
		...(requestInit ?? {}),
		credentials: 'include' as const,
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			"Auth-Token": ApplicationState.apiToken
		}
	} as RequestInit;

	if (apiRequest.payload) {
		init.method = 'POST';
		let payload = apiRequest.payload;
		//we are checking if payload is configured for serialization and serialize it automatically
		if (payload.serializeInfo || payload.constructor?.serializeInfo) {
			payload = serialize(payload);
		}
		init.body = JSON.stringify(payload);
	}

	if (apiRequest.method) {
		init.method = apiRequest.method;
	}

	let url = ApplicationState.apiUrl;
	if (apiRequest.url.startsWith('/')) {
		apiRequest.url = apiRequest.url.substring(1);
	}

	if (apiRequest.accountBased) {
		url += 'accounts/' + (apiRequest.accountId ?? ApplicationState.accountId) + '/';
	}

	url += apiRequest.url;

	if(apiRequest.subaccountsFilter == true || apiRequest.includeSubaccounts != null || apiRequest.ignoreTags){
		let builder = new UrlBuilder(url)

		if(apiRequest.subaccountsFilter == true || apiRequest.includeSubaccounts != null) {
			builder.add("includeSubaccounts", apiRequest.includeSubaccounts ?? ApplicationState.includeSubaccounts)
		}

		if(apiRequest.ignoreTags){
			builder.add("ignoreTags", true)
		}

		url = builder.build()
	}

	return new Request(url, init);
}

export type UseRemoteListOptions<TResponseResult, TResult> = {
	makeRequest?: boolean
	converter?: (t: TResponseResult) => TResult
	seed?: string
}

const defaultOptions = {
	makeRequest: true
}

export function useRemoteList<TResponseResult, TResult = TResponseResult>(apiRequest: ApiRequest<TResponseResult[]>
                                                                          , options?: UseRemoteListOptions<TResponseResult, TResult>){
	options = Object.assign({}, defaultOptions, options);

	const [list, setList] = React.useState<TResult[]>([]);
	const [loading, setLoading] = React.useState(options.makeRequest !== false)
	const [loaded, setLoaded] = React.useState(false)

	React.useEffect(() => {
		if (options.makeRequest === false) {
			setLoaded(false)
			setList([])
			return;
		}

		setLoading(true)

		apiFetch(apiRequest).then(r => {
			if (r.success) {
				setLoaded(true)
				setList(options?.converter ? r.data.map(options.converter) : r.data as unknown as TResult[],)
			}
			setLoading(false)
		});

	}, [apiRequest.url, apiRequest.accountId, options.makeRequest, options.seed]);

	return [list, loading, loaded] as const;
}

export function useRemoteListForSelect<TResponseResult, TResult = TResponseResult>(apiRequest: ApiRequest<TResponseResult[]>,
                                                options?: UseRemoteListOptions<TResponseResult, TResult>){
	const result = useRemoteList(apiRequest, options)

	return {
		options: result[0],
		loading: result[1],
		disabled: !result[2]
	}
}

export type UseRemoteListLegacyOptions = {
	directList?: boolean
	makeRequest?: boolean
}

export function useRemoteListLegacy<TResult>(url: string, options?: UseRemoteListLegacyOptions) {
	if (url.startsWith(ApplicationState.apiUrl)) {
		url = url.substring(ApplicationState.apiUrl.length);
	}
	let result = useRemoteList(new ApiRequest<TResult[]>({
		url: url
	}), {
		makeRequest: options?.makeRequest
	})

	return result[0]
}

function getHash<TEntity>(request: ApiRequestObservable<TEntity>) {
	let result = request.url

	result += request.method
	result += request.accountId
	result += request.accountBased
	result += request.includeSubaccounts
	result += request.subaccountsFilter

	return result
}

export function copyApiRequest<T>(request: ApiRequest<T>){
	let requestCopy = JSON.parse(JSON.stringify(request)) as ApiRequest<T>
	requestCopy.responseTypeArray = request.responseTypeArray
	requestCopy.responseType = request.responseType
	requestCopy.deserializationCallback = request.deserializationCallback

	return requestCopy
}

