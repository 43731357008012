import React from 'react'
import {observer} from "mobx-react"

import {AntSelect, AntSelectPropsGeneric} from "controls/react/ant/antSelect"
import {getAccountsFirstLevelOnly, getAccountsLite, useAccountsLite} from "areas/administration/accounts/api"
import {useRemoteList} from "framework/api";

const i = require('core/localization').translator({
	'Select an Account...': {
		no: 'Du må legge til en konto...'
	}
});

export type AccountSelectorProps = AntSelectPropsGeneric<string> & {
	accountId?: string
	hidePlaceholder?: boolean
}

export const AccountSelector = observer((props: AccountSelectorProps) => {
	const [accounts] = useAccountsLite(props.accountId)

	return <AntSelect dataList={accounts}
	                  placeholder={props.hidePlaceholder == true ? null : i('Select an Account...')}
	                  {...props}/>
})

export type AccountsSelectorProps = AntSelectPropsGeneric<string[]> & {
	accountId?: string
	hidePlaceholder?: boolean
}

export const AccountsSelector = observer((props: AccountsSelectorProps) => {
	const {hidePlaceholder, mode, ...rest} = props
	const [accounts, loading, loaded] = useRemoteList(getAccountsFirstLevelOnly({accountId: props.accountId}))
	const innerMode = mode === undefined ? 'multiple' : mode;

	return <AntSelect mode={innerMode}
	                  placeholder={hidePlaceholder == true ? null : i('Select an Account...')}
	                  dataList={accounts}
	                  loading={loading}
	                  disabled={!loaded}
	                  {...rest}/>
})
