import './apiDocumentation.less'

import React from 'react'
import {makeAutoObservable, runInAction} from "mobx"
import {observer} from "mobx-react"

import {Section} from "controls/react/layout/section"
import {Window} from "controls/react/kendoWrappers/window"
import {newGuid} from "tools/guid"
import {AntCollapse, AntCollapsePanel} from "controls/react/ant/antCollapse"
import {copyViaSerializr, createModelSchemaWrapper} from "framework/serializr-integration";
import {list, object, primitive} from "serializr";
import {AntButton} from "controls/react/ant/antButton";
import {DownOutlined, RightOutlined} from "@ant-design/icons";
import {Toolbar} from "controls/react/layout/toolbar";
import {apiFetch, ApiRequest} from "framework/api";
import {ToolbarMargin} from "controls/react/layout/toolbar";
import {AntInput} from "controls/react/ant/antInput";
import {linkModel} from "framework/mobx-integration";

const i18n = require('core/localization').translator({
	'Api Documentation': {},
	'There are no parameters': {},
	'Server only': {}
});

const b = require('b_').with('api-documentation')

export type ApiDocumentationProps = {
	getApiRequest: ApiRequest<ApiDocumentationSection[]>
}

export const ApiDocumentation = observer((props: ApiDocumentationProps) => {
	const storeRef = React.useRef<ApiDocumentationStore>()
	const [, setA] = React.useState<string>()

	React.useEffect(() => {
		if (storeRef.current == null) {
			storeRef.current = new ApiDocumentationStore(props)
			setA(newGuid())
		}
	}, [])

	const store = storeRef.current
	if (store == null)
		return null

	return <Section appearance={"none"} width={"full"} showOverlay={store.loading}>
		<Toolbar>
			<AntInput bounced={500} placeholder={i18n('Search...')}
			          {...linkModel(store, 'searchText' )}/>
		</Toolbar>
		{store.loaded && store.sectionsEffective?.map(x => <ApiDocumentationMethodsSectionView key={x.id} store={store} section={x}/>)}
	</Section>
})

export type ApiDocumentationMethodsListProps = {
	store: ApiDocumentationStore
	section: ApiDocumentationSection
}

export const ApiDocumentationMethodsSectionView = observer((props: ApiDocumentationMethodsListProps) => {
	return <Section>
		<Section appearance={"frame-top-only"} title={props.section.title}></Section>
		<Section appearance={'none'} contentPadding={true}>{props.section.description}</Section>
		{Object.entries(props.section.methodsWithOverloads).map(([name, value]) =>
			<ApiDocumentationMethodView key={name} store={props.store} methods={value}/>
		)}
	</Section>
})

export type ApiDocumentationMethodViewProps = {
	store: ApiDocumentationStore
	methods: ApiDocumentationMethod[]
}

export const ApiDocumentationMethodView = observer((props: ApiDocumentationMethodViewProps) => {
	const header = <div className={b('method-header')}>
		<div className={b('method-header-name')}>{props.methods[0].name}: {props.methods[0].returnType}</div>
	</div>

	return <AntCollapse size={"small"}>
		<AntCollapsePanel key={newGuid()} header={header}>
			{props.methods.map(x=>
				<ApiDocumentationMethodOverloadView key={x.id} overload={x} expanded={props.methods.length == 1}/>
			)}
		</AntCollapsePanel>
	</AntCollapse>
})

export type ApiDocumentationMethodOverloadViewProps = {
	overload: ApiDocumentationMethod
	expanded: boolean
}

export const ApiDocumentationMethodOverloadView = observer((props: ApiDocumentationMethodOverloadViewProps) => {
	const {overload} = props
	const [expanded, setExpanded] = React.useState(props.expanded)

	if(props.expanded && overload.parameters.length == 0){
		return <div>{i18n('There are no parameters')}</div>
	}

	let title = "(" + (overload.parameters.map(p => p.type).join(", ") || "void") + ")"

	return <Section appearance={"frame-top-only"}
	                contentHidden={!expanded}>
		<Toolbar title={title} margin={ToolbarMargin.Bottom}>
			<AntButton icon={expanded ? <DownOutlined/> : <RightOutlined/>}
			           size={"small"}
			           onClick={() => setExpanded(!expanded)}
			           position={"before-title"} title={expanded ? i18n('Collapse') : i18n('Expand')}/>
		</Toolbar>
		<Section appearance="none" direction="row" contentPadding={true}>
			<label className={b('description-label')}>{i18n('Description')}</label>
			<span>{overload.description}</span>
		</Section>
		<table>
			<thead>
				<tr>
					<th className={b('method-parameter-name', {header: true})}>{i18n('Name')}</th>
					<th className={b('method-parameter-type', {header: true})}>{i18n('Type')}</th>
					<th className={b('method-parameter-description', {header: true})}>{i18n('Description')}</th>
					<th className={b('method-parameter-data', {header: true})}>{i18n('Data')}</th>
				</tr>
			</thead>
			<tbody>
				{overload.parameters.map(p => <tr key={p.id}>
					<td className={b('method-parameter-name')}>{p.name}</td>
					<td className={b('method-parameter-type')}>{p.type}</td>
					<td className={b('method-parameter-description')}>{p.description}</td>
					<td className={b('method-parameter-data')}>{p.data?.replaceAll(",", ", ")}</td>
				</tr>)}
			</tbody>
		</table>
	</Section>
})

export type ApiDocumentationWindowProps = ApiDocumentationProps & {
	onClose?: () => void
	visible?: boolean
}
export const ApiDocumentationWindow = observer((props: ApiDocumentationWindowProps) => {
	const {onClose, ...rest} = props

	return <Window width={1000}
	               height={700} flexContent={true}
	               title={i18n('Api Documentation')}
	               visible={props.visible}
	               onClose={onClose}>
		<ApiDocumentation {...props}/>
	</Window>
})

export class ApiDocumentationMethodParameter {
	id: string
	data: string
	type: string
	name: string
	description: string

	constructor() {
		makeAutoObservable(this)
	}
}

createModelSchemaWrapper(ApiDocumentationMethodParameter, {
	id: primitive(),
	name: primitive(),
	data: primitive(),
	type: primitive(),
	description: primitive()
})

export class ApiDocumentationMethod {
	id: string
	name: string
	description: string
	returnType: string
	parameters: ApiDocumentationMethodParameter[]

	constructor() {
		makeAutoObservable(this)
	}

	get searchString(){
		let result = (this.description ?? '').toUpperCase() + (this.name ?? '').toUpperCase()

		if(this.parameters.length > 0){
			result += this.parameters.map(p => (p.name?.toUpperCase() ?? '') + (p.description?.toUpperCase() ?? '') + (p.data?.toUpperCase() ?? '') ).join()
		}

		return result
	}
}

createModelSchemaWrapper(ApiDocumentationMethod, {
	id: primitive(),
	name: primitive(),
	description: primitive(),
	returnType: primitive(),
	parameters: list(object(ApiDocumentationMethodParameter))
})

export class ApiDocumentationSection{
	id: string
	name: string
	description: string
	serverOnly: boolean
	valid: boolean
	visible: boolean
	methods: ApiDocumentationMethod[]

	constructor() {
		makeAutoObservable(this)

		this.id = newGuid()
	}

	get title() {
		let title = this.name;
		if (this.serverOnly) {
			title += ' (' + i18n('Server only') + ')';
		}
		return title;
	}

	get methodsWithOverloads(){
		let result: Record<string, ApiDocumentationMethod[]> = {}
		for(const method of this.methods){
			if(result[method.name] == null){
				result[method.name] = []
			}

			result[method.name].push(method)
		}
		return result
	}
}

createModelSchemaWrapper(ApiDocumentationSection, {
	id: primitive(),
	name: primitive(),
	description: primitive(),
	serverOnly: primitive(),
	valid: primitive(),
	visible: primitive(),
	methods: list(object(ApiDocumentationMethod))
})

export class ApiDocumentationStore {
	sections: ApiDocumentationSection[]
	loading: boolean = false
	loaded: boolean = false
	searchText: string

	constructor(public props: ApiDocumentationProps) {
		makeAutoObservable(this)

		this.load()
	}

	get sectionsEffective() {
		if (!this.searchText)
			return this.sections

		const searchPattern = this.searchText.toUpperCase()

		return this.sections.map(s => {
			let methods = s.methods.filter(m => m.searchString.indexOf(searchPattern) != -1)

			if (methods.length > 0) {
				let result = copyViaSerializr(s)
				result.methods = methods
				return result
			}

			return null
		}).filter(s => s != null)
	}

	async load() {
		this.loading = true

		let result = await apiFetch(this.props.getApiRequest)

		runInAction(() => {
			if (result.success) {
				this.sections = result.data;

				this.sections.forEach(x => {
					x.id = newGuid() //we need unique ids as react keys
					x.methods.forEach(m => {
						m.id = newGuid()
						m.parameters.forEach(p => p.id = newGuid())
					})
				})
				this.loaded = true
			}
			this.loading = false
		})
	}
}
