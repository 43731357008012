import {DataList} from "framework/entities/dataList";

const i = require('core/localization').translator({
	"Scrollable": {
		"no": "Skroll"
	},
	"Scale": {
		"no": "Skaler"
	},
	"Responsive": {
		"no": "Responsiv"
	},
});

export enum LayoutType {
	Scroll = 'scroll',
	Adaptive = 'adaptive',
	Scale = 'scale',
}

let layoutTypeDataSource: DataList<string>
export function getLayoutTypeDataSource() {
	if(layoutTypeDataSource == null ) {
		layoutTypeDataSource = [{
			id: LayoutType.Scroll,
			name: i('Scrollable')
		}, {
			id: LayoutType.Scale,
			name: i('Scale')
		}, {
			id: LayoutType.Adaptive,
			name: i('Responsive')
		}];
	}
	return layoutTypeDataSource
}
