import {makeAutoObservable} from "mobx"
import {observer} from "mobx-react"
import React from "react"

import {Section} from "controls/react/layout/section"
import {AccountsSelector} from "controls/react/accountSelector"
import {AntSelect} from "controls/react/ant/antSelect"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {ShowAsLabel} from "controls/designer/dataSourcesManager/sharedProperties"
import {UnorderedListOutlined} from "@ant-design/icons"
import {AntButton} from "controls/react/ant/antButton"
import {searchSlasLite, SlaEntry} from "areas/sla/api"
import {DataListLoader} from "controls/designer/features/redirectOnClick/dataListLoader"
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource"
import {Designer} from "controls/designer/designer"
import {MxCell} from "controls/designer/mxGraphInterfaces"
import {SlaDataSourceElement} from "controls/designer/dataSourcesManager/slaDataSourceElement"
import {showDataSourceWindow} from "controls/designer/dataSourcesManager/dataSourceWindow";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "framework/applicationState";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {useStore} from "core/react/useStore";

const i18n = require('core/localization/localization').translator({
	'Select a SLA...': {}
});

export class CellSlaDataSource implements CellDataSourceBase{
	id: string
	accounts: string[] = []
	showAsLabel: boolean
	type: CellDataSourceType.Sla = CellDataSourceType.Sla
	slas: {
		id: string
		accountId: string
	}[] = []

	constructor() {
		makeAutoObservable(this)
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new SlaDataSourceElement(designer, cell)
	}

	valid(){
		return this.slas.length > 0
	}
}

export class SlaDataSourceEditorStore {
	slaDataListLoader: DataListLoader<SlaEntry>

	dataSource: CellSlaDataSource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellSlaDataSource

		this.slaDataListLoader = new DataListLoader(() => {
			if(!this.dataSource.accounts?.length){
				return null
			}
			return searchSlasLite({
				accountIds: this.dataSource.accounts,
				includeSubaccounts: true
			})
		}, {
			beforeLoad: () => this.dataSource.slas = []
		})
	}

	init(){
		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}
	}

	showSlasWindow = () => {
		showDataSourceWindow({
			title: i18n('SLA'),
			selectedIds: this.dataSource?.slas?.map(x => x.id) ?? [],
			data: this.slaDataListLoader.dataList,
			columns: [{
				field: 'name',
				title: i18n('Name'),
				filterable: 'string'
			}, {
				field: 'accountName',
				title: i18n('Account'),
				filterable: 'string'
			}, {
				field: 'description',
				title: i18n('Description'),
				filterable: 'string'
			}],
			onOk: (items) => {
				this.dataSource.slas = items.map(x => ({
					id: x.id,
					accountId: x.accountId,
				}))
			},
			designerStore: this.designerStore
		})
	}

	destroy() {
		this.slaDataListLoader.destroy()
	}
}

export const SlaDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => new SlaDataSourceEditorStore(designerStore), {
		deps: [designerStore.dataSourcesManager.selected]
	})

	const icon = <AntButton icon={<UnorderedListOutlined/>}
	                               size={"small"}
	                               onClick={store.showSlasWindow}
	                               disabled={!store.slaDataListLoader.loaded}/>

	return <Section appearance={'none'} childrenPadding={true}>
		<FormEntryNew label={i18n('Account')} model={store.dataSource} modelField={"accounts"}>
			<AccountsSelector hidePlaceholder={true}/>
		</FormEntryNew>

		<FormEntryNew label={i18n('SLA')} model={store.dataSource} modelField={'slas'} headerAdditionalContent={icon}>
			<AntSelect {...store.slaDataListLoader.props}
			           placeholder={i18n('Select a SLA...')}
			           mode={"multiple"}
			           triggerChangeOnClose={true}
			           valueAsObject={true}
			           objectFields={['id', 'accountId']}/>
		</FormEntryNew>

		<ShowAsLabel datasource={store.dataSource}/>
	</Section>
})
