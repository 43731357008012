import React, {KeyboardEventHandler, useCallback, useEffect} from "react";
import {Select} from 'antd';
import {Link} from "core/react/links";
import {SizeType} from "antd/lib/config-provider/SizeContext";
import {debounce} from "lodash";
import TriggerContext from '@rc-component/trigger/es/context'

export {TriggerContext}


export function renderSelectOptions<DataItem extends Record<string, string|number>, TKey extends keyof DataItem>(
	list: DataItem[], {nameField, valueField} : {nameField: TKey, valueField: TKey},
                                     renderer?:  (item: any) => React.ReactNode ) {
	const {Option} = Select
	return list.map(x => <Option key={x[valueField]} value={x[valueField]} disabled={x.disabled}>
		{!renderer && x[nameField]}
		{renderer && renderer(x)}
	</Option>);
}

export type AntSizeType = SizeType

type ValueLinkHolder<T> = {
	valueLink?: Link<T>
}

export function captureValueLink<VT, T extends ValueLinkHolder<VT>>(props: T): Omit<T, "valueLink"> & {onChange: ChangeEventHandlerWrapped<VT, any>} {
	let {valueLink, ...others} = props;

	if (valueLink) {
		Object.assign(others, valueLink.props);
	}

	return others;
}


export type ChangeEventHandlerWrapped<VT, E extends { value: VT}> = (value: VT, e: React.ChangeEvent<E>) => void;

type OnChangeHolder<VT, E extends {value: VT }> = {
	onChange?: ChangeEventHandlerWrapped<VT, E>
}


export function wrapOnChange<VT, E extends {value: VT}, T extends OnChangeHolder<VT, E>>(props: T){
	const wrapper = useCallback((e: React.ChangeEvent<E>) => {
		props.onChange?.(e.target.value as VT, e);
	}, [props.onChange]);
	return {
		...props,
		onChange: wrapper
	}
}

export type OnKeyUpHolder<E extends Element> = {
	onKeyPress?: KeyboardEventHandler<E>,
	onPressEnter?: KeyboardEventHandler<E>
	onPressEsc?: KeyboardEventHandler<E>
}

export function useEnterEscHandlers<E extends Element, P extends OnKeyUpHolder<E>>(props: P){
	let {onPressEnter, onPressEsc, ...restProps} = props

	if(!onPressEnter && !onPressEsc)
		return restProps

	const initialOnKeyPress = props.onKeyPress

	restProps.onKeyPress = (e: React.KeyboardEvent<E>) => {
		if (onPressEnter && e.key == 'Enter') {
			onPressEnter(e)
		}

		if (onPressEsc && e.key == 'Escape') {
			onPressEsc(e)
		}

		initialOnKeyPress?.(e)
	};
	return restProps
}


export function useBounced<T extends string|number>(bounced: number = null, value: T, onChange: (v: T) => void) {
	const [valueProxy, setValueProxy] = React.useState(value)

	useEffect(() => {
		onChangeBounced.current = debounce((v: T) => {
			onChange(v)
		}, bounced ?? 1000);
	}, [bounced, onChange]);

	const onChangeBounced = React.useRef(debounce((v: T) => {
		onChange(v)
	}, bounced ?? 1000))

	const onChangeBouncedWrapper = React.useCallback((v: T) => {
		setValueProxy(v)
		onChangeBounced.current(v)
	}, [])

	React.useEffect(() => {
		setValueProxy(value)
	}, [value])

	return [bounced ? valueProxy : value, bounced ? onChangeBouncedWrapper : onChange] as const
}

export function useBouncedWithEvent<TElement extends HTMLInputElement|HTMLTextAreaElement>(
	bounced: number = null, value: string, onChange: (e: React.ChangeEvent<TElement>) => void) {
	const [valueProxy, setValueProxy] = React.useState(value)

	useEffect(() => {
		onChangeBounced.current = debounce((e: React.ChangeEvent<TElement>) => {
			onChange(e)
		}, bounced ?? 1000);
	}, [bounced, onChange]);

	const onChangeBounced = React.useRef(debounce((e: React.ChangeEvent<TElement>) => {
		onChange(e)
	}, bounced ?? 1000))

	const onChangeBouncedWrapper = React.useCallback((e: React.ChangeEvent<TElement>) => {
		e.persist()
		setValueProxy(e.target.value)
		onChangeBounced.current(e)
	}, [])

	React.useEffect(() => {
		setValueProxy(value)
	}, [value])

	return [bounced ? valueProxy : value, bounced ? onChangeBouncedWrapper : onChange] as const
}

//this is a similar implementation what ant has for tracking popups on popups to be able to check where click\movement happened
//TriggerContext declared in rc-component, we have to reuse it as so we can insert our code into the chain
export function useTriggerContext(){
	let subPopupElements = React.useRef<Record<string, HTMLElement>>({})
	let parentContext = React.useContext(TriggerContext)

	let context = React.useMemo( () => {
		return {
			registerSubPopup: function registerSubPopup(id: string, subPopupEle: HTMLElement) {
				subPopupElements.current[id] = subPopupEle;
				parentContext === null || parentContext === void 0 || parentContext.registerSubPopup(id, subPopupEle);
			}
		};
	}, [parentContext]);

	return {context, subPopupElements: subPopupElements.current}
}
