import {Api} from "tools/api";
import {useRemoteListLegacy, useRemoteList} from "framework/api";


export class CloudBillingMonitorsApi {
	static cloudTypesUrl(accountId){
		return Api.accountRoot(accountId) + 'monitors/cloudTypes';
	}

	static listUrl(accountId, cloudType){
		return Api.accountRoot(accountId) + `monitors/types/${cloudType}/lite`;
	}

	static subscriptionsListUrl(accountId, cloudType, monitorId, hideAll = false){
		return Api.accountRoot(accountId) + `monitors/${cloudType}/subscriptions?monitorId=${monitorId}&hideAll=${hideAll}`;
	}

	static getCost({resources, resourceType, subscriptions, thresholdType,
		               monitorId, historicMonths, cloudType, showHiSeverity, showBudgetSeverity}){
		let url = `${Api.sessionRoot()}monitors/${cloudType}/resourceCost`;

		return Api.fetchPost(url, {
			monitorId,
			subscriptions: subscriptions,
			resources,
			resourceType,
			thresholdType,
			historicMonths,
			showHiSeverity,
			showBudgetSeverity
		});
	}

	static updateBudget(cloudType, payload) {
		const url = `${Api.sessionRoot()}monitors/${cloudType}/externalBudget`;
		return Api.fetchPost(url, payload);
	}
}

export function useCloudTypes(accountId) {
	return useRemoteListLegacy(CloudBillingMonitorsApi.cloudTypesUrl(accountId), {directList: true});
}

export function useMonitors(accountId, cloudType) {
	return useRemoteListLegacy(
		CloudBillingMonitorsApi.listUrl(accountId, cloudType), {
			directList: true,
			makeRequest: !!cloudType
		});
}

export function useSubscriptions(accountId, cloudType, monitorId, hideAll) {
	return useRemoteListLegacy(CloudBillingMonitorsApi.subscriptionsListUrl(accountId, cloudType, monitorId, hideAll), {
		makeRequest: !!monitorId && !!cloudType
	});
}

export function useResources(accountId, cloudType, monitorId, subscriptions, resourceType, hideTotal) {
	return useRemoteList(
		{
			url: `monitors/${cloudType}/resources`,
			accountId: accountId,
			accountBased: true,
			payload: {
				monitorId,
				subscriptions: subscriptions,
				hideTotal: !!hideTotal,
				resourceType
			}
		},
		{
			makeRequest: !!monitorId && !!subscriptions && !!cloudType && !!resourceType
		});
}

export function useResourceTypes(accountId, cloudType) {
	return useRemoteListLegacy(
		Api.accountRoot(accountId) + `monitors/${cloudType}/resourceTypes`,
		{
			makeRequest: !!cloudType
		});
}
