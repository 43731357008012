import {makeAutoObservable, toJS} from "mobx"
import {observer} from "mobx-react"
import React, {useMemo} from "react"

import {Section} from "controls/react/layout/section"
import FormEntry from "controls/react/form/formEntry"
import {linkModel, MobxManager} from "framework/mobx-integration"
import {AntSelect} from "controls/react/ant/antSelect"
import {CellDataSourceType} from "controls/designer/dataSourcesManager/cellDataSourceType"
import {
	MetricValueAsSeverity,
	MetricValueToSeverityHolder
} from "controls/designer/dataSourcesManager/metricValueAsSeverity"
import {TimePeriod, TimePeriodSelector} from "controls/react/form/timePeriodSelector"
import {AntInput} from "controls/react/ant/antInput"
import {DataList} from "framework/entities/dataList"
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {CellDataSourceBase} from "controls/designer/dataSourcesManager/cellDataSource";
import {Designer} from "controls/designer/designer";
import {MxCell} from "controls/designer/mxGraphInterfaces";
import {MetricDataSourceElement} from "controls/designer/dataSourcesManager/metricDataSourceElement";
import {DisplayLabelType, TotalMetricType} from "controls/designer/dataSourcesManager/metricShared";
import {DataSourceEditorContext} from "controls/designer/dataSourcesManager/dataSourceEditorContext";
import {DesignerStore} from "controls/designer/designerStore";
import {ApplicationState} from "framework/applicationState";
import {FormEntryNew, HelpIcon} from "controls/react/form/formEntryNew";
import {
	MetricConfiguration,
	MetricSelectorAdvanced
} from "areas/service-boards/widgets/common/metricSelectorAdvanced";
import {useStore} from "core/react/useStore";
import {TimePeriodType} from "controls/react/form/timePeriodType";

const i18n = require('core/localization/localization').translator({
	"User Input": {
		"no": "Bruker inndata"
	},
	"Display Unit": {
		"no": "Visningsenhet",
	},
	"Display label": {
		"no": "Visningsnavn",
	},
	"none-total-metric-tooltip": {
		"en": "Metric is displayed as normal",
		"no": "Metrikker vises som normalt"
	},
	"metric-total-metric-tooltip": {
		"en": "You can select a second Metric. This Metric will be placed next to the Metric selected above.",
		"no": "Du kan velge en ekstra metrikk. Denne Metrikken plasseres ved siden av den første metrikken."
	},
	"user_input-total-metric-tooltip": {
		"en": "You can input a string. The string will be placed next to the first Metric. Eg. 100%.",
		"no": "Du kan legge inn en streng, den blir plassert ved siden av den første metrikken. F.eks. 100%."
	},
	"NONE-display-unit-tooltip": {
		"en": "We dont display unit",
		"no": "Vi viser ikke enhet"
	},
	"METRIC_UNIT-display-unit-tooltip": {
		"en": "We use the unit of the selected Metric",
		"no": "Vi bruker enheten til den valgte metrikken."
	},
	"CUSTOM_UNIT-display-unit-tooltip": {
		"en": "You can input a custom unit to be displayed",
		"no": "Du kan legge inn en tilpasset enhet."
	},
	"TOTAL_UNIT-display-unit-tooltip": {
		"en": "We use the unit of the Total Metric. This is only available if total Metric is selected",
		"no": "Vi bruker enheten til Total metrikken. Dette er bare tilgjengelig hvis total metrikk er valgt."
	},
	"NONE-display-label-tooltip": {
		"en": "No label is set. You can still add a custom label by double clicking the Shape.",
		"no": "Navn settes ikke. Du kan fortsatt legge til et navn ved å dobbeltklikke på formen."
	},
	"TOTAL_DISPLAY_LABEL-display-label-tooltip": {
		"en": "We use the name of the Total Metric in the Shape.",
		"no": "Vi bruker navnet på Total metrikk i formen."
	},
	"METRIC_DISPLAY_LABEL-display-label-tooltip": {
		"en": "We use the name of the Metric in the Shape.",
		"no": "Vi bruker navnet på metrikken i formen."
	},
	"decimal-tooltip": {
		"en": "Number of decimals we will display the Metrics with. If empty we display the Metrics as is.",
		"no": "Antall desimaler vi viser metrikken med. Hvis det er tomt, viser vi metrikken som den er."
	},
	"metric-value-as-severity-tooltip": {
		"en": "If selected you can use the Metric value to custom set severity of the Shape. The severity will match the first value that matches.",
		"no": "Hvis dette er valgt, kan du bruke metrikkverdien til å sette formens alvorlighetsgrad. Alvorlighetsgraden vil matche den første verdi som samsvarer."
	},
	'Metric': {
		no: 'Metrikk'
	},
	"Select a Metric...": {
		"no": "Velg en metrikk..."
	},
	"Custom unit": {
		"no": "Tilpasset enhet",
	},
	'Metric unit': {
		no: 'Metrikk enhet'
	},
	'Total unit': {
		no: 'Total metrikk'
	},
	'Trend': {},
	'Calculation period': {},
	'None': {},
	'1h': {},
	'1d': {},
	'7d': {},
	'30d': {},
	"Metric label": {
		"no": "Metrikk navn",
	},
	"Second metric label": {
	},
	'Second value': {},
	'Second metric': {}
});


export class MetricDataSourceEditorStore {
	totalMetricDataList: DataList<string>
	//displayUnitDataList: DataList<string>
	displayLabelDataList: DataList<string>
	mobx = new MobxManager()

	dataSource: CellMetricDatasource

	constructor(public designerStore: DesignerStore) {
		makeAutoObservable(this)

		this.dataSource = this.designerStore.dataSourcesManager.selected as CellMetricDatasource
	}

	init(){
		this.mobx.reaction(() => toJS(this.dataSource.accounts), () => {
			this.dataSource.metric = {}
			this.dataSource.secondMetric = {}
		})

		this.mobx.reaction(() => this.dataSource.totalMetricType, () => {
			this.displayLabelDataList = [
				{
					name: i18n('None'),
					id: DisplayLabelType.None
				},
				{
					name: i18n('Metric label'),
					id: DisplayLabelType.Metric
				}
			];

			if(this.dataSource.totalMetricType == TotalMetricType.Metric){
				this.displayLabelDataList.push(
					{
						name: i18n('Second metric label'),
						id: DisplayLabelType.Total
					}
				)
			}
		}, {
			fireImmediately: true
		})

		if (!this.dataSource.accounts?.length) {
			this.dataSource.accounts = [ApplicationState.accountId]
		}

		this.totalMetricDataList = [
			{
				name: i18n('None'),
				id: TotalMetricType.None
			},
			{
				name: i18n('Metric'),
				id: TotalMetricType.Metric
			},
			{
				name: i18n('User Input'),
				id: TotalMetricType.UserInput
			}
		]
	}

	destroy() {
		this.mobx.destroy()
	}
}

export const MetricDataSourceEditor = observer(() => {
	const designerStore = React.useContext(DataSourceEditorContext)

	const store = useStore(() => new MetricDataSourceEditorStore(designerStore),
		{deps: [designerStore.dataSourcesManager.selected]})

	const dataSource = store.dataSource

	return <Section appearance={'none'} childrenPadding={true}>
		<MetricSelectorAdvanced accountId={designerStore.config.accountId}
		                        model={dataSource.metric}/>

		<FormEntryNew label={i18n('Second value')}
		              headerAdditionalContent={<HelpIcon tooltip={i18n(dataSource.totalMetricType + '-total-metric-tooltip')}/> }
		              model={dataSource}
		              modelField={"totalMetricType"}>
			<AntSelect dataList={store.totalMetricDataList}/>
		</FormEntryNew>

		{dataSource.totalMetricType == TotalMetricType.Metric &&
			<MetricSelectorAdvanced accountId={dataSource.accounts.length ? dataSource.accounts[0] : null}
		                        label={i18n('Second metric')}
		                        model={dataSource.secondMetric}/>
		}

		{dataSource.totalMetricType == TotalMetricType.UserInput &&
			<FormEntryNew label={i18n('User Input')} changeOnBlur={true} model={dataSource} modelField={'totalMetricCustomValue'}>
				<AntInput/>
			</FormEntryNew>
		}

		<FormEntryNew label={i18n('Display label')}
		              model={dataSource}
		              modelField={"displayLabelType"}
		              headerAdditionalContent={<HelpIcon tooltip={i18n(dataSource.displayLabelType + '-display-label-tooltip')}/>}>
			<AntSelect dataList={store.displayLabelDataList}/>
		</FormEntryNew>

		<MetricValueAsSeverity dataSource={dataSource}/>

		<ShowTrend dataSource={dataSource}/>
	</Section>
})

const ShowTrend = observer((props: {dataSource: CellMetricDatasource}) => {
	const dataSource = props.dataSource

	const periods = useMemo(() => {
		return [
			TimePeriodType.None,
			TimePeriodType.LastHour,
			TimePeriodType.Last24Hours,
			TimePeriodType.Last7Days,
			TimePeriodType.Last30Days
		]
	}, []);

	return <Section title={i18n('Trend')}
	                margin="top"
	                appearance="frame-top-only"
	                childrenPadding={true}>
		<FormEntry>
			<AntCheckbox {...linkModel(dataSource, 'showTrend')}>{i18n('Enabled')}</AntCheckbox>
		</FormEntry>
		{dataSource.showTrend && <FormEntry label={i18n('Calculation period')}>
			<TimePeriodSelector periods={periods} {...linkModel(dataSource, 'metricTrendPeriod')}/>
		</FormEntry>}
	</Section>
})


export class CellMetricDatasource implements MetricValueToSeverityHolder, CellDataSourceBase{
	id: string
	accounts: string[] = []
	type: CellDataSourceType.Metric = CellDataSourceType.Metric

	metric: MetricConfiguration = new MetricConfiguration()
	secondMetric: MetricConfiguration = new MetricConfiguration()

	totalMetricType: TotalMetricType = TotalMetricType.None
	totalMetricCustomValue: string
	displayLabelType: DisplayLabelType = DisplayLabelType.None
	showTrend: boolean = false
	metricTrendPeriod: TimePeriod = {
		period: TimePeriodType.Last24Hours
	}
	metricValueAsSeverity: boolean
	hideMetricValue: boolean = false
	metricValueToSeverity: {
		operator: 'gte'|'lte',
		threshold: number
	}[] = [{
		operator: "gte",
		threshold: null
	},{
		operator: "gte",
		threshold: null
	},{
		operator: "gte",
		threshold: null
	}]

	constructor() {
		makeAutoObservable(this)
	}

	attachToCell(designer: Designer, cell: MxCell) {
		return new MetricDataSourceElement(designer, cell)
	}

	valid(){
		return this.metric?.metricId != null
	}
}
