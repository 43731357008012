import './legendColorSettings.less'
import {Section, Toolbar} from "controls/react/layout";
import {IconButton} from "controls/react";
import {FormEntryNew} from "controls/react/form/formEntryNew";
import {AntSelect} from "controls/react/ant/antSelect";
import {SketchColorPicker} from "controls/react/colorPicker/sketchColorPicker";
import {CloseOutlined, PlusCircleOutlined} from "@ant-design/icons";
import React, {useCallback, useEffect, useMemo} from "react";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import b_ from "b_";
import {ColorResult} from "react-color";
import {observer} from "mobx-react";
import {newGuid} from "tools/guid";
import {
	getChartBarColor,
	getCostBarChartColor
} from "controls/designer/features/widgets/cost/budget/chartDefaultColors";

const i18n = require('core/localization').translator({
	'Color Settings': {no: 'Fargeinnstillinger'},
	'Click Add to set color for selections': {no: 'Klikk Legg til for å endre farge'},
	'Column': {no: 'Kolonne'},
	'Color': {no: 'Farge'},
	'Add': {no: 'Legg til'}
}, sharedLocalization);

const b = b_.with('legend-color-settings');

export type ColorRow = {name: string, color: ColorResult, key: string};

export type LegendColorSettingsProps = {
	value?: ColorRow[],
	onChange?: (value: ColorRow[]) => void
	legend: Options[];
	columns: string[];
	title?: string;
}

export type Options = {value: string, label: string};

const getNextColor = (exists: string[]) => {
	const barColorFn = getCostBarChartColor('BLUE');
	let initialColor = '';
	while (true) {
		const color = barColorFn.next().value;
		if (typeof color != 'string' || !exists.includes(color)) {
			return color;
		}
		if (initialColor == color) {
			return color;
		}
		if (!initialColor) {
			initialColor = color;
		}
	}
}
export const LegendColorSettings = observer((props: LegendColorSettingsProps) => {
	const [colOptions, setColOptions] = React.useState(props.legend ?? []);

	const updateColumns = useCallback(() => {
		const itemIds: string[] = props.columns ?? [];
		setColOptions([...(props.legend ?? []).filter(x => itemIds.includes(x.value) && (props.value ?? []).every(y => y.key != x.value))])
	}, [props.value, props.legend, props.columns]);

	useEffect(() => {
		updateColumns()
	}, [props.value, props.legend, props.columns]);

	const addColor = useCallback(() => {
		if (colOptions.length == 0) {
			return;
		}
		if (!props.value) {
			props.value = []
		}
		const newValue = colOptions[0].value
		const newName = props.legend.find(x => x.value == newValue).label;
		const existsColors = props.value.map(x => x.color.hex);

		props.value.push({name: newName, color: { hex: getNextColor(existsColors)} as ColorResult, key: newValue});
		updateColumns();
	}, [props.value, props.legend, colOptions, updateColumns])

	const removeColor = useCallback((row: ColorRow) => {
		const index = props.value.indexOf(row);
		props.value.splice(index, 1);
		updateColumns();
	}, [props.value, props.legend, updateColumns])

	const title = useMemo(() => props.title ?? i18n('Color Settings'), [props.title]);

	return <Section appearance={'frame'} title={title} childrenPadding={true} contentClass={b('container')} className={b()}>
		<Toolbar>
			<PlusCircleOutlined onClick={addColor} title={i18n('Add')} />
		</Toolbar>
		{!props.value?.length && <Section appearance={"none"} direction={'row'} alignItems={'stretch'}>{i18n('Click Add to set color for selections')}</Section>}
		{(props.value ?? []).map(row => <Section appearance={"none"} alignItems={"stretch"} layout={"stack"} direction={'row'} contentClass={b('row')} key={row.key}>
				<LegendColorSettingsRow colOptions={colOptions} value={row} onRowChanged={updateColumns} />
				<CloseOutlined onClick={e => removeColor(row)}/>
			</Section>
		)}
	</Section>
})

export type LegendColorSettingsRowProps = {
	value?: ColorRow,
	onRowChanged?: (value: ColorRow) => void
	colOptions: Options[];
}

export const LegendColorSettingsRow = observer((props: LegendColorSettingsRowProps) => {
	useEffect(() => {
		props.onRowChanged?.(props.value);
	}, [props.value.name])

	return <>
		<FormEntryNew label={i18n('Column')} model={props.value} modelField={'name'}>
			<AntSelect options={props.colOptions} />
		</FormEntryNew>
		<FormEntryNew label={i18n('Color')} model={props.value} modelField={'color'}>
			<SketchColorPicker />
		</FormEntryNew>
	</>
})
