import './barchartWidget.less';

import React from 'react';
import Settings from 'settings';
import { Cookies } from "core/cookies";
import { sharedLocalization } from "controls/designer/features/widgets/localization";
import { BarchartMetricConfiguration } from './barchartMetricConfiguration';
import { Api, State, UserPrefs, Utils } from 'tools';
import { IconButton } from "controls/react/form/iconButton";
import ReactDOM from "react-dom";
import WidgetInfoContainer from "./widgetInfoContainer";
import { Popover, Alert } from 'antd';
import { compileName } from "../stackChartWidget/helpers";
import { AssetsRouter } from 'areas/assets/bundleDescription';
import { RemoteEventsManager } from 'core';
import {DefaultWidgetWrapper} from "controls/designer/features/widgets/defaultWidgetWrapper";
import { extractQualifierSubscriptionFields } from '../customMetrics/metricSelector';
import {ApplicationState} from "framework/applicationState";
import {createRoot} from "react-dom/client";

const b = require('b_').with('metric-barchart');

const i = require('core/localization').translator({
	"Linear gauge": {
		"no": "Lineær måler"
	},
	"Breach": {
		"no": "Brudd"
	},
	"Current": {
		"no": "Nåværende"
	},
	"Min": {
		"no": "Min"
	},
	"Max": {
		"no": "Max"
	},
	"Redirect": {
		"no": "Videresend"
	},
	"No data":{ "no" : "Ingen data"}
}, sharedLocalization);

export function getWidgetDescription() {
	return {
		form: BarchartMetricConfiguration,
		defaultConfig: {
			type: 'metric-barchart',
			title: i('Linear gauge'),
			accountId: ApplicationState.accountId,
			isDashboard: true,
			contentPadding : 10,
			legendValue: ['<Asset>', '<Name>']
		},
		widget: BarchartWidget,
		fullTitle: i('Linear gauge'),
	}
}

export class BarchartWidget extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			metric: 0,
			date: null
		};
		this.infoContainer = null;
		this.subscription = null;
		this.hasMetricSelected = this.props.config.selectedMetrics?.length > 0;
	}
	componentDidMount() {
		this.getValues();
	}
	componentWillUnmount() {
		this.subscription?.unsubscribe();
	}

	onInfoSignShow = (e) => {
		if(!this.hasMetricSelected)
			return;
		let data = this.state.qualifier;
		let coordinates = e.target.getBoundingClientRect();
		this.infoContainer = e.target;

		ReactDOM.render(<WidgetInfoContainer
			data={data}
			coordinates={coordinates}
		/>, this.infoContainer);
	}
	onInfoSignHide = (e) => {
		if (this.infoContainer) {
			ReactDOM.unmountComponentAtNode(this.infoContainer);
		}
	}
	redirect = async (e) => {
		if (!this.hasMetricSelected)
			return;

		e.stopPropagation();

		let assetId = this.props.config.selectedMetrics[0].assetId;
		if (!assetId) {
			let assetName = this.props.config.selectedMetrics[0].assetName;
			if (assetName) {
				let nameFilter = {
					filter: {
						logic: 'and',
						filters: [{
							field: "name",
							operator: "eq",
							value: "CeeReleaseAgent"
						}]
					}
				}
				let url = `${Settings.serverPath}accounts/${Cookies.CeesoftCurrentAccountId}/health/assets?includeSubaccounts=true`;
				let result = await Api.fetchPost(url, nameFilter);
				if (result?.items?.length) {
					assetId = result.items[0].id;
				}
			}
		}
		if (assetId) {
			this.props.navigator.go({
				url: AssetsRouter.details(assetId)
			});
		}

	}

	subscribe = (qualifier) => {
		if (!qualifier)
			return;
		if (!this.hasMetricSelected)
			return;
		var subscriptionObj = [{
			eventType: 'Metric',
			releaseEvents: true,
			qualifierId: this.props.config.selectedMetrics[0].metricId,
			...extractQualifierSubscriptionFields(qualifier)
		}];
		this.subscription = RemoteEventsManager.subscribeCallback(subscriptionObj, this.onEvents);
	}

	onEvents = events => {
		this.setState({
			metric: parseFloat(events.metric.v.toFixed(1)),
			date: new Date(events.metric.t).toLocaleString()
		});
	}

	render() {
		const toolbarAtTheEnd = <IconButton iconName={"info-sign"}
			embedded={true}
			onMouseEnter={this.onInfoSignShow}
			onMouseLeave={this.onInfoSignHide}/>;
		const content = <div>
			<div>{i('Value')} : <b>{this.state.metric ?? i("No data")}</b></div>
			<div>{i('Min')} : <b>{this.props.config.minValue}</b></div>
			<div>{i('Max')} : <b>{this.props.config.maxValue}</b></div>
			<div>{i('Breach')} : <b>{this.props.config.breachValue}</b></div>
			<div>{i('Current')} : <b>{this.state.metric ?? i("No data")}</b></div>
			<div>{i('Redirect')} : <b>{this.props.config.redirect?.label}</b></div>
		</div>
		const legendContainerProps = {
			noLabel: this.props.config.hideLabels,
			noNumbers:this.props.config.hideNumbers,
			noLabelAndNumber: this.props.config.hideLabels && this.props.config.hideNumbers
		}

		return <DefaultWidgetWrapper {...this.props} toolbarAtTheEnd={toolbarAtTheEnd}>
			{(!this.props.dashboardSettings.showWidgetHeader && this.props.dashboardSettings.readOnly)
					 && <div className={b('info-icon')}>{toolbarAtTheEnd}</div>}
			{!this.state.metric && <Alert type="error" message={i("No data")} banner />}
			<div className={b('barchart')} onClick={this.redirect} style={{margin:this.props.config.contentPadding??20}}>
				<Popover content={content} title={this.state.date} overlayClassName={'chart-tool-tip'}>
					<div className={b('barchart-bar', {inverted: this.props.config.invert})}>
						<div className={b('bar',{inverted: this.props.config.invert})} style={{ width: this.getMarkerPosPercentage(this.props.config.breachValue) + '%' }}>
							<div className={b('breach')}>
								{!this.props.config.hideNumbers && <p>{this.props.config.breachValue}</p>}
								{!this.props.config.hideLabels && i('Breach')}
							</div>
						</div>
						{!!this.state.metric &&
							<div className={b('current')} style={{ left: this.getMarkerPosPercentage(this.state.metric) + '%' }}>
								<div className={b('currentLine')}></div>
								<div className={b('currentText')}>
									{!this.props.config.hideNumbers && <p>{this.state.metric}</p>}
									{!this.props.config.hideLabels && i('Current')}
								</div>
							</div>
						}
						<div className={b('min')}>
							{!this.props.config.hideNumbers && <p>{this.props.config.minValue}</p>}
							{!this.props.config.hideLabels && i('Min')}
						</div>
						<div className={b('max')}>
							{!this.props.config.hideNumbers && <p>{this.props.config.maxValue}</p>}
							{!this.props.config.hideLabels && i('Max')}
						</div>
					</div>
				</Popover>


				{(this.hasMetricSelected && this.props.config.legendValue?.length > 0)
					&& 	<div className={b('legend-container',legendContainerProps)}>
					<span prevent-widget-redirect-from-states-mgr="true" onClick={this.redirect}>{compileName(this.props.config.legendValue, this.props.config.selectedMetrics[0])}</span>
				</div>}
			</div>


		</DefaultWidgetWrapper>
	}

	getValues = async () => {
		if (!this.hasMetricSelected)
			return;
		const url = `${Settings.serverPath}metrics/registeredMetrics/lastinfo`;
		const resp = await Api.fetchPost(url, [{
			metricId: this.props.config.selectedMetrics[0].metricId,
			conversion: "",
			customUnit: ""
		}]);
		this.subscribe(resp[0]?.qualifier)
		this.setState({
			metric: resp[0]?.data[0]?.v ? parseFloat(resp[0]?.data[0]?.v.toFixed(1)) : null,
			date: new Date(resp[0]?.data[0]?.t).toLocaleString(),
			qualifier: resp[0]?.qualifier
		});
	}

	getMarkerPosPercentage = (value) =>{
		return (((value - this.props.config.minValue)* 100) / (this.props.config.maxValue - this.props.config.minValue))
	}
}
