import {Localization} from 'core/localization';

export const sharedLocalization = new Localization({
	'Metric timeline': {
		no: 'Metrikktidslinje'
	},
	'Metric text': {
		no: 'Metrikktekst'
	},
	'Metric chart': {
		no: 'Metrikkgraf'
	},
	'Dashboard': {
		no: 'Dashboard'
	},
	'Components': {},
	'Edit component': {},
	'Component configuration': {},
	'Format': {},
	'Font family': {},
	'Font size': {},
	'Font style': {},
	'Font color': {},
	'Datasource': {},
	'Threshold': {},
	'Selector': {},
	// 'Name': {},
	'Unit': {},
	'Zoneid': {},
	'Aggregate level': {},
	'Show label': {
		no: 'Vis tekstobjekter'
	},
	'Show legend': {},
	'Chart type': {},
	// 'Min': {},
	// 'Max': {},
	'Path': {},
	'Options': {},
	'Wait': {},
	'Url': {},
	'Normal': {},
	'Bold': {},
	'Italic': {}
});
