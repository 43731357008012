import './datasourceSelectionConfig.less';
import {GridStore} from "controls/grid/gridStore";
import {computed, makeAutoObservable} from "mobx";
import {FieldType, RulesConfiguration} from "controls/queryBuilder/ruleDefinition";
import {GridColumnConfig} from "controls/grid/gridColumnConfig";
import moment from "moment";
import {ApplicationState} from "framework/applicationState";
import {observer} from "mobx-react";
import React, {MouseEvent} from "react";
import {Section, Toolbar, ToolbarItemPosition} from "controls/react/layout";
import {Grid} from "controls/grid/grid";
import {RemoteDataProvider} from "controls/grid/remoteDataProvider";
import {TimePeriodType} from "controls/react/form/timePeriodType";
import {GridSelectionType} from "controls/grid/gridConfiguration";
import {getDataSourceData} from "controls/designer/features/widgets/datasources/dataSourceGridWidget";
import {apiFetch, ApiRequest} from "framework/api";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import b_ from "b_";
import {BoxView} from "controls/react/layout/boxView";
import {AntButton} from "controls/react/ant/antButton";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {MobxManager} from "framework/mobx-integration";
import {FormEntryNew} from "controls/react/form/formEntryNew";

export enum SelectionMode {
	Columns = 1,
	Rows = 2,
	CategoryColumn
}

type DatasourceSelectionConfigProps = {
	accountId: string
	dataSourceId: string
	selectionMode: SelectionMode,
	categoryColumn?: string,
	value?: string[],
	onChange?: (value: string[]) => void
};

const gridCssBlock = b_.with('ceeview-grid');
export const i18n = require('core/localization').translator({
	'Category Column: Select a string column to categorize the data for the chart. Only string-type columns are supported.': {
		no: 'Kategori kolonne: Velg en streng kolonne for å kategorisere dataene for diagrammet. Bare kolonner av strengtype støttes.'
	},
	'Data rows: Select rows from Datasource to be displayed in the chart.': {
		no: 'Datarader: Velg rader fra datakilde som skal vises i diagrammet.'
	},
	'Data Columns: Select columns from Datasource to be displayed in the chart. Only integer and double columns are supported.': {
		no: 'Datakolonner: Velg kolonner fra datakilde som skal vises i diagrammet. Bare tall kolonner støttes.'
	},
	'Category selection': {no: 'Kategorivalg'}
}, sharedLocalization);

class DatasourceSelectionConfigStore {
	gridStore: GridStore<any>
	private selectedItems: string[] = [];
	title: string;
	infoMessage: string;
	filtersConfig: RulesConfiguration;
	private categoryColumn: string;
	private options: string[] = []
	mobx = new MobxManager();

	get atLeastOneSelected() {
		if (this.props.selectionMode == SelectionMode.Rows) {
			return this.gridStore.selection.dataset.items.length > 0;
		}
		return this.selectedItems.length > 0;
	}

	get allChecked() {
		return this.selectedItems.length == this.options.length;
	}

	set allChecked(val: boolean) {
		if (val) {
			this.selectedItems = [...this.options];
		} else {
			this.selectedItems = [];
		}
	}

	get selectionMode() {
		return this.props.selectionMode;
	}

	get indeterminate() {
		return this.selectedItems.length > 0 && this.selectedItems.length < this.options.length;
	}

	constructor(private props: DatasourceSelectionConfigProps) {
		makeAutoObservable(this, {
			'atLeastOneSelected': computed,
			'allChecked': computed,
			'selectionMode': computed,
			'indeterminate': computed
		});
		this.mobx.reaction(() => this.gridStore?.dataProvider?.initialized, initialized => {
			if (initialized && this.props.selectionMode == SelectionMode.Rows) {
				const selected = this.gridStore.dataProvider.data.filter(x => this.props.value.includes(x.data[this.categoryColumn].toString()));
				selected.forEach(x => this.gridStore.selection.toggleSelected(x, true));
			}
		})
	}

	init = async () => {
		this.filtersConfig = (await apiFetch(this.getDataSourceFiltersConfiguration())).data;
		this.categoryColumn = this.props.categoryColumn ?? 'rowHeader';

		this.initCategorySelectionMode();
		this.initRowSelectionMode();
		this.initColumnSelectionMode();

		this.gridStore = new GridStore<any>({
			columns: this.getColumns(this.filtersConfig),
			dataProvider: new RemoteDataProvider({
				dataSource: this.getDataRequest(),
				filtersConfiguration: this.filtersConfig
			}),
			defaults:{
				payload: {
					timePeriod: {
						period: TimePeriodType.Last24Hours
					},
					showHistoricEvents: false
				},
			},
			selection: this.props.selectionMode == SelectionMode.Rows ? GridSelectionType.Many : GridSelectionType.None
		});
	}

	initCategorySelectionMode = () => {
		if (this.props.selectionMode != SelectionMode.CategoryColumn) {
			return;
		}
		if (!this.props.value?.length && Object.keys(this.filtersConfig).includes(this.categoryColumn)) {
			this.selectedItems.push(this.categoryColumn)
		} else {
			this.selectedItems = [...this.props.value];
		}
		this.title = i18n('Category selection');
		this.infoMessage = i18n('Category Column: Select a string column to categorize the data for the chart. Only string-type columns are supported.');
	}

	initRowSelectionMode = () => {
		if (this.props.selectionMode != SelectionMode.Rows) {
			return;
		}

		this.title = i18n('Row selection');
		this.infoMessage = i18n('Data rows: Select rows from Datasource to be displayed in the chart.');
	}

	initColumnSelectionMode = () => {
		if (this.props.selectionMode != SelectionMode.Columns) {
			return;
		}

		this.title = i18n('Column selection');
		this.selectedItems = this.props.value ? [...this.props.value] : Object.keys(this.filtersConfig).filter(x => this.filtersConfig[x].type == FieldType.Number);
		this.infoMessage = i18n('Data Columns: Select columns from Datasource to be displayed in the chart. Only integer and double columns are supported.');
		this.options = Object.keys(this.filtersConfig).filter(x => this.filtersConfig[x].type == FieldType.Number);
	}

	getDataRequest = () => {
		const dataRequest = getDataSourceData(this.props);
		dataRequest.payload = {}
		dataRequest.payload.filter = {}
		dataRequest.payload.sort = []
		dataRequest.payload.skip = 0
		dataRequest.payload.take = 999
		return dataRequest;
	}

	getDataSourceFiltersConfiguration = () => {
		return new ApiRequest<RulesConfiguration>({
			url: `dataSources/${this.props.dataSourceId}/ruleConfiguration`,
			accountId: this.props.accountId,
			accountBased: true,
			method: 'POST',
			payload: {}
		})
	}

	getColumns(rules: RulesConfiguration){
		return Object.keys(rules)
			.filter(x => x != 'id')
			.map(columnName => {
				let column : GridColumnConfig<any> = {
					field: columnName,
					// hack from Bernt
					title: rules[columnName].label,
					rendererHeader: () => {
						const label = rules[columnName].label || 'Default';
						switch (this.props.selectionMode) {
							case SelectionMode.CategoryColumn: {
								return <>
									{this.filtersConfig[columnName].type == FieldType.Text && <AntCheckbox title={label}
																			   	onClick={this.onHeaderCheckboxClick}
																				value={this.selectedItems.includes(columnName)}
																			   	onChange={value => this.toggleColumn(columnName, value)}>{label}</AntCheckbox>}
									{this.filtersConfig[columnName].type != FieldType.Text && <>{label}</>}
								</>
							}
							case SelectionMode.Columns:
							{
								return <>
									{this.filtersConfig[columnName].type == FieldType.Number && <AntCheckbox title={label}
																										   	onClick={this.onHeaderCheckboxClick}
																											value={this.selectedItems.includes(columnName)}
																										   	onChange={value => this.toggleColumn(columnName, value)}>{label}</AntCheckbox>}
									{this.filtersConfig[columnName].type != FieldType.Number && <>{label}</>}
								</>
							}
						}
						return <>{label}</>
					},
					renderer: item => {
						if (rules[columnName].type == 'integer') {
							return item[columnName]?.toFixed(0) ?? '';
						}
						if (rules[columnName].type == 'date' && !!item[columnName]) {
							return moment(item[columnName]).format(ApplicationState.momentDateTimeFormat);
						}
						return item[columnName] ?? '';
					},
					className: () => gridCssBlock('cell', {selected: this.selectedItems.includes(columnName)})
				}

				return column
			})
	}

	onHeaderCheckboxClick = (ev: MouseEvent<HTMLElement>) => {
		ev.stopPropagation();
	}

	toggleColumn = (rowHeader: string, checked: boolean) => {
		if (checked) {
			if (this.props.selectionMode == SelectionMode.CategoryColumn) {
				this.selectedItems = [rowHeader];
			} else {
				this.selectedItems.push(rowHeader);
			}
		} else {
			const index = this.selectedItems.findIndex(x => x.toUpperCase() == rowHeader.toUpperCase());
			this.selectedItems.splice(index, 1);
		}
	}

	onUpdate = () => {
		if (!this.props.onChange) {
			return;
		}
		if (this.props.selectionMode == SelectionMode.Rows) {
			this.props.onChange(this.gridStore.selection.dataset.items.map(item => item.data[this.categoryColumn]));
			return;
		}
		this.props.onChange(this.selectedItems)
	}

	destroy() {
		this.gridStore.destroy();
	}
}

export const DatasourceSelectionConfig = observer(class InnerModal extends React.Component<DatasourceSelectionConfigProps> {
	store: DatasourceSelectionConfigStore;
	constructor(props: DatasourceSelectionConfigProps) {
		super(props);
		this.store = new DatasourceSelectionConfigStore(props);
	}

	async componentDidMount() {
		await this.store.init()
	}

	componentWillUnmount() {
		this.store.destroy();
	}

	render() {
		if (!this.store.gridStore?.selfInitialized) {
			return null;
		}
		return <Section appearance="none" height={"fit"}>
			<Toolbar title={this.store.title}>
				{this.store.selectionMode == SelectionMode.Columns && <FormEntryNew model={this.store} modelField={'allChecked'} position={ToolbarItemPosition.AFTER_TITLE}>
					<AntCheckbox indeterminate={this.store.indeterminate}>{this.store.allChecked ? i18n('Deselect all') : i18n('Select all')}</AntCheckbox>
				</FormEntryNew>}
				<AntButton type={'primary'} onClick={this.store.onUpdate} disabled={!this.store.atLeastOneSelected}>{i18n('Update')}</AntButton>
			</Toolbar>
			{!!this.store.infoMessage && <BoxView type={'info-2'}>{this.store.infoMessage}</BoxView>}
			<Grid store={this.store.gridStore}/>
		</Section>
	}
})
