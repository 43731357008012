import './dropDownList.less'

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {i} from 'core/localization/localization';
import {Utils} from 'tools/utils';
import {sortBy} from "lodash";

export function nameIdDataSource(){
	return {
		dataTextField: "name",
		dataValueField: "id"
	}
}

export class DropDownList extends React.PureComponent {
	constructor(props) {
		super(props);

		this.selectRef = React.createRef();
		this.wrapperRef = this.props.wrapperRef || React.createRef();
		this.state = {width: props.width ?? '100px'};
	}

	componentDidMount() {
		//dropdown uses field named optionLabel for storing placeholder
		//but combobox uses placeholder
		//so here we combine values to provide uniform interface
		let placeholder = this.props.placeholder;
		let optionLabel = this.props.optionLabel;

		if (this.props.showSelectText && !placeholder) {
			placeholder = i('Select...');
		}

		if (placeholder && !optionLabel) {
			optionLabel = placeholder;
		}

		const defaultConfig = {
			dataSource: this.props.dataSource,
			enable: !this.props.disabled,
			autoBind: this.props.autoBind !== false,
			suggest: this.props.suggest === true,
			placeholder: placeholder,
			optionLabel: optionLabel
		};

		if (!this.props.plainArray) {
			defaultConfig.dataTextField = this.props.dataTextField || 'text';
			defaultConfig.dataValueField = this.props.dataValueField || 'value';
		}

		const config = Object.assign({}, defaultConfig, this.props.config);

		config.value = this.getValue();
		config.clearButton = false;
		if (!config.change) {
			config.change = e => {
				let selectedValue = e.sender.value();
				let text = e.sender.text();
				if (this.kendoRef.selectedIndex === -1) {
					this.kendoRef.value(null);
				} else {
					this.props.onChange && this.props.onChange(selectedValue, e);
					this.props.onInfoChanged && this.props.onInfoChanged({text: text, value: selectedValue});
				}
			}
		}

		if (!config.dataBound) {
			config.dataBound = e => {
				if( this.props.onDataBound){
					this.props.onDataBound(e, JSON.parse(JSON.stringify(e.sender.dataSource.data())));
				}
				this.fillValue();
			};
		}

		if (this.props.combo) {
			this.kendoRef = $(this.selectRef.current).kendoComboBox(config).data("kendoComboBox");
		} else {
			this.kendoRef = $(this.selectRef.current).kendoDropDownList(config).data("kendoDropDownList");
		}

		if (this.props.onSelect) {
			this.kendoRef.bind('select', this.props.onSelect);
		}

		if (this.props.kendoRef) {
			this.props.kendoRef(this.kendoRef);
		}

		 this.kendoRef.enable(!this.props.disabled)
		if (this.props.disabled && this.props.clickable !== false || this.props.clickable === true) {
			this.makeNameClickable();
		}

		if(this.props.title) {
			this.kendoRef.wrapper.attr('title', this.props.title);
		}
		this.fillValue();
	}

	getValue(){
		let value = this.props.value;
		if( this.props.index != null ) {
			value = this.props.config.dataSource[this.props.index].value;
		}

		return value;
	}

	componentDidUpdate(prevProps) {
		if (!this.kendoRef)
			return;

		if (prevProps.width !== this.props.width) {
			this.setState({width: this.props.width ?? '100px'});
		}
		if (prevProps.onSelect !== this.props.onSelect) {
			if (prevProps.onSelect) {
				this.kendoRef.unbind('select', prevProps.onSelect);
			}
			if (this.props.onSelect) {
				this.kendoRef.bind('select', this.props.onSelect);
			}
		}

		if(prevProps.title != this.props.title){
			this.kendoRef.wrapper.attr('title', this.props.title);
		}

		if (this.props.isFromAccount) {
			this.kendoRef.setDataSource(this.props.dataSource);
			this.kendoRef.refresh();
		} else {
			if (prevProps.dataSource != this.props.dataSource) {
				this.kendoRef.setDataSource(this.props.dataSource);
				this.kendoRef.refresh();
			}
		}
		this.fillValue();
		this.kendoRef.enable(!this.props.disabled);
	}

	fillValue = () => {
		if (!this.props.value || !this.kendoRef?.dataSource) {
			return;
		}

		const fields = this.kendoRef.dataSource.options.fields?.map(x => x.field).filter(x => !!x) ?? ['id', 'value'];
		const predicate = (obj) => fields.some(x => obj[x] == this.props.value);
		let data = Array.from(this.kendoRef.dataSource.data() ?? []);
		if (!!this.kendoRef.dataSource.options.sort) {
			data = sortBy(data, x => x[this.kendoRef.dataSource.options.sort.field].toUpperCase());
			if (this.kendoRef.dataSource.options.sort.dir != 'asc') {
				data = data.reverse();
			}
		}

		const valueIndex = data.findIndex(predicate)
		const optionLabelExists = this.kendoRef?.optionLabel?.length > 0
		if (valueIndex >= 0) {
			// Quote from kendo documentation:
			// The numeric argument indicates the item index in the dropdown, not in the dataSource.
			// If an optionLabel is used, the dropdown item index can be obtained by incrementing the respective dataSource item index by 1.
			this.kendoRef?.select(valueIndex + optionLabelExists)
		}
	}

	onAdd = (e) => {
		e.stopPropagation();

		this.props.onAdd();
	}

	makeNameClickable() {
		Utils.setClickableNameListener(this.props.type, this.kendoRef);
	}

	render() {
		const {showAdd, containerClass} = this.props;
		const invalid = this.props.invalid ? 'invalid' : '';
		return (
			<div className={classnames('drop-down-list', containerClass, invalid)} ref={this.wrapperRef} style={this.props.style}>
				<select id={this.props.id} ref={this.selectRef} style={{width: this.state.width}}/>
				{showAdd && <div className="glyphicons plus-sign pointer" onClick={this.onAdd}/>}
			</div>
		);
	}
}

export const propTypes = {
	containerClass: PropTypes.string,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onSelect: PropTypes.func,
	onAdd: PropTypes.func,
	onDataBound: PropTypes.func,
	showAdd: PropTypes.bool,
	dataSource: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	title: PropTypes.string,
	dataTextField: PropTypes.string,
	dataValueField: PropTypes.string,
	showSelectText: PropTypes.bool,
	config: PropTypes.object,
	combo: PropTypes.bool,
	suggest: PropTypes.bool,
	autoBind: PropTypes.bool,
	placeholder: PropTypes.string,
	invalid: PropTypes.bool,
	optionLabel: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.string
	]),
	plainArray: PropTypes.bool,
	width: PropTypes.string
};

DropDownList.propTypes = propTypes;

export default DropDownList;
