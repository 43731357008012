import './gridHeaderFilter.less'

import React from "react"
import {observer} from "mobx-react"
import {makeAutoObservable, makeObservable, observable} from "mobx"
import {FilterFilled} from "@ant-design/icons"


import {GridColumn} from "controls/grid/gridColumnConfig"
import {FormEntry} from "controls/react/form"
import {Section} from "controls/react/layout/section"
import {Toolbar} from "controls/react/layout/toolbar"
import {AntButton} from "controls/react/ant/antButton"
import {AntPopover} from "controls/react/ant/antPopover"
import {GridDataEntry} from "controls/grid/gridDataEntry"
import {RuleDefinition} from "controls/queryBuilder/ruleDefinition"
import {QueryBuilderRuleOperator, QueryBuilderRuleValue} from 'controls/queryBuilder/queryBuilder'
import {copyViaSerializr} from "framework/serializr-integration";
import {MobxManager} from "framework/mobx-integration";


const i = require('core/localization/localization').translator({
	'Custom filtering applied message': {
		en: 'It looks like custom filtering is applied in view settings so we cant show a simple filter editor for the column.'
	}
});

type GridHeaderFilterProps<DataItem extends GridDataEntry> = {
	column: GridColumn<DataItem>
}

const b = require('b_').with('grid-header-filter')

class GridHeaderFilterStore{
	constructor(public operator: string, public value: string|string[]) {
		makeAutoObservable(this)
	}
}

type GridHeaderFilterState = {
	visible: boolean
}

export const GridHeaderFilter = observer(
	class GridHeaderFilterInner<DataItem extends GridDataEntry> extends React.Component<GridHeaderFilterProps<DataItem>, GridHeaderFilterState>{

		constructor(props: GridHeaderFilterProps<DataItem>) {
			super(props);

			this.state = {
				visible: false
			}
		}

		render() {
			if (!this.props.column.filteringEnabled)
				return null

			return <div onClick={this.toggleVisibility}>
				<AntPopover open={this.state.visible}
				            trigger={"click"}
				            content={<GridHeaderFilterContent column={this.props.column} hidePopup={this.hidePopup}/>}
				            destroyTooltipOnHide={true}
				            onOpenChange={newVisibile => this.setState({visible: newVisibile})}
				            placement={"bottom"}>
					<FilterFilled/>
				</AntPopover>
			</div>
		}

		toggleVisibility = (e: React.MouseEvent) => {
			this.setState({visible: !this.state.visible})

			e.preventDefault()
			e.stopPropagation()
		}

		hidePopup = () => {
			this.setState({visible: false})
		}
})

type GridHeaderFilterContentProps<DataEntry extends GridDataEntry> = GridHeaderFilterProps<DataEntry> & {hidePopup: () => void}

const GridHeaderFilterContent = observer(
	class GridHeaderFilterContentInner<DataEntry extends GridDataEntry> extends React.Component<GridHeaderFilterContentProps<DataEntry>>{
		filterCopy: RuleDefinition

		constructor(props: GridHeaderFilterContentProps<DataEntry>) {
			super(props)

			if(this.props.column.store.customFiltering)
				return

			if(this.props.column.filter)
				this.filterCopy = copyViaSerializr(this.props.column.filter)
			else
			{
				this.filterCopy = RuleDefinition.emptyRule(
					this.props.column.field,
					this.filterDescription.operators[0].value)
			}
		}

		get filterDescription(){
			return this.props.column.filterDescription
		}

		render(){
			if(this.props.column.store.customFiltering){
				return <Section className={b()}>
					<p>{i('Custom filtering applied message')}</p>
				</Section>
			}
			return <Section className={b()} childrenPadding={true} onClick={this.preventPropagation}>
				<FormEntry label={i("Operator")} vertical={true} model={this.filterCopy.properties} modelField={"operator"}>
					<QueryBuilderRuleOperator rule={this.filterCopy}
					                          config={this.props.column.store.filtersConfigurationEffective}/>
				</FormEntry>
				<FormEntry label={i("Value")} vertical={true}>
					<QueryBuilderRuleValue rule={this.filterCopy}
					                       config={this.props.column.store.filtersConfigurationEffective}
					                       onPressEnter={this.updateFilter}
					                       onPressEsc={this.props.hidePopup}
					/>
				</FormEntry>
				<Toolbar appearance={"transparent"}>
					<AntButton onClick={this.clearFilter}>{i('Reset')}</AntButton>
					<AntButton type={"primary"} onClick={this.updateFilter}>{i('Ok')}</AntButton>
				</Toolbar>
			</Section>
		}

		preventPropagation = (e: React.MouseEvent) => {
			e.stopPropagation()
		}

		updateFilter = () => {
			this.props.column.updateFilter(this.filterCopy)
			this.props.hidePopup()
		}

		clearFilter = () => {
			this.props.column.clearFilter()
			this.props.hidePopup()
		}
	}
)

class GridHeaderInlineFilterStore<T> {
	filter: RuleDefinition
	mobx = new MobxManager()
	constructor(private props: GridHeaderFilterProps<T>) {
		makeAutoObservable(this);
		this.init();
	}

	private init() {
		if(this.props.column.filter) {
			this.filter = this.props.column.filter
		} else {
			this.filter = RuleDefinition.emptyRule(
				this.props.column.field,
				this.props.column.filterDescription.operators[0].value)

			this.props.column.updateFilter(this.filter)
			this.mobx.reaction(() => this.filter.valid, (value) => {
				this.props.column.updateFilter(this.filter)
			})
		}

		this.mobx.reaction(() => this.props.column.filter, (value) => {
			if(!value) {
				this.init();
			}
		})
	}

	destroy() {
		this.mobx.destroy();
	}
}

export const GridHeaderInlineFilter = observer(
	class GridHeaderFilterContentInner<DataEntry extends GridDataEntry> extends React.Component<GridHeaderFilterProps<DataEntry>> {
		store: GridHeaderInlineFilterStore<DataEntry>;

		constructor(props: GridHeaderFilterProps<DataEntry>) {
			super(props)
			this.store = new GridHeaderInlineFilterStore<DataEntry>(props);
		}

		render(){
			return <QueryBuilderRuleValue rule={this.store.filter}
			                              config={this.props.column.store.filtersConfigurationEffective}
			                              forceOneLineLayout={true}
			                              bounced={1000}
			/>
		}

		componentWillUnmount() {
			this.store.destroy();
		}
	}
)
export const GridHeaderInlineFilter2 = observer(<DataEntry extends GridDataEntry>(props: GridHeaderFilterProps<DataEntry>) => {
	const filterCopy = RuleDefinition.emptyRule(
		props.column.field,
		props.column.filterDescription.operators[0].value)

	return <QueryBuilderRuleValue rule={filterCopy}
	                              config={props.column.store.filtersConfigurationEffective}
	/>
})
