import {observer} from "mobx-react";
import React from "react";

import FormEntry from "controls/react/form/formEntry";
import {AntCheckbox} from "controls/react/ant/antCheckbox";
import {AntInputNumber} from "controls/react/ant/antInputNumber";
import {linkModel} from "framework/mobx-integration";


import {sharedLocalization} from "vendor-init/tinymce/plugins/localization";

const i = require('core/localization').translator({
	'Same as report period': {},
	'Samples (lastN)': {}
}, sharedLocalization);


type PeriodEditorProps = {
	config: {
		selector: string
	};
}

export const PeriodEditor = observer((props: PeriodEditorProps) => {
	const onChange = (value: boolean) => {
		if (props.config.selector == 'period')
			props.config.selector = "5";
		else
			props.config.selector = 'period';
	}

	return <>
		<FormEntry label={i('Period')}>
			<AntCheckbox value={props.config.selector == 'period'} onChange={onChange}>
				{i('Same as report period')}
			</AntCheckbox>
		</FormEntry>

		{props.config.selector != 'period' &&
			<FormEntry label={i('Samples (lastN)')}>
				<AntInputNumber {...linkModel(props.config, "selector")}/>
			</FormEntry>
		}
	</>
})
