import {DataList} from "framework/entities/dataList";
import {designerLocalizations} from "areas/dashboards/graph-editor-extensions/designerLocalizations";

const i18n = require('core/localization/localization').translator({
	"None": {
		"no": "Ingen"
	},
	"Show Currency Text": {
		"no": "Vis Valuta tekst",
	},
	"Show Currency Symbol": {
		"no": "Vis Valuta symbol"
	},
	"Show Currency Code": {
		"no": "Vis Valuta kode",
	},
}, designerLocalizations);

export enum ShowCurrencyMode {
	None = 'None',
	Text = 'Text',
	Symbol = 'Symbol',
	Code = 'Code'
}

let showCurrenciesDataList: DataList<string> = null;

export function getShowCurrencyDataList() {
	if (showCurrenciesDataList == null) {
		showCurrenciesDataList = [{
			name: i18n('None'),
			id: ShowCurrencyMode.None
		}, {
			name: i18n('Show Currency Text'),
			id: ShowCurrencyMode.Text
		}, {
			name: i18n('Show Currency Symbol'),
			id: ShowCurrencyMode.Symbol
		}, {
			name: i18n('Show Currency Code'),
			id: ShowCurrencyMode.Code
		}];
	}

	return showCurrenciesDataList;
}
