import './aiQueryWidgetChat.less'

import {observer} from "mobx-react"
import React from "react"
import {SendOutlined} from '@ant-design/icons'

import {AntModal, ModalPosition} from "controls/react/ant/antModal";
import {Section, Toolbar, ToolbarItemPosition} from "controls/react/layout";
import {sharedLocalization} from "controls/designer/features/widgets/localization";
import {AntButton} from "controls/react/ant/antButton";
import {ChatHistory} from "controls/chat/chatHistory";
import {AiChatEngine} from "controls/chat/aiChatEngine";
import {htmlToText} from "tools/helpers/dom";
import {AntTextArea} from "controls/react/ant/antInput";
import {AntDivider} from "controls/react/ant/antDivider";
import {AntCheckbox} from "controls/react/ant/antCheckbox";

const i18n = require('core/localization').translator({
  "Set last response as Text output": {
    "no": "Sett sist svar som Tekst"
  }
}, sharedLocalization);

type AiQueryWidgetChatProps = {
	onClose: () => void
	height?: number
	width?: number
	top?: number
	left?: number
	query: string
	title: string
	onSetOutput?: (text: string) => void
}

const b = require('b_').with('ai-query-widget-chat');

export const AiQueryWidgetChat = observer((props: AiQueryWidgetChatProps) => {
	const [chatEngine] = React.useState(() => new AiChatEngine() );
	const [text, setText] = React.useState('')

	React.useEffect(() => {
		chatEngine.send(htmlToText(props.query))
	},[])

	const onSetOutput = React.useCallback(() => {
		props.onSetOutput(replaceNewLinesWithBr(chatEngine.messages[chatEngine.messages.length - 1].text))
		props.onClose()
	}, [chatEngine.messages.length])

	const positioning = React.useMemo(() => {
		if(props.top != null && props.left != null){
			return {
				style: {
					top: props.top,
					left: props.left
				},
				positionType: ModalPosition.Custom
			}
		}

		return {
			positionType: ModalPosition.Centered
		}
	}, [props.top, props.left])

	return <AntModal footer={null}
	                 width={props.width ?? 600}
	                 height={props.height ?? 700}
	                 open={true}
	                 resizable={true}
	                 {...positioning}
	                 onCancel={props.onClose}>
		<Section title={props.title} appearance={"none"} height={"fit"}>
			<ChatHistory chatEngine={chatEngine}/>

			<Toolbar>
				{props.onSetOutput &&
					<AntButton loading={chatEngine.sending}
					           onClick={onSetOutput}
					           type={"primary"}>{i18n('Set last response as Text output')}
					</AntButton>
				}
			</Toolbar>

			<Section appearance={"none"}
			         contentPadding={true}
			         className={b('message-section')}
			         childrenPadding={true}>

				<AntButton icon={<SendOutlined />}
				           className={b('send-button')}
				           title={i18n('Generate')}
				           disabled={chatEngine.sending}
				           onClick={() => chatEngine.send(text, true)}
				           type={"primary"} />
				<AntTextArea rows={8} value={text} onChange={setText}/>
			</Section>
		</Section>
	</AntModal>
})

function replaceNewLinesWithBr(text: string) {
	if (!text)
		return text

	return text.split("\n").join("<br />");
}
